.is-support-store {
    .area-group {
        margin-bottom: 30px;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 50px;
        }


        .l-list {
            border-top: 1px solid $color-border-frame;
            li {
                line-height: 1.5;
                border-bottom: 1px solid $color-border-frame;
                padding: 15px 0;
                @media(min-width:$screen-sm-min) {
                    display: flex;
                    padding: 20px 0;
                }
                .l-name {
                    position: relative;
                    @media(min-width:$screen-sm-min) {
                        width: 60%;
                        padding-left: 158px;
                        padding-right: 20px;
                        padding-top: 10px;
                    }
                    a {
                        font-size: 1.4rem;
                        color: $color-text-hover;
                        @media(min-width:$screen-sm-min) {
                            font-size: 1.8rem;
                        }
                    }

                    .premium-link {
                        @media(min-width:$screen-md-min) {
                            transition: all .2s;
                            &:hover{
                                opacity:0.8;
                            }
                        }
                    }


                }
                .address,
                .department {
                    font-size: 1.4rem;
                    color: #5C5C5C;
                    padding-top: 5px;
                    @media(min-width:$screen-sm-min) {
                        padding-top: 0;
                        width: 40%;
                        padding-right: 20px;
                        font-size: 1.6rem;
                    }
                }
            }

            &.store {
                li {
                    @media(min-width:$screen-sm-min) {
                        justify-content: space-between;
                    }
                    .l-name {
                        @media(min-width:$screen-sm-min) {
                            width: 30%;
                            padding-left: 0;
                        }
                    }

                    .department {
                        @media(min-width:$screen-sm-min) {
                            padding-top: 10px;
                            width: 20%;
                            padding-right: 0;
                        }
                    }


                    .address {
                        @media(min-width:$screen-sm-min) {
                            width: 50%;
                            padding-right: 0;
                        }
                    }



                }
            }
        }
    }
    .l-agent-greeting {
        @media(min-width:$screen-sm-min) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        .content {
            margin-bottom: 20px;
            @media(min-width:$screen-sm-min) {
                width: 30%;
            }
            &:last-child {
                margin-bottom: 0;
                @media(min-width:$screen-sm-min) {
                    width: 68%;
                }
            }

            &:only-child {
                width: 100%;
            }
            .img {
                margin-left: auto;
                margin-right: auto;
                @media(min-width:$screen-sm-min) {
                    margin-right: 0;
                    max-width: 380px;
                }
                img {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    height: auto;
                    width: 100%;
                    max-width: none;
                    @media(min-width:$screen-sm-min) {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .l-agent-data-list {
        border-style: solid;
        border-color: $color-border-frame;
        border-top-width: 1px;
        .l-block {
            display: flex;
            font-size: 1.4rem;
            color: $color-text;
            line-height: 1.5;
            border-style: solid;
            border-color: $color-border-frame;
            border-bottom-width: 1px;
            padding: 15px 2px;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
                padding: 25px 2px;
            }
            dt {
                width: 32%;
                font-weight: bold;
                @media(min-width:$screen-sm-min) {
                    width: 15.455%;
                }
            }

            dd {
                width: 68%;
                @media(min-width:$screen-sm-min) {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 84.545%;


                }
                .icon.premium {
                    position: static;
                    margin-top: 5px;
                    @media(min-width:$screen-sm-min) {
                        margin-top: 0;
                        margin-left: 20px;
                    }
                }
                a {
                    color: $color-blue;
                }

                .hover-img {
                    display: block;
                    @media(min-width:$screen-sm-min) {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .l-agent-gallery {
        @media(min-width:$screen-sm-min) {
            display: flex;
            flex-wrap: wrap;
        }
        li {
            margin-bottom: 15px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 0;
                width: 32%;
                margin-right: 2%;
                margin-bottom: 2%;
            }
            @media(min-width:$screen-lg-min) {
                width: 350px;
                /* height: 248px; */
                margin-right: 25px;
                margin-bottom: 25px;

            }

            .img {
                overflow: hidden;
                position: relative;
                width: 100%;
                padding-top: 70.86%;
                display: block;
                img {
                    width: auto;
                    height: auto;
                    min-width: 100%;
                    min-height: 100%;
                    max-width: inherit;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                }

            }

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }
            &:nth-child(3n) {
                @media(min-width:$screen-sm-min) {
                    margin-right: 0;
                }
            }


            img {
                max-width: none;
                width: 100%;
            }

        }
        img {
            height: auto;
        }
    }

    .store-img {
        max-width: 600px;
    }
}

.wrap-content {
	display: block;
	width: 100%;
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
    position: relative;
	padding-right: 15px;
	padding-left: 15px;
	@media(min-width:$screen-lg-min) {
		padding-right: 0;
		padding-left: 0;
	}
    &.middle {
		max-width: 1000px;
	}
	&.small {
		max-width: 880px;
	}
}

.wrap-btn {
	text-align: center;
	padding: 40px 0 60px;
	@media(min-width:$screen-sm-min) {
		padding: 50px 0 125px;
	}
	a,input {
		margin: auto;
	}
}

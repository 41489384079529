.l-section {
    margin-bottom: 40px;
    @media(min-width:$screen-sm-min) {
        margin-bottom: 80px;
    }
    &:last-child {
        /* margin-bottom: 0; */
    }
}

.anchor {
    padding-top: 160px;
    margin-top: -160px;
}


.column-pc2-sp1 {
    width: 100%;
    max-width: 1100px;
    margin: auto;
    @media(min-width:$screen-sm-min) {
        display: flex;
        justify-content: space-between;

    }
    .column {
        padding-bottom: 20px;
        @media(min-width:$screen-sm-min) {
            width: 49%;
            padding-bottom: 0;
        }
        @media(min-width:$screen-lg-min) {
            width: 530px;
        }

        .heading-text-small {
            margin-bottom: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
}


.column-img-pc2-sp1 {

    .img {
        margin-top: 20px;
        text-align: center;
    }

    @media(min-width:$screen-md-min) {
        display: flex;
        justify-content: space-between;

        .img {
            margin-top: 0;
            text-align: left;
        }
    }
}

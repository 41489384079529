.heading-huge {
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: #EEEEEE;
    font-size: 2.2rem;
    margin-bottom: 40px;
    background-image: linear-gradient(
    	-45deg,
    	$color-bg 25%,
    	#eee 25%,
    	#eee 50%,
    	$color-bg 50%,
    	$color-bg 75%,
    	#eee 75%,
    	#eee
    );
    background-size: 6px 6px;
    background-attachment: fixed;
    position: relative;
    line-height: 1.5;
    @media(min-width:$screen-sm-min) {
        font-size: 4.0rem;
        margin-bottom: 80px;
    }
    @media(min-width:$screen-md-min) {
        margin-bottom: 100px;
    }

    .l-inner {
        min-height: 100px;
        padding: 10px 0;
        display: flex;
        align-items: center;

        @media(min-width:$screen-sm-min) {
            height: 200px;
        }
        .icon.premium {
            position: static;
            margin: 0;
            display: block;
            width: 98px !important;
            height: 40px;
            background-size: cover;
            margin-left: 10px;
            @media(min-width:$screen-sm-min) {
                margin-left: 50px;
                width: 123px !important;
                height: 50px;
            }
        }

        .hover-img {
            display: block;
        }

        .content {
            &.text {
                font-weight: bold;

                @media(min-width:$screen-sm-min) {
                    width: auto;
                }
            }
            &:only-child {
                width: 100%;
            }
        }
    }
}

.heading-border-red {
    font-size: 2.2rem;
    padding-left: 10px;
    margin-bottom: 25px;
    position: relative;
    font-weight: bold;
    line-height: 1.25;
    padding-top: 5px;
    @media(min-width:$screen-sm-min) {
        font-size: 3.6rem;
        padding-left: 25px;
        margin-bottom: 45px;
    }

    &:before {
        content: '';
        display: block;
        width: 3px;
        height: 80%;
        background-color: $color-red;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        @media(min-width:$screen-sm-min) {
            width: 6px;
            /* height: 36px; */

        }

    }
}

.heading-top-bottom-line {
    font-size: 2.4rem;
    margin-bottom: 30px;
    line-height: 1.25;
    font-weight: bold;
    border-style: solid;
    border-color: #F0F0F0;
    border-top-width: 3px;
    border-bottom-width: 3px;
    padding: 20px 0 15px;
    @media(min-width:$screen-sm-min) {
        padding: 50px 0 40px;
        font-size: 3.8rem;
        margin-bottom: 60px;
    }
}

.heading-text-largest {
    font-size: 2.4rem;
    margin-bottom: 20px;
    line-height: 1.25;
    font-weight: bold;
    @media(min-width:$screen-sm-min) {
        font-size: 3.8rem;
        margin-bottom: 30px;
    }
}


.heading-text-large {
    font-size: 2.2rem;
    line-height: 1.25;
    font-weight: bold;
    margin-bottom: 20px;
    @media(min-width:$screen-sm-min) {
        font-size: 3.0rem;
        margin-bottom: 30px;
    }
}
.heading-text-medium {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.25;
    margin-bottom: 10px;
    @media(min-width:$screen-sm-min) {
        margin-bottom: 25px;
        font-size: 2.6rem;
    }
}

.heading-text-small {
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1.25;
    margin-bottom: 20px;
    @media(min-width:$screen-sm-min) {
        font-size: 2.4rem;
    }

}


.heading-top {
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
    @media(min-width:$screen-sm-min) {
        font-size: 3.6rem;
    }
    .icon {
        position: relative;
        top: 2px;
    }
}

.heading-top-medium {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 10px;
    line-height: 1.5;
    @media(min-width:$screen-sm-min) {
        font-size: 3.0rem;
        margin-bottom: 15px;
    }
    .icon {
        width: 22px;
        height: 22px;
        vertical-align: middle;
        margin-right: 15px;
        @media(min-width:$screen-sm-min) {
            width: 42px;
            height: 42px;
            position: relative;
            margin-right: 25px;
            top: -2px;

        }

    }
}

.heading-arrow-link {
    position: relative;
    margin-bottom: 20px;
    @media(min-width:$screen-sm-min) {
        margin-bottom: 40px;
    }
    .title {
        text-align: center;
        font-size: 2.2rem;
        font-weight: bold;
        @media(min-width:$screen-sm-min) {
            font-size: 3.6rem;
        }
    }

    .text-link {
        text-align: right;
        display: block;
        padding-top: 15px;
        @media(min-width:$screen-sm-min) {
            padding-top: 0;
            display: inline-block;
            position: absolute;
            top: 15px;
            right: 0;

        }
        a {
            font-size: 1.2rem;
            color: $color-red;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
            }
            @media(min-width:$screen-md-min) {
                &:hover {
                    text-decoration: underline;
                }
            }


            .icon {
                width: 11px;
                height: 11px;
                @media(min-width:$screen-sm-min) {
                    margin-left: 5px;
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }
}

.heading-number {
    font-weight: bold;
    font-size: 2.2rem;
    margin-bottom: 20px;
    padding-left: 35px;
    position: relative;
    line-height: 1.25;
    @media(min-width:$screen-sm-min) {
        font-size: 3.0rem;
        margin-bottom: 35px;
        padding-left: 50px;
    }
    .m-number {
        width: 28px;
        height: 28px;
        line-height: 28px;
        font-size: 1.6rem;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        color: #fff;
        font-family: $font-number;
        font-weight: bold;
        background-color: $color-red;
        position: absolute;
        left: 0;
        top: -2px;
        @media(min-width:$screen-sm-min) {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 2rem;
            top: -4px;
        }
    }
}

.heading-daygo {
    text-align: center;
    background-color: $color-bg-yellow;
    padding: 20px 10px;
    position: relative;
    @media(min-width:$screen-sm-min) {
        padding: 32px 10px;
    }
    br {
        display: block;
        @media(min-width:$screen-lg-min) {
            display: none;
        }
    }
    &:before {
        content: '';
        display: block;
        background-image: url(../img/common/daygo-01.png);
        width: 60px;
        height: 68px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -5px;
        margin: auto;
        background-size: cover;
        @media(min-width:$screen-sm-min) {
            width: 126px;
            height: 142px;
            left: 10px;
        }
        @media(min-width:$screen-lg-min) {
            left: 110px;
        }
    }
    &:after {
        content: '';
        display: block;
        background-image: url(../img/common/daygo-02.png);
        width: 60px;
        height: 67px;
        right: -5px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background-size: cover;
        @media(min-width:$screen-sm-min) {
            width: 126px;
            height: 140px;
            right: 10px;
        }
        @media(min-width:$screen-lg-min) {
            right: 110px;
        }
    }

    span {
        display: inline-block;
        color: $color-red;
        font-size: 1.7rem;
        font-weight: bold;
        line-height: 1.25;
        @media(min-width:$screen-sm-min) {
            font-size: 2.4rem;
        }
    }
}

.is-corporate-disclosure {
    .year-list {
        background-color: $color-bg;
        border-radius: 4px;
        display: flex;
        width: 100%;
        padding: 20px 0;
        margin-top: 25px;
        @media(min-width:$screen-sm-min) {
            padding: 25px 0;
            margin-top: 40px;
        }
        li {
            border-right: 2px solid $color-border-frame;
            border-radius: 2px;
            text-align: center;
            width: 20%;
            padding: 3px 0;
            @media(min-width:$screen-sm-min) {
                width: 150px;
                padding: 6px 0;
            }
            &:last-child {
                border-right: none;
            }
        }
        a {
            color: $color-text;
            font-size: 1.6rem;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
            }
        }


    }

    .list-pdf {
        li {
            display: flex;
            gap: 20px;
            flex-direction: column;
            justify-content: space-between;

            .add {
                display: flex;
                flex-direction: column;
                gap: 20px;
                text-align: right;

                a {
                    margin-left: auto;
                }
            }

            @media(min-width:$screen-sm-min) {
                flex-direction: row;
            }
        }
    }

    .column-layout {
        @media(min-width:$screen-sm-min) {
            display: flex;
            justify-content: space-between;
        }
        .cell {
            @media(min-width:$screen-sm-min) {
                width: 48.183%;
            }
        }
        .group {
            padding-top: 30px;
            @media(min-width:$screen-sm-min) {
                padding-top: 50px;
            }
        }
    }




}

.table-01 {
    width: 100%;
    border: 1px solid $color-border-frame;
    font-size: 1.3rem;
    line-height: 1.5;
    @media(min-width:$screen-sm-min) {
        font-size: 1.8rem;
    }
    &.double-header {
        thead {
            border-bottom: 1px solid $color-border-frame;
            th {
                background-color: $color-bg-yellow;
                text-align: center;
            }
        }
        tbody {
            th {
                background-color: $color-bg;
            }
            td {
                text-align: center;
                border-right: 1px solid $color-border-frame;
                &:last-child {
                    border-right: none;
                }
            }

        }

    }

    &.fixed {
        table-layout:fixed !important;
    }
    tr {
        border-bottom: 1px solid $color-border-frame;
        &:last-child {
            border-bottom: none;
        }
    }
    th {
        background-color: $color-bg-yellow;
        font-weight: bold;
        padding: 15px;
        width: 35.5%;
        border-right: 1px solid $color-border-frame;
        vertical-align: top;
        @media(min-width:$screen-sm-min) {
            padding: 30px;
            width: 25.5%;
        }
    }
    td {
        padding: 15px;
        background-color: #fff;
        @media(min-width:$screen-sm-min) {
            padding: 30px;
        }

        .text {
            padding-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                padding-bottom: 20px;
            }
        }
        .text2 {
            color: #5C5C5C;
            font-size: 1.2rem;
            padding-bottom: 10px;
            margin-top: -5px;
            @media(min-width:$screen-sm-min) {
                margin-top: -10px;
                font-size: 1.6rem;
                padding-bottom: 20px;
            }
        }

        .gray {
            background-color: $color-bg;
            padding: 15px;
            margin-bottom: 15px;
            color: $color-text;
            @media(min-width:$screen-sm-min) {
                padding: 20px;
                margin-bottom: 20px;
            }
        }

        .list {
            padding-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                padding-bottom: 20px;
            }
            li {
                padding-bottom: 5px;
                position: relative;
                padding-left: 22px;
                @media(min-width:$screen-sm-min) {
                    padding-left: 35px;
                }
                &:before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    top: 6px;
                    left: 10px;
                    background-color: $color-text;
                    border-radius: 50%;
                    position: absolute;
                    @media(min-width:$screen-sm-min) {
                        width: 6px;
                        height: 6px;
                        top: 8px;
                        left: 20px;
                    }
                }
            }
        }

        .text:last-child,
        .text2:last-child,
        .list:last-child {
            padding-bottom: 0;
        }
    }

    .inner-layout {
        @media(min-width:$screen-sm-min) {
            display: flex;
            justify-content: space-between;

        }
        .left {
            @media(min-width:$screen-sm-min) {
                width: 72%;
            }
            @media(min-width:$screen-lg-min) {
                width: 550px;
            }
        }
        .right {
            width: 80%;
            margin: 5px auto 0;
            @media(min-width:$screen-sm-min) {
                margin: 0;
                width: 23%;
            }
            @media(min-width:$screen-lg-min) {
                width: 180px;
            }



        }


    }

}

.table-02 {
    overflow: scroll;
    border-left: 1px solid $color-border-frame;

    @media(min-width:$screen-sm-min) {
        overflow: inherit;
    }

    table {
        border-collapse: separate;
        width: 800px;
        border-top: 1px solid $color-border-frame;
        line-height: 1.5;
        font-size: 1.4rem;

        @media(min-width:$screen-sm-min) {
            width: 100%;
        }

        @media(min-width:$screen-md-min) {
            font-size: 1.8rem;
        }
    }

    thead {

        th {
            border-bottom: 1px solid $color-border-frame;
            border-right: 1px solid $color-border-frame;
            vertical-align: middle;
            text-align: center;
            padding: 15px 0;
            background-color: $color-bg-yellow;

            span {
                color: $color-text-hover;
            }

            &.sticky {
                position: sticky;
                width: 20%;
                left: 0;
                z-index: 1;
                -webkit-overflow-scrolling: touch;
            }

            @media(min-width:$screen-sm-min) {

                &.sticky {
                    position: static;
                    width: 25%;
                }
            }
        }

        .supplement {
            text-align: left;
            font-size: 1.4rem;
            padding-left: 10px;
            padding-right: 10px;
            @media(min-width:$screen-sm-min) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    tbody {

        th, td {
            border-bottom: 1px solid $color-border-frame;
            border-right: 1px solid $color-border-frame;
            vertical-align: middle;
        }

        th {
            background-color: $color-bg;
            position: sticky;
            padding: 10px;
            @media(min-width:$screen-sm-min) {
                padding: 15px;
            }

            &:nth-of-type(1) {
                /* word-break: keep-all; */
                width: 84px;
                @media(min-width:$screen-sm-min) {
                    width: 25px;
                }
                text-align: center;
                left: 0;
                z-index: 1;
            }

            /* spのみ */
            &.number {
                @media(max-width:$screen-sm-min) {
                    font-size: 1.2rem;
                    width: 60px;
                }
            }
            &.number-title {
                @media(max-width:$screen-sm-min) {
                    left: 44px !important;

                }
            }



            &:nth-of-type(2) {
                width: 155px;
                padding: 20px 10px;
                left: 56px;
                z-index: 2;
            }

            span {
                color: $color-text-hover;
            }


            @media(min-width:$screen-sm-min) {

                position: static;

                &:nth-of-type(1) {
                    width: 90px;
                }

                &:nth-of-type(2) {
                    width: 330px;
                }
            }
        }

        td {
            padding: 20px;
            text-align: center;

            &:nth-of-type(1) {
                text-align: left;
                /* width: 140px; */
            }

            &.double-circle {
                font-size: 2rem;
            }

            @media(min-width:$screen-sm-min) {

                &:nth-of-type(1) {
                    /* width: 170px; */
                }
            }
        }
    }
}

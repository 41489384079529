.is-shanaiho {
    .year-list {
        background-color: $color-bg;
        border-radius: 4px;
        display: flex;
        width: 100%;
        padding: 20px 0;
        margin-top: 25px;
        @media(min-width:$screen-sm-min) {
            padding: 25px 0;
            margin-top: 40px;
        }
        li {
            border-right: 2px solid $color-border-frame;
            border-radius: 2px;
            text-align: center;
            width: 20%;
            padding: 3px 0;
            @media(min-width:$screen-sm-min) {
                width: 150px;
                padding: 6px 0;
            }
            &:last-child {
                border-right: none;
            }
        }
        a {
            color: $color-text;
            font-size: 1.6rem;
            @media(min-width:$screen-sm-min) {
                font-size: 18px;
            }
        }


    }


    .shnaiho-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .shanaiho-content {
            margin-bottom: 40px;
            @media(min-width:$screen-sm-min) {
                width: 49%;
                margin-bottom: 50px;
            }
            @media(min-width:$screen-lg-min) {
                width: 530px;
            }

            .shanaiho-title {
                font-size: 18px;
                margin-bottom: 15px;

                @media(min-width:$screen-sm-min) {
                    margin-bottom: 20px;
                    font-size: 2.4rem;
                }
            }

            .shanaiho-info {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                @media(min-width:$screen-sm-min) {
                    margin-bottom: -40px;
                }
                .pdf-shanaiho-link-area {
                    width: 25%;
                    margin: 0 auto;

                    a {
                        display: block;
                        font-size: 1.6rem;
                        color: #275eac;
                        line-height: 1.25;

                        img {
                            background-color: #f7f7f7;
                        }
                        figcaption {
                            margin-top: 5px;
                        }
                        .icon {
                            width: 16px;
                            height: 16px;
                            vertical-align: middle;
                        }
                    }

                }
                .pdf-info {
                    padding-left: 20px;
                    width: 75%;
                    box-sizing: border-box;

                    .shanaiho-topics-title {
                        font-size: 18px;
                        margin-bottom: 15px;

                        @media(min-width:$screen-sm-min) {
                            font-size: 2.4rem;
                        }
                    }

                    hr {
                        border: 0;
                        border-top: 1px dashed #D1D1D1;
                    }

                    .topic-text {
                        word-break: break-all;
                        @media(min-width:$screen-sm-min) {
                            font-size: 1.6rem;
                        }
                    }

                    .topic-other-text {
                        word-break: break-all;
                        @media(min-width:$screen-sm-min) {
                            font-size: 1.6rem;
                        }
                    }
                }
            }
        }
    }
}

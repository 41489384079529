.center {
    text-align: center;
}

.text-box {
    width: 100%;
    font-size: 1.4rem;
    padding: 20px 15px;
    line-height: 1.75;
    border: 1px solid #d1d1d1;
}

.text-box {
    width: 100%;
    font-size: 1.4rem;
    padding: 20px 15px;
    line-height: 1.75;
    border: 1px solid $color-border-frame;
    margin: 20px auto ;
    @media(min-width:$screen-sm-min) {
        font-size: 1.8rem;
        margin: 25px auto;
        padding: 25px 30px;
    }
    .insurance-name {
        margin-right:100px;
    }
    .insurance-start-date {
        display: inline-block;
    }
}

.smartphonestep {
    width: 80%;
}

.box-red-line {
    border: 1px solid #ECADAF;
    color: $color-red-light;
    font-size: 1.4rem;
    padding: 15px;
    line-height: 1.5;
    @media(min-width:$screen-md-min) {
        font-size: 1.8rem;
        padding: 20px;
    }
}


.box-my-page {
    border: 1px solid $color-border-frame;
    border-radius: 4px;
    padding: 20px 15px;
    margin-top: 20px;
    @media(min-width:$screen-sm-min) {
        margin-top: 40px;
        padding: 30px;
    }
    h3 {
        font-size: 1.8rem;
        font-weight: bold;
        padding-bottom: 10px;
        @media(min-width:$screen-sm-min) {
            font-size: 2.6rem;
            padding-bottom: 15px;
        }
    }
    .inner {
        @media(min-width:$screen-sm-min) {
            display: flex;
            justify-content: space-between;
        }
        .text {
            font-size: 1.4rem;
            line-height: 1.5;
            font-weight: bold;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
                width: 65.438%;
            }
            @media(min-width:$screen-md-min) {
                width: 67.438%;
            }
            a {
                color: $color-text;
                text-decoration: underline;
                margin-top: 5px;
                display: inline-block;
                @media(min-width:$screen-md-min) {
                    margin-top: 10px;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .l-btn {
            padding-top: 15px;
            @media(min-width:$screen-sm-min) {
                padding-top: 0;
                width: 30.903%;
            }
            @media(min-width:$screen-md-min) {
                width: 28.903%;
            }
            .btn-square {
                @media(min-width:$screen-sm-min) {
                    padding: 40px 5px;
                    font-size: 1.6rem;
                }
                @media(min-width:$screen-md-min) {
                    padding: 40px 10px;
                    font-size: 1.8rem;
                }
                .icon {
                    @media(min-width:$screen-sm-min) {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                        vertical-align: middle;
                    }
                }
            }

        }
    }
}

.box-beginning {
    @media(min-width:$screen-sm-min) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

    }
    .left {
        @media(min-width:$screen-sm-min) {
            width: 62%;
        }
        @media(min-width:$screen-lg-min) {
            width: 684px;
        }
    }
    .right {
        margin: 30px auto 0;
        width: 70%;
        @media(min-width:$screen-sm-min) {
            margin: 0;
            width: 31%;
        }
        @media(min-width:$screen-lg-min) {
            width: 340px;
        }
    }

    &.img-big {
        .left {
            @media(min-width:$screen-sm-min) {
                width: 56.5%;
            }
            @media(min-width:$screen-lg-min) {
                width: 620px;
            }
        }
        .right {
            margin: 30px auto 0;
            width: 70%;
            @media(min-width:$screen-sm-min) {
                margin: 0;
                width: 39.5%;
            }
            @media(min-width:$screen-lg-min) {
                width: 435px;
            }
        }
    }


    .beginning-list {
        display: inline-block;
        margin-top: 15px;
        margin-bottom: -10px;
        @media(min-width:$screen-sm-min) {
            margin-top: 25px;
            margin-bottom: 0;
        }
        li {
            display: inline-block;
            color: #fff;
            text-align: center;
            background-color: $color-red-light;
            font-size: 1.4rem;
            margin-right: 5px;
            padding: 8px 5px;
            width: 100px;
            border-radius: 20px;
            margin-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 5px;
                font-size: 1.6rem;
                margin-right: 10px;
                padding: 10px 5px;
                width: 130px;
                border-radius: 25px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .text-box {
        width: 100%;
        font-size: 1.4rem;
        margin-top: 20px;
        padding: 20px 15px;
        line-height: 1.75;
        border: 1px solid $color-border-frame;
        @media(min-width:$screen-sm-min) {
            font-size: 1.8rem;
            margin-top: 50px;
            padding: 25px 30px;
        }
    }





}

.box-white {

    margin-top: 50px;
    .inner {
        background-color: #fff;
        padding: 70px;
    }
    .heading {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .wrap-btn {
        padding-bottom: 0;
        .btn-round {
            max-width: 740px;
        }
    }
}

.box-heading-yellow {
    border: 1px solid $color-border-frame;
    border-radius: 4px;
    line-height: 1.75;
    margin-bottom: 20px;
    @media(min-width:$screen-sm-min) {
        display: table;
        width: 100%;
        margin-bottom: 30px;
    }
    &:last-child {
        margin-bottom: 0;
    }
    dt {
        background-color: $color-bg-red;
        font-size: 1.6rem;
        padding: 10px;
        text-align: center;
        font-weight: bold;
        @media(min-width:$screen-sm-min) {
            display: table-cell;
            vertical-align: middle;
            font-size: 2rem;
            width: 278px;
            padding: 10px 15px;

        }
    }
    dd {
        font-size: 1.4rem;
        padding: 15px;
        @media(min-width:$screen-sm-min) {
            display: table-cell;
            vertical-align: top;
            padding: 25px;
            font-size: 1.8rem;
        }

        .indent {
            padding-left: 2em;
        }


    }
}

.box-contract-flow {
    background-color: $color-bg;
    margin-top: 20px;
    padding: 30px 15px;
    text-align: left;
    @media(min-width:$screen-sm-min) {
        margin-top: 60px;
        padding: 40px;
    }
    .heading {
        font-size: 1.8rem;
        padding-bottom: 15px;
        font-weight: bold;
        @media(min-width:$screen-sm-min) {
            font-size: 3.0rem;
            padding-bottom: 20px;
        }
    }

    .text {
        line-height: 1.75;
        font-size: 1.4rem;
        padding-bottom: 10px;
        @media(min-width:$screen-sm-min) {
            font-size: 1.8rem;
            padding-bottom: 45px;
        }
    }

    .flow-list {
        @media(min-width:$screen-sm-min) {
            display: flex;
            justify-content: space-between;
        }
        li {
            margin-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                width: 32%;
                margin-bottom: 0;
            }
            @media(min-width:$screen-lg-min) {
                width: 320px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .group {
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            @media(min-width:$screen-sm-min) {
                text-align: center;
                padding: 0;
                display: block;
                padding-top: 24px;
                height: 300px;
            }
            .step {
                font-family: $font-number;
                display: block;
                font-weight: bold;
                font-size: 1.4rem;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.8rem;
                    padding-bottom: 25px;
                }
                .number {
                    display: block;
                    font-size: 2.6rem;
                    padding-top: 2px;
                    @media(min-width:$screen-sm-min) {
                        font-size: 3.4rem;
                        padding-top: 4px;
                    }
                }
            }

            .img {
                width: 50px;
                @media(min-width:$screen-sm-min) {
                    width: 80px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            .title {
                font-size: 1.4rem;
                line-height: 1.5;
                font-weight: bold;
                width: 33%;
                @media(min-width:$screen-sm-min) {
                    margin-top: 25px;
                    font-size: 1.8rem;
                    height: 3em;
                    display: table;
                    width: 100%;
                }
                .inner {
                    @media(min-width:$screen-sm-min) {
                        display: table-cell;
                        vertical-align: middle;
                    }
                }
            }
        }
    }


    .announce-list {
        background-color: #fff;
        margin-top: 20px;
        position: relative;
        padding: 20px 10px;
        @media(min-width:$screen-sm-min) {
            display: flex;
            margin-top: 30px;
            padding: 40px 0 20px;
        }
        &:before {
            @media(min-width:$screen-sm-min) {
                content: '';
                width: 2px;
                height: 80%;
                background-color: $color-border;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
        li {
            text-align: center;
            padding-bottom: 20px;
            @media(min-width:$screen-sm-min) {
                width: 50%;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
        .text {
            font-size: 1.4rem;
            line-height: 1.5;
            padding-bottom: 10px;
            font-weight: bold;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
                padding-bottom: 30px;
            }
        }

        .btn-round {
            margin-left: auto;
            margin-right: auto;
            @media(min-width:$screen-sm-min) {
                max-width: 300px;
                font-size: 1.8rem;
                height: 54px;
                line-height: 54px;
            }
            .icon {
                fill:#fff;
            }
        }
    }

    .announce-list-2 {
        background-color: #fff;
        position: relative;
        padding: 20px 10px;
        @media(min-width:$screen-sm-min) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 0;
        }
        li {
            text-align: center;
            padding-bottom: 20px;
            @media(min-width:$screen-sm-min) {
                width: 50%;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
        .text {
            font-size: 1.4rem;
            line-height: 1.5;
            padding-bottom: 10px;
            font-weight: bold;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
                padding-bottom: 30px;
            }
        }

        .btn-round {
            margin-left: auto;
            margin-right: auto;
            @media(min-width:$screen-sm-min) {
                max-width: 300px;
                font-size: 1.8rem;
                height: 54px;
                line-height: 50px;
            }
            .icon {
                fill:#fff;
            }
        }
    }
}

.box-4charm {
    background-color: $color-bg-yellow;
    border: 1px solid $color-border-frame;
    padding: 30px 15px;
    text-align: center;
    margin-top: 40px;
    @media(min-width:$screen-sm-min) {
        margin-top: 80px;
        padding: 45px 15px;
    }
    .heading {
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 5px;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 10px;
        }
        .text1 {
            display: block;
            font-size: 2rem;
            @media(min-width:$screen-sm-min) {
                font-size: 2.6rem;
            }
        }
        .text2 {
            font-size: 2.2rem;
            @media(min-width:$screen-sm-min) {
                font-size: 3.0rem;
            }
            span {
                font-size: 2.4rem;
                color: $color-red;
                font-family: $font-number;
                @media(min-width:$screen-sm-min) {
                    font-size: 3.4rem;
                }
            }
        }
    }
    .wrap-btn {
        padding: 15px 0 0;
        max-width: 470px;
        margin-left: auto;
        margin-right: auto;
        @media(min-width:$screen-sm-min) {
            padding: 20px 0 0;
        }
    }

}

.box-pamphlet {
    background-color: $color-bg;
    margin-top: 30px;
    padding: 15px;
    @media(min-width:$screen-sm-min) {
        margin-top: 50px;
        padding: 30px;
    }
    .inner {
        background-color: #fff;
        padding: 20px 15px;
        position: relative;
        @media(min-width:$screen-sm-min) {
            padding: 50px 30px;
        }
    }
    .text {
        line-height: 1.5;
        font-size: 1.6rem;
        padding-bottom: 10px;
        font-weight: bold;
        @media(min-width:$screen-sm-min) {
            font-size: 2rem;
            padding-bottom: 25px;
        }
        &.last-child {
            padding-bottom: 0;
        }
        a {
            color: $color-text-hover;
            font-weight: normal;
        }
    }

    .pamphlet-img {
        margin-top: 20px;
        margin: 20px auto 0;
        text-align: center;
        @media(min-width:$screen-md-min) {
            margin: 0;
            position: absolute;
            top: 10px;
            right: 0;
            width: 380px;
        }
        @media(min-width:$screen-lg-min) {
            top: -10px;
            width: 489px;
        }
    }

    .pamphlet-img-recruit {
        margin-top: 20px;
        margin: 20px auto 0;
        text-align: center;
        @media(min-width:$screen-md-min) {
            margin: 0;
            position: absolute;
            top: -35px;
            right: -10px;
            width: 400px;
        }
        @media(min-width:$screen-lg-min) {
            top: -35px;
            right: -10px;
            width: 468px;
        }
    }



}

.box-gray {
    background-color: $color-bg;
    padding: 10px;
    margin-bottom: 15px;
    @media(min-width:$screen-sm-min) {
        padding: 20px;
        margin-bottom: 20px;
    }
    .inner {
        padding: 15px;
        @media(min-width:$screen-sm-min) {
            padding: 20px;
            display: flex;
            justify-content: space-between;
        }
        .group {
            background-color: #fff;
            padding: 15px;
            margin-bottom: 15px;
            @media(min-width:$screen-sm-min) {
                width: 48%;
                padding: 30px;
            }
            @media(min-width:$screen-lg-min) {
                width: 495px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .text-parts-2 {
                font-weight: bold;
                padding-bottom: 10px;
            }
            &.w-100 {
                width: 100%;
            }
        }
    }


    .text-parts-small {
        color: $color-text;
        margin-bottom: 10px;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .list-indent{
        font-size: 1.4rem;
        line-height: 1.75;
        @media(min-width:$screen-sm-min) {
            font-size: 1.6rem;
        }
        li {
            padding-bottom: 5px;
            padding-left: 2em;
            text-indent: -2em;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    .list-dot {
        font-size: 1.4rem;
        line-height: 1.75;
        @media(min-width:$screen-sm-min) {
            font-size: 1.6rem;
        }
        li {
            margin-top: 0;
            padding-bottom: 5px;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

.box-yellow {
    background-color: $color-bg-yellow;
    padding: 20px 15px;
    margin-top: 30px;
    @media(min-width:$screen-sm-min) {
        padding: 40px;
        margin-top: 60px;
    }
    .inner {
        background-color: #fff;
        padding: 20px 15px;
        @media(min-width:$screen-sm-min) {
            padding: 40px;
        }
    }
    .box-beginning {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media(max-width:$screen-sm-min) {
            flex-direction: column;
            gap: 20px;

        }

        .left {
            width: 30%;
            margin: 0;
            @media(max-width:$screen-sm-min) {
                width: 100%;
                text-align: center;
            }
        }
        .right {
            width: 68%;
            margin: 0;
            @media(max-width:$screen-sm-min) {
                width: 100%;
            }
        }
    }
}

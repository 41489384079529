.tab-1 {
	margin-bottom: 40px;
	@media(min-width:$screen-sm-min) {
		margin-bottom: 70px;
	}
	.list-menu {
		display: table;
		width: 100%;
		table-layout: fixed;
		position: relative;
		li {
			display: table-cell;
			vertical-align: middle;
			padding-right: 4px;
			position: relative;
			&:last-child {
				padding-right: 0;
			}
			&.active {
				z-index: 1;
				a {
					color: $color-red;
					background-color: $color-bg-yellow;
				}
			}
			@media(min-width:$screen-md-min) {
				&:hover {
					z-index: 1;
					a {
						color: $color-red;
						background-color: $color-bg-yellow;
					}
				}
			}

		}
		a {
			display: table;
			width: 100%;
			text-align: center;
			font-size: 13px;
			background-color: $color-bg;
			color: $color-text;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			padding: 0 10px;
			height: 75px;
			transition: all .2s;
			border-style: solid;
			border-color: $color-border-frame;
			border-top-width: 2px;
			border-left-width: 2px;
			border-right-width: 2px;
			@media(min-width:$screen-sm-min) {
				height: auto;
				line-height: 1.5;
				font-size: 18px;
				padding: 0 10px;
				height: 70px;
			}
			span {
				line-height: 1.5;
				display: table-cell;
				vertical-align: middle;
				font-weight: bold;
			}
		}
	}

	.list-area {
		border: 2px solid $color-border-frame;
		background-color: $color-bg-yellow;
		display: flex;
		flex-wrap: wrap;
		padding-top: 25px;
		padding-right: 15px;
		padding-left: 15px;
		position: relative;
		top: -3px;
		display: none;
		@media(min-width:$screen-sm-min) {
			top: -2px;
			padding-top: 50px;
			padding-right: 60px;
			padding-left: 60px;
		}
		&.active {
			display: flex;
		}
		li {
			width: 33%;
			padding-bottom: 20px;
			@media(min-width:$screen-sm-min) {
				padding-bottom: 50px;
				width: 16.66%;
			}
		}
		a {
			font-size: 1.6rem;
			color: $color-text;
			@media(min-width:$screen-sm-min) {
				font-size: 1.8rem;
			}
			.icon {
				vertical-align: middle;
				margin-left: 5px;
				width: 8px;
				height: 8px;
				transform: rotate(180deg);
				@media(min-width:$screen-sm-min) {
					margin-left: 10px;
					width: 10px;
					height: 10px;
				}
			}
		}


	}
}

.tab-2 {
	margin-bottom: 40px;
	@media(min-width:$screen-sm-min) {
		margin-bottom: 80px;
	}
	.list-menu {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		position: relative;
		border-style: solid;
		border-color: $color-border-frame;
		border-top-width: 2px;
		border-left-width: 2px;
		border-radius: 4px;
		@media(min-width:$screen-sm-min) {
			flex-wrap: nowrap;
			border-bottom-width: 2px;
		}
		li {
			position: relative;
			text-align: center;
			width: 50%;
			border-style: solid;
			border-color: $color-border-frame;
			border-bottom-width: 2px;
			border-right-width: 2px;
			@media(min-width:$screen-sm-min) {
				border-bottom: none;
				width: 100%;
			}


			&.active {
				a {
					color: $color-red;
					background-color: $color-bg-yellow;
				}
			}
			@media(min-width:$screen-md-min) {
				&:hover {
					a {
						color: $color-red;
						background-color: $color-bg-yellow;
					}
				}
			}


		}
		a {
			display: table;
			width: 100%;
			text-align: center;
			font-size: 13px;
			background-color: $color-bg;
			color: $color-text;
			padding: 0 10px;
			height: 75px;
			transition: all .2s;
			@media(min-width:$screen-sm-min) {
				height: auto;
				font-size: 16px;
				padding: 0 10px;
				height: 100px;
			}
			@media(min-width:$screen-md-min) {
				font-size: 18px;
			}
			span {
				line-height: 1.5;
				display: table-cell;
				vertical-align: middle;
				font-weight: bold;
			}
		}
	}


}

.tab-3 {
	width: 100%;
	display: table;
	table-layout:fixed;
	li {
		display: table-cell;
		vertical-align: middle;
		padding-right: 5px;
		@media(min-width:$screen-sm-min) {
			padding-right: 20px;
		}
		&:last-child {
			padding-right: 0;
		}
	}

	.link {
		display: table;
		width: 100%;
		color: $color-text;
		background-color: $color-bg;
		border-style: solid;
		border-color: $color-border-frame;
		font-size: 13px;
		height: 75px;
		border-top-width: 1px;
		border-left-width: 1px;
		border-right-width: 1px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		text-align: center;
		position: relative;
		padding-right: 5px;
		padding-left: 5px;
		@media(min-width:$screen-sm-min) {
			font-size: 2.2rem;
			height: 100px;
			border-top-width: 2px;
			border-left-width: 2px;
			border-right-width: 2px;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			padding-right: 10px;
			padding-left: 10px;
		}
		@media(min-width:$screen-md-min) {
			padding-right: 20px;
			padding-left: 20px;
			font-size: 24px;
			transition: all .2s;
			&:hover {
				color: $color-red;
				background-color: $color-bg-yellow;
			}
		}
		span {
			line-height: 1.25;
			display: table-cell;
			vertical-align: middle;
			font-weight: bold;
		}
		&.active {
			background-color: $color-bg-yellow;
			color: $color-red;
			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 4px;
				background-color: $color-bg-yellow;
				position: absolute;
				bottom: 0;
				left: 0;
				bottom: -4px;
				@media(min-width:$screen-sm-min) {
					bottom: -2px;
				}
			}

		}
	}
}

.tab-3-content {
	border-top: 1px solid $color-border-frame;
	padding-top: 30px;
	padding-bottom: 30px;
	@media(min-width:$screen-sm-min) {
		border-top: 2px solid $color-border-frame;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	&.mb {
		margin-bottom: 30px;
		@media(min-width:$screen-sm-min) {
			margin-bottom: 60px;
		}
	}

	.tab-3-group {
		background-color: #fff;
		padding: 30px 20px;
		margin-top: 20px;
		@media(min-width:$screen-sm-min) {
			padding: 80px 40px;
			margin-top: 60px;
		}

		&.mt-0 {
			margin-top: 0;
		}

	}

	.tab-3-group-b {
		background-color: #fff;
		padding: 40px 15px;
		margin-bottom: 60px;
		@media(min-width:$screen-sm-min) {
			padding: 80px 40px;
			margin-bottom: 60px;
		}

		.heading {
			text-align: center;
			font-weight: bold;
			font-size: 2.2rem;
			margin-bottom: 20px;
			@media(min-width:$screen-sm-min) {
				font-size: 3.6rem;
				margin-bottom: 35px;
			}

		}
	}
}

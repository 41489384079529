.m-link-pdf {
    padding-right: 22px;
    color: $color-text-hover;
    text-decoration: underline;
    position: relative;
    background-image: url(../img/common/post-pdf.png);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: right center;
    margin-right: 5px;
    @media(min-width:$screen-sm-min) {
        padding-right: 30px;
        background-size: 26px;
        padding-top: 10px;
    }
    @media(min-width:$screen-md-min) {
        &:hover {
            text-decoration: none;
        }
    }
}

.m-link-excel {
    padding-right: 25px;
    color: $color-text-hover;
    text-decoration: underline;
    position: relative;
    @media(min-width:$screen-sm-min) {
        padding-right: 40px;
    }
    @media(min-width:$screen-md-min) {
        &:hover {
            text-decoration: none;
        }
    }
    &:after {
        content: '';
        display: block;
        background-image: url(../icon/icon_excel.svg);
        background-repeat: no-repeat;
        position: absolute;
        top: -2px;
        right: 0;
        background-size: 18px;
        width: 20px;
        height: 20px;
        @media(min-width:$screen-sm-min) {
            top: -6px;
            background-size: 29px;
            width: 34px;
            height: 34px;
        }
    }

}

.m-link-blank {
    color: $color-text-hover;
    text-decoration: underline;
    position: relative;
    padding-right: 20px;
    background-image: url(../img/common/post-window-blue.png);
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: right center;
    margin-right: 5px;
    @media(min-width:$screen-sm-min) {
        padding-right: 28px;
        background-size: 18px;
    }
    @media(min-width:$screen-md-min) {
        &:hover {
            text-decoration: none;
        }
    }


}


.link-arrow-right {
    color: $color-red;
    display: inline-block;
    font-size: 1.2rem;
    @media(min-width:$screen-md-min) {
        &:hover {
            text-decoration: underline;
        }
    }
    .icon {
        width: 13px;
        height: 13px;
        vertical-align: middle;
        margin-left: 1px;
        position: relative;
        top: -2px;
    }
}


.link-blue {
    color: $color-text-hover;
    text-decoration: underline;
    @media(min-width:$screen-md-min) {
        &:hover {
            text-decoration: none;
        }
    }
}

.link-banner:hover {
    opacity: 0.5;
}

.l-footer {
    .l-footer-qa {
        background-color: $color-bg;
        padding: 30px 0;
        border-top: 1px solid $color-border-frame;
        @media(min-width:$screen-sm-min) {
            padding: 60px 0;
        }

        .upside{
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 80px;
            }
        }
        .heading {
            font-size: 1.8rem;
            font-weight: bold;
            width: 60%;
            @media(min-width:$screen-xs-min) {
                font-size: 2.2rem;
            }
            @media(min-width:$screen-md-min) {
                font-size: 3.0rem;
            }
        }
        .link-text {
            font-size: 1.2rem;
            @media(min-width:$screen-md-min) {
                font-size: 1.8rem;
            }

            .arrow-right {
                width: 13px;
                height: 13px;
                @media(min-width:$screen-md-min) {
                    width: 11px;
                    height: 11px;
                }
            }
            a {
                color: $color-red;
            }
        }


    }

    .l-footer-banner {
        padding: 30px 0;
        border-top: 1px solid $color-border-frame;
        @media(min-width:$screen-sm-min) {
            padding: 40px 0;
        }
        .l-list-banner {
            padding-top: 20px;
            margin-top: 20px;
            border-top: 1px solid $color-border;
            display: flex;
            justify-content:space-between;
            flex-wrap: wrap;
            @media(min-width:$screen-sm-min) {
                padding-top: 40px;
                margin-top: 40px;
                justify-content: flex-start;
            }
            li {
                width: 48%;
                margin-bottom: 4%;
                @media(min-width:$screen-sm-min) {
                    width: auto;
                    margin-right: 20px;
                    margin-bottom: 20px;
                }
                @media(min-width:$screen-lg-min) {
                    margin-bottom: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .l-list-text {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media(min-width:$screen-sm-min) {
            padding-top: 40px;
            justify-content: flex-start;
        }
        li {
            width: 47%;
            margin-bottom: 6%;
            @media(min-width:$screen-sm-min) {
                padding-left: 0.5em;
                width: auto;
                margin-right: 30px;
                margin-bottom: 3%;
            }
            @media(min-width:$screen-lg-min) {
                margin-bottom: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .link {
            color: $color-text;
            display: flex;
            font-size: 1.2rem;
            @media(min-width:$screen-sm-min) {
                font-size: 1.4rem;
            }
        }

        .icon {
            margin-left: 5px;
            vertical-align: bottom;
            @media(min-width:$screen-sm-min) {
                margin-left: 10px;
            }
        }



    }

    .l-beneath {
        background-color: $color-bg;
        padding: 30px 0 25px;
        @media(min-width:$screen-sm-min) {
            padding: 40px 0 25px;
        }
        .l-left {
            .l-list {
                @media(min-width:$screen-sm-min) {
                }
                li {
                    border-right: 1px solid $color-text;
                    display: inline-block;
                    vertical-align: middle;
                    padding-right: 10px;
                    margin-right: 10px;
                    @media(min-width:$screen-sm-min) {
                        padding-right: 18px;
                        margin-right: 18px;
                    }
                    &:last-child {
                        border-right: none;
                        padding-right: 0;
                        margin-right: 0;
                        @media(min-width:$screen-sm-min) {
                        }
                    }
                }
                a {
                    font-size: 1.3rem;
                    color: $color-text;
                    @media(min-width:$screen-sm-min) {
                        font-size: 1.6rem;
                    }

                }
            }
        }
        .l-right {
            text-align: left;
            padding-top: 25px;
            @media(min-width:$screen-sm-min) {
                padding-right: 40px;
            }
            .img {
                width: 148px;
                margin: 0;
                @media(min-width:$screen-sm-min) {
                    width: 205px;
                }
            }

            .text {
                padding-top: 25px;
                font-size: 1.3rem;
                line-height: 1.5;
                @media(min-width:$screen-sm-min) {
                    padding-top: 20px;
                    font-size: 1.6rem;
                }
            }



        }






    }

    .l-copyright {
        display: block;
        background-color: #CA101A;
        color: #fff;
        font-size: 8px;
        font-family: $font-number;
        line-height: 1.5;
        padding: 10px 0;
        font-weight: normal;
        @media(min-width:$screen-sm-min) {
            font-size: 1.4rem;
        }
        @media(max-width:$screen-sm-min) {
            font-size: 1.4rem;
            padding-bottom: 100px;
        }
    }

    .back-top {
        display: block;
        opacity: 0;
        z-index: -1;
        width: 58px;
        height: 58px;
        right: 15px;
        background-color: #fff;
        border: 1px solid $color-border-frame;
        position: fixed;
        bottom: -5%;
        border-radius: 50%;
        cursor: pointer;
        transition: all .5s;
        @media(min-width:$screen-sm-min) {
            right: 12px;
        }
        @media(min-width:$screen-md-min) {
            transition: all .2s;
            &:hover {
                background-color: #fff;
                background-color: #CA101A;
                border: 1px solid #CA101A;
                .icon {
                    fill:#fff;
                }
            }
        }

        &.is-display {
            bottom: 120px;
            opacity: 1;
            z-index: 10;
        }


        .icon {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            transition: all .2s;
            @media(min-width:$screen-sm-min) {
                width: 22px;
                height: 22px;
            }
        }
    }

    .sp-navigation {
        background-color: #F0F0F0;
        @media(min-width:$screen-md-min) {
            display: none;
        }
        .l-list {
            border-top: 1px solid $color-border;
            li {
                border-bottom: 1px solid $color-border;
                &:last-child {
                    border-bottom: none;
                }
            }
            .link {
                display: block;
                font-size: 1.4rem;
                color: $color-text;
                padding: 19px 15px;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.6rem;
                }

            }
        }
        .l-list-sub {
            border-top: 1px solid $color-border-navi;
            li {
                border-bottom: 1px solid $color-border;
            }
            .link {
                display: block;
                font-size: 1.2rem;
                color: $color-text;
                padding: 16px 15px;
            }
        }
    }
    .fixed-sub-menu {
        margin: 0;
        margin-bottom: 12px;
        display: flex;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 2;

        li {
            background: #CA101A;
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: -12px;
            border-right: solid 1px #F0F0F0;
        }
        li:first-child {
            border-radius: 4px 0 0 0;
        }
        li:last-child {
            border-radius: 0 4px 0 0;
            border-right: none;
        }
        a {
            color: #F0F0F0;
            .group {
                padding: 12px;
            }
        }
        @media (hover: hover) {
            li:hover {
                background: #AC0000;
            }
        }
    }
    @media(max-width:$screen-sm-min) {
        .fixed-sub-menu {
            width: 100%;
            li {
                width: 100%;
            }
        }
    }
}

.is-business {
    .automobile_liability_insurance-box {
        background-color: $color-bg-yellow;
        padding: 20px 15px;
        margin-top: 30px;
        @media(min-width:$screen-sm-min) {
            padding: 40px;
            margin-top: 60px;
        }
        .inner {
            background-color: #fff;
            padding: 20px 15px;
            @media(min-width:$screen-sm-min) {
                padding: 40px;
            }
        }
    }

    .automobile-list {
        li {
            font-size: 1.4rem;
            padding-left: 10px;
            line-height: 1.5;
            margin-bottom: 5px;
            position: relative;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
                padding-left: 10px;
            }
            .indent {
                display: block;
                padding-left: 1em;
                text-indent: -1em;
            }
            &:before {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $color-text;
                position: absolute;
                left: 0;
                top: 7px;
                @media(min-width:$screen-sm-min) {
                    top: 10px;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }


    .special_terms-box {
        text-align: center;
        background-color: $color-bg-yellow;
        text-align: center;
        padding: 20px 15px;
        @media(min-width:$screen-sm-min) {
            padding: 40px;
        }
        .heading {
            font-size: 2rem;
            margin-bottom: 20px;
            font-weight: bold;
            color: $color-red;
            line-height: 1.5;
            @media(min-width:$screen-sm-min) {
                font-size: 2.6rem;
                margin-bottom: 25px;
            }
        }
        .heading2 {
            font-weight: bold;
            margin-bottom: 10px;
            font-size: 1.7rem;
            line-height: 1.5;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 15px;
                font-size: 2.4rem;
            }
        }
        .text-box {
            background-color: #fff;
            padding: 10px;
        }


    }


}

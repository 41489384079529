.is-disaster {

    .disaster-box {
        margin-bottom: 20px;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
        }
        .left {
            @media(min-width:$screen-sm-min) {
                width: 700px;
            }
        }
        .right {
            width: 70%;
            margin: 20px auto 0;
            @media(min-width:$screen-sm-min) {
                margin: 0;
                width: 340px;
            }
        }
    }

    .disaster-title {
        font-size: 2.2rem;
        line-height: 1.25;
        font-weight: bold;
        margin-bottom: 20px;
        background-color: $color-green;
        color: #fff;
        padding: 5px 10px;
        @media(min-width:$screen-sm-min) {
            padding: 10px 20px;
            font-size: 3.0rem;
            margin-bottom: 30px;
        }
    }

    .disaster-question {
        margin-bottom: 25px;
        display: flex;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 30px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        label {
            border: 1px solid $color-green;
            width: 70px;
            height: 110px;
            padding: 0 0 20px;
            cursor:pointer;
            margin-right: 10px;
            background-color: #fff;
            @media(min-width:$screen-sm-min) {
                margin-right: 20px;
                height: auto;
                width: 90px;
                padding: 0 0 20px;
            }
            @media(min-width:$screen-md-min) {
                width: 70px;
                margin-right: 40px;

            }
            span {
                background-color: $color-green;
                display: inline-block;
                text-align: center;
                padding: 20px 10px;
                color: #fff;
                font-weight: bold;
                display: block;
                font-family: $font-number;
                font-size: 1.8rem;
            }
            i.icon {
                margin: 15px auto 0;
                display: block;
            }

        }
        .text {
            width: 100%;
            padding-top: 10px;
            line-height: 1.5;
            @media(min-width:$screen-sm-min) {
                width: 980px;
            }
            @media(min-width:$screen-md-min) {
                padding-top: 20px;
            }

            h4 {
                font-size: 1.6rem;
                margin-bottom: 5px;
                font-weight: bold;
                @media(min-width:$screen-sm-min) {
                    font-size: 2rem;
                    margin-bottom: 10px;
                }
            }
            p {
                font-size: 1.4rem;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.8rem;
                }
            }
        }
    }

    .disaster-btn-list {
        margin-top: 10px;
        @media(min-width:$screen-sm-min) {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

        }
        button {
            margin: 0 auto 20px;
            @media(min-width:$screen-sm-min) {
                margin: 0 10px;
            }
        }
    }

    .disaster-result {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        background-color: #98cb00;
        max-width: 900px;
        padding: 15px;
        border-radius: 6px;
        @media(min-width:$screen-sm-min) {
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
            padding: 20px;
        }
        .left {
            width: 20%;
            margin: auto;
            @media(min-width:$screen-sm-min) {
                width: 20%;
                margin: 0;
            }
        }
        .right {
            background-color: #fff;
            padding: 20px 15px;
            font-size: 1.6rem;
            line-height: 1.75;
            margin-top: 10px;
            @media(min-width:$screen-sm-min) {
                margin-top: 0;
                padding: 20px;
                width: 80%;
                font-size: 1.8rem;
            }
        }



    }

    .disaster-maemii {
        @media(min-width:$screen-sm-min) {
            display: flex;
            justify-content: space-between;
        }
        .left {
            width: 300px;
            margin: auto;
            @media(min-width:$screen-sm-min) {
                width: 27%;
                margin: 0;
            }
            @media(min-width:$screen-lg-min) {
                width: 300px;
            }
        }

        .right {
            padding-top: 10px;
            @media(min-width:$screen-sm-min) {
                width: 68%;
                padding-top: 0;
            }
            @media(min-width:$screen-lg-min) {
                width: 750px;
            }
        }
    }

    .disaster-img-box-list {
        display: flex;
        flex-wrap:wrap;
        justify-content: space-between;
        @media(min-width:$screen-sm-min) {
            justify-content: flex-start;
        }
    }

    .disaster-img-box {
        padding:20px 15px;
        background-color: #fff;
        width: 48%;
        margin-bottom: 4%;
        @media(min-width:$screen-sm-min) {
            padding: 30px 20px;
            margin-right: 2%;
            margin-bottom: 2%;
            width: 32%;

        }
        &:nth-child(3) {
            margin-right: 0;
        }
        img {
            display: block;
            margin: auto;
        }

        .text-parts-1 {
            margin-top: 5px;
            @media(min-width:$screen-sm-min) {
                margin-top: 10px;
            }
        }

    }


    .disaster-img {
        margin-top: 10px;
        margin-bottom: 10px;
        @media(min-width:$screen-sm-min) {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }


    .box-table {
        background-color: #fff;
        padding: 10px;
        @media(min-width:$screen-sm-min) {
            padding: 20px;
        }
    }

    .li-large {
        font-size: 1.8rem;
        font-weight: bold;
    }

    .tab-2 {
        .list-menu {
            .inline-flex {
                display: inline-flex;
            }
        }
    }
}

.map-box {
    .map {
        width: 100%;
        height: 350px;
        line-height: 1.5;
        @media(min-width:$screen-sm-min) {
            height: 500px;
        }
        .store-name {
            display: block;
            font-size: 1.6rem;
            color: $color-text;
        }
        .store-data {
            font-size: 1.2rem;
            color: #888888;
            display: block;
        }
    }
}

.l-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    border-top: 4px solid $color-red;
    border-bottom: 1px solid #D0D0D0;
    background-color: #fff;
    @media(min-width:$screen-md-min) {
        border-top: 8px solid $color-red;
        transition: all .5s;
    }

    &.sticky {
        @media(min-width:$screen-md-min) {
            border-top: 4px solid $color-red;
            .header-inner {
                padding: 0 10px 0;
                @media(min-width:$screen-lg-min) {
                    padding: 0;
                }
            }
            .l-logo-nav .logo {
                width: 130px;
                top: 10px;
            }
            .l-logo-nav {
                margin-bottom: 10px;
            }
            .l-global-nav .l-list .item {
                padding-bottom: 10px;
            }
            .l-global-nav .l-list .link {
                font-size: 18px;
            }
            .l-global-nav .l-list .link:after {
                bottom: -10px;
            }
            .l-global-nav .sub-menu {
                top: 109px;
            }
        }
    }
    .btn-sp-wrap {
        display: flex;
        align-items: center;
    }

    .btn-sp-font-size {
        margin-right: 10px;
        @media(min-width:$screen-md-min) {
            display: none;
        }
    }

    .btn-sp-menu {
        width: 60px;
        height: 56px;
        border-left: 1px solid #F0F0F0;
        position: relative;

        @media(min-width:$screen-md-min) {
            display: none;
        }
        &.active {
            .item {
                &.first {
                    transform: rotate(45deg);
                    top: 22px;
                }
                &.second {
                    opacity: 0;
                }
                &.thaad {
                    transform: rotate(-45deg);
                    top: 22px;
                }
            }
            .text {
                &.inactive {
                    display: none;
                }
                &.active {
                    display: block;
                }
            }
        }
        .item {
            width: 22px;
            height: 2px;
            margin: auto;
            background-color: $color-red;
            border-radius: 2px;
            position: absolute;
            left: 0;
            right: 0;
            transition: all .2s;
            &.first {
                top: 15px;
            }
            &.second {
                top: 22px;
            }
            &.thaad {
                top: 29px;
            }
        }

        .text {
            text-align: center;
            font-size: 8px;
            color: $color-red;
            position: absolute;
            left: 0;
            right: 0;
            top: 38px;
            margin: auto;
            &.active {
                display: none;
            }
        }


    }




    .header-inner {
        max-width: 1100px;
        margin-right: auto;
        margin-left: auto;
        padding: 0 0 0 15px;
        @media(min-width:$screen-md-min) {
            padding: 33px 10px 0;
            transition: all .5s;
        }
        @media(min-width:$screen-lg-min) {
            padding: 33px 0 0;
        }
    }
    .l-logo-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(min-width:$screen-md-min) {
            align-items: flex-start;
            margin-bottom: 30px;
            transition: all .5s;
        }


        .logo {
            display: block;
            width: 114px;
            margin-bottom: 0;

            @media(min-width:$screen-md-min) {
                width: 190px;
                position: relative;
                top: 0;
                transition: all .2s;
            }
            @media(min-width:$screen-lg-min) {
                width: 224px;
            }
            a {
                display: block;
            }

        }
        .l-list {
            display: none;
            @media(min-width:$screen-md-min) {
                display: flex;
                align-items: center;
                margin-top: 10px;
            }
            li {
                padding-left: 10px;
                @media(min-width:$screen-lg-min) {
                    padding-left: 10px;
                }
            }
            .link-text {
                font-size: 1.4rem;
                color: $color-text-sub1;
            }
            .link-btn {
                border: 1px solid $color-red;
                background-color: $color-red;
                border-radius: 4px;
                display: block;
                // width: 183px;
                color: #fff;
                font-size: 1.3rem;
                text-align: center;
                // height: 36px;
                // line-height: 34px;
                padding: 8px;
                @media(min-width:$screen-md-min) {
                    &:hover {
                        background-color: #CA101A;
                    }
                }
                &.green {
                    border: 1px solid $color-green;
                    background-color: $color-green;
                    @media(min-width:$screen-md-min) {
                        &:hover {
                            background-color: #017A52;
                        }
                    }
                }

                .icon {
                    position: relative;
                    top: 1px;
                }



            }


        }

    }
    .l-font-size {
        margin-right: 5px;
        .font-size-btn {
            background-color: #D9D9D9;
            padding: 5px 8px;
            border-radius: 4px;
            font-size: 13px;
        }
        .is-active {
            background-color: #454545;
            color: #f0f0f0;
        }
    }

    /* google カスタム検索 */
    .l-search {
        border-top: 1px solid $color-border;
        margin-top: 0;
        padding: 15px 15px 0;
        @media(min-width:$screen-md-min) {
            border-top: none;
            padding: 0;
        }
        .inner {
            display: flex;
            position: relative;
        }

        .wrap {
            position: relative;
            width: 80%;
            @media(min-width:$screen-md-min) {
                width: 140px;
            }
            @media(min-width:$screen-lg-min) {
                width: 200px;
            }


            .icon {
                position: absolute;
                top: 0;
                left: 10px;
                bottom: 0;
                margin: auto;
                z-index: 1;
            }

        }
        .search-box{
            border: none;
            font-size: 1.6rem;
            background-color: $color-bg;
            width: 100%;
            padding: 8px 10px 8px 30px;
            position: relative;
            color: $color-text;
            &::placeholder{
                color: $color-text-sub2;
                font-size: 1.3rem;
            }
        }


        .search-btn {
             display: block;
             background-color: $color-text-sub1;
             color: #fff;
             text-align: center;
             font-size: 1.4rem;
             width: 20%;
            //  height: 40px;
            //  line-height: 40px;
             border-top-right-radius : 4px;
             border-bottom-right-radius : 4px;
             cursor: pointer;
             @media(min-width:$screen-md-min) {
                 width: 63px;
                 &:hover {
                     background-color: #2B2B2B;
                 }
             }
        }


    }


}

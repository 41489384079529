

/* .l-form {
    border: 1px solid $color-border-frame;
    &:first-child {
        margin-bottom: 30px;
        margin-top: 25px;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 50px;
            margin-top: 35px;
        }
    }
    .item {
        width: 100%;
        border-bottom: 1px solid $color-border-frame;
        @media(min-width:$screen-sm-min) {
            display: table;
        }
        &:last-child {
            border-bottom: none;
        }
        dt {
            background-color: $color-bg;
            font-size: 16px;
            border-bottom: 1px solid $color-border-frame;
            padding: 15px 10px;
            @media(min-width:$screen-sm-min) {
                padding: 0;
                font-size: 1.8rem;
                border-bottom:none;
                border-right: 1px solid $color-border-frame;
                padding-left: 20px;
                width: 240px;
                display: table-cell;
                vertical-align: middle;
            }
            @media(min-width:$screen-sm-min) {
                padding-left: 30px;
                width: 320px;
            }
            br {
                display: none;
                @media(min-width:$screen-sm-min) {
                    display: block;
                }
            }
        }
        dd {
            padding: 15px 10px;
            @media(min-width:$screen-sm-min) {
                padding: 25px 40px;
                display: table-cell;
                vertical-align: middle;
            }
            .inner {
                @media(min-width:$screen-sm-min) {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }
            }
            p {
                font-size: 14px;
                line-height: 1.75;
                margin-bottom: 20px;
                @media(min-width:$screen-sm-min) {
                    font-size: 16px;
                    margin-bottom: 30px;
                }
                &.bottom-small {
                    margin-bottom: 10px;
                    @media(min-width:$screen-sm-min) {
                        margin-bottom: 15px;
                    }
                }

                a {
                    color: $color-text-hover;
                }
            }
        }

        .group {
            padding: 0;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &.no-flex {
                display: block;
                .text {
                    padding-bottom: 7px;
                }
            }
            &.bottom-space {
                @media(min-width:$screen-sm-min) {
                    margin-bottom: 20px;
                }
            }
            &.w-50 {
                @media(min-width:$screen-sm-min) {
                    width: 50%;
                }
            }
            .text {
                display: inline-block;
                font-size: 14px;
                padding-right: 10px;
                min-width: 40px;
                line-height: 1.5;
                @media(min-width:$screen-sm-min) {
                    font-size: 16px;
                }
                &.hyphen {
                    padding-right: 10px;
                    padding-left: 10px;
                    min-width: 0;
                    @media(min-width:$screen-sm-min) {
                        padding-right: 12px;
                        padding-left: 12px;
                    }
                }
            }

        }

        .mwform-checkbox-field-text {
            display: inline-block;
            font-size: 14px;
            vertical-align: middle;
            @media(min-width:$screen-sm-min) {
                padding-left: 5px;
                font-size: 16px;
            }
        }

    }

    .required {
        font-size: 1rem;
        padding: 4px 4px;
        display: inline-block;
        vertical-align: middle;
        color: $color-red;
        border: 1px solid $color-red;
        margin-left: 5px;
        background-color: #fff;
        @media(min-width:$screen-sm-min) {
            font-size: 1.2rem;
            padding: 4px 5px;
        }
        &.bottom {
            @media(min-widths:$screen-sm-min) {
                margin-left: 0;
                margin-top: 5px;
            }
        }
    }
} */








/* 共通 */
/*----------------------------------------------------------------------------*/
input,
select,
option,
textarea {
    font-size: 1rem;

    // firefox対策、.field内のUIの最小値を修正
    min-width: 1em;
}
label {
    white-space: nowrap;

    /*
    &,
    & > * {
        vertical-align: middle;
        line-height: 1;
    }
    */
}


/* テキスト、セレクト */
/*----------------------------------------------------------------------------*/
// 自動入力時の見た目をリセット
:-webkit-autofill {
    /*
    box-shadow: 0 0 0 1000px #eee inset !important;
    // background-color: transparent !important;
    // background-color: rgba(255, 255, 0, 1.0) !important;
    */
    box-shadow: inherit !important;
    background-color: inherit !important;

    &:active,
    &:focus {
        /*
        box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
        */
        box-shadow: inherit !important;
        background-color: inherit !important;
    }
}

// placeholder
::-webkit-input-placeholder {
    color: rgba($color-text, 0.2);
}
::-moz-placeholder {
    color: rgba($color-text, 0.2);
    opacity: 1;
}
:-ms-input-placeholder {
    color: rgba($color-text, 0.2);
}
:input-placeholder {
    color: rgba($color-text, 0.2);
}
:focus::-webkit-input-placeholder {
    color: rgba($color-text, 0.5);
}
:focus::-moz-placeholder {
    color: rgba($color-text, 0.5);
    opacity: 1;
}
:focus:-ms-input-placeholder {
    color: rgba($color-text, 0.5);
}
:focus:input-placeholder {
    color: rgba($color-text, 0.5);
}


// テキスト系のinput、textarea、select 共通
input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="number"],
input[type="file"],
textarea,
select,
option {
    // サイズ
	padding: 0.75em 0.5em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: block;

    // 線と背景
    border-radius: 4px;
    border: 1px solid $color-border-frame;
    background-color: #fff;

    &.middle {
        @media(min-width:$screen-sm-min) {
            max-width: 300px;
        }
    }
    &.small {
        max-width: 74px;
    }

    &:hover {

    }
    &:active,
    &:focus {
        outline: none;
        // -webkit-
        // box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05) inset;
        border-color: $color-text-hover;
        box-shadow: $shadow-size-focus $color-text-hover;
        background-color: #fff;
    }
    &:disabled,
    &[readonly] {
        color: rgba(0, 0, 0, 0.5) !important;
        background-color: #ddd !important;
        border-color: $color-border !important;
        box-shadow: none !important;
        cursor: default !important;
    }
}

// テキスト系のinput

input[type="number"] {
    width: 6em;
    min-width: 6em; // IE対策
    flex: 0 0 6em;
}
input[type="file"] {
    padding: calc(0.75em - 1.5px) 0.5em;
    cursor: pointer;
}

// textarea
textarea {
    width: 100%;
    height: 300px;
    resize: vertical;
    display: block;


}

// select (1行)
select {
    // padding-right: 32px;
    padding-right: 24px;

    background-repeat: no-repeat;
    background-image: url(../img/common/icon-select-margin.svg);
    background-position: right center;
    background-size: 32px 16px;
    appearance: none;
    line-height: 1;
    max-width: 178px;

    position: relative;

    // IEで矢印を非表示
    &::-ms-expand {
        display: none;
    }

    option {

    }
}
select[value=""] {
    color: #f00;
}

// select (複数行)
select[size]:not([size="1"]),
select[multiple] {
    padding: 0;
    background-image: none;
    overflow: auto;

    option {
        // padding: 0.75em 1em;
        border: none;
    }
    optgroup {
        padding: 0.5em 0;
        color: #ccc;
        font-size: 0.85em;
        font-weight: normal;
        text-indent: 5px;

        & > option {
            padding-left: 0;
            padding-right: 0;
            color: $color-text;
            font-size: 1rem;
            text-indent: 0;
        }
        & > option::before {
            content: "";
            width: 1em;
            display: inline-block;
        }

        & > option:first-child {
            margin-top: 5px;
            border-top: 1px dotted #ccc;
        }
    }
    optgroup:not(:first-child) {
        border-top: 1px dotted #ccc;
    }
    optgroup:last-child {
        padding-bottom: 0;
    }
}


// バリデーション
/* input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="number"],
input[type="file"],
textarea,
select {
    // 失敗、エラー
    &.is-invalid {
        color: $color-danger;
        border-color: $color-danger;
        background-color: $color-danger-light;

        &:hover {

        }
        &:active,
        &:focus {
            background-color: #fff;
            box-shadow: $shadow-size-focus $color-danger;
        }
        &:disabled,
        &[readonly] {

        }

        // placeholder
        &::-webkit-input-placeholder {
            color: rgba($color-danger, 0.35);
        }
        &::-moz-placeholder {
            color: rgba($color-danger, 0.35);
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: rgba($color-danger, 0.35);
        }
        &:input-placeholder {
            color: rgba($color-danger, 0.35);
        }
        &:focus::-webkit-input-placeholder {
            color: rgba($color-danger, 0.65);
        }
        &:focus::-moz-placeholder {
            color: rgba($color-danger, 0.65);
            opacity: 1;
        }
        &:focus:-ms-input-placeholder {
            color: rgba($color-danger, 0.65);
        }
        &:focus:input-placeholder {
            color: rgba($color-danger, 0.65);
        }
    }

    // 成功
    &.is-valid {
        color: $color-success;
        border-color: $color-success;
        background-color: $color-success-light;

        &:hover {

        }
        &:active,
        &:focus {
            background-color: #fff;
            box-shadow: $shadow-size-focus $color-success;
        }
        &:disabled,
        &[readonly] {

        }

        // placeholder
        &::-webkit-input-placeholder {
            color: rgba($color-success, 0.35);
        }
        &::-moz-placeholder {
            color: rgba($color-success, 0.35);
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: rgba($color-success, 0.35);
        }
        &:input-placeholder {
            color: rgba($color-success, 0.35);
        }
        &:focus::-webkit-input-placeholder {
            color: rgba($color-success, 0.65);
        }
        &:focus::-moz-placeholder {
            color: rgba($color-success, 0.65);
            opacity: 1;
        }
        &:focus:-ms-input-placeholder {
            color: rgba($color-success, 0.65);
        }
        &:focus:input-placeholder {
            color: rgba($color-success, 0.65);
        }
    }
}
 */


/* ラジオボタン、チェックボックス */
/*----------------------------------------------------------------------------*/

// ラジオボタン、チェックボックスを囲んでいるlabel
.field-radio,
.field-checkbox {
    flex-wrap: wrap;
    margin: 0.5em -0.5em;

    // 「.field > *」のmargin、paddingをキャンセル
    /*
    & > label,
    & > label:not(:first-child),
    & > label:not(:last-child) {
        margin: 0;
        padding: 0.5em;
    }
    */
    & > *,
    & > *:not(:first-child),
    & > *:not(:last-child) {
        margin: 0;
        padding: 0.5em;
        flex: 0 0 auto;
    }

    // WordPress MW WP Form
    & > .mwform-radio-field,
    & > .mwform-checkbox-field {
        margin: 0 !important;

        &.vertical-item {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(100% - 0.5em - 0.5em);
        }
    }

    // & > label {
    label {
        // MW WP Formではみ出るのでコメントアウト
        // max-width: calc(100% - 0.5em - 0.5em);
        color: $color-text;
        display: flex;
        align-items: center;
        // IEでラジオボタンが横方向に潰れる
        // flex: 0 1 7em;
        // flex: 0 0 auto;
        cursor: pointer;
        white-space: nowrap;

        .icon {
            display: inline-block;
        }
        span {

        }
    }

    // & > label:hover {
    label:hover {

    }
    // 将来的にはjQueryを使用せず
    // :focus-withinで対応可能。
    // & > label:active,
    // & > label:focus-within,
    // & > label.focus {
    label:active,
    label:focus-within,
    label.focus {

    }
    // & > label.disabled {
    label.disabled {
        &,
        & *,
        input[type="radio"],
        input[type="checkbox"] {
            color: #ddd;
            cursor: default;
        }
    }
}

.field-radio-vertical,
.field-checkbox-vertical {
    display: block;
}

/*
// フォントで実装
// radio、checkbox共通
input[type="radio"],
input[type="checkbox"] {
    margin-right: 1rem;
    cursor: pointer;

    &.before-icon {
        margin: 0;
        opacity: 0;
        width: 1rem;
        height: 1rem;
        font-size: 1rem;

        & + .icon {
            margin: 0 0.25rem 0 -1rem;
            padding: 0;
            font-size: 1.5rem;
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1;
            vertical-align: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & + .icon::before {
            font-size: 1.25rem;
            margin: 0;
            display: block;

        }

        & ~ * {
            color: $color-gray;
        }
        &:checked {
            & + .icon::before,
            & ~ * {
                color: $color-btn;
            }
        }

        // Firefoxでは「:read-only」はdisabled以外のときも適用されてしまう
        &:disabled,
        &[readonly] {
            &:checked {
                & + .icon::before,
                & ~ * {
                    color: #ddd;
                    cursor: default;
                }
            }
        }
    }
}

// radio
input[type="radio"].before-icon {
    & + .icon::before {
        @mixin icon-radio;
    }
    &:checked + .icon::before {
        @mixin icon-radio-checked-fill;
    }
}

// checkbox
input[type="checkbox"].before-icon {
    & + .icon::before {
        @mixin icon-checkbox-round;
    }
    &:checked + .icon::before {
        @mixin icon-checkbox-round-checked-fill;
    }
}
*/


// CSSで実装
// radio、checkbox共通
input[type="radio"],
input[type="checkbox"] {
    margin-right: 1rem;
    cursor: pointer;

    &.before-icon {
        margin: 0;
        opacity: 0;
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
        display: none;

        & + .icon {
            margin: 0;
            padding: 0;
            font-size: 1.5rem;
            width: 22px;
            height: 22px;
            line-height: 1;
            vertical-align: middle;
            display: inline-block;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-border-frame;
            background-color: #fff;
            @media(min-widths:$screen-sm-min) {
                width: 26px;
                height: 26px;
            }
        }
        & + .icon::before {
            transform: none;
            display: block;
        }
    }
}

// radio
input[type="radio"].before-icon {
    & + .icon {
        border-radius: 50%;

        box-shadow: 0 0 0 2px $color-border inset;
        background-color: $color-field;
        box-sizing: border-box;
        overflow: hidden;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            margin: auto;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: transparent;
            display: block;
            transform: scale(0.5);
        }
    }
    &:checked + .icon {
        background-color: #fff;
        box-shadow: 0 0 0 2px $color-text inset;

        &::before {
            background-color: rgba($color-text, 0.75);
        }
    }

    // focus
    &:focus {
        outline: none;
    }
    &:active,
    &:focus {
        & + .icon {
            box-shadow: 0 0 0 2px $color-text-hover inset;

            &::before {

            }
        }
        &:checked + .icon {
            &::before {
                background-color: rgba($color-text-hover, 0.75);
            }
        }
    }

    // disabled
    &:disabled,
    &[readonly] {
        & + .icon {
            background-color: #ddd;
            box-shadow: 0 0 0 2px $color-border inset;
            color: #ddd;
            cursor: default;

            &::before {

            }
        }
        &:checked + .icon {
            &::before {
                background-color: $color-border;
            }
        }
    }
}

// checkbox
input[type="checkbox"].before-icon {
    & + .icon {
        border-radius: 10%;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            width: 74%;
            height: 40%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: none;
            border-left: 3px solid $color-red;
            border-bottom: 3px solid $color-red;
            box-sizing: border-box;
            transform: translate(10%, -40%) rotate(-45deg);
            @media(min-width:$screen-sm-min) {
                width: 74%;
                height: 40%;
            }
        }
    }
    &:checked + .icon {

        &::before {
            display: inline-block;
        }
    }

    // focus
    &:focus {
        outline: none;
    }
    &:active,
    &:focus {
        & + .icon {
            box-shadow: 0 0 0 2px $color-text-hover inset;

            &::before {

            }
        }
        &:checked + .icon {
            &::before {
                border-color: rgba($color-text-hover, 0.75);
            }
        }
    }

    // disabled
    &:disabled,
    &[readonly] {
        & + .icon {
            background-color: #ddd;
            box-shadow: 0 0 0 2px $color-border inset;
            color: #ddd;
            cursor: default;

            &::before {

            }
        }
        &:checked + .icon {
            &::before {
                border-color: $color-border;
            }
        }
    }
}


// バリデーション
/*
input[type="radio"].before-icon {
    // 失敗、エラー
    &.is-invalid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-danger, 0.25) inset;
            background-color: $color-danger-light;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-danger inset;

            &::before {
                background-color: $color-danger;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-danger, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }

    // 成功
    &.is-valid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-success, 0.25) inset;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-success inset;

            &::before {
                background-color: $color-success;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-success, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }
}
input[type="checkbox"].before-icon {
    // 失敗、エラー
    &.is-invalid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-danger, 0.25) inset;
            background-color: $color-danger-light;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-danger inset;

            &::before {
                border-color: $color-danger;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-danger, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }

    // 成功
    &.is-valid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-success, 0.25) inset;
            background-color: $color-success-light;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-success inset;

            &::before {
                border-color: $color-success;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-success, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }
}
 */

.hover-text-red {
	@media(min-width:$screen-md-min) {
		transition: all .2s;
		&:hover{
			color: $color-red !important;
		}
	}
}

.hover-text-line {
	@media(min-width:$screen-md-min) {
		&:hover{
			text-decoration: underline;
		}
	}
}

.hover-img {
	position: relative;
	display: inline-block;
	@media(min-width:$screen-md-min) {
		&:hover{
			&:before {
				opacity:0.2;
			}
		}
	}

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0;
		transition: all .2s;
	}




}

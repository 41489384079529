
.btn-round {
    display: block;
    border: 2px solid $color-red;
    background-color: #fff;
    color: $color-red;
    text-align: center;
    height: 42px;
    line-height: 40px;
    width: 100%;
    font-size: 1.6rem;
    border-radius: 35px;
    position: relative;
    cursor: pointer;
    font-weight: bold;
    @media(min-width:$screen-sm-min) {
        font-size: 2.0rem;
        height: 60px;
        line-height: 60px;
    }
    @media(min-width:$screen-md-min) {
		transition: all .2s;
		&:hover{
            border: 2px solid #CA101A;
            background-color: #CA101A;
            color: #fff;
            .icon {
                fill:#fff;
            }
		}
	}

    &.sp-2line {
        @media(max-width:$screen-xs-min) {
            height: auto;
            line-height: 1.5em;
            padding: 10px 30px;
        }
    }





    &.red {
        background-color: $color-red;
        color: #fff;
        @media(min-width:$screen-md-min) {
            &:hover {
                background-color: #fff;
                color: $color-red;
            }
        }
        .arrow-right,
        .arrow-left {
            fill:#fff;
        }
        @media(min-width:$screen-md-min) {
            &:hover {
                .icon {
                    fill:$color-red;
                }
            }
        }


    }
    .arrow-right,
    .arrow-left {
        position: absolute;
        width: 11px;
        height: 11px;

        top: 0;
        bottom: 0;
        margin: auto;
        transition: all .2s;
        @media(min-width:$screen-sm-min) {
            width: 13px;
            height: 13px;

        }
    }
    .arrow-right {
        right: 15px;
        @media(min-width:$screen-sm-min) {
            right: 15px;
        }
    }
    .arrow-left {
        left: 15px;
        @media(min-width:$screen-sm-min) {
            left: 15px;
        }
    }




    &.small {
        max-width: 320px;
        font-size: 1.3rem;
        @media(min-width:$screen-sm-min) {
            font-size: 1.6rem;
        }

    }
    &.medium {
        max-width: 520px;
        font-size: 1.6rem;
        @media(min-width:$screen-sm-min) {
            font-size: 2.4rem;
        }
    }
    &.large {
        max-width: 740px;
        font-size: 1.6rem;
        @media(min-width:$screen-sm-min) {
            font-size: 2.4rem;
        }
    }
    &.emphasis {
        max-width: 740px;
    }

    &.h-70 {
        @media(min-width:$screen-sm-min) {
            line-height: 70px;
            height: 70px;
        }
    }

}

.btn-square {
    color: #fff;
    display: block;
    text-align: center;
    border-radius: 4px;
    font-size: 1.3rem;
    padding: 18px 10px;
    position: relative;
    font-weight: bold;
    @media(min-width:$screen-sm-min) {
        font-size: 2rem;
        padding: 25px 10px;
    }
    .arrow-right {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 15px;
        width: 11px;
        height: 11px;
        fill:#fff;
        @media(min-width:$screen-sm-min) {
            right: 20px;
            width: 13px;
            height: 13px;
        }
    }

    &.lead-icon {
        /* padding: 7px 10px; */
        font-size: 1.2rem;
        line-height: 1.5;
        @media(min-width:$screen-sm-min) {
            line-height: 1;
            padding: 22px 10px;
            font-size: 1.6rem;
        }
        .icon {

            @media(min-width:$screen-sm-min) {
                margin: auto;
                margin-right: 20px;
                display: inline-block;
            }
        }
    }
    &.green {
        background-color: $color-green;
        @media(min-width:$screen-md-min) {
    		transition: all .2s;
    		&:hover{
                background-color: #017A52;
    		}
    	}
    }
    &.blue {
        background-color: $color-blue;
        @media(min-width:$screen-md-min) {
    		transition: all .2s;
    		&:hover{
                background-color: #066994;
    		}
    	}
    }
}

.btn-list {
	display: table;
	width: 100%;
	background-color: $color-bg-yellow;
	border: 1px solid $color-border-frame;
	border-radius: 4px;
	padding: 20px 10px;
	position: relative;
    font-weight: bold;
	@media(min-width:$screen-sm-min) {
		padding: 20px 10px;
		height: 90px;
	}
	@media(min-width:$screen-md-min) {
		padding: 20px;
		&:hover {
            text-decoration: none !important;
			.heading {
				color: $color-red;
                text-decoration: none !important;
			}
		}
	}
	.heading {
		color: $color-text;
		font-size: 1.4rem;
		display: table-cell;
		vertical-align: middle;
		line-height: 1.5;
		@media(min-width:$screen-md-min) {
			font-size: 1.7rem;
		}
	}
	.icon {
		width: 11px;
		height: 11px;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 10px;
		margin: auto;
		@media(min-width:$screen-md-min) {
			width: 13px;
			height: 13px;
			right: 20px;
		}
	}
}

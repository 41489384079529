.is-personal {
    .wrap-quote {
        text-align: center;

        .quote-btn {
            padding: 25px 0;

            a {
                margin: 0 auto;
                @media(max-width:$screen-xs-min) {
                    margin: auto;
                }
            }
    }

    }

    .charm-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media(min-width:$screen-sm-min) {
            margin-bottom: -40px;
        }
        li {
            margin-bottom: 15px;
            @media(min-width:$screen-sm-min) {
                width: 49%;
                margin-bottom: 2%;
            }
            @media(min-width:$screen-lg-min) {
                width: 530px;
                margin-bottom: 40px;
            }


            &:last-child {
                @media(max-width:$screen-sm-min) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .tab-3-group {
        .heading-text-large {
            text-align: center;
        }
        .img {
            text-align: center;
            margin-bottom: 20px;
            width: 70%;
            margin-right: auto;
            margin-left: auto;
            @media(min-width:$screen-sm-min) {
                width: 380px;
                margin-bottom: 60px;
            }
        }
    }

    .supplement-text {
        font-size: 1.6rem;
        color: #5C5C5C;
        line-height: 1.5;
    }


    .car-box {
        margin-top:20px;
        padding: 30px 20px;
        text-align: center;
        background-color: #fff;
        @media(min-width:$screen-sm-min) {
            margin-top: 60px;
            padding: 80px 20px;
        }
        @media(min-width:$screen-lg-min) {
            padding: 80px 40px;
        }


        .wrap-btn {
            padding-bottom: 0;
            max-width: 740px;
            margin-right: auto;
            margin-left: auto;
        }
        .car-list {
            margin-top: 20px;
            @media(min-width:$screen-sm-min) {
                margin-top: 35px;
                display: flex;
                justify-content: space-between;

            }
            li {
                margin-bottom: 20px;
                @media(min-width:$screen-sm-min) {
                    margin-bottom: 0;
                    width: 32%;
                }
                @media(min-width:$screen-lg-min) {
                    width: 320px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .link {
                color: $color-text;
                border: 1px solid $color-border-frame;
                display: block;
                padding: 20px 15px;
                background-color: #fff;
                position: relative;
                @media(min-width:$screen-sm-min) {
                    padding: 35px 10px;
                }
                @media(min-width:$screen-lg-min) {
                    padding: 35px;
                    height: auto !important;
                }
                @media(min-width:$screen-md-min) {
                    transition: all .2s;
                    &:hover {
                        &:after {
                            opacity: 0.4;
                        }
                        .img {
                            opacity:0.8;
                        }
                        .text-parts-1 {
                            /* color: $color-red; */
                        }
                    }
                }
                .heading-text-small {
                    color: $color-red;
                    padding-top: 15px;
                    @media(min-width:$screen-sm-min) {
                        padding-top: 35px;
                    }
                }
                .img {
                    transition: all .2s;
                }
                .text-parts-1 {
                    transition: all .2s;
                    font-weight: bold;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    opacity: 0;
                    transition: all .2s;
                    background-color: #eee;
                }



            }




        }

    }


    .l-point {
        .table-02 {
            margin: 10px 0;

            @media(min-width:$screen-sm-min) {
                margin: 15px 0;
            }
        }

        .text-green {
            color: $color-green;
        }

        .point-box {
            background-color: $color-bg-yellow;
            text-align: center;
            padding: 20px;
            margin-top: 20px;
            @media(min-width:$screen-sm-min) {
                padding: 40px;
                margin-top: 40px;
            }
            .heading-text-medium {
                text-align: center;
                color: $color-red;
            }
            .inner {
                margin-top: 20px;
                padding: 20px;
                background-color: #fff;
                @media(min-width:$screen-sm-min) {
                    margin-top: 30px;
                    padding: 40px;
                }
                .text-parts-1,
                .list-dot {
                    text-align: left;
                }

            }
        }

        .point-list {
            @media(min-width:$screen-sm-min) {
                display: flex;
                justify-content: space-between;
            }
            li {
                margin-bottom: 15px;
                @media(min-width:$screen-sm-min) {
                    margin-bottom: 0;
                    width: 32%;
                }
                @media(min-width:$screen-lg-min) {
                    width: 346px;
                }
            }
            .group {
                border: 1px solid $color-border-frame;
                background-color: #fff;
                text-align: center;
                padding: 30px 10px 10px;
                @media(min-width:$screen-sm-min) {
                    padding: 30px 10px;
                }
                .heading {
                    font-weight: bold;
                    font-size: 1.7rem;
                    margin-bottom: 15px;
                    @media(min-width:$screen-sm-min) {
                        font-size: 2.4rem;
                        margin-bottom: 25px;
                    }
                }
                .img {
                    text-align: center;
                    width: 80%;
                    margin: auto;
                    @media(min-width:$screen-sm-min) {
                        width: 260px;
                    }
                }
            }


        }

        .point-group {
            padding-bottom: 30px;
            @media(min-width:$screen-sm-min) {
                padding-bottom: 50px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .heading {
                font-size: 1.7rem;
                margin-bottom: 5px;
                font-weight: bold;
                line-height: 1.5;
                @media(min-width:$screen-sm-min) {
                    font-size: 2.4rem;
                    margin-bottom: 10px;
                }
            }
            .text {
                line-height: 1.5;
                font-size: 1.6rem;
                margin-bottom: 10px;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.8rem;
                    margin-bottom: 15px;
                }
                a {
                    color: $color-text-hover;
                    text-decoration: underline !important;
                    @media(min-width:$screen-md-min) {
                        &:hover {
                            text-decoration: none !important;
                        }
                    }
                    .window {
                        fill: $color-text-hover;
                    }


                }
            }

            .text2 {
                font-size: 1.2rem;
                margin-bottom: 10px;
                line-height: 1.5;
                color: #5C5C5C;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.6rem;
                    margin-bottom: 15px;
                }
            }

            .group-list {
                line-height: 1.5;
                font-size: 1.4rem;
                margin-bottom: 10px;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.8rem;
                    margin-bottom: 15px;
                }
                li {
                    margin-bottom: 3px;
                    padding-left: 10px;
                    position: relative;
                    @media(min-width:$screen-sm-min) {
                        padding-left: 15px;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:before {
                        content: '';
                        display: block;
                        background-color: $color-text;
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        position: absolute;
                        top: 10px;
                        left: 0;
                        @media(min-width:$screen-sm-min) {
                            width: 6px;
                            height: 6px;
                            top: 10px;
                        }
                    }
                }
            }

            .text,
            .text2,
            .group-list {
                &:last-child {
                    margin-bottom: 0;
                }
            }

        }

        .border-box {
            border-style: solid;
            border-color: #F0F0F0;
            border-top-width: 3px;
            border-bottom-width: 3px;
            padding: 30px 0 40px;
            @media(min-width:$screen-sm-min) {
                padding: 45px 0 80px;
            }
        }

        .style-box {
            background-color: $color-bg-yellow;
            padding: 20px;
            margin-bottom: 20px;
            @media(min-width:$screen-sm-min) {
                padding: 40px;
                margin-bottom: 40px;
            }
            .box-list {
                @media(min-width:$screen-sm-min) {
                    display: flex;
                    justify-content: space-between;
                }
                li {
                    margin-bottom: 10px;
                    @media(min-width:$screen-sm-min) {
                        width: 30%;
                        margin-bottom: 0;
                    }
                }
                .group {
                    display: flex;
                    justify-content: space-between;
                    .left {
                        width: 60%;
                        padding-top: 10px;
                        @media(min-width:$screen-sm-min) {
                            width: 170px;
                            padding-top: 10px;
                        }
                    }
                    .right {
                        width: 35%;
                        @media(min-width:$screen-sm-min) {
                            width: 120px;
                        }
                    }
                    .heading {
                        font-size: 1.6rem;
                        margin-bottom: 10px;
                        font-weight: bold;
                        color: $color-red;
                        @media(min-width:$screen-sm-min) {
                            font-size: 2rem;
                            margin-bottom: 15px;
                        }
                    }
                    .text {
                        font-size: 1.4rem;
                        line-height: 1.5;
                        @media(min-width:$screen-sm-min) {
                            font-size: 1.6rem;
                            line-height: 1.5;
                        }
                    }
                }

                li.harf-01 {
                    @media(min-width:$screen-sm-min) {
                        width: 47%;
                        margin-bottom: 0;

                        .left {
                            width: 280px;
                            padding-top: 10px;
                        }
                        .right {
                            width: 160px;
                        }
                    }
                }
                li.harf-02 {
                    @media(min-width:$screen-sm-min) {
                        width: 47%;
                        margin-bottom: 0;

                        .left {
                            width: 320px;
                            padding-top: 10px;
                        }
                        .right {
                            width: 120px;
                        }
                    }
                }
            }
        }



    }

    .personal-card-layout {
        margin-bottom: 20px;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 4%;
            display: flex;
            justify-content: space-between;
        }
        @media(min-width:$screen-lg-min) {
            margin-bottom: 40px;
        }


        &:last-child {
            margin-bottom: 0;
        }
    }

    &.is-special {
        &.special {
            .heading-text-large {
                margin-bottom: 5px;
                @media(min-width:$screen-sm-min) {
                    margin-bottom: 15px;
                }
            }
        }




        .wrap-btn {
            padding: 20px 0 0;
            @media(min-width:$screen-sm-min) {
                padding: 40px 0 0;
            }
        }

    }

}

@charset "utf-8";

.slick-dots {
  text-align: center;
  li {
    display: inline-block;
    padding: 0 5px;
    &.slick-active {
      button {
        background-color: #fff;
      }
    }
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      appearance: none;
      background-color: #C0C1C3;
      font-size: 0;
      width: 8px;
      height: 8px;
      @include border-radius(50%);
      @include transition(0.2s);
      @media(min-width:$screen-sm-min) {
        width: 18px;
        height: 18px;
      }
      @media(min-width:$screen-md-min) {
        &:hover {
          background-color: #fff;
        }
    	}
    }
  }
}

.slick-slider {
  position: relative;
  .slick-arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    font-size: 0;

    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    &:before,&:after {
      font-size: 3.0rem;
      color: #fff;
      @media(min-width:$screen-sm-min) {
        font-size: 6.0rem;
      }
    }

    &.slick-prev {
      left: -10%;
      @media(min-width:$screen-md-min) {
        left: 5%;
        //サブナビゲーションのタブの上に出ないように調整
        z-index: 99;
      }
    }
    &.slick-next {
      right: -10%;
      @media(min-width:$screen-md-min) {
        right: 5%;
        //サブナビゲーションのタブの上に出ないように調整
        z-index: 99;
      }
    }


  }

}

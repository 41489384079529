
.is-rode-service {
    .area-group {
        .l-list {
            border-top: 1px solid $color-border-frame;
            li {
                line-height: 1.5;
                border-bottom: 1px solid $color-border-frame;
                padding: 15px 0;
                @media(min-width:$screen-sm-min) {
                    display: flex;
                    padding: 20px 0;
                }
                .l-name {
                    position: relative;
                    @media(min-width:$screen-sm-min) {
                        width: 40%;
                        padding-left: 158px;
                        padding-right: 20px;
                        padding-top: 10px;
                    }
                    .name {
                        font-size: 1.4rem;
                        @media(min-width:$screen-sm-min) {
                            font-size: 1.8rem;
                        }
                    }
                    a {
                        color: $color-text-hover;
                    }

                    .premium-link {
                        @media(min-width:$screen-md-min) {
                            transition: all .2s;
                            &:hover{
                                opacity:0.8;
                            }
                        }
                    }


                }
                .address,
                .department {
                    font-size: 1.4rem;
                    color: #5C5C5C;
                    padding-top: 5px;
                    @media(min-width:$screen-sm-min) {
                        padding-top: 0;
                        width: 60%;
                        padding-right: 20px;
                        font-size: 1.6rem;
                    }
                }
            }

            &.store {
                li {
                    @media(min-width:$screen-sm-min) {
                        justify-content: space-between;
                    }
                    .l-name {
                        @media(min-width:$screen-sm-min) {
                            width: 30%;
                            padding-left: 0;
                        }
                    }

                    .department {
                        @media(min-width:$screen-sm-min) {
                            padding-top: 10px;
                            width: 20%;
                            padding-right: 0;
                        }
                    }


                    .address {
                        @media(min-width:$screen-sm-min) {
                            width: 50%;
                            padding-right: 0;
                        }
                    }



                }
            }
        }
    }
}

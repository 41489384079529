.is-contractor-policy {
    // 横並び3つ、4つ、5つとHTML側の修正だけで対応できないため書き直す (2020/01/15)
    // .l-policy-nav {
    //     display: flex;
    //     justify-content: space-between;
    //     flex-wrap: wrap;
    //     li {
    //         width: 48%;
    //         margin-bottom: 4%;
    //         @media(min-width:$screen-sm-min) {
    //             width: 23%;
    //             margin-bottom: 0;
    //         }
    //         @media(min-width:$screen-lg-min) {
    //             width: 260px;
    //         }
    //     }
    // }

    .l-policy-nav {
        margin: -10px;
        display: flex;
        flex-wrap: wrap;

        li {
            margin: 10px;
            flex-grow: 0;
            flex-shrink: 0;
            // 20pxは余白
            flex-basis: calc(50% - 20px * 1);

            @media(min-width:$screen-md-min) {
                flex: 1 0 0%;
            }
            @media(min-width:$screen-lg-min) {
                // width: 260px;
            }
        }
    }


    .l-policy-pdf-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 50px;
        }

        .thumbnail {
            width: 20.29%;
            @media(min-width:$screen-sm-min) {
                width: 120px;
            }
            img {
                width: 100% !important;
                height: auto !important;
            }
        }
        .text {
            width: 74.71%;
            font-size: 1.6rem;
            line-height: 1.5;
            margin-left: 5%;
            font-weight: bold;
            @media(min-width:$screen-sm-min) {
                margin-left: 50px;
                font-size: 3.0rem;
            }
        }
    }

    .l-policy-pdf-list {
        padding-top: 5px;
        @media(min-width:$screen-sm-min) {
            padding-top: 15px;
        }
        li {
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .l-policy-category-description {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #5C5C5C;
        @media(min-width:$screen-sm-min) {
            font-size: 1.6rem;
        }
        p {
            padding-top: 5px;
            @media(min-width:$screen-sm-min) {
                padding-top: 15px;
            }
        }
    }


    .l-group {
        .spacer-large  {
            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    #injury-1,
    #injury-2,
    #injury-3,
    #outdoor-1,
    #outdoor-2,
    #property-1,
    #liability-1,
    #work-2 {
            h2 {
                display: none;
            }
    }




}

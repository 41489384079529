.l-information-desk {
    border-style: solid;
    border-color: $color-border;
    border-top-width: 1px;
    /* border-bottom-width: 1px; */
    padding: 30px 0 30px;
    @media(min-width:$screen-sm-min) {
        padding: 50px 0 30px;
    }

    .list-column-3  {
        justify-content: flex-start;
        li {
            @media(min-width:$screen-sm-min) {
                margin-right: 2%;
            }
            @media(min-width:$screen-lg-min) {
                margin-right: 22px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }

    .btn-list {
        @media(min-width:$screen-sm-min) {
    		height: 100px;
    	}
    }
}





.adr-flow-wrap {
    display: flex;
    justify-content: space-between;
}
.adr-flow-left {
    width: 8%;
    @media(min-width:$screen-sm-min) {
        margin-right: 8px;
        width: auto;
    }

    > div {
        background-color: #FAF8EF;
        border-radius: 4px;
        text-align: center;
        font-size: 1.3rem;
        @media(min-width:$screen-sm-min) {
            width: 20px;
        }

        div {
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            display: inline-block;
            text-align: left;
            padding: 50px 0;
            font-weight: bold;
        }

        &#text01 {
            height: 573px;
        }

        &#text02 {
            height: 1196px;
            position: relative;
            top: 403px;
        }
    }

    @media(min-width:$screen-xs-min) {
        margin-right: 25px;

        > div {
            width: 60px;
            font-size: 1.6rem;
        }
    }
}
.adr-flow {
    width: 73%;
    @media(min-width:$screen-sm-min) {
        margin-right: 8px;
        width: auto;
    }

    .step {
        padding: 15px;

        dl {

            dt {

                span {
                    display: inline-block;
                }

                .number {
                    font-family: $font-number;
                    font-size: 1.8rem;
                    vertical-align: middle;
                    margin-right: 10px;

                    span {
                        font-size: 3.4rem;
                        margin-left: 5px;
                        vertical-align: middle;
                    }
                }

                .text {
                    vertical-align: bottom;
                    font-weight: bold;
                }
            }

            dd {
                margin-top: 20px;
            }
        }
    }

    .step01, .step03, .step05, .step08 {
        background-color: #F1F7EE;

        dl dt {
            color: #80BA5F;
        }
    }
    .step02, .step04, .step06, .step07, .step09 {
        background-color: #E4F4F5;

        dl dt {
            color: #00AAB0;
        }
    }

    .arrow-block {
        background-image: url(../img/support/adr/flow/flow-bg01.png);
        background-repeat: repeat-y;
        background-position: 37px;
        min-height: 20px;
        line-height: 1.5;

        .arrow-block-inner-01 {
            padding: 20px 0 20px 70px;
        }

        &.last {

            width: 290px;
        }

        .arrow-block-inner-02 {
            padding: 20px 0;

            .text {
                padding-left: 70px;
                margin-bottom: 30px;
            }
        }

        .arrow-block-inner-03 {
            padding: 20px 0 0 0;
        }
    }

    @media(min-width:$screen-xs-min) {
        margin-right: 25px;

        .step {
            padding: 30px;
        }
    }
}
.adr-flow-right {
    width: 14%;
    @media(min-width:$screen-sm-min) {
        width: auto;
    }

    dl {
        background-color: #E4F4F5;
        border-radius: 4px;
        @media(min-width:$screen-sm-min) {
            width: 48px;
        }

        dt {
            background-color: #00AAB0;
            color: #ffffff;
            text-align: center;
            border-radius: 4px 4px 0 0;
            padding: 10px 0;
            width: 100%;
            line-height: 1.3;
            font-size: 1.2rem;

            div {
                -webkit-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                display: inline-block;
                text-align: left;
                font-weight: bold;
            }
        }

        dd {
            color: #00AAB0;
            width: 100%;
            padding: 15px 0 0 0;
            line-height: 1.5;
            text-align: center;
            font-size: 1.4rem;

            div {
                -webkit-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                display: inline-block;
                text-align: left;
                letter-spacing: 0.25em;
                font-weight: bold;
            }
        }

        &#text01 {
            height: 861px;
        }

        &#text02 {
            height: 1028px;
        }
    }

    @media(min-width:$screen-xs-min) {

        dl {
            width: 130px;

            dt {
                font-size: 1.6rem;

                div {
                    -webkit-writing-mode: inherit;
                    -ms-writing-mode: inherit;
                    writing-mode: inherit;
                    text-align: center;
                }
            }

            dd {
                font-size: 2rem;
            }
        }
    }
}





.is-support {
    .premium-box {
        background-color: $color-bg-yellow;
        padding: 15px;
        @media(min-width:$screen-sm-min) {
            padding: 40px;
        }
        .heading-text-small {
            color: $color-red;
        }
        .inner {
            background-color: #fff;
            padding: 15px;
            @media(min-width:$screen-sm-min) {
                padding: 40px;
            }
        }

        .top-part {
            display: flex;
            align-items: center;
            .img {
                margin-left: 10px;
                margin-bottom: 20px;
                @media(min-width:$screen-sm-min) {
                    margin-left: 20px;
                }
            }
        }

        .list-dot {
            margin-top: 10px;
            @media(min-width:$screen-sm-min) {
                margin-top: 30px;
            }
            li {
                font-size: 1.4rem;
                margin-top: 0;
                line-height: 1.5;
                padding-bottom: 5px;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.8rem;
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
    .accident-contact {
        .service-link {
            .arrow-right {
                transform: rotate(90deg);
            }
        }
    }

    .service-box {
        background-color: $color-bg-yellow;
        padding: 20px;
        margin-top: 30px;
        @media(min-width:$screen-sm-min) {
            padding: 40px;
            margin-top: 50px;
        }
        .heading-text-medium {
            text-align: center;
        }

        .inner {
            background-color: #fff;
            margin-top: 20px;
            padding: 20px;
            @media(min-width:$screen-sm-min) {
                margin-top: 40px;
                padding: 40px;
            }
            .text-parts-small {
                color: $color-text;
            }
            .l-list {
                margin-top: 5px;
                @media(min-width:$screen-md-min) {
                    display: flex;

                }
                li {
                    margin-bottom: 10px;
                    font-size: 1.4rem;
                    @media(min-width:$screen-sm-min) {
                        font-size: 1.8rem;
                    }
                    @media(min-width:$screen-md-min) {
                        margin-bottom: 0;
                        margin-right: 15px;
                    }
                    @media(min-width:$screen-lg-min) {
                        margin-right: 40px;
                    }
                    &.last-child {
                        margin-right: 0;
                    }
                }
            }
        }

    }

    .service-tel-contact {
        border-top: 1px solid $color-border-frame;
        margin-top: 30px;
        @media(min-width:$screen-sm-min) {
            margin-top: 50px;
        }
        .group {
            border-bottom: 1px solid $color-border-frame;
            padding: 20px 0;
            @media(min-width:$screen-sm-min) {
                padding: 30px 0;
            }
            @media(min-width:$screen-md-min) {
                display: flex;
            }
            .left {
                padding-bottom: 10px;
                @media(min-width:$screen-md-min) {
                    padding-bottom: 0;
                    padding-top: 10px;
                    width: 40%;
                }
                @media(min-width:$screen-lg-min) {
                    width: 520px;
                }
            }

            .heading {
                font-size: 1.7rem;
                font-weight: bold;
                @media(min-width:$screen-sm-min) {
                    font-size: 2.4rem;
                }
                span {
                    margin-top: 5px;
                    display: block;
                    font-weight: normal;
                }
            }
            .telephone {
                font-size: 14px;
                margin-top: 5px;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.8rem;
                    margin-top: 10px;
                }
                .icon {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                    @media(min-width:$screen-sm-min) {
                        width: 26px;
                        height: 26px;
                    }
                }
                .number {
                    font-family: $font-number;
                    font-weight: bold;
                    font-size: 26px;
                    @media(min-width:$screen-sm-min) {
                        font-size: 32px;
                    }
                }

            }
        }

    }

    .adr-column {
        @media(min-width:$screen-sm-min) {
            display: flex;
            justify-content: space-between;
        }
        .left {
            @media(min-width:$screen-sm-min) {
                width: 56%;
            }
            @media(min-width:$screen-lg-min) {
                width: 625px;
            }
        }
        .right {
            width: 100%;
            text-align: center;
            margin: 20px auto 0;
            @media(min-width:$screen-sm-min) {
                margin: 0;
                width: 39%;
            }
            @media(min-width:$screen-lg-min) {
                width: 435px;
            }
        }

        a {
            text-decoration: underline;
            color: $color-text-hover;
            @media(min-width:$screen-md-min) {
                &:hover {
                    text-decoration: none;
                }
            }

        }


    }

    .adr-column2 {
        margin-top: 30px;
        @media(min-width:$screen-sm-min) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 60px;
        }
        .left {
            @media(min-width:$screen-sm-min) {
                width: 856px;
            }
        }
        .right {
            margin: 20px auto 0;
            width: 70%;
            @media(min-width:$screen-sm-min) {
                margin: 0;
                width: 200px;
            }
        }
        .heading-text-medium {
            margin-bottom: 5px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 10px;
            }
        }

        a {
            text-decoration: underline;
            color: $color-text-hover;
            @media(min-width:$screen-md-min) {
                &:hover {
                    text-decoration: none;
                }
            }

        }


    }

    .adr-cole-box {
        text-align: center;
        margin-top: 20px;
        position: relative;
        @media(min-width:$screen-sm-min) {
            margin-top: 40px;
        }
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 17px 11px 0 11px;
            border-color: #E69497 transparent transparent transparent;
            margin-left: auto;
            margin-right: auto;
            @media(min-width:$screen-sm-min) {
                border-width: 34px 23px 0 23px;
            }

        }
    }

    .adr-box {
        background-color: $color-bg-yellow;
        margin-top: 15px;
        padding: 15px;
        @media(min-width:$screen-sm-min) {
            margin-top: 25px;
            padding: 40px;
        }
        .inner {
            background-color: #fff;
            padding: 15px;
            @media(min-width:$screen-sm-min) {
                padding: 30px;
            }
        }
        .m-name {
            border-bottom: 1px solid $color-border-navi;
            padding-bottom: 5px;
            margin-bottom: 15px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 20px;
            }
            @media(min-width:$screen-md-min) {
                display: flex;
                align-items: flex-end;
            }

            span {
                display: block;
            }
            .heading-top-medium {
                margin-bottom: 0;
                @media(min-width:$screen-md-min) {
                    margin-right: 20px;
                }
                @media(min-width:$screen-lg-min) {
                    margin-right: 40px;
                }
            }

        }

        .l-dial {
            margin-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                display: flex;
                margin-bottom: 10px;
            }
            dt {
                font-size: 1.4rem;
                font-weight: bold;
                margin-bottom: 10px;
                @media(min-width:$screen-sm-min) {
                    margin-bottom: 0;
                    padding-top: 10px;
                    width: 210px;
                    font-size: 1.8rem;
                }
            }
            dd {
                font-family: $font-number;
                font-weight: bold;
                font-size: 2.4rem;
                @media(min-width:$screen-sm-min) {
                    font-size: 3.2rem;
                }
                .icon {
                    vertical-align: middle;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: -2px;
                    @media(min-width:$screen-sm-min) {
                        width: 26px;
                        height: 26px;
                    }
                }
            }
        }

        .text {
            margin-top: 10px;
            font-size: 1.2rem;
            line-height: 1.5;
            @media(min-width:$screen-sm-min) {
                font-size: 1.6rem;
            }
        }
    }

    .adr-box2 {
        background-color: $color-bg-yellow;
        padding: 15px;
        margin-top: 15px;
        @media(min-width:$screen-sm-min) {
            padding: 40px;
            margin-top: 50px;
        }
        .heading-text-medium {
            text-align: center;
        }
        .inner {
            @media(min-width:$screen-sm-min) {
                display: flex;
                justify-content: space-between;
            }
        }
        .column {
            background-color: #fff;
            padding: 15px;
            margin-bottom: 15px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 0;
                width: 495px;
                padding: 30px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .text-parts-1 {
                margin-bottom: 10px;
                @media(min-width:$screen-sm-min) {
                    margin-bottom: 20px;
                }
                &.last-child {
                    margin-bottom: 0;
                }
            }

        }

    }

}

.spacer-medium {
    .list-dot:not(:first-child) {
        margin-top: 20px;
    }
}

.card-red-heading {
    .text-sm {
        font-size: 12px;
        @media(min-width:$screen-md-min) {
            font-size: 16px;
        }
    }
}

.card-red-contact {
    .inner {
        display: flex;
        flex-direction: column-reverse;
        gap: 0px;
        align-items: center;

        @media(min-width:$screen-lg-min) {
            gap: 40px;
            flex-direction: row;
        }
    }
}

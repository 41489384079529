.is-contractor {

    .inner-column {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 80px;

        .img {
            text-align: center;
        }
    }

    .btn-wrap {
        text-align: center;

        .btn-round {
            display: inline-block;
        }
    }

    .contact-document {
        border: 1px solid $color-border-frame;
        border-radius: 4px;
        padding: 20px 15px;
        margin-top: 20px;
        @media(min-width:$screen-sm-min) {
            margin-top: 40px;
            padding: 30px;
        }
        h3 {
            font-size: 1.8rem;
            font-weight: bold;
            padding-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                font-size: 2.6rem;
                padding-bottom: 15px;
            }
        }
        .inner {
            @media(min-width:$screen-md-min) {
                display: flex;
                justify-content: space-between;
            }
            .l-text {
                @media(min-width:$screen-md-min) {
                    width: 65.438%;
                }
                @media(min-width:$screen-lg-min) {
                    width: 67.438%;
                }
            }
            .text {
                font-size: 1.4rem;
                line-height: 1.5;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.8rem;
                }
            }

            .l-list {
                margin-top: 10px;
                @media(min-width:$screen-sm-min) {
                    display: flex;
                    margin-top: 15px;
                }
                li {
                    padding-bottom: 10px;
                    @media(min-width:$screen-sm-min) {
                        padding-bottom: 0;
                        margin-right: 15px;
                    }
                    @media(min-width:$screen-lg-min) {
                        margin-right: 40px;

                    }
                    &:last-child {
                        margin-right: 0;
                        padding-bottom: 0;
                    }
                }
                .link {
                    font-size: 14px;
                    text-decoration: underline;
                    color: $color-text-hover;
                    @media(min-width:$screen-sm-min) {
                        font-size: 1.6rem;
                    }
                    @media(min-width:$screen-lg-min) {
                        font-size: 1.8rem;
                    }
                    @media(min-width:$screen-md-min) {
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                .icon {
                    width: 20px;
                    height: 20px;
                    vertical-align: bottom;
                    margin-left: 5px;
                    @media(min-width:$screen-sm-min) {
                        width: 22px;
                        height: 22px;
                    }
                    @media(min-width:$screen-lg-min) {
                        width: 29px;
                        height: 29px;
                    }
                }
            }


            .l-btn {
                padding-top: 15px;
                @media(min-width:$screen-sm-min) {

                }
                @media(min-width:$screen-md-min) {
                    padding-top: 0;
                    width: 30.903%;
                }
                @media(min-width:$screen-lg-min) {
                    width: 28.903%;
                }
            }

        }
    }


    .l-coral {
        padding-top: 30px;
        @media(min-width:$screen-sm-min) {
            padding-top: 50px;
        }
        @media(min-width:$screen-md-min) {
            display: flex;
            justify-content: space-between;
        }
        .left {
            width: 80%;
            margin: auto;
            @media(min-width:$screen-sm-min) {
                width: 380px;
            }
            @media(min-width:$screen-md-min) {
                margin: 0;
                width: 34.546%;
            }
        }
        .right {
            padding-top: 20px;
            @media(min-width:$screen-md-min) {
                padding-top: 0;
                width: 62.6%;
            }

        }
        .text-link {
            padding-top: 15px;
            @media(min-width:$screen-sm-min) {
            }
            a {
                color: $color-text;
                font-size: 1.6rem;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.8rem;
                }
                .icon {
                    width: 9px;
                    height: 9px;
                    @media(min-width:$screen-sm-min) {
                        width: 13px;
                        height: 13px;
                    }
                }
            }
        }
        .text {
            font-size: 1.6rem;
            line-height: 1.75;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
            }
        }

        .l-list {
            padding-top: 20px;
            @media(min-width:$screen-sm-min) {
                padding-top: 55px;
                display: flex;
                justify-content: space-between;
                width: 90%;
                margin: auto;
            }
            @media(min-width:$screen-md-min) {
                width: 100%;
            }
            li {
                position: relative;
                margin-bottom: 15px;
                @media(min-width:$screen-sm-min) {
                    width: 20%;
                    margin-bottom: 0;
                }
                &:after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 15px 11px 0 11px;
                    border-color: #e79499 transparent transparent transparent;
                    margin: 5px auto;
                    @media(min-width:$screen-sm-min) {
                        margin: 0;
                        position: absolute;
                        top: 20%;
                        right: -10%;
                        border-width: 16px 0 16px 22px;
                        border-color: transparent transparent transparent #e79499;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                    &:after {
                        display: none;
                    }
                }
            }
            .group {
                display: flex;
                align-items: center;
                @media(min-width:$screen-sm-min) {
                    display: block;
                }

                .img {
                    display: block;
                    width: 60px;
                    margin-right: 15px;
                    @media(min-width:$screen-sm-min) {
                        width: auto;
                        margin-right: 0;
                    }

                    img {
                        @media(min-width:$screen-sm-min) {
                            display: block;
                            margin: auto;
                            width: 91px;
                        }
                    }
                }

                .caption {
                    display: block;
                    font-size: 1.4rem;
                    line-height: 1.5;
                    @media(min-width:$screen-sm-min) {
                        text-align: center;
                        font-size: 1.1rem;
                        padding-top: 10px;
                    }
                    br {
                        display: none;
                        @media(min-width:$screen-sm-min) {
                            display: block;
                        }
                    }
                }
            }
        }


    }

    .heading-emergency {
        background-color: $color-bg-yellow;
        text-align: center;
        font-weight: bold;
        font-size: 2.2rem;
        height: 70px;
        line-height: 70px;
        margin-bottom: 30px;
        position: relative;
        @media(min-width:$screen-sm-min) {
            font-size: 4.0rem;
            height: 130px;
            line-height: 130px;
            margin-bottom: 55px;
        }
        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 14px 13px 0 13px;
            bottom: -12px;
            border-color: $color-bg-yellow transparent transparent transparent;
            position: absolute;
            right: 0;
            left: 0;
            margin: auto;
            @media(min-width:$screen-sm-min) {
                border-width: 24px 23px 0 23px;
                bottom: -22px;
            }
        }
    }


    .content-nav {
        display: flex;
        justify-content: space-between;
        li {
            width: 48%;
            @media(min-width:$screen-sm-min) {
            }
            @media(min-width:$screen-lg-min) {
                width: 540px;
            }
        }
        .link {
            display: block;
            background-color: #fff;
            color: $color-text;
            text-align: center;
            border: 1px solid $color-border-frame;
            border-radius: 4px;
            font-size: 1.6rem;
            padding: 14px 5px;
            font-weight: bold;
            height: 100%;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
                padding: 40px 10px 0;
                height: 110px;
            }
            @media(min-width:$screen-md-min) {
                transition: all .2s;
                &:hover {
                    color: $color-red;
                }
            }


            .icon {
                display: block;
                width: 14px;
                height: 14px;
                margin-top: 10px;
                margin-left: auto;
                margin-right: auto;
                transform: rotate(180deg);
                @media(min-width:$screen-sm-min) {
                    width: 18px;
                    height: 18px;
                    margin-top: 20px;
                }
            }
        }

    }

    .resolution-box01 {
        background-color: $color-bg-yellow;
        border-radius: 4px;
        padding: 30px 15px;
        margin-top: 30px;
        @media(min-width:$screen-sm-min) {
            text-align: center;
            padding: 50px 40px;
            margin-top: 50px;
        }
        .img {
            text-align: center;
        }
        .inner-white {
            background-color: #fff;
            margin-top: 20px;
            padding: 20px 15px;
            @media(min-width:$screen-sm-min) {
                margin-top: 40px;
                padding: 40px 60px;
            }
        }
    }

    .automobile_liability_insurance-box {
        background-color: $color-bg;
        padding: 20px 15px;
        margin-top: 20px;
        @media(min-width:$screen-sm-min) {
            padding: 40px;
            margin-top: 40px;
        }
        .inner {
            padding: 20px 15px;
            background-color: #fff;
            @media(min-width:$screen-sm-min) {
                padding: 40px;
            }
        }
        .text-parts-2 {
            margin-bottom: 10px;
            font-weight: bold;
        }

    }

    .resolution-box02 {
        margin-top:20px;
        padding: 30px 20px;
        text-align: center;
        background-color: #fff;
        @media(min-width:$screen-sm-min) {
            margin-top: 60px;
            padding: 80px 30px;
        }
        @media(min-width:$screen-lg-min) {
            padding: 80px 175px;
        }


        .wrap-btn {
            padding-bottom: 0;
        }
        .list {
            margin-top: 20px;
            @media(min-width:$screen-sm-min) {
                margin-top: 35px;
                display: flex;
                justify-content: space-between;

            }
            li {
                margin-bottom: 20px;
                @media(min-width:$screen-sm-min) {
                    margin-bottom: 0;
                    width: 48%;
                }
                @media(min-width:$screen-lg-min) {
                    width: 360px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .link {
                color: $color-text;
                border: 1px solid $color-border-frame;
                display: block;
                padding: 20px 15px;
                background-color: #fff;
                @media(min-width:$screen-sm-min) {
                    padding: 35px;
                }
                @media(min-width:$screen-md-min) {
                    transition: all .2s;
                    &:hover {
                        .img {
                            opacity:0.8;
                        }
                        .text-parts-1 {
                            color: $color-red;
                        }
                    }
                }
                .heading-text-small {
                    color: $color-red;
                    padding-top: 15px;
                    @media(min-width:$screen-sm-min) {
                        padding-top: 35px;
                    }
                }
                .img {
                    transition: all .2s;
                }
                .text-parts-1 {
                    transition: all .2s;
                    font-weight: bold;
                }
            }
        }
    }
}

.is-shimaeco {
    .recycling-img {
        width: 60px;
        margin-right: 0;
        margin-left: auto;
        @media(min-width:$screen-sm-min) {
            width: 120px;
        }
    }
    .activity-box {
        background-color: $color-bg-yellow;
        padding: 20px 15px;
        @media(min-width:$screen-sm-min) {
            padding: 40px;
        }


        .inner div:first-child {
            margin-bottom: 25px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 50px;
            }
        }

        p.text-parts-1 {
            margin-bottom: 15px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 30px;
            }

            a {
                color: $color-text-hover;
                text-decoration: underline;
                @media(min-width:$screen-md-min) {
                    &:hover {
                        text-decoration: none;
                    }
                }

                icon.window {
                    fill: $color-text-hover;
                }
            }
            &.mb-30 {
                margin-bottom: 30px;
            }
        }
        p.text-parts-small {
            margin-bottom: 30px;
        }
    }

    .corporate-card-list {
        margin-bottom: 50px;
        margin-top: 40px;
        @media(min-width:$screen-sm-min) {
            margin-top: 80px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 110px;
        }
        li {
            margin-bottom: 4%;
            @media(min-width:$screen-sm-min) {
                width: 32%;
            }
            @media(min-width:$screen-lg-min) {
                width: 530px;
                margin-bottom: 40px;
            }
        }

        .corporate-card {
            display: block;
            color: $color-text;
            @media(min-width:$screen-md-min) {
                &:hover {
                    .img {
                        &:before {
            				opacity:0.2;
            			}
                    }
                    .text {
                        color: $color-red;
                    }

                }
            }

            .img {
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    opacity: 0;
                    transition: all .2s;
                }
            }

            .text {
                font-size: 1.4rem;
                margin-top: 10px;
                display: block;
                line-height: 1.5;
                @media(min-width:$screen-sm-min) {
                    font-size: 2rem;
                    margin-top: 15px;
                }
                @media(min-width:$screen-md-min) {
                    font-size: 2.2rem;
                }
                @media(min-width:$screen-md-min) {
                    transition: all .2s;
                }

            }
            .icon {
                width: 10px;
                height: 10px;
                vertical-align: middle;
                position: relative;
                top: -2px;
                @media(min-width:$screen-sm-min) {
                    width: 16px;
                    height: 16px;
                    top: -1px;
                }
            }

        }
    }
}

.is-one_jibai {
    .heading-huge .content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
    }

    .banner-one_jibai {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }
}

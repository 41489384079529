@charset "utf-8";

.accident-contact {
    .card-red-heading {
        .heading {
            line-height: 1.2;
            padding: 15px 10px;
        }
        .text-sm {
            font-size: 1.2rem;
            @media(min-width:$screen-md-min) {
                font-size: 1.6rem;
            }
        }
        .padding-variable {
            padding: 24px 10px;
            @media(min-width:$screen-md-min) {
                padding: 30px 10px;
            }
        }
    }
    .card-red-contact {
        .text-left {
            text-align: left;
        }
        .list-dot {
            line-height: 1.5;
            text-align: left;
            font-size: 1.4rem;
            li+li {
                margin-top: 0px;
            }
        }
        .service-link {
            .text-parts-1 {
                text-align: center;
            }
        }
        .l-caption {
            display: inline-block;
        }
        .caption-text {
            text-align: left;
            line-height: 1.5;
            margin-top: 20px;
            font-size: 1.4rem;
        }
    }
}


.accident-contact-travel {
    .card-red-heading {
        .heading {
            line-height: 1.2;
            padding: 15px 10px;
        }
        .text-sm {
            font-size: 1.2rem;
            @media(min-width:$screen-md-min) {
                font-size: 1.6rem;
            }
        }
        .padding-variable {
            padding: 24px 10px;
            @media(min-width:$screen-md-min) {
                padding: 30px 10px;
            }
        }

        @media(min-width:$screen-sm-min) {
            width: 100%;
        }
        @media(min-width:$screen-lg-min) {
            width: 100%;
        }


    }
    .card-red-contact {

        .l-number {
            .number {
                font-size: 24px;
                @media(min-width:$screen-sm-min) {
                    font-size: 28px;
                }
                @media(min-width:$screen-md-min) {
                    font-size: 36px;
                }
            }
        }

        .list-dot {
            line-height: 1.5;
            text-align: left;
            font-size: 1.4rem;
            li+li {
                margin-top: 0px;
            }
        }
        .l-caption {
            display: inline-block;
        }
        .caption-text {
            text-align: left;
            line-height: 1.5;
            margin-top: 20px;
            font-size: 1.4rem;
        }

        .contact-wrap {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .contact-group {
                border: 1px solid #d1d1d1;
                flex: 1;

                .header {
                    background-color: #fdefef;
                    padding: 16px;

                    .text {
                        font-size: 2rem;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }
                    .text-small {
                        font-size: 1.4rem;
                        font-weight: bold;
                        line-height: 1.4;
                    }

                    @media(max-width:$screen-sm-min) {

                        .text {
                            font-size: 1.5rem;
                            margin-bottom: 5px;
                        }
                        .text-small {
                            font-size: 1.2rem;
                            line-height: 1.2;
                        }
                    }
                }

                .group {
                    border: none;
                    padding-bottom: 20px;
                    padding-right: 20px;
                    padding-left: 20px;

                    .item-wrap {
                        display: flex;
                        gap: 40px;
                        align-items: center;
                        justify-content: center;

                        @media(max-width:$screen-sm-min) {
                            flex-direction: column;
                        }
                    }
                    .l-text {
                        text-align: left;

                        .text {
                            font-size: 1.6rem;
                            font-weight: bold;
                            line-height: 1.4;
                        }
                        .text-small {
                            font-size: 1.4rem;
                            margin-top: 8px;
                            margin-bottom: 16px;
                            line-height: 1.4;
                        }

                        @media(max-width:$screen-sm-min) {

                            .text {
                                font-size: 1.4rem;
                            }
                            .text-small {
                                font-size: 1.2rem;
                            }
                        }
                    }
                    .number-wrap {
                        display: flex;
                        flex-direction: column;
                        gap: 12px 0;
                        text-align: left;

                        .l-number {
                            .number {
                                @media(min-width:$screen-sm-min) {
                                    font-size: 26px;
                                }
                                @media(min-width:$screen-md-min) {
                                    font-size: 28px;
                                }
                            }
                        }
                    }
                }

                .group:not(:first-child){
                    border-top: 1px solid #d1d1d1;
                }
            }


        }
    }

    .l-caption {
        border-top: 1px solid #d1d1d1;
    }
}

.spacer-large {
    padding-bottom: 40px;
    @media(min-width:$screen-sm-min) {
        padding-bottom: 80px;
    }

}
.spacer-medium {
    padding-bottom: 30px;
    @media(min-width:$screen-sm-min) {
        padding-bottom: 50px;
    }
}
.spacer-small {
    padding-bottom: 20px;
    @media(min-width:$screen-sm-min) {
        padding-bottom: 30px;
    }
}

.spacer-xsmall {
    padding-bottom: 10px;
    margin-bottom: 0 !important;
    @media(min-width:$screen-sm-min) {
        padding-bottom: 20px;
    }
}

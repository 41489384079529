.is-home {

    /* --------------------------------------------------*/
    /* KV
    /* --------------------------------------------------*/
    .l-kv {
        background-color: $color-bg;
        padding: 0px 0 40px;
        position: relative;
        /* margin-bottom: 30px; */

        /* NOTE: 2021/03/19: ホームのスライドショー部分にて、スマホ時に横スクロールが生じるため追加 */
        overflow: hidden;

        @media(min-width:$screen-sm-min) {
            padding: 0px 0 70px;
            /* margin-bottom: 50px; */
        }
        .img {
            /* height: 254px; */
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;

            /* padding-top: 67.5%; */
            /* NOTE: 画像の縦横比から高さを割り出す */
            padding-top: calc(100vw * 768 / 1080);

            position: relative;
            @media(min-width:$screen-sm-min) {
                padding-top: 27.5%;
                /* background-size: contain;
                padding-top: 0;
                height: 460px; */
            }
            &.sp {
                display: block;
                @media(min-width:$screen-sm-min) {
                    display: none;
                }
            }
            &.pc {
                display: none;
                @media(min-width:$screen-sm-min) {
                    display: block;
                }
                /* --------------------------------------------------*/
                /* 70周年記念CSS
                /* --------------------------------------------------*/
                &.aniv70-key-visual {
                    padding-top: 460px;
                    @media(min-width: 1700px) {
                        padding-top: 30%;
                    }
                }
                &.smartphonepay-key-visual {
                    padding-top: 460px;
                    &:hover {
                        opacity: 0.8;
                    }
                    @media(min-width: 1700px) {
                        padding-top: 30%;
                    }
                }
            }

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                z-index: 1;
                opacity: 0;
                transition: all .2s;
            }
        }

        .variable-box {
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;

            /* padding-top: 67.5%; */
            /* NOTE: 画像の縦横比から高さを割り出す */
            padding-top: calc(100vw * 768 / 1080);

            position: relative;
            background-image: url(../img/top/kv-01.jpg);
            @media(min-width:$screen-sm-min) {
                padding-top: 0;
                height: 460px;
            }
            @media(min-width: 1700px) {
                padding-top: 30%;
            }
            .btn-link {
                display: block;
                position: absolute;
                top: 17%;
                left: 0;
                right: 0;
                margin-right: auto;
                margin-left: auto;
                width: 55%;
                @media(min-width:$screen-sm-min) {
                    top: 20%;
                    max-width: 300px;
                    width: 100%;
                }
                @media(min-width:$screen-md-min) {
                    transition: all .2s;
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

        }

        &:not(.slick-initialized) {
            > div:not(:first-child){
                display: none;
            }
        }


        .slick-dots {
            position: absolute;
            bottom: 0;
            right: 0;
            bottom: 15px;
            left: 0;
            margin: auto;
            @media(min-width:$screen-sm-min) {
                bottom: 30px;
            }
            li {
                padding: 0 4px;
                @media(min-width:$screen-sm-min) {
                    padding: 0 8px;
                }
            }

            .slick-active {
                button {
                    background-color: $color-red;
                }
            }
            button {
                display: block;
                border-radius: 50%;
                width: 7px;
                height: 7px;
                background-color: $color-border-navi;
                @media(min-width:$screen-sm-min) {
                    width: 13px;
                    height: 13px;
                }
                @media(min-width:$screen-md-min) {
                    transition: all .2s;
                    &:hover {
                        background-color: $color-red;
                    }
                }
            }
        }

        .link {
            @media(min-width:$screen-md-min) {
                &:hover {
                    .img {
                        &:before {
                            opacity: 0.2;
                        }
                    }
                }
            }
        }
    }


    /* --------------------------------------------------*/
    /* 重要なお知らせ
    /* --------------------------------------------------*/
    .l-important-notices {
        border-style: solid;
        border-color: #ECADAF;
        border-width: 1px;
        border-radius: 4px;
        width: 100%;
        margin-bottom: 30px;
        margin-top: 30px;
        @media(min-width:$screen-sm-min) {
            display: table;
            border-width: 3px;
            margin-top: 50px;
            margin-bottom: 50px;
        }
        .heading {
            background-color: $color-bg-red;
            text-align: center;
            color: $color-red;
            font-size: 1.5rem;
            padding: 11px 0;
            font-weight: bold;
            @media(min-width:$screen-sm-min) {
                padding: 0;
                width: 240px;
                display: table-cell;
                vertical-align: middle;
                font-size: 1.8rem;
            }
        }
        .l-list {
            padding: 15px 15px;
            @media(min-width:$screen-sm-min) {
                padding: 28px 40px;
                display: table-cell;
                vertical-align: middle;
            }
            li {
                padding-bottom: 10px;
                @media(min-width:$screen-sm-min) {
                    padding-bottom: 26px;
                }
                &:last-child {
                    padding-bottom: 0;
                }
                a {
                    line-height: 1.5;
                    color: $color-base;
                    font-size: 1.4rem;
                    @media(min-width:$screen-sm-min) {
                        font-size: 1.8rem;
                    }
                }

            }
        }

    }



    /* --------------------------------------------------*/
    /* 交通事故にあわれたら
    /* --------------------------------------------------*/
    .l-accident {
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: center;
        @media(min-width:$screen-sm-min) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .text-parts-1 {
            text-align: left;
            @media(min-width:$screen-sm-min) {
                text-align: center;

            }
        }

        .box-announce {
            background-color: #fff;
            border: 1px solid $color-border-frame;
            margin-top: 20px;
            padding: 20px 15px;
            border-radius: 4px;
            @media(min-width:$screen-sm-min) {
                margin-top: 40px;
                padding: 30px 30px;
                align-items: center;
                justify-content: space-between;
            }
            @media(min-width:$screen-md-min) {
                display: flex;
            }
            @media(min-width:$screen-lg-min) {
                padding: 30px 50px;
            }





            h4 {
                font-size: 1.6rem;
                font-weight: bold;
                line-height: 1.5;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.8rem;
                }
                @media(min-width:$screen-lg-min) {
                    font-size: 2rem;
                }
            }
            .l-list {
                display: flex;
                justify-content: space-between;
                padding-top: 15px;
                @media(min-width:$screen-sm-min) {
                    justify-content: flex-start;
                }
                @media(min-width:$screen-md-min) {
                    padding-top: 0;
                }
                li {
                    width: 48%;
                    @media(min-width:$screen-sm-min) {
                        width: 300px;
                        margin-left: 30px;
                    }
                    @media(min-width:$screen-lg-min) {
                        margin-left: 40px;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }


        }




    }



    /* --------------------------------------------------*/
    /* ご検討中のお客さま
    /* --------------------------------------------------*/
    .l-consideration {
        text-align: center;
        padding-top: 40px;
        @media(min-width:$screen-sm-min) {
            padding-top: 80px;
        }
        .heading-top {
            @media(min-width:$screen-sm-min) {
                margin-bottom: 45px;
            }
        }
        .btn-square {
            margin-top: 10px;
            @media(min-width:$screen-sm-min) {
                margin-top: 20px;
            }
        }



    }


    /* --------------------------------------------------*/
    /* ご契約者さま
    /* --------------------------------------------------*/
    .l-contractor {
        .text-parts-1 {
            text-align: center;
        }


        .l-information-link {
            margin-top: 30px;
            @media(min-width:$screen-sm-min) {
                margin-top: 40px;
                display: flex;
                justify-content: space-between;

            }
            .card-red-heading {
                @media(min-width:$screen-sm-min) {
                    width: 55%;
                }
                @media(min-width:$screen-lg-min) {
                    width: 625px;
                }
                .text-parts-1 {
                    text-align: left;
                }
            }

            .column {
                background-color: $color-bg;
                padding: 30px 15px;
                margin-top: 30px;
                @media(min-width:$screen-sm-min) {
                    margin-top: 0;
                    padding: 30px 25px;
                    width: 42%;
                }
                @media(min-width:$screen-lg-min) {
                    width: 435px;
                }
                .inner {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    @media(min-width:$screen-sm-min) {
                        margin-bottom: 33px;
                    }
                    @media(min-width:$screen-md-min) {
                        margin-bottom: 3px;
                    }
                    .left {
                        width: 64%;
                    }
                    .right {
                        width: 25.978%;
                        @media(min-width:$screen-sm-min) {
                            position: relative;
                            top: -10px;
                            width: 30%;
                        }
                        @media(min-width:$screen-md-min) {
                            width: 25.978%;
                        }
                    }
                }
                h3 {
                    font-size: 2rem;
                    font-weight: bold;
                    padding-bottom: 25px;
                    @media(min-width:$screen-sm-min) {
                        padding-bottom: 35px;
                        font-size: 2.6rem;
                    }
                }
                .text {
                    line-height: 1.5;
                    font-size: 1.4rem;
                    @media(min-width:$screen-sm-min) {
                        font-size: 1.8rem;
                    }
                }

                .btn-round {
            		margin: auto;
            		width: 90%;
            		@media(min-width:$screen-sm-min) {
            			width: 100%;
            		}
            	}



            }


        }

    }


    /* --------------------------------------------------*/
    /* ご検討中のお客さま
    /* --------------------------------------------------*/
    .l-effort {
        background-color: $color-bg;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 30px;
        @media(min-width:$screen-sm-min) {
            padding-top: 80px;
            padding-bottom: 60px;
        }
        .l-list {
            margin-top: 20px;

            /* 768px */
            @media(min-width:$screen-sm-min) {
                display: flex;
                justify-content: space-between;
                margin-top: 45px;
            }
            li {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }

                /* 768px */
                @media(min-width:$screen-sm-min) {
                    flex: 1 1 0%;
                    /* width: 48%; */
                    margin-bottom: 0;
                    margin-right: 40px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                /* 1200px */
                @media(min-width:$screen-lg-min) {
                    /* width: 530px; */
                }
            }
            .group {
                text-align: left;
                display: block;
                color: $color-text;
                text-decoration: none;
                @media(min-width:$screen-md-min) {
                    &:hover{
                        .img {
                            &:before {
                                opacity:0.2;
                            }
                        }
            		}
                }

                .img {
                    position: relative;
                    &:before {
                		content: '';
                		display: block;
                		width: 100%;
                		height: 100%;
                		background-color: #fff;
                		position: absolute;
                		top: 0;
                		left: 0;
                		z-index: 1;
                		opacity: 0;
                		transition: all .2s;
                	}
                }


                .title {
                    font-size: 1.6rem;
                    padding-top: 15px;
                    font-weight: bold;
                    @media(min-width:$screen-sm-min) {
                        font-size: 2rem;
                        padding-top: 20px;
                    }
                    @media(min-width:$screen-md-min) {
                        font-size: 2.4rem;
                        padding-top: 35px;
                    }
                }
                .text {
                    line-height: 1.5;
                    font-size: 1.4rem;
                    padding-top: 5px;
                    @media(min-width:$screen-sm-min) {
                        font-size: 1.6rem;
                        padding-top: 10px;
                    }
                    @media(min-width:$screen-md-min) {
                        font-size: 1.8rem;
                    }
                }

            }
        }
    }

    /* --------------------------------------------------*/
    /* ニュース
    /* --------------------------------------------------*/
    .l-news {
        padding-top: 40px;
        @media(min-width:$screen-sm-min) {
            padding-top: 80px;

        }

        .post-area {
            background-color: #fff;
            border: 1px solid $color-border-frame;
            border-radius: 4px;
            padding: 20px 15px;
            @media(min-width:$screen-md-min) {
                padding: 40px;
            }
        }

    }

    /* --------------------------------------------------*/
    /* コラム
    /* --------------------------------------------------*/
    .l-column {
        padding-bottom: 0;
        @media(min-width:$screen-sm-min) {
        }
        .post-list {
            padding-bottom: 20px;
            @media(min-width:$screen-sm-min) {
                padding-bottom: 40px;
            }
        }
    }
}

/* a:link, a:visited を追加するとボタンの文字色にも影響がある */
a {
    text-decoration: none;
}
a:hover,
a:active {
    text-decoration: none;
}

html {
    font-size: 10px;
}

.ta-l {
    text-align: left !important;
}

.ta-c {
    text-align: center !important;
}
.ta-r {
    text-align: right !important;
}

.text-red-light {
    color: $color-red-light;
}

.text-red {
    color: $color-red;
}

.fw-b {
    font-weight: bold;
}

.fw-n {
    font-weight: normal;
}

.text-parts-small {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #5C5C5C;
    text-indent: 0;

    @media(min-width:$screen-sm-min) {
        font-size: 1.4rem;
        line-height: 1.75;
    }
}

.text-parts-1-group p,
.text-parts-1 {
    font-size: 1.4rem;
    line-height: 1.75;
    color: $color-text;
    @media(min-width:$screen-sm-min) {
        font-size: 1.8rem;
    }
}

.text-parts-2 {
    color: $color-text;
    font-size: 1.6rem;
    line-height: 1.75;
    @media(min-width:$screen-sm-min) {
        font-size: 2rem;
    }
}


.text-parts-3 {
    font-size: 2rem;
    @media(min-width:$screen-sm-min) {
        font-size: 2.6rem;
    }
}

.sup {
    vertical-align: super;
    font-size: smaller;
}

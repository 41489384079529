html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	font-size: 100%;
	vertical-align: baseline;
}

html {
	line-height: 1;
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

caption,
th,
td {
	text-align: left;
	font-weight: normal;
	vertical-align: middle;
}

q,
blockquote {
	quotes: none;
}

q:before,
q:after,
blockquote:before,
blockquote:after {
	content: "";
	content: none;
}

a img {
	border: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
	display: block;
}

input, button, textarea, select {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-appearance: none;
	appearance: none;
}

/* 色 */
/*----------------------------------------------------------------------------*/
/* 共通の色 */



/* 文字の色 */



/* 背景の色 */


/* 線の色 */

/* アラート色 */

/* フォーム */

/* モジュールの色 */


/* px数 (変数化しすぎると逆に大変なので極力増やさない) */
/*----------------------------------------------------------------------------*/
/* ヘッダーの高さ */

/* 影 */

/* 行間 */
/* $line-height-text: 1.75; */
/* $line-height-title: 1.5; */

/* フォント */

/* WordPressの管理者用ヘッダー */
body.logged-in #wpadminbar {
        position: fixed;
        top: 0;
    }
body.logged-in header {
        top: 46px;
    }
body.logged-in header .global-nav nav {
            top: calc(46px + 50px);
        }
body.logged-in .alert-group {
        top: calc(46px + 50px + 5px);
    }

@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 783px) {
        body.logged-in header {
            top: 32px;
        }

            body.logged-in header .global-nav nav {
                top: calc(32px + 50px);
            }
        body.logged-in .alert-group {
            top: calc(32px + 50px + 5px);
        }

}
@media only screen and (min-width: 1000px) {
    /* body.customize-support header, */
        body.logged-in header .global-nav nav {
            top: auto;
        }
        body.logged-in .alert-group {
            top: calc(32px + 70px + 5px);
        }
}

/* WordPress管理画面対応 */
/*
html.global-nav-active {
    body.customize-support .toggle-content {
        max-height: calc(100vh - 46px - 70px);

        @media only screen and (min-width: 783px) {
            max-height: calc(100vh - 32px - 70px);
        }
    }
}
*/

/* WordPress */
.mw_wp_form form .error {
        padding: 5px 10px;
        background-color: #ee9999;
        color: #ffffff;
    }

/* BODYに対するスタイル */
body {
    /* word-break: break-all; */
    color: #222222;
    font-family: 'hiragino kaku gothic pro', sans-serif;
    word-wrap: break-word;
}

* {
    box-sizing: border-box;
}

img {
    vertical-align: bottom;
    max-width: 100%;
}

/* chromeで表示される青枠を消す */
*:focus {
    outline: none;
}

.view-sp {
    display: block;
}

@media(min-width:768px) {

.view-sp {
        display: none
}
    }

.view-tab {
    display: block;
}

@media(min-width:992px) {

.view-tab {
        display: none
}
    }

.view-pc {
    display: none;
}

@media(min-width:992px) {

.view-pc {
        display: block
}
    }

.view-tab-pc {
    display: none;
}

@media(min-width:768px) {

.view-tab-pc {
        display: block
}
    }

.js-tel a {
        color: #222222;
        text-decoration: none;
    }



/* 便利なCSSを記述 */
/*----------------------------------------------------------------------------*/
:first-child {
    margin-top: 0;
}
:last-child {
    margin-bottom: 0;
}

/* clearfix、回り込み */
/*---------------------------------------------------*/
/*
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    zoom: 1;
}
.float-left { float: left; }
.float-right { float: right; }
.clear { clear: both; }
*/

/* ブロック */
/*---------------------------------------------------*/
.d-block { display: block !important; }
.d-inline { display: inline; }
.d-inline-block { display: inline-block; }
.d-table { display: table; }
.d-table-cell { display: table-cell; }
.d-flex { display: flex; }

/* 余白 */
/*---------------------------------------------------*/
.mt-auto { margin-top: auto !important; }
.mt-0 { margin-top: 0px !important; }
.mt-5 { margin-top: 5px !important; }
.mt-10 { margin-top: 10px !important; }
.mt-15 { margin-top: 15px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px !important; }
.mt-50 { margin-top: 50px !important; }
.mt-60 { margin-top: 60px !important; }
.mt-70 { margin-top: 70px !important; }
.mt-80 { margin-top: 80px !important; }
.mt-90 { margin-top: 90px !important; }
.mt-100 { margin-top: 100px !important; }
.mt-110 { margin-top: 110px !important; }
.mt-120 { margin-top: 120px !important; }
.mt-130 { margin-top: 130px !important; }
.mt-140 { margin-top: 140px !important; }
.mt-150 { margin-top: 150px !important; }
.mt-200 { margin-top: 200px !important; }
.mt-250 { margin-top: 250px !important; }

.mb-auto { margin-bottom: auto !important; }
.mb-0 { margin-bottom: 0px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mb-60 { margin-bottom: 60px !important; }
.mb-70 { margin-bottom: 70px !important; }
.mb-80 { margin-bottom: 80px !important; }
.mb-90 { margin-bottom: 90px !important; }
.mb-100 { margin-bottom: 100px !important; }
.mb-110 { margin-bottom: 110px !important; }
.mb-120 { margin-bottom: 120px !important; }
.mb-130 { margin-bottom: 130px !important; }
.mb-140 { margin-bottom: 140px !important; }
.mb-150 { margin-bottom: 150px !important; }
.mb-200 { margin-bottom: 200px !important; }
.mb-250 { margin-bottom: 250px !important; }

.ml-auto { margin-left: auto !important; }
.ml-0 { margin-left: 0px !important; }
.ml-5 { margin-left: 5px !important; }
.ml-10 { margin-left: 10px !important; }
.ml-15 { margin-left: 15px !important; }
.ml-20 { margin-left: 20px !important; }
.ml-30 { margin-left: 30px !important; }
.ml-40 { margin-left: 40px !important; }
.ml-50 { margin-left: 50px !important; }
.ml-60 { margin-left: 60px !important; }
.ml-70 { margin-left: 70px !important; }
.ml-80 { margin-left: 80px !important; }
.ml-90 { margin-left: 90px !important; }
.ml-100 { margin-left: 100px !important; }
.ml-110 { margin-left: 110px !important; }
.ml-120 { margin-left: 120px !important; }
.ml-130 { margin-left: 130px !important; }
.ml-140 { margin-left: 140px !important; }
.ml-150 { margin-left: 150px !important; }
.ml-200 { margin-left: 200px !important; }
.ml-250 { margin-left: 250px !important; }

.mr-auto { margin-right: auto !important; }
.mr-0 { margin-right: 0px !important; }
.mr-5 { margin-right: 5px !important; }
.mr-10 { margin-right: 10px !important; }
.mr-15 { margin-right: 15px !important; }
.mr-20 { margin-right: 20px !important; }
.mr-30 { margin-right: 30px !important; }
.mr-40 { margin-right: 40px !important; }
.mr-50 { margin-right: 50px !important; }
.mr-60 { margin-right: 60px !important; }
.mr-70 { margin-right: 70px !important; }
.mr-80 { margin-right: 80px !important; }
.mr-90 { margin-right: 90px !important; }
.mr-100 { margin-right: 100px !important; }
.mr-110 { margin-right: 110px !important; }
.mr-120 { margin-right: 120px !important; }
.mr-130 { margin-right: 130px !important; }
.mr-140 { margin-right: 140px !important; }
.mr-150 { margin-right: 150px !important; }
.mr-200 { margin-right: 200px !important; }
.mr-250 { margin-right: 250px !important; }

.pt-0 { padding-top: 0px !important; }
.pt-5 { padding-top: 5px !important; }
.pt-10 { padding-top: 10px !important; }
.pt-15 { padding-top: 15px !important; }
.pt-20 { padding-top: 20px !important; }
.pt-30 { padding-top: 30px !important; }
.pt-40 { padding-top: 40px !important; }
.pt-50 { padding-top: 50px !important; }
.pt-60 { padding-top: 60px !important; }
.pt-70 { padding-top: 70px !important; }
.pt-80 { padding-top: 80px !important; }
.pt-90 { padding-top: 90px !important; }
.pt-100 { padding-top: 100px !important; }
.pt-110 { padding-top: 110px !important; }
.pt-120 { padding-top: 120px !important; }
.pt-130 { padding-top: 130px !important; }
.pt-140 { padding-top: 140px !important; }
.pt-150 { padding-top: 150px !important; }
.pt-200 { padding-top: 200px !important; }
.pt-250 { padding-top: 250px !important; }

.pb-0 { padding-bottom: 0px !important; }
.pb-5 { padding-bottom: 5px !important; }
.pb-10 { padding-bottom: 10px !important; }
.pb-15 { padding-bottom: 15px !important; }
.pb-20 { padding-bottom: 20px !important; }
.pb-30 { padding-bottom: 30px !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-50 { padding-bottom: 50px !important; }
.pb-60 { padding-bottom: 60px !important; }
.pb-70 { padding-bottom: 70px !important; }
.pb-80 { padding-bottom: 80px !important; }
.pb-90 { padding-bottom: 90px !important; }
.pb-100 { padding-bottom: 100px !important; }
.pb-110 { padding-bottom: 110px !important; }
.pb-120 { padding-bottom: 120px !important; }
.pb-130 { padding-bottom: 130px !important; }
.pb-140 { padding-bottom: 140px !important; }
.pb-150 { padding-bottom: 150px !important; }
.pb-200 { padding-bottom: 200px !important; }
.pb-250 { padding-bottom: 250px !important; }

.pl-0 { padding-left: 0px !important; }
.pl-5 { padding-left: 5px !important; }
.pl-10 { padding-left: 10px !important; }
.pl-15 { padding-left: 15px !important; }
.pl-20 { padding-left: 20px !important; }
.pl-30 { padding-left: 30px !important; }
.pl-40 { padding-left: 40px !important; }
.pl-50 { padding-left: 50px !important; }
.pl-60 { padding-left: 60px !important; }
.pl-70 { padding-left: 70px !important; }
.pl-80 { padding-left: 80px !important; }
.pl-90 { padding-left: 90px !important; }
.pl-100 { padding-left: 100px !important; }
.pl-110 { padding-left: 110px !important; }
.pl-120 { padding-left: 120px !important; }
.pl-130 { padding-left: 130px !important; }
.pl-140 { padding-left: 140px !important; }
.pl-150 { padding-left: 150px !important; }
.pl-200 { padding-left: 200px !important; }
.pl-250 { padding-left: 250px !important; }

.pr-0 { padding-right: 0px !important; }
.pr-5 { padding-right: 5px !important; }
.pr-10 { padding-right: 10px !important; }
.pr-15 { padding-right: 15px !important; }
.pr-20 { padding-right: 20px !important; }
.pr-30 { padding-right: 30px !important; }
.pr-40 { padding-right: 40px !important; }
.pr-50 { padding-right: 50px !important; }
.pr-60 { padding-right: 60px !important; }
.pr-70 { padding-right: 70px !important; }
.pr-80 { padding-right: 80px !important; }
.pr-90 { padding-right: 90px !important; }
.pr-100 { padding-right: 100px !important; }
.pr-110 { padding-right: 110px !important; }
.pr-120 { padding-right: 120px !important; }
.pr-130 { padding-right: 130px !important; }
.pr-140 { padding-right: 140px !important; }
.pr-150 { padding-right: 150px !important; }
.pr-200 { padding-right: 200px !important; }
.pr-250 { padding-right: 250px !important; }

/* 幅 */
/*---------------------------------------------------*/
.w-5 { width: 5% !important; }
.w-10 { width: 10% !important; }
.w-15 { width: 15% !important; }
.w-20 { width: 20% !important; }
.w-25 { width: 25% !important; }
.w-30 { width: 30% !important; }
.w-35 { width: 35% !important; }
.w-40 { width: 40% !important; }
.w-45 { width: 45% !important; }
.w-50 { width: 50% !important; }
.w-55 { width: 55% !important; }
.w-60 { width: 60% !important; }
.w-65 { width: 65% !important; }
.w-70 { width: 70% !important; }
.w-75 { width: 75% !important; }
.w-80 { width: 80% !important; }
.w-85 { width: 85% !important; }
.w-90 { width: 90% !important; }
.w-95 { width: 95% !important; }
.w-100 { width: 100% !important; }







/* その他 */
/*---------------------------------------------------*/
/* いいねボタンの横幅が途切れてしまう現象への対策 */
.fb-like iframe {
    max-width: none !important;
}


/* 画面幅によって非表示 */
/*----------------------------------------------------------------------------*/

/* a:link, a:visited を追加するとボタンの文字色にも影響がある */
a {
    text-decoration: none;
}
a:hover,
a:active {
    text-decoration: none;
}

html {
    font-size: 10px;
}

.ta-l {
    text-align: left !important;
}

.ta-c {
    text-align: center !important;
}
.ta-r {
    text-align: right !important;
}

.text-red-light {
    color: #D3484E;
}

.text-red {
    color: #CA101A;
}

.fw-b {
    font-weight: bold;
}

.fw-n {
    font-weight: normal;
}

.text-parts-small {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #5C5C5C;
    text-indent: 0;
}

@media(min-width:768px) {

.text-parts-small {
        font-size: 1.4rem;
        line-height: 1.75
}
    }

.text-parts-1-group p,
.text-parts-1 {
    font-size: 1.4rem;
    line-height: 1.75;
    color: #222222;
}

@media(min-width:768px) {

.text-parts-1-group p,
.text-parts-1 {
        font-size: 1.8rem
}
    }

.text-parts-2 {
    color: #222222;
    font-size: 1.6rem;
    line-height: 1.75;
}

@media(min-width:768px) {

.text-parts-2 {
        font-size: 2rem
}
    }


.text-parts-3 {
    font-size: 2rem;
}


@media(min-width:768px) {


.text-parts-3 {
        font-size: 2.6rem
}
    }

.sup {
    vertical-align: super;
    font-size: smaller;
}

.heading-huge {
    border-style: solid;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: #EEEEEE;
    font-size: 2.2rem;
    margin-bottom: 40px;
    background-image: linear-gradient(
    	-45deg,
    	#F7F7F7 25%,
    	#eee 25%,
    	#eee 50%,
    	#F7F7F7 50%,
    	#F7F7F7 75%,
    	#eee 75%,
    	#eee
    );
    background-size: 6px 6px;
    background-attachment: fixed;
    position: relative;
    line-height: 1.5;
}

@media(min-width:768px) {

.heading-huge {
        font-size: 4.0rem;
        margin-bottom: 80px
}
    }

@media(min-width:992px) {

.heading-huge {
        margin-bottom: 100px
}
    }

.heading-huge .l-inner {
        min-height: 100px;
        padding: 10px 0;
        display: flex;
        align-items: center;
    }

@media(min-width:768px) {

.heading-huge .l-inner {
            height: 200px
    }
        }

.heading-huge .l-inner .icon.premium {
            position: static;
            margin: 0;
            display: block;
            width: 98px !important;
            height: 40px;
            background-size: cover;
            margin-left: 10px;
        }

@media(min-width:768px) {

.heading-huge .l-inner .icon.premium {
                margin-left: 50px;
                width: 123px !important;
                height: 50px
        }
            }

.heading-huge .l-inner .hover-img {
            display: block;
        }

.heading-huge .l-inner .content.text {
                font-weight: bold;
            }

@media(min-width:768px) {

.heading-huge .l-inner .content.text {
                    width: auto
            }
                }

.heading-huge .l-inner .content:only-child {
                width: 100%;
            }

.heading-border-red {
    font-size: 2.2rem;
    padding-left: 10px;
    margin-bottom: 25px;
    position: relative;
    font-weight: bold;
    line-height: 1.25;
    padding-top: 5px;
}

@media(min-width:768px) {

.heading-border-red {
        font-size: 3.6rem;
        padding-left: 25px;
        margin-bottom: 45px
}
    }

.heading-border-red:before {
        content: '';
        display: block;
        width: 3px;
        height: 80%;
        background-color: #CA101A;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;

    }

@media(min-width:768px) {

.heading-border-red:before {
            width: 6px
            /* height: 36px; */

    }

        }

.heading-top-bottom-line {
    font-size: 2.4rem;
    margin-bottom: 30px;
    line-height: 1.25;
    font-weight: bold;
    border-style: solid;
    border-color: #F0F0F0;
    border-top-width: 3px;
    border-bottom-width: 3px;
    padding: 20px 0 15px;
}

@media(min-width:768px) {

.heading-top-bottom-line {
        padding: 50px 0 40px;
        font-size: 3.8rem;
        margin-bottom: 60px
}
    }

.heading-text-largest {
    font-size: 2.4rem;
    margin-bottom: 20px;
    line-height: 1.25;
    font-weight: bold;
}

@media(min-width:768px) {

.heading-text-largest {
        font-size: 3.8rem;
        margin-bottom: 30px
}
    }


.heading-text-large {
    font-size: 2.2rem;
    line-height: 1.25;
    font-weight: bold;
    margin-bottom: 20px;
}


@media(min-width:768px) {


.heading-text-large {
        font-size: 3.0rem;
        margin-bottom: 30px
}
    }
.heading-text-medium {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.25;
    margin-bottom: 10px;
}
@media(min-width:768px) {
.heading-text-medium {
        margin-bottom: 25px;
        font-size: 2.6rem
}
    }

.heading-text-small {
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1.25;
    margin-bottom: 20px;

}

@media(min-width:768px) {

.heading-text-small {
        font-size: 2.4rem

}
    }


.heading-top {
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
}


@media(min-width:768px) {


.heading-top {
        font-size: 3.6rem
}
    }


.heading-top .icon {
        position: relative;
        top: 2px;
    }

.heading-top-medium {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 10px;
    line-height: 1.5;
}

@media(min-width:768px) {

.heading-top-medium {
        font-size: 3.0rem;
        margin-bottom: 15px
}
    }

.heading-top-medium .icon {
        width: 22px;
        height: 22px;
        vertical-align: middle;
        margin-right: 15px;

    }

@media(min-width:768px) {

.heading-top-medium .icon {
            width: 42px;
            height: 42px;
            position: relative;
            margin-right: 25px;
            top: -2px

    }

        }

.heading-arrow-link {
    position: relative;
    margin-bottom: 20px;
}

@media(min-width:768px) {

.heading-arrow-link {
        margin-bottom: 40px
}
    }

.heading-arrow-link .title {
        text-align: center;
        font-size: 2.2rem;
        font-weight: bold;
    }

@media(min-width:768px) {

.heading-arrow-link .title {
            font-size: 3.6rem
    }
        }

.heading-arrow-link .text-link {
        text-align: right;
        display: block;
        padding-top: 15px;
    }

@media(min-width:768px) {

.heading-arrow-link .text-link {
            padding-top: 0;
            display: inline-block;
            position: absolute;
            top: 15px;
            right: 0
    }

        }

.heading-arrow-link .text-link a {
            font-size: 1.2rem;
            color: #CA101A;
        }

@media(min-width:768px) {

.heading-arrow-link .text-link a {
                font-size: 1.8rem
        }
            }

@media(min-width:992px) {
                .heading-arrow-link .text-link a:hover {
                    text-decoration: underline;
                }
            }

.heading-arrow-link .text-link a .icon {
                width: 11px;
                height: 11px;
            }

@media(min-width:768px) {

.heading-arrow-link .text-link a .icon {
                    margin-left: 5px;
                    width: 13px;
                    height: 13px
            }
                }

.heading-number {
    font-weight: bold;
    font-size: 2.2rem;
    margin-bottom: 20px;
    padding-left: 35px;
    position: relative;
    line-height: 1.25;
}

@media(min-width:768px) {

.heading-number {
        font-size: 3.0rem;
        margin-bottom: 35px;
        padding-left: 50px
}
    }

.heading-number .m-number {
        width: 28px;
        height: 28px;
        line-height: 28px;
        font-size: 1.6rem;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        color: #fff;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        background-color: #CA101A;
        position: absolute;
        left: 0;
        top: -2px;
    }

@media(min-width:768px) {

.heading-number .m-number {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 2rem;
            top: -4px
    }
        }

.heading-daygo {
    text-align: center;
    background-color: #FAF8EF;
    padding: 20px 10px;
    position: relative;
}

@media(min-width:768px) {

.heading-daygo {
        padding: 32px 10px
}
    }

.heading-daygo br {
        display: block;
    }

@media(min-width:1200px) {

.heading-daygo br {
            display: none
    }
        }

.heading-daygo:before {
        content: '';
        display: block;
        background-image: url(../img/common/daygo-01.png);
        width: 60px;
        height: 68px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -5px;
        margin: auto;
        background-size: cover;
    }

@media(min-width:768px) {

.heading-daygo:before {
            width: 126px;
            height: 142px;
            left: 10px
    }
        }

@media(min-width:1200px) {

.heading-daygo:before {
            left: 110px
    }
        }

.heading-daygo:after {
        content: '';
        display: block;
        background-image: url(../img/common/daygo-02.png);
        width: 60px;
        height: 67px;
        right: -5px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background-size: cover;
    }

@media(min-width:768px) {

.heading-daygo:after {
            width: 126px;
            height: 140px;
            right: 10px
    }
        }

@media(min-width:1200px) {

.heading-daygo:after {
            right: 110px
    }
        }

.heading-daygo span {
        display: inline-block;
        color: #CA101A;
        font-size: 1.7rem;
        font-weight: bold;
        line-height: 1.25;
    }

@media(min-width:768px) {

.heading-daygo span {
            font-size: 2.4rem
    }
        }

.list-column-3 {
    justify-content: space-between;
}

@media(min-width:768px) {

.list-column-3 {
        display: flex
}
    }

.list-column-3 li {
        padding-bottom: 15px;
    }

@media(min-width:768px) {

.list-column-3 li {
            padding-bottom: 0;
            width: 32%
    }
        }

@media(min-width:1200px) {

.list-column-3 li {
            width: 340px
    }
        }

.list-column-3 li:last-child {
            padding-bottom: 0;
        }

.list-column-3.flex-wra{
        flex-wrap: wrap;
    }

@media(min-width:768px) {

.list-column-3.flex-wra li {
                margin-bottom: 2%
        }
            }

@media(min-width:1200px) {

.list-column-3.flex-wra li {
                width: 352px;
                margin-bottom: 20px
        }
            }

.list-column-3.qa {
        display: flex;
    }

@media(min-width:768px) {

.list-column-3.qa {
            justify-content: flex-start
    }
        }

.list-column-3.qa li {
            padding-bottom: 0;
            width: 48%;




        }

@media(min-width:768px) {

.list-column-3.qa li {
                width: 32%;
                margin-right: 4%




        }
            }

@media(min-width:768px) {

.list-column-3.qa li:last-child {
                    margin-right: 0
            }
                }

.list-column-3.qa li:nth-child(3) {
                display: none;
            }

@media(min-width:768px) {

.list-column-3.qa li:nth-child(3) {
                    display: block
            }
                }


.list-column-4 {
    justify-content: space-between;



}


@media(min-width:768px) {


.list-column-4 {
        display: flex



}
    }


.list-column-4 li {
        padding-bottom: 15px;
    }


@media(min-width:768px) {


.list-column-4 li {
            padding-bottom: 0;
            width: 23%
    }
        }


@media(min-width:1200px) {


.list-column-4 li {
            width: 260px
    }
        }


.list-column-4 li:last-child {
            padding-bottom: 0;
        }

.list-pdf {
    margin-top: 15px;
    border-style: dashed;
    border-color: #D1D1D1;
    border-bottom-width: 1px;
}

@media(min-width:768px) {

.list-pdf {
        margin-top: 20px
}
    }

.list-pdf li {
        border-style: dashed;
        border-color: #D1D1D1;
        border-top-width: 1px;
        padding: 10px 5px;
    }

@media(min-width:768px) {

.list-pdf li {
            padding: 30px 10px
    }
        }

.list-pdf li.indent-1 {
            padding-left: 1em;
        }

@media(min-width:768px) {

.list-pdf li.indent-1 {
                padding-left: 2em
        }
            }

.list-pdf li.indent-2 {
            padding-left: 2em;
        }

@media(min-width:768px) {

.list-pdf li.indent-2 {
                padding-left: 4em
        }
            }

.list-pdf a {
        font-size: 1.6rem;
        color: #275EAC;
        line-height: 1.25;
    }

@media(min-width:768px) {

.list-pdf a {
            font-size: 1.8rem
    }
        }

.list-pdf .icon.pdf {
        width: 16px;
        height: 16px;
    }

@media(min-width:768px) {

.list-pdf .icon.pdf {
            width: 18px;
            height: 18px
    }
        }

@media(min-width:768px) {

.list-btn {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        margin-bottom: -20px
}
    }

.list-btn li {
        margin-bottom: 20px;

    }

@media(min-width:768px) {

.list-btn li {
            width: 49%

    }
        }

@media(min-width:1200px) {

.list-btn li {
            width: 540px

    }
        }


.list-flow {
    border-top: 3px solid #F0F0F0;

}


.list-flow .item {
        border-bottom: 3px solid #F0F0F0;
        padding: 20px 0;
        position: relative;
    }


@media(min-width:768px) {


.list-flow .item {
            padding: 50px 0
    }
        }


.list-flow .item:last-child:before {
                display: none;
            }


.list-flow .item:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 14px 0 14px;
            bottom: -14px;
            border-color: #E69497 transparent transparent transparent;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
        }


@media(min-width:768px) {


.list-flow .item:before {
                border-width: 34px 23px 0 23px;
                bottom: -22px
        }
            }


@media(min-width:768px) {


.list-flow .group {
            display: flex;
            justify-content: space-between



    }
        }


.list-flow .group.no-img {
            display: block;
        }


.list-flow .group .left {
            padding-bottom: 15px;
        }


@media(min-width:768px) {


.list-flow .group .left {
                padding-bottom: 0;
                width: 65%
        }
            }


@media(min-width:1200px) {


.list-flow .group .left {
                width: 720px
        }
            }


.list-flow .group .right {
            margin: auto;
            width: 60%;
            text-align: center;
        }


@media(min-width:768px) {


.list-flow .group .right {
                width: 26%;
                margin: 0
        }
            }


@media(min-width:1200px) {


.list-flow .group .right {
                width: 270px
        }

            }


.list-flow .group .title {
            font-size: 1.8rem;
            margin-bottom: 10px;
            line-height: 1.5;
            font-weight: bold;
            position: relative;
            padding-left: 38px;
        }


@media(min-width:768px) {


.list-flow .group .title {
                font-size: 3.0rem;
                margin-bottom: 25px;
                padding-left: 60px
        }
            }


.list-flow .group .title .number {
                width: 28px;
                height: 28px;
                line-height: 28px;
                font-size: 14px;
                display: inline-block;
                text-align: center;
                color: #fff;
                background-color: #CA101A;
                font-weight: bold;
                font-family: 'Roboto', sans-serif;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: -8px;
                bottom: 0;
                margin: auto;
            }


@media(min-width:768px) {


.list-flow .group .title .number {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 2rem
            }
                }


.list-flow .group .step-title {
            font-size: 1.8rem;
            margin-bottom: 10px;
            line-height: 1.5;
            font-weight: bold;
            position: relative;


        }


@media(min-width:768px) {


.list-flow .group .step-title {
                font-size: 3.0rem;
                margin-bottom: 25px;
                padding-left: 100px


        }
            }


.list-flow .group .step-title .number {
                font-family: 'Roboto', sans-serif;
                color: #CA101A;
                font-weight: bold;
                vertical-align: middle;
                font-size: 14px;
                display: block;
            }


@media(min-width:768px) {


.list-flow .group .step-title .number {
                    position: absolute;
                    top: -5px;
                    left: 0;
                    display: inline-block;
                    font-size: 1.8rem
            }
                }


.list-flow .group .step-title .number span {
                    font-size: 2.2rem;
                    vertical-align: middle;
                    display: inline-block;
                    padding-left: 2px;
                }


@media(min-width:768px) {


.list-flow .group .step-title .number span {
                        padding-left: 5px;
                        font-size: 3.4rem
                }
                    }


.list-flow .group .l-contact {
            background-color: #F7F7F7;
            padding: 20px;
            margin-top: 20px;
            text-align: center;
        }


@media(min-width:768px) {


.list-flow .group .l-contact {
                text-align: left;
                padding: 30px;
                margin-top: 25px
        }
            }


.list-flow .group .l-contact .heading {
                font-size: 1.8rem;
                margin-bottom: 10px;
                font-weight: bold;
            }


@media(min-width:768px) {


.list-flow .group .l-contact .heading {
                    font-size: 2.6rem;
                    margin-bottom: 15px
            }
                }


@media(min-width:768px) {


.list-flow .group .l-contact .l-tel {
                    display: flex;
                    align-items: flex-end
            }
                }


.list-flow .group .l-contact .l-tel .number {
                    font-size: 2.4rem;
                    margin-right: 5px;
                    font-weight: bold;
                    font-family: 'Roboto', sans-serif;
                }


@media(min-width:768px) {


.list-flow .group .l-contact .l-tel .number {
                        font-size: 3.8rem;
                        margin-right: 10px
                }
                    }


.list-flow .group .l-contact .l-tel .supplement {
                    font-size: 1.4rem;
                    display: inline-block;
                    padding-top: 10px;
                }


@media(min-width:768px) {


.list-flow .group .l-contact .l-tel .supplement {
                        padding-top: 0;
                        font-size: 1.6rem
                }
                    }


.list-flow .group .l-contact .l-tel .free-dial {
                    width: 25px;
                    height: auto;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 5px;
                }


@media(min-width:768px) {


.list-flow .group .l-contact .l-tel .free-dial {
                        width: 35px
                }
                    }


.list-flow .group .text-parts-1 {
            margin-bottom: 10px;
        }


@media(min-width:768px) {


.list-flow .group .text-parts-1 {
                margin-bottom: 20px
        }
            }


.list-flow .group .text-parts-1:last-child {
                margin-bottom: 0;
            }


.list-flow .group .text-parts-1 a {
                color: #275EAC;
                text-decoration: underline;


            }


@media(min-width:992px) {
                    .list-flow .group .text-parts-1 a:hover {
                        text-decoration: none;
                    }
                }


.list-flow .group-box {
        background-color: #FAF8EF;
        text-align: center;
        padding: 30px 15px;
    }


@media(min-width:768px) {


.list-flow .group-box {
            padding: 40px
    }
        }


.list-flow .group-box .l-report {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
        }


@media(min-width:768px) {


.list-flow .group-box .l-report {
                padding-bottom: 25px
        }
            }


.list-flow .group-box .l-report .number {
                padding: 0 10px;
                font-size: 1.8rem;
                font-weight: bold;
            }


@media(min-width:768px) {


.list-flow .group-box .l-report .number {
                    padding: 0 20px;
                    font-size: 3.0rem
            }
                }


.list-flow .group-box .l-report .number span {
                    font-size: 2rem;
                    color: #CA101A;
                }


@media(min-width:768px) {


.list-flow .group-box .l-report .number span {
                        font-size: 3.4rem
                }
                    }


.list-flow .group-box .l-step {
            background-color: #FDEFEF;
            margin-top: 10px;
            padding: 20px;
            text-align: left;
        }


@media(min-width:768px) {


.list-flow .group-box .l-step {
                display: flex;
                justify-content: center;
                margin-top: 15px;
                padding: 20px 0
        }
            }


.list-flow .group-box .l-step li {
                font-size: 1.4rem;
                font-weight: bold;
                padding-bottom: 10px;
                color: #CA101A;
            }


@media(min-width:768px) {


.list-flow .group-box .l-step li {
                    padding: 0 17px;
                    font-size: 2rem
            }
                }


.list-flow .group-box .l-step li:last-child {
                    padding-bottom: 0;

                }


.list-flow .group-box .l-step li span {
                    width: 19px;
                    height: 19px;
                    line-height: 19px;
                    font-size: 1rem;
                    margin-right: 5px;
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    color: #fff;
                    background-color: #CA101A;
                    font-weight: bold;
                    font-family: 'Roboto', sans-serif;
                    border-radius: 50%;
                }


@media(min-width:768px) {


.list-flow .group-box .l-step li span {
                        width: 26px;
                        height: 26px;
                        line-height: 26px;
                        font-size: 1.4rem;
                        margin-right: 10px
                }
                    }


.list-flow .group-box .inner {
            background-color: #fff;
            margin-top: 20px;
            padding: 20px 15px;
            text-align: left;
        }


@media(min-width:768px) {


.list-flow .group-box .inner {
                text-align: center;
                margin-top: 40px;
                padding: 40px 60px
        }
            }


.list-flow .group-box .inner .text-parts-1 {
                text-align: left;
                padding: 15px 0 10px;
            }


@media(min-width:768px) {


.list-flow .group-box .inner .text-parts-1 {
                    padding: 20px 0 25px
            }
                }


.list-flow .group-box .inner .supplement {
                text-align: left;
                font-size: 1.3rem;
                color: #5C5C5C;
                line-height: 1.5;
            }


@media(min-width:768px) {


.list-flow .group-box .inner .supplement {
                    font-size: 1.6rem
            }
                }


.list-flow .group-box .inner .supplement li {
                    padding-bottom: 5px;
                }


.list-flow .group-box .inner .supplement li:last-child {
                        padding-bottom: 0;
                    }


.list-flow .wrap-btn {
        padding-bottom: 0;
    }


.list-dot li {
        padding-left: 1em;
        text-indent: -1em;
    }


.list-dot li::before {
            content: '・';
        }


.list-dot li .wrap-btn {
            padding-bottom: 0;
        }


.list-dot li .btn-round {
            max-width: 740px;
            margin-left: auto;
            margin-right: auto;
        }


.list-dot li + li {
            margin-top: 10px;
        }


@media(min-width:768px) {

            .list-dot li + li {
                margin-top: 20px;
            }
        }


.list-dot.small li {
            margin-top: 0;
            font-size: 1.6rem;
            line-height: 1.5;
            padding-bottom: 5px;
        }


.list-dot.small li:last-child {
                padding-bottom: 0;
            }


.list-dot.mt-none li + li {
            margin-top: 0;
        }


/* pdfアイコンの表示レイアウトが崩れてしまうため、崩れないように設定を上書き */


.list-dot .mtli_attachment {
        display: inline;
    }


.list-anker-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}


@media(min-width:768px) {


.list-anker-link {
        display: table;
        table-layout:fixed

}
    }


.list-anker-link li {
        width: 49%;
        margin-bottom: 2%;
    }


@media(min-width:768px) {


.list-anker-link li {
            margin-bottom: 0;
            width: auto;
            padding-right: 5px;
            display: table-cell;
            vertical-align: top
    }
        }


@media(min-width:992px) {


.list-anker-link li {
            padding-right: 20px
    }
        }


.list-anker-link li:last-child {
            padding-right: 0;
        }


.list-anker-link .link {
        display: block;
        color: #222222;
        text-align: center;
        font-size: 13px;
        height: 65px;
        padding: 0 5px 0;
        background-color: #FAF8EF;
        border: 1px solid #D1D1D1;
        border-radius: 4px;
        position: relative;
        line-height: 1.25;
        font-weight: bold;
        display: table;
        width: 100%;
    }


@media(min-width:768px) {


.list-anker-link .link {
            font-size: 18px;
            height: 110px;
            padding: 0 10px 0
    }
        }


@media(min-width:992px) {


.list-anker-link .link {
            transition: all .2s
    }
            .list-anker-link .link:hover {
                color: #CA101A;
            }
        }


.list-anker-link .link .inner {
            display: table-cell;
            vertical-align: middle;
            padding-bottom: 5px;
        }


@media(min-width:768px) {


.list-anker-link .link .inner {
                padding-bottom: 10px
        }
            }


.list-anker-link .link .icon {
            width: 11px;
            height: 11px;
            margin-right: auto;
            margin-left: auto;
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
            position: absolute;
            bottom: 5px;
            left: 0;
            right: 0;
            margin-right: auto;
            margin-left: auto;
        }


@media(min-width:768px) {


.list-anker-link .link .icon {
                width: 18px;
                height: 18px;
                bottom: 10px
        }
            }


/* .column-pc2-sp1 { */
.list-card-red-heading {
    width: 100%;
    max-width: 1100px;
    margin: auto;
}
@media(min-width:768px) {
.list-card-red-heading {
        display: flex;
        justify-content: space-between;
        align-items:stretch
}

    }



/*
(1)
用
*/
.list-ol-01 > li {
        padding-left: 34px;
        text-indent: -34px;
    }
.list-ol-01 > li .indent-none {
            padding-left: 0;
            text-indent: 0;
            margin-left: -34px;
        }
/*
1.
用
*/
.list-ol-02 > li {
        padding-left: 1.6em;
        text-indent: -1.6em;
    }
.list-ol-02 > li .indent-none {
            padding-left: 0;
            text-indent: 0;
            margin-left: -1.6em;
        }

.list-ol-03 > li {
        padding-left: 1em;
        text-indent: -1em;
    }

.list-ol-03 > li .indent-none {
            padding-left: 0;
            text-indent: 0;
            margin-left: -1em;
        }

.table-01 {
    width: 100%;
    border: 1px solid #D1D1D1;
    font-size: 1.3rem;
    line-height: 1.5;

}

@media(min-width:768px) {

.table-01 {
        font-size: 1.8rem

}
    }

.table-01.double-header thead {
            border-bottom: 1px solid #D1D1D1;
        }

.table-01.double-header thead th {
                background-color: #FAF8EF;
                text-align: center;
            }

.table-01.double-header tbody th {
                background-color: #F7F7F7;
            }

.table-01.double-header tbody td {
                text-align: center;
                border-right: 1px solid #D1D1D1;
            }

.table-01.double-header tbody td:last-child {
                    border-right: none;
                }

.table-01.fixed {
        table-layout:fixed !important;
    }

.table-01 tr {
        border-bottom: 1px solid #D1D1D1;
    }

.table-01 tr:last-child {
            border-bottom: none;
        }

.table-01 th {
        background-color: #FAF8EF;
        font-weight: bold;
        padding: 15px;
        width: 35.5%;
        border-right: 1px solid #D1D1D1;
        vertical-align: top;
    }

@media(min-width:768px) {

.table-01 th {
            padding: 30px;
            width: 25.5%
    }
        }

.table-01 td {
        padding: 15px;
        background-color: #fff;
    }

@media(min-width:768px) {

.table-01 td {
            padding: 30px
    }
        }

.table-01 td .text {
            padding-bottom: 10px;
        }

@media(min-width:768px) {

.table-01 td .text {
                padding-bottom: 20px
        }
            }

.table-01 td .text2 {
            color: #5C5C5C;
            font-size: 1.2rem;
            padding-bottom: 10px;
            margin-top: -5px;
        }

@media(min-width:768px) {

.table-01 td .text2 {
                margin-top: -10px;
                font-size: 1.6rem;
                padding-bottom: 20px
        }
            }

.table-01 td .gray {
            background-color: #F7F7F7;
            padding: 15px;
            margin-bottom: 15px;
            color: #222222;
        }

@media(min-width:768px) {

.table-01 td .gray {
                padding: 20px;
                margin-bottom: 20px
        }
            }

.table-01 td .list {
            padding-bottom: 10px;
        }

@media(min-width:768px) {

.table-01 td .list {
                padding-bottom: 20px
        }
            }

.table-01 td .list li {
                padding-bottom: 5px;
                position: relative;
                padding-left: 22px;
            }

@media(min-width:768px) {

.table-01 td .list li {
                    padding-left: 35px
            }
                }

.table-01 td .list li:before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    top: 6px;
                    left: 10px;
                    background-color: #222222;
                    border-radius: 50%;
                    position: absolute;
                }

@media(min-width:768px) {

.table-01 td .list li:before {
                        width: 6px;
                        height: 6px;
                        top: 8px;
                        left: 20px
                }
                    }

.table-01 td .text:last-child,
        .table-01 td .text2:last-child,
        .table-01 td .list:last-child {
            padding-bottom: 0;
        }

@media(min-width:768px) {

.table-01 .inner-layout {
            display: flex;
            justify-content: space-between


    }

        }

@media(min-width:768px) {

.table-01 .inner-layout .left {
                width: 72%
        }
            }

@media(min-width:1200px) {

.table-01 .inner-layout .left {
                width: 550px
        }
            }

.table-01 .inner-layout .right {
            width: 80%;
            margin: 5px auto 0;



        }

@media(min-width:768px) {

.table-01 .inner-layout .right {
                margin: 0;
                width: 23%



        }
            }

@media(min-width:1200px) {

.table-01 .inner-layout .right {
                width: 180px



        }
            }

.table-02 {
    overflow: scroll;
    border-left: 1px solid #D1D1D1;
}

@media(min-width:768px) {

.table-02 {
        overflow: inherit
}
    }

.table-02 table {
        border-collapse: separate;
        width: 800px;
        border-top: 1px solid #D1D1D1;
        line-height: 1.5;
        font-size: 1.4rem;
    }

@media(min-width:768px) {

.table-02 table {
            width: 100%
    }
        }

@media(min-width:992px) {

.table-02 table {
            font-size: 1.8rem
    }
        }

.table-02 thead th {
            border-bottom: 1px solid #D1D1D1;
            border-right: 1px solid #D1D1D1;
            vertical-align: middle;
            text-align: center;
            padding: 15px 0;
            background-color: #FAF8EF;
        }

.table-02 thead th span {
                color: #275EAC;
            }

.table-02 thead th.sticky {
                position: sticky;
                width: 20%;
                left: 0;
                z-index: 1;
                -webkit-overflow-scrolling: touch;
            }

@media(min-width:768px) {

                .table-02 thead th.sticky {
                    position: static;
                    width: 25%;
                }
            }

.table-02 thead .supplement {
            text-align: left;
            font-size: 1.4rem;
            padding-left: 10px;
            padding-right: 10px;
        }

@media(min-width:768px) {

.table-02 thead .supplement {
                padding-left: 15px;
                padding-right: 15px
        }
            }

.table-02 tbody th, .table-02 tbody td {
            border-bottom: 1px solid #D1D1D1;
            border-right: 1px solid #D1D1D1;
            vertical-align: middle;
        }

.table-02 tbody th {
            background-color: #F7F7F7;
            position: sticky;
            padding: 10px;
        }

@media(min-width:768px) {

.table-02 tbody th {
                padding: 15px
        }
            }

.table-02 tbody th:nth-of-type(1) {
                /* word-break: keep-all; */
                width: 84px;
            }

@media(min-width:768px) {

.table-02 tbody th:nth-of-type(1) {
                    width: 25px;
            }
                }

.table-02 tbody th:nth-of-type(1) {
                text-align: center;
                left: 0;
                z-index: 1;
}

/* spのみ */

@media(max-width:768px) {

.table-02 tbody th.number {
                    font-size: 1.2rem;
                    width: 60px
            }
                }

@media(max-width:768px) {

.table-02 tbody th.number-title {
                    left: 44px !important
            }

                }

.table-02 tbody th:nth-of-type(2) {
                width: 155px;
                padding: 20px 10px;
                left: 56px;
                z-index: 2;
            }

.table-02 tbody th span {
                color: #275EAC;
            }

@media(min-width:768px) {

.table-02 tbody th {

                position: static
        }

                .table-02 tbody th:nth-of-type(1) {
                    width: 90px;
                }

                .table-02 tbody th:nth-of-type(2) {
                    width: 330px;
                }
            }

.table-02 tbody td {
            padding: 20px;
            text-align: center;
        }

.table-02 tbody td:nth-of-type(1) {
                text-align: left;
                /* width: 140px; */
            }

.table-02 tbody td.double-circle {
                font-size: 2rem;
            }

@media(min-width:768px) {

                .table-02 tbody td:nth-of-type(1) {
                    /* width: 170px; */
                }
            }

img {
    max-width: 100%;
}

/* https://www.daidokasai.co.jp/personal/injury/ で使用 */
.img-group {
    margin: -15px;
    display: flex;
}
/* flex-wrap: wrap; */
.img-group > * {
        margin: 15px;
    }

iframe {
    max-width: 100%;
    display: block;
}

.icon {
    display: inline-block;
    vertical-align: initial;










}

.icon.tryangle-right {
        width: 9px;
        height: 9px;
        fill:#454545;
    }

.icon.home,
    .icon.key {
        width: 12px;
        height: 12px;
        fill:#fff;
    }

.icon.search {
        width: 14px;
        height: 14px;
        fill:#888888;
    }

.icon.arrow-right,
    .icon.arrow-left,
    .icon.arrow-top {
        fill:#CA101A;
    }

.icon.question {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        color: #fff;
        font-size: 1.8rem;
        border-radius: 50%;
        background-color: #CA101A;
    }

@media(min-width:768px) {

.icon.question {
            font-size: 2.4rem;
            width: 52px;
            height: 52px;
            line-height: 52px
    }
        }

.icon.anser {
        width: 19px;
        height: 19px;
        line-height: 19px;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-weight: bold;
        color: #fff;
        font-size: 1rem;
        border-radius: 50%;
        background-color: #888888;
    }

@media(min-width:768px) {

.icon.anser {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 1.8rem
    }
        }

.icon.open-close {
        width: 20px;
        height: 20px;
        top: 15px;
        right: 10px;
        position: absolute;
        border-radius: 4px;
        background-color: #DDDDDD;


    }

@media(min-width:768px) {

.icon.open-close {
            width: 30px;
            height: 30px;
            top: 25px;
            right: 25px


    }
        }

.icon.open-close:before,
        .icon.open-close:after {
            content: '';
            display: block;
            border-radius: 2px;
            background-color: #888888;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

.icon.open-close:before {
            width: 12px;
            height: 2px;
        }

@media(min-width:768px) {

.icon.open-close:before {
                width: 18px
        }
            }

.icon.open-close:after {
            width: 2px;
            height: 12px;
            transition: all .2s;
        }

@media(min-width:768px) {

.icon.open-close:after {
                height: 18px
        }
            }

.icon.open-close.active:after {
                opacity:0;
                -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
            }

.icon.window {
        width: 10px;
        height: 10px;
        fill:#454545;
    }

@media(min-width:768px) {

.icon.window {
            width: 15px;
            height: 15px
    }
        }

.icon.tel {
        width: 14px;
        height: 14px;
    }

.icon.tel .object {
            fill:#CA101A;
        }

.icon.tel .bgc {
            fill:#fff;
        }

.icon.freee-dial {
        display: inline-block;
        width: 36px;
        height: 27px;
        background-image: url(../img/common/freee-dial.png);
        background-size: cover;
        margin-right: 5px;
        vertical-align: bottom;
    }

@media(min-width:768px) {

.icon.freee-dial {
            margin-right: 10px;
            vertical-align: initial
    }
        }

.icon.hidden {
        visibility: hidden;
    }

.icon.tryangle-right {
        width: 6px;
        height: 6px;
        fill: #CA101A;
        vertical-align: middle;
        position: relative;
        top: -2px;
    }

.icon.premium {
        display: block;
        width: 98px;
        height: 40px;
        background-image: url(../img/support/agency/premium.png);
        background-size: cover;
        margin: 0 0 5px;
    }

@media(min-width:768px) {

.icon.premium {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto
    }
        }

.icon.pdf {
        width: 18px;
        height: 18px;
    }

@media(min-width:768px) {

.icon.pdf {
            width: 29px;
            height: 29px
    }
        }

.icon.pdf .red {
            fill:#CA101A;
        }

.icon.pdf .white {
            fill:#fff;
        }

.icon.excel {
        fill:#00672E;
    }

.icon.exclamation {
        width: 14px;
        height: 14px;
        margin-right: 3px;
        fill:#CA101A;
    }

@media(min-width:768px) {

.icon.exclamation {
            vertical-align: sub;
            width: 20px;
            height: 20px;
            margin-right: 10px
    }
        }

.icon.tel {
        width: 18px;
        height: 18px;
    }

@media(min-width:768px) {

.icon.tel {
            width: 34px;
            height: 34px
    }
        }

.icon.tel .white{
            fill:none;
        }

.icon.tel .red{
            fill:#CA101A;
        }

.icon.document,
    .icon.mail {
        width: 16px;
        height: 16px;
        fill:#fff;
        vertical-align: middle;
    }

@media(min-width:768px) {

.icon.document,
    .icon.mail {
            position: relative;
            top: -2px;
            width: 20px;
            height: 20px
    }
        }

.icon.people {
        fill:#029162;
    }

.icon.building {
        fill:#077DB0;
    }



/* .l-form {
    border: 1px solid $color-border-frame;
    &:first-child {
        margin-bottom: 30px;
        margin-top: 25px;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 50px;
            margin-top: 35px;
        }
    }
    .item {
        width: 100%;
        border-bottom: 1px solid $color-border-frame;
        @media(min-width:$screen-sm-min) {
            display: table;
        }
        &:last-child {
            border-bottom: none;
        }
        dt {
            background-color: $color-bg;
            font-size: 16px;
            border-bottom: 1px solid $color-border-frame;
            padding: 15px 10px;
            @media(min-width:$screen-sm-min) {
                padding: 0;
                font-size: 1.8rem;
                border-bottom:none;
                border-right: 1px solid $color-border-frame;
                padding-left: 20px;
                width: 240px;
                display: table-cell;
                vertical-align: middle;
            }
            @media(min-width:$screen-sm-min) {
                padding-left: 30px;
                width: 320px;
            }
            br {
                display: none;
                @media(min-width:$screen-sm-min) {
                    display: block;
                }
            }
        }
        dd {
            padding: 15px 10px;
            @media(min-width:$screen-sm-min) {
                padding: 25px 40px;
                display: table-cell;
                vertical-align: middle;
            }
            .inner {
                @media(min-width:$screen-sm-min) {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }
            }
            p {
                font-size: 14px;
                line-height: 1.75;
                margin-bottom: 20px;
                @media(min-width:$screen-sm-min) {
                    font-size: 16px;
                    margin-bottom: 30px;
                }
                &.bottom-small {
                    margin-bottom: 10px;
                    @media(min-width:$screen-sm-min) {
                        margin-bottom: 15px;
                    }
                }

                a {
                    color: $color-text-hover;
                }
            }
        }

        .group {
            padding: 0;
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &.no-flex {
                display: block;
                .text {
                    padding-bottom: 7px;
                }
            }
            &.bottom-space {
                @media(min-width:$screen-sm-min) {
                    margin-bottom: 20px;
                }
            }
            &.w-50 {
                @media(min-width:$screen-sm-min) {
                    width: 50%;
                }
            }
            .text {
                display: inline-block;
                font-size: 14px;
                padding-right: 10px;
                min-width: 40px;
                line-height: 1.5;
                @media(min-width:$screen-sm-min) {
                    font-size: 16px;
                }
                &.hyphen {
                    padding-right: 10px;
                    padding-left: 10px;
                    min-width: 0;
                    @media(min-width:$screen-sm-min) {
                        padding-right: 12px;
                        padding-left: 12px;
                    }
                }
            }

        }

        .mwform-checkbox-field-text {
            display: inline-block;
            font-size: 14px;
            vertical-align: middle;
            @media(min-width:$screen-sm-min) {
                padding-left: 5px;
                font-size: 16px;
            }
        }

    }

    .required {
        font-size: 1rem;
        padding: 4px 4px;
        display: inline-block;
        vertical-align: middle;
        color: $color-red;
        border: 1px solid $color-red;
        margin-left: 5px;
        background-color: #fff;
        @media(min-width:$screen-sm-min) {
            font-size: 1.2rem;
            padding: 4px 5px;
        }
        &.bottom {
            @media(min-widths:$screen-sm-min) {
                margin-left: 0;
                margin-top: 5px;
            }
        }
    }
} */








/* 共通 */
/*----------------------------------------------------------------------------*/
input,
select,
option,
textarea {
    font-size: 1rem;

    /* firefox対策、.field内のUIの最小値を修正 */
    min-width: 1em;
}
label {
    white-space: nowrap;

    /*
    &,
    & > * {
        vertical-align: middle;
        line-height: 1;
    }
    */
}


/* テキスト、セレクト */
/*----------------------------------------------------------------------------*/
/* 自動入力時の見た目をリセット */
:-webkit-autofill {
    /*
    box-shadow: 0 0 0 1000px #eee inset !important;
    // background-color: transparent !important;
    // background-color: rgba(255, 255, 0, 1.0) !important;
    */
    box-shadow: inherit !important;
    background-color: inherit !important;
}
:-webkit-autofill:active,
    :-webkit-autofill:focus {
        /*
        box-shadow: 0 0 0 1000px #f5f5f5 inset !important;
        */
        box-shadow: inherit !important;
        background-color: inherit !important;
    }

/* placeholder */
::-webkit-input-placeholder {
    color: rgba(34,34,34, 0.2);
}
::-moz-placeholder {
    color: rgba(34,34,34, 0.2);
    opacity: 1;
}
:-ms-input-placeholder {
    color: rgba(34,34,34, 0.2);
}
:input-placeholder {
    color: rgba(34,34,34, 0.2);
}
:focus::-webkit-input-placeholder {
    color: rgba(34,34,34, 0.5);
}
:focus::-moz-placeholder {
    color: rgba(34,34,34, 0.5);
    opacity: 1;
}
:focus:-ms-input-placeholder {
    color: rgba(34,34,34, 0.5);
}
:focus:input-placeholder {
    color: rgba(34,34,34, 0.5);
}


/* テキスト系のinput、textarea、select 共通 */
input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="number"],
input[type="file"],
textarea,
select,
option {
    /* サイズ */
	padding: 0.75em 0.5em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: block;

    /* 線と背景 */
    border-radius: 4px;
    border: 1px solid #D1D1D1;
    background-color: #fff;
}
@media(min-width:768px) {
input[type="text"].middle, input[type="search"].middle, input[type="tel"].middle, input[type="url"].middle, input[type="email"].middle, input[type="password"].middle, input[type="datetime"].middle, input[type="date"].middle, input[type="month"].middle, input[type="week"].middle, input[type="datetime-local"].middle, input[type="number"].middle, input[type="file"].middle, textarea.middle, select.middle, option.middle {
            max-width: 300px
    }
        }
input[type="text"].small, input[type="search"].small, input[type="tel"].small, input[type="url"].small, input[type="email"].small, input[type="password"].small, input[type="datetime"].small, input[type="date"].small, input[type="month"].small, input[type="week"].small, input[type="datetime-local"].small, input[type="number"].small, input[type="file"].small, textarea.small, select.small, option.small {
        max-width: 74px;
    }
input[type="text"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="url"]:hover, input[type="email"]:hover, input[type="password"]:hover, input[type="datetime"]:hover, input[type="date"]:hover, input[type="month"]:hover, input[type="week"]:hover, input[type="datetime-local"]:hover, input[type="number"]:hover, input[type="file"]:hover, textarea:hover, select:hover, option:hover {

    }
input[type="text"]:active,
    input[type="text"]:focus,
    input[type="search"]:active,
    input[type="search"]:focus,
    input[type="tel"]:active,
    input[type="tel"]:focus,
    input[type="url"]:active,
    input[type="url"]:focus,
    input[type="email"]:active,
    input[type="email"]:focus,
    input[type="password"]:active,
    input[type="password"]:focus,
    input[type="datetime"]:active,
    input[type="datetime"]:focus,
    input[type="date"]:active,
    input[type="date"]:focus,
    input[type="month"]:active,
    input[type="month"]:focus,
    input[type="week"]:active,
    input[type="week"]:focus,
    input[type="datetime-local"]:active,
    input[type="datetime-local"]:focus,
    input[type="number"]:active,
    input[type="number"]:focus,
    input[type="file"]:active,
    input[type="file"]:focus,
    textarea:active,
    textarea:focus,
    select:active,
    select:focus,
    option:active,
    option:focus {
        outline: none;
        /* -webkit- */
        /* box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.05) inset; */
        border-color: #275EAC;
        box-shadow: 0 0 0 1px inset #275EAC;
        background-color: #fff;
    }
input[type="text"]:disabled,
    input[type="text"][readonly],
    input[type="search"]:disabled,
    input[type="search"][readonly],
    input[type="tel"]:disabled,
    input[type="tel"][readonly],
    input[type="url"]:disabled,
    input[type="url"][readonly],
    input[type="email"]:disabled,
    input[type="email"][readonly],
    input[type="password"]:disabled,
    input[type="password"][readonly],
    input[type="datetime"]:disabled,
    input[type="datetime"][readonly],
    input[type="date"]:disabled,
    input[type="date"][readonly],
    input[type="month"]:disabled,
    input[type="month"][readonly],
    input[type="week"]:disabled,
    input[type="week"][readonly],
    input[type="datetime-local"]:disabled,
    input[type="datetime-local"][readonly],
    input[type="number"]:disabled,
    input[type="number"][readonly],
    input[type="file"]:disabled,
    input[type="file"][readonly],
    textarea:disabled,
    textarea[readonly],
    select:disabled,
    select[readonly],
    option:disabled,
    option[readonly] {
        color: #000000 !important;
        color: rgba(0, 0, 0, 0.5) !important;
        background-color: #ddd !important;
        border-color: #DDDDDD !important;
        box-shadow: none !important;
        cursor: default !important;
    }

/* テキスト系のinput */

input[type="number"] {
    width: 6em;
    min-width: 6em; /* IE対策 */
    flex: 0 0 6em;
}
input[type="file"] {
    padding: calc(0.75em - 1.5px) 0.5em;
    cursor: pointer;
}

/* textarea */
textarea {
    width: 100%;
    height: 300px;
    resize: vertical;
    display: block;


}

/* select (1行) */
select {
    /* padding-right: 32px; */
    padding-right: 24px;

    background-repeat: no-repeat;
    background-image: url(../img/common/icon-select-margin.svg);
    background-position: right center;
    background-size: 32px 16px;
    -webkit-appearance: none;
            appearance: none;
    line-height: 1;
    max-width: 178px;

    position: relative;
}
/* IEで矢印を非表示 */
select::-ms-expand {
        display: none;
    }
select option {

    }
select[value=""] {
    color: #f00;
}

/* select (複数行) */
select[size]:not([size="1"]),
select[multiple] {
    padding: 0;
    background-image: none;
    overflow: auto;
}
select[size]:not([size="1"]) option, select[multiple] option {
        /* padding: 0.75em 1em; */
        border: none;
    }
select[size]:not([size="1"]) optgroup, select[multiple] optgroup {
        padding: 0.5em 0;
        color: #ccc;
        font-size: 0.85em;
        font-weight: normal;
        text-indent: 5px;
    }
select[size]:not([size="1"]) optgroup > option, select[multiple] optgroup > option {
            padding-left: 0;
            padding-right: 0;
            color: #222222;
            font-size: 1rem;
            text-indent: 0;
        }
select[size]:not([size="1"]) optgroup > option::before, select[multiple] optgroup > option::before {
            content: "";
            width: 1em;
            display: inline-block;
        }
select[size]:not([size="1"]) optgroup > option:first-child, select[multiple] optgroup > option:first-child {
            margin-top: 5px;
            border-top: 1px dotted #ccc;
        }
select[size]:not([size="1"]) optgroup:not(:first-child), select[multiple] optgroup:not(:first-child) {
        border-top: 1px dotted #ccc;
    }
select[size]:not([size="1"]) optgroup:last-child, select[multiple] optgroup:last-child {
        padding-bottom: 0;
    }


/* バリデーション */
/* input[type="text"],
input[type="search"],
input[type="tel"],
input[type="url"],
input[type="email"],
input[type="password"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime-local"],
input[type="number"],
input[type="file"],
textarea,
select {
    // 失敗、エラー
    &.is-invalid {
        color: $color-danger;
        border-color: $color-danger;
        background-color: $color-danger-light;

        &:hover {

        }
        &:active,
        &:focus {
            background-color: #fff;
            box-shadow: $shadow-size-focus $color-danger;
        }
        &:disabled,
        &[readonly] {

        }

        // placeholder
        &::-webkit-input-placeholder {
            color: rgba($color-danger, 0.35);
        }
        &::-moz-placeholder {
            color: rgba($color-danger, 0.35);
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: rgba($color-danger, 0.35);
        }
        &:input-placeholder {
            color: rgba($color-danger, 0.35);
        }
        &:focus::-webkit-input-placeholder {
            color: rgba($color-danger, 0.65);
        }
        &:focus::-moz-placeholder {
            color: rgba($color-danger, 0.65);
            opacity: 1;
        }
        &:focus:-ms-input-placeholder {
            color: rgba($color-danger, 0.65);
        }
        &:focus:input-placeholder {
            color: rgba($color-danger, 0.65);
        }
    }

    // 成功
    &.is-valid {
        color: $color-success;
        border-color: $color-success;
        background-color: $color-success-light;

        &:hover {

        }
        &:active,
        &:focus {
            background-color: #fff;
            box-shadow: $shadow-size-focus $color-success;
        }
        &:disabled,
        &[readonly] {

        }

        // placeholder
        &::-webkit-input-placeholder {
            color: rgba($color-success, 0.35);
        }
        &::-moz-placeholder {
            color: rgba($color-success, 0.35);
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: rgba($color-success, 0.35);
        }
        &:input-placeholder {
            color: rgba($color-success, 0.35);
        }
        &:focus::-webkit-input-placeholder {
            color: rgba($color-success, 0.65);
        }
        &:focus::-moz-placeholder {
            color: rgba($color-success, 0.65);
            opacity: 1;
        }
        &:focus:-ms-input-placeholder {
            color: rgba($color-success, 0.65);
        }
        &:focus:input-placeholder {
            color: rgba($color-success, 0.65);
        }
    }
}
 */


/* ラジオボタン、チェックボックス */
/*----------------------------------------------------------------------------*/

/* ラジオボタン、チェックボックスを囲んでいるlabel */
.field-radio,
.field-checkbox {
    flex-wrap: wrap;
    margin: 0.5em -0.5em;
}
/* 「.field > *」のmargin、paddingをキャンセル */
/*
    & > label,
    & > label:not(:first-child),
    & > label:not(:last-child) {
        margin: 0;
        padding: 0.5em;
    }
    */
.field-radio > *,
    .field-radio > *:not(:first-child),
    .field-radio > *:not(:last-child),
    .field-checkbox > *,
    .field-checkbox > *:not(:first-child),
    .field-checkbox > *:not(:last-child) {
        margin: 0;
        padding: 0.5em;
        flex: 0 0 auto;
    }
/* WordPress MW WP Form */
.field-radio > .mwform-radio-field,
    .field-radio > .mwform-checkbox-field,
    .field-checkbox > .mwform-radio-field,
    .field-checkbox > .mwform-checkbox-field {
        margin: 0 !important;
    }
.field-radio > .mwform-radio-field.vertical-item, .field-radio > .mwform-checkbox-field.vertical-item, .field-checkbox > .mwform-radio-field.vertical-item, .field-checkbox > .mwform-checkbox-field.vertical-item {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(100% - 0.5em - 0.5em);
        }
/* & > label { */
.field-radio label, .field-checkbox label {
        /* MW WP Formではみ出るのでコメントアウト */
        /* max-width: calc(100% - 0.5em - 0.5em); */
        color: #222222;
        display: flex;
        align-items: center;
        /* IEでラジオボタンが横方向に潰れる */
        /* flex: 0 1 7em; */
        /* flex: 0 0 auto; */
        cursor: pointer;
        white-space: nowrap;
    }
.field-radio label .icon, .field-checkbox label .icon {
            display: inline-block;
        }
.field-radio label span, .field-checkbox label span {

        }
/* & > label:hover { */
.field-radio label:hover, .field-checkbox label:hover {

    }
/* 将来的にはjQueryを使用せず */
/* :focus-withinで対応可能。 */
/* & > label:active, */
/* & > label:focus-within, */
/* & > label.focus { */
.field-radio label:active,
    .field-radio label:focus-within,
    .field-radio label.focus,
    .field-checkbox label:active,
    .field-checkbox label:focus-within,
    .field-checkbox label.focus {

    }
/* & > label.disabled { */
.field-radio label.disabled,
        .field-radio label.disabled *,
        .field-radio label.disabled input[type="radio"],
        .field-radio label.disabled input[type="checkbox"],
        .field-checkbox label.disabled,
        .field-checkbox label.disabled *,
        .field-checkbox label.disabled input[type="radio"],
        .field-checkbox label.disabled input[type="checkbox"] {
            color: #ddd;
            cursor: default;
        }

.field-radio-vertical,
.field-checkbox-vertical {
    display: block;
}

/*
// フォントで実装
// radio、checkbox共通
input[type="radio"],
input[type="checkbox"] {
    margin-right: 1rem;
    cursor: pointer;

    &.before-icon {
        margin: 0;
        opacity: 0;
        width: 1rem;
        height: 1rem;
        font-size: 1rem;

        & + .icon {
            margin: 0 0.25rem 0 -1rem;
            padding: 0;
            font-size: 1.5rem;
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1;
            vertical-align: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & + .icon::before {
            font-size: 1.25rem;
            margin: 0;
            display: block;

        }

        & ~ * {
            color: $color-gray;
        }
        &:checked {
            & + .icon::before,
            & ~ * {
                color: $color-btn;
            }
        }

        // Firefoxでは「:read-only」はdisabled以外のときも適用されてしまう
        &:disabled,
        &[readonly] {
            &:checked {
                & + .icon::before,
                & ~ * {
                    color: #ddd;
                    cursor: default;
                }
            }
        }
    }
}

// radio
input[type="radio"].before-icon {
    & + .icon::before {
        @mixin icon-radio;
    }
    &:checked + .icon::before {
        @mixin icon-radio-checked-fill;
    }
}

// checkbox
input[type="checkbox"].before-icon {
    & + .icon::before {
        @mixin icon-checkbox-round;
    }
    &:checked + .icon::before {
        @mixin icon-checkbox-round-checked-fill;
    }
}
*/


/* CSSで実装 */
/* radio、checkbox共通 */
input[type="radio"],
input[type="checkbox"] {
    margin-right: 1rem;
    cursor: pointer;
}
input[type="radio"].before-icon, input[type="checkbox"].before-icon {
        margin: 0;
        opacity: 0;
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
        display: none;
    }
input[type="radio"].before-icon + .icon, input[type="checkbox"].before-icon + .icon {
            margin: 0;
            padding: 0;
            font-size: 1.5rem;
            width: 22px;
            height: 22px;
            line-height: 1;
            vertical-align: middle;
            display: inline-block;
            align-items: center;
            justify-content: center;
            border: 1px solid #D1D1D1;
            background-color: #fff;
        }
@media(min-widths:768px) {
input[type="radio"].before-icon + .icon, input[type="checkbox"].before-icon + .icon {
                width: 26px;
                height: 26px
        }
            }
input[type="radio"].before-icon + .icon::before, input[type="checkbox"].before-icon + .icon::before {
            -webkit-transform: none;
                    transform: none;
            display: block;
        }

/* radio */
input[type="radio"].before-icon + .icon {
        border-radius: 50%;

        box-shadow: 0 0 0 2px #DDDDDD inset;
        background-color: #fafafa;
        box-sizing: border-box;
        overflow: hidden;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
input[type="radio"].before-icon + .icon::before {
            content: "";
            margin: auto;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: transparent;
            display: block;
            -webkit-transform: scale(0.5);
                    transform: scale(0.5);
        }
input[type="radio"].before-icon:checked + .icon {
        background-color: #fff;
        box-shadow: 0 0 0 2px #222222 inset;
    }
input[type="radio"].before-icon:checked + .icon::before {
            background-color: rgba(34,34,34, 0.75);
        }
/* focus */
input[type="radio"].before-icon:focus {
        outline: none;
    }
input[type="radio"].before-icon:active + .icon, input[type="radio"].before-icon:focus + .icon {
            box-shadow: 0 0 0 2px #275EAC inset;
        }
input[type="radio"].before-icon:active + .icon::before, input[type="radio"].before-icon:focus + .icon::before {

            }
input[type="radio"].before-icon:active:checked + .icon::before, input[type="radio"].before-icon:focus:checked + .icon::before {
                background-color: rgba(39,94,172, 0.75);
            }
/* disabled */
input[type="radio"].before-icon:disabled + .icon, input[type="radio"].before-icon[readonly] + .icon {
            background-color: #ddd;
            box-shadow: 0 0 0 2px #DDDDDD inset;
            color: #ddd;
            cursor: default;
        }
input[type="radio"].before-icon:disabled + .icon::before, input[type="radio"].before-icon[readonly] + .icon::before {

            }
input[type="radio"].before-icon:disabled:checked + .icon::before, input[type="radio"].before-icon[readonly]:checked + .icon::before {
                background-color: #DDDDDD;
            }

/* checkbox */
input[type="checkbox"].before-icon + .icon {
        border-radius: 10%;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        align-items: center;
        justify-content: center;
    }
input[type="checkbox"].before-icon + .icon::before {
            content: "";
            width: 74%;
            height: 40%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: none;
            border-left: 3px solid #CA101A;
            border-bottom: 3px solid #CA101A;
            box-sizing: border-box;
            -webkit-transform: translate(10%, -40%) rotate(-45deg);
                    transform: translate(10%, -40%) rotate(-45deg);
        }
@media(min-width:768px) {
input[type="checkbox"].before-icon + .icon::before {
                width: 74%;
                height: 40%
        }
            }
input[type="checkbox"].before-icon:checked + .icon::before {
            display: inline-block;
        }
/* focus */
input[type="checkbox"].before-icon:focus {
        outline: none;
    }
input[type="checkbox"].before-icon:active + .icon, input[type="checkbox"].before-icon:focus + .icon {
            box-shadow: 0 0 0 2px #275EAC inset;
        }
input[type="checkbox"].before-icon:active + .icon::before, input[type="checkbox"].before-icon:focus + .icon::before {

            }
input[type="checkbox"].before-icon:active:checked + .icon::before, input[type="checkbox"].before-icon:focus:checked + .icon::before {
                border-color: rgba(39,94,172, 0.75);
            }
/* disabled */
input[type="checkbox"].before-icon:disabled + .icon, input[type="checkbox"].before-icon[readonly] + .icon {
            background-color: #ddd;
            box-shadow: 0 0 0 2px #DDDDDD inset;
            color: #ddd;
            cursor: default;
        }
input[type="checkbox"].before-icon:disabled + .icon::before, input[type="checkbox"].before-icon[readonly] + .icon::before {

            }
input[type="checkbox"].before-icon:disabled:checked + .icon::before, input[type="checkbox"].before-icon[readonly]:checked + .icon::before {
                border-color: #DDDDDD;
            }


/* バリデーション */
/*
input[type="radio"].before-icon {
    // 失敗、エラー
    &.is-invalid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-danger, 0.25) inset;
            background-color: $color-danger-light;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-danger inset;

            &::before {
                background-color: $color-danger;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-danger, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }

    // 成功
    &.is-valid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-success, 0.25) inset;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-success inset;

            &::before {
                background-color: $color-success;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-success, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }
}
input[type="checkbox"].before-icon {
    // 失敗、エラー
    &.is-invalid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-danger, 0.25) inset;
            background-color: $color-danger-light;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-danger inset;

            &::before {
                border-color: $color-danger;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-danger, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }

    // 成功
    &.is-valid {
        & + .icon {
            box-shadow: 0 0 0 2px rgba($color-success, 0.25) inset;
            background-color: $color-success-light;
        }
        &:checked + .icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px $color-success inset;

            &::before {
                border-color: $color-success;
            }
        }

        // focus
        &:active,
        &:focus {
            & + .icon {
                background: rgba($color-success, 0.2);

                &::before {

                }
            }
            &:checked + .icon {
                &::before {

                }
            }
        }
    }
}
 */

/* アラート、メッセージ、Nag */
/* https://getbootstrap.com/docs/4.1/components/alerts/ */
/* メッセージ */
/* https://semantic-ui.com/collections/message.html */
/* Nag */
/* https://semantic-ui.com/modules/nag.html */
/*----------------------------------------------------------------------------*/
.alert {
    padding: .75em 1em;
    line-height: 1.5;
    border-radius: 4px;
    background-color: #aaa;
    color: #fff;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    align-items: center;
}
.alert .btn {
        margin-left: auto;
        padding: 0;
        width: 2em;
        min-width: auto;
        height: 2em;
        min-height: auto;
        flex: 0 0 2em;
        font-size: .5em;
        line-height: 1;
        border: none;
        border-radius: 50%;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.2);
        color: #fff;
        box-shadow: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
.alert .btn:hover {
        color: #fff;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.5);
    }
.alert.alert-danger {
        background-color: #ec5858;
    }
.alert.alert-warning {
        background-color: #e6bd41;
    }
.alert.alert-success {
        background-color: #6bc55d;
    }

.alert-group {
    position: fixed;
    top: calc(50px + 5px);
    left: 5px;
    right: 5px;
    z-index: 10000;
}

.alert-group .alert:not(:last-child) {
        margin-bottom: 5px;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

.alert-group {
        top: calc(70px + 5px)
}
    }

.alert-group.alert-group-static {
        position: static;
    }

.alert-group.alert-group-static .alert {
            position: static;
        }

.badge {
    border-style: solid;
    border-color: #919BA8;
    color: #919BA8;
    border-width: 1px;
    font-size: 1.2rem;
    display: block;
    text-align: center;
    padding: 6px 5px;
    background-color: #fff;
}

@media(min-width:768px) {

.badge {
        font-size: 1.4rem
}
    }

.badge.recruit {
        border-color: #DB9356;
        color: #DB9356;
    }

.badge.csr {
        border-color: #439D69;
        color: #439D69;
    }

.badge.organization {
        border-color: #43779D;
        color: #43779D;
    }

.badge.service {
        border-color: #39A0C9;
        color: #39A0C9;
    }

.badge.disaster {
        border-color: #D87376;
        color: #D87376;
    }


.btn-round {
    display: block;
    border: 2px solid #CA101A;
    background-color: #fff;
    color: #CA101A;
    text-align: center;
    height: 42px;
    line-height: 40px;
    width: 100%;
    font-size: 1.6rem;
    border-radius: 35px;
    position: relative;
    cursor: pointer;
    font-weight: bold;

}


@media(min-width:768px) {


.btn-round {
        font-size: 2.0rem;
        height: 60px;
        line-height: 60px

}
    }


@media(min-width:992px) {


.btn-round {
		transition: all .2s

}
		.btn-round:hover{
            border: 2px solid #CA101A;
            background-color: #CA101A;
            color: #fff;
		}
            .btn-round:hover .icon {
                fill:#fff;
            }
	}


@media(max-width:480px) {


.btn-round.sp-2line {
            height: auto;
            line-height: 1.5em;
            padding: 10px 30px
    }
        }


.btn-round.red {
        background-color: #CA101A;
        color: #fff;


    }


@media(min-width:992px) {
            .btn-round.red:hover {
                background-color: #fff;
                color: #CA101A;
            }
        }


.btn-round.red .arrow-right,
        .btn-round.red .arrow-left {
            fill:#fff;
        }


@media(min-width:992px) {
                .btn-round.red:hover .icon {
                    fill:#CA101A;
                }
        }


.btn-round .arrow-right,
    .btn-round .arrow-left {
        position: absolute;
        width: 11px;
        height: 11px;

        top: 0;
        bottom: 0;
        margin: auto;
        transition: all .2s;
    }


@media(min-width:768px) {


.btn-round .arrow-right,
    .btn-round .arrow-left {
            width: 13px;
            height: 13px
    }

        }


.btn-round .arrow-right {
        right: 15px;
    }


@media(min-width:768px) {


.btn-round .arrow-right {
            right: 15px
    }
        }


.btn-round .arrow-left {
        left: 15px;
    }


@media(min-width:768px) {


.btn-round .arrow-left {
            left: 15px
    }
        }


.btn-round.small {
        max-width: 320px;
        font-size: 1.3rem;

    }


@media(min-width:768px) {


.btn-round.small {
            font-size: 1.6rem

    }
        }


.btn-round.medium {
        max-width: 520px;
        font-size: 1.6rem;
    }


@media(min-width:768px) {


.btn-round.medium {
            font-size: 2.4rem
    }
        }


.btn-round.large {
        max-width: 740px;
        font-size: 1.6rem;
    }


@media(min-width:768px) {


.btn-round.large {
            font-size: 2.4rem
    }
        }


.btn-round.emphasis {
        max-width: 740px;
    }


@media(min-width:768px) {


.btn-round.h-70 {
            line-height: 70px;
            height: 70px
    }
        }

.btn-square {
    color: #fff;
    display: block;
    text-align: center;
    border-radius: 4px;
    font-size: 1.3rem;
    padding: 18px 10px;
    position: relative;
    font-weight: bold;
}

@media(min-width:768px) {

.btn-square {
        font-size: 2rem;
        padding: 25px 10px
}
    }

.btn-square .arrow-right {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 15px;
        width: 11px;
        height: 11px;
        fill:#fff;
    }

@media(min-width:768px) {

.btn-square .arrow-right {
            right: 20px;
            width: 13px;
            height: 13px
    }
        }

.btn-square.lead-icon {
        /* padding: 7px 10px; */
        font-size: 1.2rem;
        line-height: 1.5;
    }

@media(min-width:768px) {

.btn-square.lead-icon {
            line-height: 1;
            padding: 22px 10px;
            font-size: 1.6rem
    }
        }

@media(min-width:768px) {

.btn-square.lead-icon .icon {
                margin: auto;
                margin-right: 20px;
                display: inline-block
        }
            }

.btn-square.green {
        background-color: #029162;
    }

@media(min-width:992px) {

.btn-square.green {
    		transition: all .2s
    }
    		.btn-square.green:hover{
                background-color: #017A52;
    		}
    	}

.btn-square.blue {
        background-color: #077DB0;
    }

@media(min-width:992px) {

.btn-square.blue {
    		transition: all .2s
    }
    		.btn-square.blue:hover{
                background-color: #066994;
    		}
    	}

.btn-list {
	display: table;
	width: 100%;
	background-color: #FAF8EF;
	border: 1px solid #D1D1D1;
	border-radius: 4px;
	padding: 20px 10px;
	position: relative;
    font-weight: bold;
}

@media(min-width:768px) {

.btn-list {
		padding: 20px 10px;
		height: 90px
}
	}

@media(min-width:992px) {

.btn-list {
		padding: 20px
}
		.btn-list:hover {
            text-decoration: none !important;
		}
			.btn-list:hover .heading {
				color: #CA101A;
                text-decoration: none !important;
			}
	}

.btn-list .heading {
		color: #222222;
		font-size: 1.4rem;
		display: table-cell;
		vertical-align: middle;
		line-height: 1.5;
	}

@media(min-width:992px) {

.btn-list .heading {
			font-size: 1.7rem
	}
		}

.btn-list .icon {
		width: 11px;
		height: 11px;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 10px;
		margin: auto;
	}

@media(min-width:992px) {

.btn-list .icon {
			width: 13px;
			height: 13px;
			right: 20px
	}
		}

html .fc .fc-view-container .fc-view .fc-widget-header {
                    border-top-color: transparent;
                    border-left-color: transparent;
                    border-right-color: transparent;
                }

html .fc .fc-view-container .fc-view .fc-widget-header th.fc-day-header {
                        background: none;
                        border-color: transparent;
                        font-weight: bold;
                    }

html .fc .fc-view-container .fc-view .fc-widget-header th.fc-sat {
                        color: #3c83af;
                    }

html .fc .fc-view-container .fc-view .fc-widget-header th.fc-sun {
                        color: #a23737;
                    }

html .fc .fc-day-grid-event {
            margin: 0;
        }

html .fc .fc-day-top {
            text-align: center;
        }

html .fc .fc-day-top .fc-day-number {
                float: none;
            }

html .fc .fc-day-top.fc-past {
            opacity: 0.25;
        }

/* 文字 */

html .fc .fc-content-skeleton {
            padding: 0;
        }

html .fc .fc-content-skeleton table,
            html .fc .fc-content-skeleton thead,
            html .fc .fc-content-skeleton tbody,
            html .fc .fc-content-skeleton tfoot,
            html .fc .fc-content-skeleton tr,
            html .fc .fc-content-skeleton th,
            html .fc .fc-content-skeleton td {
                border: none;
            }

html .fc .fc-content-skeleton .fc-event-container a.fc-event,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event-dot {
                    background-color: transparent;
                    border: none;
                }

html .fc .fc-content-skeleton .fc-event-container a.fc-event {
                    text-align: center;
                    font-size: 1.6rem;
                }

/* 青 */

html .fc .fc-content-skeleton .fc-event-container a.fc-event.possible,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.possible:hover {
                    color: #3c83af;
                }

/* 赤 */

html .fc .fc-content-skeleton .fc-event-container a.fc-event.few,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.few:hover {
                    color: #a23737;
                }

html .fc .fc-content-skeleton .fc-event-container a.fc-event.few .fc-title::before {
                        content: "残";
                        margin-right: 0.5em;
                        color: #222222;
                        font-size: 0.8em;
                        display: inline-block;
                    }

html .fc .fc-content-skeleton .fc-event-container a.fc-event.past {
                    color: transparent !important;
                }

/* グレー */

html .fc .fc-content-skeleton .fc-event-container a.fc-event.impossible,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.impossible:hover,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.closed,
                html .fc .fc-content-skeleton .fc-event-container a.fc-event.closed:hover {
                    color: #999;
                }

html .fc .fc-bgevent-skeleton td.fc-bgevent {
                background: none;
            }

/* 背景色 */

/* 青 */

html .fc .fc-bg td.bg-possible {
                background: #e7f1f7;
            }

/*td.bg-possible:hover,*/

html .fc .fc-bg td.bg-possible.active {
                background: #cde5f3;
            }

/* 赤 */

html .fc .fc-bg td.bg-few {
                background: #f8e6e6;
            }

/*td.bg-impossible:hover,
            td.bg-few:hover,*/

html .fc .fc-bg td.bg-impossible.active,
            html .fc .fc-bg td.bg-few.active {
                background: #fdd2d2;
            }

/* 黄 */

html .fc .fc-bg td.fc-today {
                background: #fffacc;
            }

/*td.fc-today:hover,*/

html .fc .fc-bg td.fc-today.active {
                background: #fff8b9;
            }

/* グレー */

html .fc .fc-bg td.fc-past,
            html .fc .fc-bg td.bg-impossible,
            html .fc .fc-bg td.bg-closed {
                background: #f2f2f2 !important;
            }

html .fc .fc-bg td.fc-disabled-day {
                background: #ddd;
                opacity: 1.0;
            }

html .fc .fc-toolbar.fc-header-toolbar .fc-left,
            html .fc .fc-toolbar.fc-header-toolbar .fc-right,
            html .fc .fc-toolbar.fc-header-toolbar .fc-center {
                margin-bottom: 1em;
                display: block;
            }

html .fc .fc-toolbar.fc-header-toolbar .fc-left,
                html .fc .fc-toolbar.fc-header-toolbar .fc-left > *,
                html .fc .fc-toolbar.fc-header-toolbar .fc-right,
                html .fc .fc-toolbar.fc-header-toolbar .fc-right > *,
                html .fc .fc-toolbar.fc-header-toolbar .fc-center,
                html .fc .fc-toolbar.fc-header-toolbar .fc-center > * {
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    float: none;
                }

html .fc .fc-toolbar.fc-header-toolbar .fc-center > *:not(:last-child) {
                    margin-right: 1em;
                }

@media only screen and (min-width: 480px) {

html .fc .fc-toolbar.fc-header-toolbar {
                display: flex;
                align-items: center
        }

                html .fc .fc-toolbar.fc-header-toolbar .fc-left,
                html .fc .fc-toolbar.fc-header-toolbar .fc-right,
                html .fc .fc-toolbar.fc-header-toolbar .fc-center {
                    margin-bottom: 0;
                }

                    html .fc .fc-toolbar.fc-header-toolbar .fc-left,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-left > *,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-right,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-right > *,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-center,
                    html .fc .fc-toolbar.fc-header-toolbar .fc-center > * {
                        margin: 0;
                        text-align: initial;
                    }

                html .fc .fc-toolbar.fc-header-toolbar .fc-left {
                    margin-right: auto;
                }
            }

@media only screen and (min-width: 660px) {

            }

@media only screen and (min-width: 770px) {

            }

@media only screen and (min-width: 1000px) {

            }

html .fc .fc-toolbar h2 {
            font-size: 1.5rem;
        }

html .fc .fc-state-default,
        html .fc .fc-state-default.fc-corner-left,
        html .fc .fc-state-default.fc-corner-right {
            outline: none;

            border: none;
            font-size: 0.85em;
            text-align: center;
            line-height: 1;
            box-shadow: 0 0 0 2px #222222 inset;
            display: inline-block;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            /*  */
            padding: 0.75em 1.5em;
            border-radius: calc(1em + 1em + 1em);

            /*  */
            background-color: #222222;
            color: #fff;

            /* 不要記述削除 */
        }

html .fc .fc-state-default:hover, html .fc .fc-state-default.fc-corner-left:hover, html .fc .fc-state-default.fc-corner-right:hover {
                background-color: transparent;
                color: #222222;
            }

html .fc .fc-state-default,
        html .fc .fc-state-default.fc-corner-left,
        html .fc .fc-state-default.fc-corner-right {
            float: none;
            height: auto;
            text-shadow: none;
            background-image: none;

            transition: opacity 0.15s ease-in-out 0s,
                        background 0.15s ease-in-out 0s,
                        background-color 0.15s ease-in-out 0s,
                        border-radius 0.15s ease-in-out 0s,
                        border-color 0.15s ease-in-out 0s,
                        color 0.15s ease-in-out 0s,
                        box-shadow 0.15s ease-in-out 0s,
                        text-shadow 0.15s ease-in-out 0s,
                        outline 0.15s ease-in-out 0s;
}

html .fc .fc-state-disabled,
        html .fc .fc-state-disabled:hover,
        html .fc .fc-state-disabled.fc-state-hover,
        html .fc .fc-state-disabled.fc-state-hover:hover {
            background-color: #222222;
            color: #fff;
        }

html .fc .fc-state-hover {
            background-position: initial;
        }

html .fc .fc-scroller {
            overflow-x: initial !important;
            overflow-y: initial !important;
            height: initial !important;
        }

html .fc.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
            float: none;
        }

html .fc.fc .fc-row .fc-content-skeleton table,
        html .fc.fc .fc-row .fc-content-skeleton td,
        html .fc.fc .fc-row .fc-helper-skeleton td {
            text-align: center;
            vertical-align: middle;
        }

/*
    margin-top: 20px;
    margin-bottom: 20px;
    */

.module-group .content > *, .module .content > *, .border-group .content > *, .border .content > *, .card-group .content > *, .card .content > *, .banner-group .content > *, .banner .content > * {
            display: block;
        }

.module-group .content > *:not(:last-child), .module .content > *:not(:last-child), .border-group .content > *:not(:last-child), .border .content > *:not(:last-child), .card-group .content > *:not(:last-child), .card .content > *:not(:last-child), .banner-group .content > *:not(:last-child), .banner .content > *:not(:last-child) {
            margin-bottom: 10px;
        }

.module-group .text, .module .text, .border-group .text, .border .text, .card-group .text, .card .text, .banner-group .text, .banner .text {
        line-height: calc(1em + 10px);
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

    }

@media only screen and (min-width: 1000px) {

    }


.module-group,
.border-group,
.card-group {
    margin: -10px;
}


.module-group > *, .border-group > *, .card-group > * {
        margin: 10px;
        box-sizing: border-box;
    }


@media only screen and (min-width: 480px) {


.module-group,
.border-group,
.card-group {
        display: flex;
        flex-wrap: wrap
}

        .module-group > *, .border-group > *, .card-group > * {
            flex: 1 0 40%;
        }
    }


@media only screen and (min-width: 660px) {

    }


@media only screen and (min-width: 770px) {
        .module-group > *, .border-group > *, .card-group > * {
            flex: 1 0 20%;
        }
    }


@media only screen and (min-width: 1000px) {

    }

.module-group > * > *, .module > * {
        display: block;
    }

.module-group > * > *:not(:last-child), .module > *:not(:last-child) {
        margin-bottom: 10px;
    }

.border-group > *,
.border {
    padding: 15px;
    border: 2px solid #DDDDDD;
}

.border-group > * > *, .border > * {
        display: block;
    }

.border-group > * > *:not(:last-child), .border > *:not(:last-child) {
        margin-bottom: 10px;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

    }

@media only screen and (min-width: 770px) {

.border-group > *,
.border {
        /* padding: 30px; */
}
    }

@media only screen and (min-width: 1000px) {

    }


/* カード(背景あり)のエリア */
/*----------------------------------------------------------------------------*/
.card-group > *,
.card {
    padding: 15px;
    background: #fff;
    color: #222222;
}
.card-group > * > *, .card > * {
        display: block;
    }
.card-group > * > *:not(:last-child), .card > *:not(:last-child) {
        margin-bottom: 10px;
    }
.card-group > * .thumbnail:first-child, .card .thumbnail:first-child {
        margin-top: -15px;
        margin-left: -15px;
        margin-right: -15px;
    }
.card-group > * .thumbnail:last-child, .card .thumbnail:last-child {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -15px;
    }
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {
.card-group > *,
.card {
        /* padding: 30px; */
}
    }
@media only screen and (min-width: 1000px) {

    }


/* 枠線のエリア */
/*----------------------------------------------------------------------------*/
.banner-group > *:not(:last-child) {
        margin-bottom: 20px;
    }
.banner-group > *,
.banner {
    display: flex;
}
.banner-group > * > *, .banner > * {
        flex: 1 1 0%;
    }
.banner-group > * > .content, .banner > .content {
        padding: 15px;
        background: #fff;
    }
.banner-group > * > .content > *, .banner > .content > * {
            display: block;
        }
.banner-group > * > .content > *:not(:last-child), .banner > .content > *:not(:last-child) {
            margin-bottom: 10px;
        }


/* 横並びのナビゲーション */
/*----------------------------------------------------------------------------*/
.nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.nav > *:not(:last-child) {
        margin-right: 0.5em;
    }
.nav a:not(.btn) {
        padding: 10px 5px;
        color: #222222;
        opacity: 0.5;

        /* aタグの装飾をキャンセル */
        border-bottom: none;
    }
.nav a:not(.btn):hover {
            color: #222222;
            opacity: 1.0;
        }
.nav a:not(.btn).active {
            opacity: 1.0;
            position: relative;
        }
.nav a:not(.btn).active::before {
                content: "";
                width: 100%;
                height: 2px;
                background: #222222;
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
            }
@media only screen and (min-width: 480px) {
.nav {
        /* display: flex; */
        /* align-items: center; */
}
    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {

    }
@media only screen and (min-width: 1000px) {

    }






/* パンくずリスト */
/* https://getbootstrap.com/docs/4.1/components/breadcrumb/ */
/*----------------------------------------------------------------------------*/
.breadcrumb {
    width: 100%;
    display: none;
    align-items: center;
    box-sizing: border-box;
}
.breadcrumb ol {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
    }
.breadcrumb ol li {
            margin: 0;
            display: block;
            white-space: nowrap;
        }
.breadcrumb ol li + li::before {
            content: ">";
            padding: 0 0.5em;
            display: inline-block;
        }
@media only screen and (min-width: 480px) {
.breadcrumb {
        display: flex
}
    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {

    }
@media only screen and (min-width: 1000px) {

    }
@media only screen and (min-width: 1300px) {

    }



/* [課題] モーダル */
/* https://getbootstrap.com/docs/4.1/components/modal/ */
/*----------------------------------------------------------------------------*/


/* [課題] ポップオーバー、ツールチップス、ポップアップ */
/* https://getbootstrap.com/docs/4.1/components/popovers/ */
/* https://getbootstrap.com/docs/4.1/components/tooltips/ */
/* https://semantic-ui.com/modules/popup.html */
/*----------------------------------------------------------------------------*/


/* [課題] プログレス */
/* https://getbootstrap.com/docs/4.1/components/progress/ */
/* https://semantic-ui.com/modules/progress.html */
/*----------------------------------------------------------------------------*/
.progress {
    background: #dcdcdc;
    background: rgba(220, 220, 220, 0.5);
    min-height: 15px;
    /* border-radius: 4px; */
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}
.progress .progress-bar {
        background: #222222;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        font-size: 1rem;
        line-height: 1;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

/* [課題] ローダー */
/* https://semantic-ui.com/elements/loader.html */
/*----------------------------------------------------------------------------*/


/* [課題] ステップ */
/* https://semantic-ui.com/elements/step.html */
/*----------------------------------------------------------------------------*/
.step-group {
    display: flex;
    /* justify-content: space-between; */

    position: relative;
}
.step-group .step {
        flex: 1 1 0%;
        text-align: center;
        position: relative;
        cursor: default;
    }
.step-group .step .badge {
            margin: 0 auto 1em auto;
            padding: 0;
            width: 40px;
            height: 40px;

            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
            color: #000000;
            color: rgba(0, 0, 0, 0.2);
            font-size: 1.0em;
            font-weight: bold;

            display: flex;
            align-items: center;
            justify-content: center;

            position: relative;
            z-index: 1000;
        }
.step-group .step .content {
            color: #222222;
            font-size: 1.2rem;
        }
.step-group .step.active .badge {
                background-color: #222222;
                box-shadow: 0 0 0 1px #222222 inset;
                color: #fff;
            }
.step-group .step.active .content {
                color: #222222;
            }
.step-group .step:hover {
            opacity: 1.0;
        }
.step-group .step:not(:first-child)::before,
        .step-group .step:not(:first-child)::after {
            content: "";
            height: 2px;
            display: block;
            position: absolute;
            top: calc(40px / 2 - 2px / 2);
            left: -50%;
            z-index: 0;
        }
.step-group .step:not(:first-child)::before {
            width: 100%;
            background: #000000;
            background: rgba(0, 0, 0, 0.2);
        }
.step-group .step:not(:first-child)::after {
            width: 0%;
            background: #222222;
            transition: width 0.2s ease-in-out 0.0s;
        }
/*
        &::before {
            background: $color-base;
        }
        */
.step-group .step.active:not(:first-child)::after {
            width: 100%;
        }

/* [課題] Accordion */
/*  https://semantic-ui.com/modules/accordion.html */
/*----------------------------------------------------------------------------*/


/* [課題] 検索できるドロップダウン */
/* https://semantic-ui.com/modules/dropdown.html */
/*----------------------------------------------------------------------------*/


/* [課題] Scrollspy */
/* https://getbootstrap.com/docs/4.1/components/scrollspy/ */
/*----------------------------------------------------------------------------*/







/* box */
/*----------------------------------------------------------------------------*/

.box {
    margin: 0;
    padding: 20px;
    width: 200px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    flex: 0 0 200px;
}

section.box {
        display: block;
    }

.box.box-img {
    padding: 0;
}
.box.box-text {
    width: auto;
    flex: 1 1 auto;
}

.box:not(.box-white) {
    background-color: #222222;
    color: #fff;
}

.box:not(.box-white) table,
        .box:not(.box-white) table thead,
        .box:not(.box-white) table tbody,
        .box:not(.box-white) table tfoot,
        .box:not(.box-white) table tr,
        .box:not(.box-white) table th,
        .box:not(.box-white) table td {
            border-color: #fff;
        }
.box.box-white {
    background-color: #fff;
    color: #222222;
}

@media only screen and (min-width: 480px) {
    .box-group {
        display: flex;
        /*
        & > .box {
            flex: 1 1 auto;
        }
        */
    }
}
@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 770px) {

}
@media only screen and (min-width: 1000px) {
    .box {
        padding: 20px;

    }
    .box-md {
        /* padding: 60px; */
        padding: 40px;
        width: 400px;
        min-height: 400px;
        flex: 0 0 400px;
    }
    .box-lg {
        /* padding: 100px; */
        padding: 80px;
        width: 600px;
        min-height: 400px;
        flex: 0 0 600px;
    }
    .box-fluid {
        width: auto;
        height: auto;
        padding: 80px;
        flex: 1 1 auto;
    }
}

/*
.box-title {
    height: auto;
}
*/

.box-text {
    min-height: 200px;
    height: auto;
}

.box-text .title {
        margin-bottom: 15px;
        line-height: 1;
    }

@media only screen and (min-width: 480px) {

    }

@media only screen and (min-width: 660px) {

.box-text {
        max-width: 400px
}
    }

@media only screen and (min-width: 770px) {
        .box-text .title {
            margin-bottom: 25px;
            line-height: 1;
        }
    }

@media only screen and (min-width: 1000px) {

    }

@media only screen and (min-width: 1200px) {

    }

@media only screen and (min-width: 1400px) {

    }

@media only screen and (min-width: 1600px) {

    }


.box .btn {
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }


/* box */
/*----------------------------------------------------------------------------*/
.circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 0 0 2px #222222 inset;
    box-sizing: border-box;
}
@media only screen and (min-width: 480px) {

    }
@media only screen and (min-width: 660px) {

    }
@media only screen and (min-width: 770px) {
.circle {
        width: 160px;
        height: 160px
}
    }
@media only screen and (min-width: 1000px) {

    }
/* aタグの装飾をキャンセル */
html body .circle {
        border: none !important;
    }
/* ボタンのサイズ */
.circle.circle-lg {

    }
.circle.circle-sm {
        width: 70px;
        height: 70px;
    }
.circle,
    .circle.circle-fill {
        background-color: #222222;
        color: #fff;
    }
a.circle:hover, a.circle.circle-fill:hover {
            background-color: transparent;
            color: #222222;
        }
.circle.circle-outline {
        background-color: transparent;
        color: #222222;
    }
a.circle.circle-outline:hover {
            background-color: #222222;
            color: #fff;
        }
.circle.circle-skeleton {
        background-color: transparent;
        box-shadow: none;
        color: #222222;
    }
a.circle.circle-skeleton:hover {
            background-color: #000000;
            background-color: rgba(0, 0, 0, 0.2);
            color: #222222;
        }
.circle > span {
        display: block;
        text-align: center;
        line-height: 1.2;
    }
.circle > span span {
            display: inline-block;
        }
@media only screen and (min-width: 480px) {

        }
@media only screen and (min-width: 660px) {

        }
@media only screen and (min-width: 770px) {
.circle > span {
            line-height: 1.5
    }
        }
@media only screen and (min-width: 1000px) {

        }

.wrap-content {
	display: block;
	width: 100%;
	max-width: 1100px;
	margin-left: auto;
	margin-right: auto;
    position: relative;
	padding-right: 15px;
	padding-left: 15px;
}

@media(min-width:1200px) {

.wrap-content {
		padding-right: 0;
		padding-left: 0
}
	}

.wrap-content.middle {
		max-width: 1000px;
	}

.wrap-content.small {
		max-width: 880px;
	}

.wrap-btn {
	text-align: center;
	padding: 40px 0 60px;
}

@media(min-width:768px) {

.wrap-btn {
		padding: 50px 0 125px
}
	}

.wrap-btn a,.wrap-btn input {
		margin: auto;
	}

@media(min-width:992px) {

.ani-btn {
		transition: all .2s
}
	}

@media(min-width:992px) {

.hover-text-red {
		transition: all .2s
}
		.hover-text-red:hover{
			color: #CA101A !important;
		}
	}

@media(min-width:992px) {
		.hover-text-line:hover{
			text-decoration: underline;
		}
	}

.hover-img {
	position: relative;
	display: inline-block;




}

@media(min-width:992px) {
			.hover-img:hover:before {
				opacity:0.2;
			}
	}

.hover-img:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: #fff;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		opacity: 0;
		transition: all .2s;
	}

.card-qa {
	display: block;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #D1D1D1;
	position: relative;
	padding: 30px 10px 10px;
	height: 100%;
}

@media(min-width:768px) {

.card-qa {
		padding: 45px 25px 10px
}
	}

@media(min-width:992px) {
			.card-qa:hover .text {
				color: #CA101A;
			}
	}

.card-qa .icon {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		top: -18px;
	}

@media(min-width:768px) {

.card-qa .icon {
			top: -24px
	}
		}

.card-qa .text {
		color: #222222;
		line-height: 1.75;
		font-size: 1.3rem;
		font-weight: bold;
	}

@media(min-width:768px) {

.card-qa .text {
			font-size: 1.6rem
	}
		}

@media(min-width:992px) {

.card-qa .text {
			transition: all .2s
	}

		}

.card-pattern1 {
	display: block;
	background-color: #FAF8EF;
	border: 1px solid #D1D1D1;
	border-radius: 4px;
	padding: 15px 10px;
}

@media(min-width:768px) {

.card-pattern1 {
		padding: 25px;
		height: 100%
}
	}

@media(min-width:992px) {
			.card-pattern1:hover .text {
				color: #CA101A;
			}
	}

.card-pattern1 .heading {
		color: #CA101A;
		font-size: 1.4rem;
		font-weight: bold;
	}

@media(min-width:768px) {

.card-pattern1 .heading {
			font-size: 1.8rem
	}
		}

.card-pattern1 .icon {
		width: 11px;
		height: 11px;
		margin-left: 10px;
		vertical-align: middle;
	}

@media(min-width:768px) {

.card-pattern1 .icon {
			width: 13px;
			height: 13px;
			margin-left: 25px
	}
		}

.card-pattern1 .text {
		color: #222222;
		font-size: 1.3rem;
		line-height: 1.75;
		padding-top: 10px;
		font-weight: bold;
	}

@media(min-width:768px) {

.card-pattern1 .text {
			font-size: 1.4rem;
			padding-top: 15px
	}
		}

@media(min-width:992px) {

.card-pattern1 .text {
			transition: all .2s
	}
		}

.card-arrow-bottom {
	border: 1px solid #D1D1D1;
	border-radius: 4px;
	background-color: #FAF8EF;
	display: block;
	text-align: center;
	color: #222222;
	font-size: 1.3rem;
	height: 60px;
	padding-top: 16px;
	line-height: 1.5;
	position: relative;
	font-weight: bold;
}

@media(min-width:768px) {

.card-arrow-bottom {
		font-size: 1.6rem;
		height: 120px;
		padding-top: 30px
}
	}

@media(min-width:992px) {

.card-arrow-bottom {
		/* font-size: 1.8rem; */
		transition: all .2s
}
		.card-arrow-bottom:hover {
			color: #CA101A;
		}
	}

@media(min-width:1200px) {

.card-arrow-bottom {
		font-size: 1.8rem
}
	}

.card-arrow-bottom .icon {
		width: 11px;
		height: 11px;
		margin-top: 8px;
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
		display: block;
		margin-right: auto;
		margin-left: auto;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 10px;
	}

@media(min-width:768px) {

.card-arrow-bottom .icon {
			width: 18px;
			height: 18px;
			bottom: 25px
	}

		}

.card-pdf {
	display: block;
	border: 1px solid #D1D1D1;
	border-radius: 4px;
	background-color: #FAF8EF;
	padding: 0 15px;
	font-weight: bold;
}

@media(min-width:768px) {

.card-pdf {
		padding: 0 20px
}
	}

.card-pdf a {
		position: relative;
		display: block;
		padding: 17px 30px 17px 0;
		font-size: 1.3rem;
		color: #222222;
		line-height: 1.5;
	}

@media(min-width:768px) {

.card-pdf a {
			padding: 22px 40px 22px 0;
			font-size: 1.6rem
	}
		}

@media(min-width:992px) {

.card-pdf a {
			transition: all .2s
	}
			.card-pdf a:hover {
				color: #CA101A;
			}
		}

.card-pdf .icon {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
	}

.card-pdf .l-supplement {
		border-top: 1px dashed #D1D1D1;
	}

.card-red-heading {
	text-align: center;
	margin-bottom: 20px;
	border-radius: 4px;
	border-style: solid;
	border-color: #D1D1D1;
	border-bottom-width: 1px;
	border-top-width: 1px;
	border-left-width: 1px;
	border-right-width: 1px;
	background-color: #fff;




}

@media(min-width:768px) {

.card-red-heading {
		width: 49%;
		margin-bottom: 0




}
	}

@media(min-width:1200px) {

.card-red-heading {
		width: 530px




}
	}

.card-red-heading:last-child {
		padding-bottom: 0;
	}

.card-red-heading.w-100 {
		width: 100%;
		text-align: left;
	}

.card-red-heading .title {
		border: solid 1px;
		border-radius: 4px;
		margin-bottom: 20px;
		padding: 10px;
		font-weight: 700;
	}

@media(min-width:768px) {

.card-red-heading .title {
			margin-bottom: 20px;
			font-size: 1.4rem
	}
		}

.card-red-heading .sub-title {
		font-size: 2.4rem;
		font-weight: 700;
		margin-top: 20px;
	}

.card-red-heading .heading {
		text-align: center;
		background-color: #CA101A;
		color: #fff;
		font-size: 1.5rem;
		padding: 13px 10px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		font-weight: bold;
	}

@media(min-width:992px) {

.card-red-heading .heading {
			font-size: 2.4rem;
			padding: 17px 10px
	}
		}

.card-red-heading .heading .text-parts-small {
			margin-top: 10px;
			color: #fff;
			display: block;
		}

.card-red-heading .time {
		padding-top: 10px;
		font-size: 1.2rem;
	    line-height: 1.5;
	    text-align: center;
	}

@media(min-width:768px) {

.card-red-heading .time {
	        font-size: 1.6rem
	}
	    }

.card-red-heading .card-red-contact {
		background-color: #fff;
		padding-top: 25px;
		padding-bottom: 25px;
		padding-right: 10px;
		padding-left: 10px;
	}

@media(min-width:768px) {

.card-red-heading .card-red-contact {
			padding-top: 30px;
			padding-bottom: 30px;
			padding-right: 20px;
			padding-left: 20px
	}
		}

.card-red-heading .text-parts-1,
	.card-red-heading .text-parts-2 {
		margin-bottom: 10px;
	}

@media(min-width:768px) {

.card-red-heading .text-parts-1,
	.card-red-heading .text-parts-2 {
			margin-bottom: 15px
	}
		}

.card-red-heading .text-parts-1:last-child, .card-red-heading .text-parts-2:last-child {
			margin-bottom: 0;
		}

.card-red-heading .btn-round {
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		margin-top: 10px;
	}

@media(min-width:768px) {

.card-red-heading .btn-round {
			width: 100%;
			margin-top: 20px
	}
		}

.card-red-heading .l-number .number {
			font-family: 'Roboto', sans-serif;
			font-weight: 900;
			font-size: 30px;
		}

@media(min-width:768px) {

.card-red-heading .l-number .number {
				font-size: 38px
		}
			}

@media(min-width:992px) {

.card-red-heading .l-number .number {
				font-size: 50px
		}
			}

.card-red-heading .l-number .number-en {
			font-family: 'Roboto', sans-serif;
			font-weight: 900;
			font-size: 20px;
		}

@media(min-width:768px) {

.card-red-heading .l-number .number-en {
				font-size: 28px
		}
			}

@media(min-width:992px) {

.card-red-heading .l-number .number-en {
				font-size: 36px
		}
			}

.card-red-heading .group {
		border-bottom: 1px solid #d1d1d1;
		padding-bottom: 20px;
	}

.card-red-heading .number-en-group {
		display: inline-block;
		text-align: left;
	}

.card-red-heading .caption-text-2 {
		text-align: center;
		line-height: 1.5;
		font-size: 14px;
		font-weight: bold;
	}

.card-post-thumbnail {
	display: block;
	border: 1px solid #D1D1D1;
	border-radius: 4px;
	background-color: #fff;





}

@media(min-width:992px) {
				.card-post-thumbnail:hover .img:before {
					opacity:0.3;
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
					overflow: hidden;
				}
			.card-post-thumbnail:hover .title {
				color: #D3484E;
			}
			.card-post-thumbnail:hover .text {
				text-decoration: underline;
			}
	}

.card-post-thumbnail .img {
		width: 100%;
		padding-top: 58.04%;
		position: relative;
		background-image: url(../img/column/noimage.png);
		background-size: cover;
		background-position: center;
		background-color: #fff;
		position: relative;
	}

.card-post-thumbnail .img:before {
			content: '';
			display: block;
			background-color: #fff;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			transition: all .2s;
			opacity: 0;

		}

.card-post-thumbnail .l-text {
		padding: 15px 15px 8px;
		background-color: #fff;
	}

@media(min-width:768px) {

.card-post-thumbnail .l-text {
			padding: 25px 20px 8px
	}
		}

.card-post-thumbnail .title {
		font-size: 1.8rem;
		padding-bottom: 5px;
		line-height: 1.5;
		color: #222222;
		transition: all .2s;
		font-weight: bold;
	}

@media(min-width:768px) {

.card-post-thumbnail .title {
			font-size: 2rem;
			padding-bottom: 10px
	}
		}

.card-post-thumbnail .text {
		font-size: 1.4rem;
		padding-bottom: 5px;
		line-height: 1.5;
		color: #454545;
	}

@media(min-width:768px) {

.card-post-thumbnail .text {
			font-size: 1.6rem;
			padding-bottom: 10px
	}
		}

.card-post-thumbnail .date {
		font-family: 'Roboto', sans-serif;
		color: #454545;
		font-size: 1.3rem;
		padding-bottom: 5px;
	}

@media(min-width:768px) {

.card-post-thumbnail .date {
			font-size: 1.5rem;
			padding-bottom: 10px
	}
		}

.card-post-thumbnail .tags-list {
		font-size: 0;
	}

.card-post-thumbnail .tags-list li {
			display: inline-block;
			border: 1px solid #454545;
			color: #454545;
			text-align: center;
			border-radius: 2px;
			margin-right: 4px;
			margin-bottom: 4px;
			padding: 5px 5px;
			font-size: 1rem;
		}

@media(min-width:768px) {

.card-post-thumbnail .tags-list li {
				font-size: 1.2rem;
				margin-right: 6px;
				margin-bottom: 6px
		}
			}


.card-support {
	height: 100%;
	text-align: center;
	background-color: #FAF8EF;
	border: 1px solid #D1D1D1;
	padding: 20px 15px;
}


@media(min-width:768px) {


.card-support {
		padding: 30px 25px
}
	}


.card-support .heading {
		background-color: #CA101A;
		color: #fff;
		font-size: 1.4rem;
		height: 28px;
		line-height: 28px;
		border-radius: 4px;
		display: inline-block;
		width: 100px;
		font-weight: bold;
	}


@media(min-width:768px) {


.card-support .heading {
			width: 120px;
			font-size: 1.8rem;
			height: 36px;
			line-height: 36px
	}
		}


.card-support .text {
		font-size: 1.8rem;
		padding: 20px 0 15px;
		line-height: 1.5;
		font-weight: bold;
	}


@media(min-width:768px) {


.card-support .text {
			font-size: 2.2rem;
			padding: 25px 0 20px
	}
		}


@media(min-width:992px) {


.card-support .text {
			font-size: 2.4rem
	}
		}


.card-support .small-text {
		font-size: 1.2rem;
	    line-height: 1.5;
	    color: #5C5C5C;
		display: block;
		text-align: center;
		margin-top: 5px;
		font-weight: normal;
	}


@media(min-width:768px) {


.card-support .small-text {
			margin-top: 10px;
	        font-size: 1.6rem
	}
	    }


@media(min-width:768px) {


.card-support .wrap.double {
				display: flex;
				justify-content: space-between
		}
			}


.card-support .wrap.double a {
				margin-bottom: 15px;
			}


@media(min-width:768px) {


.card-support .wrap.double a {
					margin-bottom: 0;
					width: 48%
			}
				}


@media(min-width:1200px) {


.card-support .wrap.double a {
					width: 220px
			}
				}


.card-support .wrap.double a:last-child {
					margin-bottom: 0;
				}


.card-support .btn-round {
		font-size: 1.3rem;
	}


@media(min-width:768px) {


.card-support .btn-round {
			font-size: 1.8rem
	}
		}


.card-support .btn-round:last-child {
			margin-top: 10px;
		}


@media(min-width:768px) {


.card-support .btn-round:last-child {
				margin-top: 15px
		}
			}


.card-red-light-heading {
	border: 1px solid #D1D1D1;
	border-radius: 4px;
	margin-bottom: 20px;

}


@media(min-width:768px) {


.card-red-light-heading {
		width: 48%;
		margin-bottom: 0

}
	}


@media(min-width:1200px) {


.card-red-light-heading {
		width: 530px

}
	}


.card-red-light-heading:last-child {
		margin-bottom: 0;
	}


.card-red-light-heading.w-100 {
		width: 100%;
	}


.card-red-light-heading .m-link-pdf {
		font-weight: normal;
	}


@media(max-width:1200px) {


.card-red-light-heading .m-link-pdf {
			font-size: 1.6rem
	}
		}


.card-red-light-heading .heading {
		font-size: 1.8rem;
		font-weight: bold;
		line-height: 1.5;
		padding: 15px;
		border-radius: 4px;
		background-color: #FDEFEF;
	}


@media(min-width:768px) {


.card-red-light-heading .heading {
			font-size: 2.2rem;
			padding: 18px 25px
	}
		}


.card-red-light-heading .l-text {
		padding: 15px;


	}


@media(min-width:768px) {


.card-red-light-heading .l-text {
			padding: 25px


	}
		}


@media(min-width:768px) {


.card-red-light-heading .l-text .layout {
				display: flex;
				justify-content: space-between
		}
			}


@media(min-width:768px) {


.card-red-light-heading .l-text .layout .left {
					width: 60%
			}
				}


@media(min-width:1200px) {


.card-red-light-heading .l-text .layout .left {
					width: 660px
			}
				}


.card-red-light-heading .l-text .layout .right {
				width: 70%;
				margin: 15px auto 0;

			}


@media(min-width:768px) {


.card-red-light-heading .l-text .layout .right {
					margin: 0;
					width: 35%

			}
				}


@media(min-width:1200px) {


.card-red-light-heading .l-text .layout .right {
					width: 340px

			}
				}


.card-red-light-heading .card-pdf {
		max-width: 540px;
		margin-bottom: 20px;
	}


@media(min-width:768px) {


.card-red-light-heading .card-pdf {
			margin-bottom: 30px
	}
		}


.card-red-light-heading .text-parts-1,
	.card-red-light-heading .text-parts-small {
		padding-bottom: 10px;
	}


@media(min-width:768px) {


.card-red-light-heading .text-parts-1,
	.card-red-light-heading .text-parts-small {
			padding-bottom: 20px
	}
		}


.card-red-light-heading .text-parts-1 {
		font-weight: bold;
	}


.card-red-light-heading ul {
		padding-bottom: 10px;
		font-weight: normal;
		font-weight: bold;
	}


@media(min-width:768px) {


.card-red-light-heading ul {
			padding-bottom: 20px
	}
		}


.card-red-light-heading ul li {
			position: relative;
			font-size: 1.2rem;
			padding-left: 10px;
			line-height: 1.5;
			margin-bottom: 3px;
		}


@media(min-width:768px) {


.card-red-light-heading ul li {
				font-size: 1.6rem;
				padding-left: 10px
		}
			}


.card-red-light-heading ul li:last-child {
				margin-bottom: 0;
			}


.card-red-light-heading ul li:before {
				content: '';
				display: block;
				width: 4px;
				height: 4px;
				background-color: #222222;
				border-radius: 50%;
				position: absolute;
				top: 7px;
				left: 0;
			}


@media(min-width:768px) {


.card-red-light-heading ul li:before {
					top: 10px
			}
				}


.card-red-light-heading .text-parts-1:last-child, .card-red-light-heading .text-parts-small:last-child, .card-red-light-heading ul:last-child {
			padding-bottom: 0;
		}

.card-yellow-light-heading {
	border: 1px solid #D1D1D1;
	margin-bottom: 20px;

}

@media(min-width:768px) {

.card-yellow-light-heading {
		width: 48%;
		margin-bottom: 0

}
	}

@media(min-width:1200px) {

.card-yellow-light-heading {
		width: 530px

}
	}

.card-yellow-light-heading:last-child {
		margin-bottom: 0;
	}

.card-yellow-light-heading.w-100 {
		width: 100%;

	}

.card-yellow-light-heading .heading {
		font-size: 1.8rem;
		font-weight: bold;
		line-height: 1.5;
		padding: 15px;
		background-color: #FAF8EF;
	}

@media(min-width:768px) {

.card-yellow-light-heading .heading {
			font-size: 2.2rem;
			padding: 18px 25px
	}
		}

.card-yellow-light-heading .l-text {
		padding: 15px;


	}

@media(min-width:768px) {

.card-yellow-light-heading .l-text {
			padding: 25px


	}
		}

@media(min-width:768px) {

.card-yellow-light-heading .l-text .layout {
				display: flex;
				justify-content: space-between
		}
			}

@media(min-width:768px) {

.card-yellow-light-heading .l-text .layout .left {
					width: 60%
			}
				}

@media(min-width:1200px) {

.card-yellow-light-heading .l-text .layout .left {
					width: 660px
			}
				}

.card-yellow-light-heading .l-text .layout .right {
				width: 70%;
				margin: 15px auto 0;

			}

@media(min-width:768px) {

.card-yellow-light-heading .l-text .layout .right {
					margin: 0;
					width: 35%

			}
				}

@media(min-width:1200px) {

.card-yellow-light-heading .l-text .layout .right {
					width: 340px

			}
				}

.card-yellow-light-heading .card-pdf {
		max-width: 540px;
		margin-bottom: 20px;
	}

@media(min-width:768px) {

.card-yellow-light-heading .card-pdf {
			margin-bottom: 30px
	}
		}

.card-yellow-light-heading .text-parts-1,
	.card-yellow-light-heading .text-parts-small {
		padding-bottom: 10px;
	}

@media(min-width:768px) {

.card-yellow-light-heading .text-parts-1,
	.card-yellow-light-heading .text-parts-small {
			padding-bottom: 20px
	}
		}

.card-yellow-light-heading ul {
		padding-bottom: 10px;
	}

@media(min-width:768px) {

.card-yellow-light-heading ul {
			padding-bottom: 20px
	}
		}

.card-yellow-light-heading ul li {
			position: relative;
			font-size: 1.2rem;
			padding-left: 10px;
			line-height: 1.5;
			margin-bottom: 3px;
		}

@media(min-width:768px) {

.card-yellow-light-heading ul li {
				font-size: 1.6rem;
				padding-left: 10px
		}
			}

.card-yellow-light-heading ul li:last-child {
				margin-bottom: 0;
			}

.card-yellow-light-heading ul li:before {
				content: '';
				display: block;
				width: 4px;
				height: 4px;
				background-color: #222222;
				border-radius: 50%;
				position: absolute;
				top: 7px;
				left: 0;
			}

@media(min-width:768px) {

.card-yellow-light-heading ul li:before {
					top: 10px
			}
				}

.card-yellow-light-heading .text-parts-1:last-child, .card-yellow-light-heading .text-parts-small:last-child, .card-yellow-light-heading ul:last-child {
			padding-bottom: 0;
		}

.breadcrumb .l-list {
		font-size: 0;
		padding-top: 15px;
	}

.breadcrumb .l-list li {
			font-size: 1.3rem;
			color: #222222;
			position: relative;
			padding-bottom: 15px;
			padding-right: 20px;
			margin-right: 20px;
			display: inline-block;
		}

.breadcrumb .l-list li:after {
				content: '';
				display: block;
				width: 6px;
			    height: 6px;
			    border: 1px solid;
			    border-color: #BBBBBB #BBBBBB transparent transparent;
			    -webkit-transform: rotate(45deg);
			            transform: rotate(45deg);
				position: absolute;
				top: 0;
				right: 0;
			}

.breadcrumb .l-list li:last-child {
				padding-right: 0;
				margin-right: 0;
			}

.breadcrumb .l-list li:last-child:after {
					display: none;
				}

.breadcrumb .l-list li a {
				color: #222222;
			}

.tab-1 {
	margin-bottom: 40px;
}

@media(min-width:768px) {

.tab-1 {
		margin-bottom: 70px
}
	}

.tab-1 .list-menu {
		display: table;
		width: 100%;
		table-layout: fixed;
		position: relative;
	}

.tab-1 .list-menu li {
			display: table-cell;
			vertical-align: middle;
			padding-right: 4px;
			position: relative;

		}

.tab-1 .list-menu li:last-child {
				padding-right: 0;
			}

.tab-1 .list-menu li.active {
				z-index: 1;
			}

.tab-1 .list-menu li.active a {
					color: #CA101A;
					background-color: #FAF8EF;
				}

@media(min-width:992px) {
				.tab-1 .list-menu li:hover {
					z-index: 1;
				}
					.tab-1 .list-menu li:hover a {
						color: #CA101A;
						background-color: #FAF8EF;
					}
			}

.tab-1 .list-menu a {
			display: table;
			width: 100%;
			text-align: center;
			font-size: 13px;
			background-color: #F7F7F7;
			color: #222222;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			padding: 0 10px;
			height: 75px;
			transition: all .2s;
			border-style: solid;
			border-color: #D1D1D1;
			border-top-width: 2px;
			border-left-width: 2px;
			border-right-width: 2px;
		}

@media(min-width:768px) {

.tab-1 .list-menu a {
				height: auto;
				line-height: 1.5;
				font-size: 18px;
				padding: 0 10px;
				height: 70px
		}
			}

.tab-1 .list-menu a span {
				line-height: 1.5;
				display: table-cell;
				vertical-align: middle;
				font-weight: bold;
			}

.tab-1 .list-area {
		border: 2px solid #D1D1D1;
		background-color: #FAF8EF;
		display: flex;
		flex-wrap: wrap;
		padding-top: 25px;
		padding-right: 15px;
		padding-left: 15px;
		position: relative;
		top: -3px;
		display: none;


	}

@media(min-width:768px) {

.tab-1 .list-area {
			top: -2px;
			padding-top: 50px;
			padding-right: 60px;
			padding-left: 60px


	}
		}

.tab-1 .list-area.active {
			display: flex;
		}

.tab-1 .list-area li {
			width: 33%;
			padding-bottom: 20px;
		}

@media(min-width:768px) {

.tab-1 .list-area li {
				padding-bottom: 50px;
				width: 16.66%
		}
			}

.tab-1 .list-area a {
			font-size: 1.6rem;
			color: #222222;
		}

@media(min-width:768px) {

.tab-1 .list-area a {
				font-size: 1.8rem
		}
			}

.tab-1 .list-area a .icon {
				vertical-align: middle;
				margin-left: 5px;
				width: 8px;
				height: 8px;
				-webkit-transform: rotate(180deg);
				        transform: rotate(180deg);
			}

@media(min-width:768px) {

.tab-1 .list-area a .icon {
					margin-left: 10px;
					width: 10px;
					height: 10px
			}
				}

.tab-2 {
	margin-bottom: 40px;


}

@media(min-width:768px) {

.tab-2 {
		margin-bottom: 80px


}
	}

.tab-2 .list-menu {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		position: relative;
		border-style: solid;
		border-color: #D1D1D1;
		border-top-width: 2px;
		border-left-width: 2px;
		border-radius: 4px;
	}

@media(min-width:768px) {

.tab-2 .list-menu {
			flex-wrap: nowrap;
			border-bottom-width: 2px
	}
		}

.tab-2 .list-menu li {
			position: relative;
			text-align: center;
			width: 50%;
			border-style: solid;
			border-color: #D1D1D1;
			border-bottom-width: 2px;
			border-right-width: 2px;


		}

@media(min-width:768px) {

.tab-2 .list-menu li {
				border-bottom: none;
				width: 100%


		}
			}

.tab-2 .list-menu li.active a {
					color: #CA101A;
					background-color: #FAF8EF;
				}

@media(min-width:992px) {
					.tab-2 .list-menu li:hover a {
						color: #CA101A;
						background-color: #FAF8EF;
					}
			}

.tab-2 .list-menu a {
			display: table;
			width: 100%;
			text-align: center;
			font-size: 13px;
			background-color: #F7F7F7;
			color: #222222;
			padding: 0 10px;
			height: 75px;
			transition: all .2s;
		}

@media(min-width:768px) {

.tab-2 .list-menu a {
				height: auto;
				font-size: 16px;
				padding: 0 10px;
				height: 100px
		}
			}

@media(min-width:992px) {

.tab-2 .list-menu a {
				font-size: 18px
		}
			}

.tab-2 .list-menu a span {
				line-height: 1.5;
				display: table-cell;
				vertical-align: middle;
				font-weight: bold;
			}

.tab-3 {
	width: 100%;
	display: table;
	table-layout:fixed;
}

.tab-3 li {
		display: table-cell;
		vertical-align: middle;
		padding-right: 5px;
	}

@media(min-width:768px) {

.tab-3 li {
			padding-right: 20px
	}
		}

.tab-3 li:last-child {
			padding-right: 0;
		}

.tab-3 .link {
		display: table;
		width: 100%;
		color: #222222;
		background-color: #F7F7F7;
		border-style: solid;
		border-color: #D1D1D1;
		font-size: 13px;
		height: 75px;
		border-top-width: 1px;
		border-left-width: 1px;
		border-right-width: 1px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		text-align: center;
		position: relative;
		padding-right: 5px;
		padding-left: 5px;
	}

@media(min-width:768px) {

.tab-3 .link {
			font-size: 2.2rem;
			height: 100px;
			border-top-width: 2px;
			border-left-width: 2px;
			border-right-width: 2px;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			padding-right: 10px;
			padding-left: 10px
	}
		}

@media(min-width:992px) {

.tab-3 .link {
			padding-right: 20px;
			padding-left: 20px;
			font-size: 24px;
			transition: all .2s
	}
			.tab-3 .link:hover {
				color: #CA101A;
				background-color: #FAF8EF;
			}
		}

.tab-3 .link span {
			line-height: 1.25;
			display: table-cell;
			vertical-align: middle;
			font-weight: bold;
		}

.tab-3 .link.active {
			background-color: #FAF8EF;
			color: #CA101A;

		}

.tab-3 .link.active:before {
				content: '';
				display: block;
				width: 100%;
				height: 4px;
				background-color: #FAF8EF;
				position: absolute;
				bottom: 0;
				left: 0;
				bottom: -4px;
			}

@media(min-width:768px) {

.tab-3 .link.active:before {
					bottom: -2px
			}
				}

.tab-3-content {
	border-top: 1px solid #D1D1D1;
	padding-top: 30px;
	padding-bottom: 30px;
}

@media(min-width:768px) {

.tab-3-content {
		border-top: 2px solid #D1D1D1;
		padding-top: 60px;
		padding-bottom: 60px
}
	}

.tab-3-content.mb {
		margin-bottom: 30px;
	}

@media(min-width:768px) {

.tab-3-content.mb {
			margin-bottom: 60px
	}
		}

.tab-3-content .tab-3-group {
		background-color: #fff;
		padding: 30px 20px;
		margin-top: 20px;

	}

@media(min-width:768px) {

.tab-3-content .tab-3-group {
			padding: 80px 40px;
			margin-top: 60px

	}
		}

.tab-3-content .tab-3-group.mt-0 {
			margin-top: 0;
		}

.tab-3-content .tab-3-group-b {
		background-color: #fff;
		padding: 40px 15px;
		margin-bottom: 60px;
	}

@media(min-width:768px) {

.tab-3-content .tab-3-group-b {
			padding: 80px 40px;
			margin-bottom: 60px
	}
		}

.tab-3-content .tab-3-group-b .heading {
			text-align: center;
			font-weight: bold;
			font-size: 2.2rem;
			margin-bottom: 20px;

		}

@media(min-width:768px) {

.tab-3-content .tab-3-group-b .heading {
				font-size: 3.6rem;
				margin-bottom: 35px

		}
			}

.map-box .map {
        width: 100%;
        height: 350px;
        line-height: 1.5;
    }

@media(min-width:768px) {

.map-box .map {
            height: 500px
    }
        }

.map-box .map .store-name {
            display: block;
            font-size: 1.6rem;
            color: #222222;
        }

.map-box .map .store-data {
            font-size: 1.2rem;
            color: #888888;
            display: block;
        }

.box-red-line {
    border: 1px solid #ECADAF;
    color: #D3484E;
    font-size: 1.4rem;
    padding: 15px;
    line-height: 1.5;
}

@media(min-width:992px) {

.box-red-line {
        font-size: 1.8rem;
        padding: 20px
}
    }


.box-my-page {
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    padding: 20px 15px;
    margin-top: 20px;
}


@media(min-width:768px) {


.box-my-page {
        margin-top: 40px;
        padding: 30px
}
    }


.box-my-page h3 {
        font-size: 1.8rem;
        font-weight: bold;
        padding-bottom: 10px;
    }


@media(min-width:768px) {


.box-my-page h3 {
            font-size: 2.6rem;
            padding-bottom: 15px
    }
        }


@media(min-width:768px) {


.box-my-page .inner {
            display: flex;
            justify-content: space-between
    }
        }


.box-my-page .inner .text {
            font-size: 1.4rem;
            line-height: 1.5;
            font-weight: bold;
        }


@media(min-width:768px) {


.box-my-page .inner .text {
                font-size: 1.8rem;
                width: 65.438%
        }
            }


@media(min-width:992px) {


.box-my-page .inner .text {
                width: 67.438%
        }
            }


.box-my-page .inner .text a {
                color: #222222;
                text-decoration: underline;
                margin-top: 5px;
                display: inline-block;
            }


@media(min-width:992px) {


.box-my-page .inner .text a {
                    margin-top: 10px
            }
                    .box-my-page .inner .text a:hover {
                        text-decoration: none;
                    }
                }


.box-my-page .inner .l-btn {
            padding-top: 15px;

        }


@media(min-width:768px) {


.box-my-page .inner .l-btn {
                padding-top: 0;
                width: 30.903%

        }
            }


@media(min-width:992px) {


.box-my-page .inner .l-btn {
                width: 28.903%

        }
            }


@media(min-width:768px) {


.box-my-page .inner .l-btn .btn-square {
                    padding: 40px 5px;
                    font-size: 1.6rem
            }
                }


@media(min-width:992px) {


.box-my-page .inner .l-btn .btn-square {
                    padding: 40px 10px;
                    font-size: 1.8rem
            }
                }


@media(min-width:768px) {


.box-my-page .inner .l-btn .btn-square .icon {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                        vertical-align: middle
                }
                    }

@media(min-width:768px) {

.box-beginning {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap





}

    }

@media(min-width:768px) {

.box-beginning .left {
            width: 62%
    }
        }

@media(min-width:1200px) {

.box-beginning .left {
            width: 684px
    }
        }

.box-beginning .right {
        margin: 30px auto 0;
        width: 70%;
    }

@media(min-width:768px) {

.box-beginning .right {
            margin: 0;
            width: 31%
    }
        }

@media(min-width:1200px) {

.box-beginning .right {
            width: 340px
    }
        }

@media(min-width:768px) {

.box-beginning.img-big .left {
                width: 56.5%
        }
            }

@media(min-width:1200px) {

.box-beginning.img-big .left {
                width: 620px
        }
            }

.box-beginning.img-big .right {
            margin: 30px auto 0;
            width: 70%;
        }

@media(min-width:768px) {

.box-beginning.img-big .right {
                margin: 0;
                width: 39.5%
        }
            }

@media(min-width:1200px) {

.box-beginning.img-big .right {
                width: 435px
        }
            }

.box-beginning .beginning-list {
        display: inline-block;
        margin-top: 15px;
        margin-bottom: -10px;
    }

@media(min-width:768px) {

.box-beginning .beginning-list {
            margin-top: 25px;
            margin-bottom: 0
    }
        }

.box-beginning .beginning-list li {
            display: inline-block;
            color: #fff;
            text-align: center;
            background-color: #D3484E;
            font-size: 1.4rem;
            margin-right: 5px;
            padding: 8px 5px;
            width: 100px;
            border-radius: 20px;
            margin-bottom: 10px;
        }

@media(min-width:768px) {

.box-beginning .beginning-list li {
                margin-bottom: 5px;
                font-size: 1.6rem;
                margin-right: 10px;
                padding: 10px 5px;
                width: 130px;
                border-radius: 25px
        }
            }

.box-beginning .beginning-list li:last-child {
                margin-right: 0;
            }

.box-beginning .text-box {
        width: 100%;
        font-size: 1.4rem;
        margin-top: 20px;
        padding: 20px 15px;
        line-height: 1.75;
        border: 1px solid #D1D1D1;
    }

@media(min-width:768px) {

.box-beginning .text-box {
            font-size: 1.8rem;
            margin-top: 50px;
            padding: 25px 30px
    }
        }

.box-white {

    margin-top: 50px;
}

.box-white .inner {
        background-color: #fff;
        padding: 70px;
    }

.box-white .heading {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 15px;
    }

.box-white .wrap-btn {
        padding-bottom: 0;
    }

.box-white .wrap-btn .btn-round {
            max-width: 740px;
        }

.box-heading-yellow {
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    line-height: 1.75;
    margin-bottom: 20px;
}

@media(min-width:768px) {

.box-heading-yellow {
        display: table;
        width: 100%;
        margin-bottom: 30px
}
    }

.box-heading-yellow:last-child {
        margin-bottom: 0;
    }

.box-heading-yellow dt {
        background-color: #FDEFEF;
        font-size: 1.6rem;
        padding: 10px;
        text-align: center;
        font-weight: bold;
    }

@media(min-width:768px) {

.box-heading-yellow dt {
            display: table-cell;
            vertical-align: middle;
            font-size: 2rem;
            width: 278px;
            padding: 10px 15px
    }

        }

.box-heading-yellow dd {
        font-size: 1.4rem;
        padding: 15px;


    }

@media(min-width:768px) {

.box-heading-yellow dd {
            display: table-cell;
            vertical-align: top;
            padding: 25px;
            font-size: 1.8rem


    }
        }

.box-heading-yellow dd .indent {
            padding-left: 2em;
        }

.box-contract-flow {
    background-color: #F7F7F7;
    margin-top: 20px;
    padding: 30px 15px;
    text-align: left;
}

@media(min-width:768px) {

.box-contract-flow {
        margin-top: 60px;
        padding: 40px
}
    }

.box-contract-flow .heading {
        font-size: 1.8rem;
        padding-bottom: 15px;
        font-weight: bold;
    }

@media(min-width:768px) {

.box-contract-flow .heading {
            font-size: 3.0rem;
            padding-bottom: 20px
    }
        }

.box-contract-flow .text {
        line-height: 1.75;
        font-size: 1.4rem;
        padding-bottom: 10px;
    }

@media(min-width:768px) {

.box-contract-flow .text {
            font-size: 1.8rem;
            padding-bottom: 45px
    }
        }

@media(min-width:768px) {

.box-contract-flow .flow-list {
            display: flex;
            justify-content: space-between
    }
        }

.box-contract-flow .flow-list li {
            margin-bottom: 10px;
        }

@media(min-width:768px) {

.box-contract-flow .flow-list li {
                width: 32%;
                margin-bottom: 0
        }
            }

@media(min-width:1200px) {

.box-contract-flow .flow-list li {
                width: 320px
        }
            }

.box-contract-flow .flow-list li:last-child {
                margin-bottom: 0;
            }

.box-contract-flow .flow-list .group {
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
        }

@media(min-width:768px) {

.box-contract-flow .flow-list .group {
                text-align: center;
                padding: 0;
                display: block;
                padding-top: 24px;
                height: 300px
        }
            }

.box-contract-flow .flow-list .group .step {
                font-family: 'Roboto', sans-serif;
                display: block;
                font-weight: bold;
                font-size: 1.4rem;
            }

@media(min-width:768px) {

.box-contract-flow .flow-list .group .step {
                    font-size: 1.8rem;
                    padding-bottom: 25px
            }
                }

.box-contract-flow .flow-list .group .step .number {
                    display: block;
                    font-size: 2.6rem;
                    padding-top: 2px;
                }

@media(min-width:768px) {

.box-contract-flow .flow-list .group .step .number {
                        font-size: 3.4rem;
                        padding-top: 4px
                }
                    }

.box-contract-flow .flow-list .group .img {
                width: 50px;
            }

@media(min-width:768px) {

.box-contract-flow .flow-list .group .img {
                    width: 80px;
                    margin-right: auto;
                    margin-left: auto
            }
                }

.box-contract-flow .flow-list .group .title {
                font-size: 1.4rem;
                line-height: 1.5;
                font-weight: bold;
                width: 33%;
            }

@media(min-width:768px) {

.box-contract-flow .flow-list .group .title {
                    margin-top: 25px;
                    font-size: 1.8rem;
                    height: 3em;
                    display: table;
                    width: 100%
            }
                }

@media(min-width:768px) {

.box-contract-flow .flow-list .group .title .inner {
                        display: table-cell;
                        vertical-align: middle
                }
                    }

.box-contract-flow .announce-list {
        background-color: #fff;
        margin-top: 20px;
        position: relative;
        padding: 20px 10px;
    }

@media(min-width:768px) {

.box-contract-flow .announce-list {
            display: flex;
            margin-top: 30px;
            padding: 40px 0 20px
    }
        }

@media(min-width:768px) {

.box-contract-flow .announce-list:before {
                content: '';
                width: 2px;
                height: 80%;
                background-color: #DDDDDD;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto
        }
            }

.box-contract-flow .announce-list li {
            text-align: center;
            padding-bottom: 20px;
        }

@media(min-width:768px) {

.box-contract-flow .announce-list li {
                width: 50%
        }
            }

.box-contract-flow .announce-list li:last-child {
                padding-bottom: 0;
            }

.box-contract-flow .announce-list .text {
            font-size: 1.4rem;
            line-height: 1.5;
            padding-bottom: 10px;
            font-weight: bold;
        }

@media(min-width:768px) {

.box-contract-flow .announce-list .text {
                font-size: 1.8rem;
                padding-bottom: 30px
        }
            }

.box-contract-flow .announce-list .btn-round {
            margin-left: auto;
            margin-right: auto;
        }

@media(min-width:768px) {

.box-contract-flow .announce-list .btn-round {
                max-width: 300px;
                font-size: 1.8rem;
                height: 54px;
                line-height: 54px
        }
            }

.box-contract-flow .announce-list .btn-round .icon {
                fill:#fff;
            }

.box-contract-flow .announce-list-2 {
        background-color: #fff;
        position: relative;
        padding: 20px 10px;
    }

@media(min-width:768px) {

.box-contract-flow .announce-list-2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 0
    }
        }

.box-contract-flow .announce-list-2 li {
            text-align: center;
            padding-bottom: 20px;
        }

@media(min-width:768px) {

.box-contract-flow .announce-list-2 li {
                width: 50%
        }
            }

.box-contract-flow .announce-list-2 li:last-child {
                padding-bottom: 0;
            }

.box-contract-flow .announce-list-2 .text {
            font-size: 1.4rem;
            line-height: 1.5;
            padding-bottom: 10px;
            font-weight: bold;
        }

@media(min-width:768px) {

.box-contract-flow .announce-list-2 .text {
                font-size: 1.8rem;
                padding-bottom: 30px
        }
            }

.box-contract-flow .announce-list-2 .btn-round {
            margin-left: auto;
            margin-right: auto;
        }

@media(min-width:768px) {

.box-contract-flow .announce-list-2 .btn-round {
                max-width: 300px;
                font-size: 1.8rem;
                height: 54px;
                line-height: 50px
        }
            }

.box-contract-flow .announce-list-2 .btn-round .icon {
                fill:#fff;
            }

.box-4charm {
    background-color: #FAF8EF;
    border: 1px solid #D1D1D1;
    padding: 30px 15px;
    text-align: center;
    margin-top: 40px;

}

@media(min-width:768px) {

.box-4charm {
        margin-top: 80px;
        padding: 45px 15px

}
    }

.box-4charm .heading {
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 5px;
    }

@media(min-width:768px) {

.box-4charm .heading {
            margin-bottom: 10px
    }
        }

.box-4charm .heading .text1 {
            display: block;
            font-size: 2rem;
        }

@media(min-width:768px) {

.box-4charm .heading .text1 {
                font-size: 2.6rem
        }
            }

.box-4charm .heading .text2 {
            font-size: 2.2rem;
        }

@media(min-width:768px) {

.box-4charm .heading .text2 {
                font-size: 3.0rem
        }
            }

.box-4charm .heading .text2 span {
                font-size: 2.4rem;
                color: #CA101A;
                font-family: 'Roboto', sans-serif;
            }

@media(min-width:768px) {

.box-4charm .heading .text2 span {
                    font-size: 3.4rem
            }
                }

.box-4charm .wrap-btn {
        padding: 15px 0 0;
        max-width: 470px;
        margin-left: auto;
        margin-right: auto;
    }

@media(min-width:768px) {

.box-4charm .wrap-btn {
            padding: 20px 0 0
    }
        }

.box-pamphlet {
    background-color: #F7F7F7;
    margin-top: 30px;
    padding: 15px;



}

@media(min-width:768px) {

.box-pamphlet {
        margin-top: 50px;
        padding: 30px



}
    }

.box-pamphlet .inner {
        background-color: #fff;
        padding: 20px 15px;
        position: relative;
    }

@media(min-width:768px) {

.box-pamphlet .inner {
            padding: 50px 30px
    }
        }

.box-pamphlet .text {
        line-height: 1.5;
        font-size: 1.6rem;
        padding-bottom: 10px;
        font-weight: bold;
    }

@media(min-width:768px) {

.box-pamphlet .text {
            font-size: 2rem;
            padding-bottom: 25px
    }
        }

.box-pamphlet .text.last-child {
            padding-bottom: 0;
        }

.box-pamphlet .text a {
            color: #275EAC;
            font-weight: normal;
        }

.box-pamphlet .pamphlet-img {
        margin-top: 20px;
        margin: 20px auto 0;
        text-align: center;
    }

@media(min-width:992px) {

.box-pamphlet .pamphlet-img {
            margin: 0;
            position: absolute;
            top: 10px;
            right: 0;
            width: 380px
    }
        }

@media(min-width:1200px) {

.box-pamphlet .pamphlet-img {
            top: -10px;
            width: 489px
    }
        }

.box-pamphlet .pamphlet-img-recruit {
        margin-top: 20px;
        margin: 20px auto 0;
        text-align: center;
    }

@media(min-width:992px) {

.box-pamphlet .pamphlet-img-recruit {
            margin: 0;
            position: absolute;
            top: -35px;
            right: -10px;
            width: 400px
    }
        }

@media(min-width:1200px) {

.box-pamphlet .pamphlet-img-recruit {
            top: -35px;
            right: -10px;
            width: 468px
    }
        }

.box-gray {
    background-color: #F7F7F7;
    padding: 10px;
    margin-bottom: 15px;
}

@media(min-width:768px) {

.box-gray {
        padding: 20px;
        margin-bottom: 20px
}
    }

.box-gray .inner {
        padding: 15px;
    }

@media(min-width:768px) {

.box-gray .inner {
            padding: 20px;
            display: flex;
            justify-content: space-between
    }
        }

.box-gray .inner .group {
            background-color: #fff;
            padding: 15px;
            margin-bottom: 15px;
        }

@media(min-width:768px) {

.box-gray .inner .group {
                width: 48%;
                padding: 30px
        }
            }

@media(min-width:1200px) {

.box-gray .inner .group {
                width: 495px
        }
            }

.box-gray .inner .group:last-child {
                margin-bottom: 0;
            }

.box-gray .inner .group .text-parts-2 {
                font-weight: bold;
                padding-bottom: 10px;
            }

.box-gray .inner .group.w-100 {
                width: 100%;
            }

.box-gray .text-parts-small {
        color: #222222;
        margin-bottom: 10px;
    }

@media(min-width:768px) {

.box-gray .text-parts-small {
            margin-bottom: 15px
    }
        }

.box-gray .text-parts-small:last-child {
            margin-bottom: 0;
        }

.box-gray .list-indent{
        font-size: 1.4rem;
        line-height: 1.75;
    }

@media(min-width:768px) {

.box-gray .list-indent{
            font-size: 1.6rem
    }
        }

.box-gray .list-indent li {
            padding-bottom: 5px;
            padding-left: 2em;
            text-indent: -2em;
        }

.box-gray .list-indent li:last-child {
                padding-bottom: 0;
            }

.box-gray .list-dot {
        font-size: 1.4rem;
        line-height: 1.75;
    }

@media(min-width:768px) {

.box-gray .list-dot {
            font-size: 1.6rem
    }
        }

.box-gray .list-dot li {
            margin-top: 0;
            padding-bottom: 5px;
        }

.box-gray .list-dot li:last-child {
                padding-bottom: 0;
            }

.box-yellow {
    background-color: #FAF8EF;
    padding: 20px 15px;
    margin-top: 30px;
}

@media(min-width:768px) {

.box-yellow {
        padding: 40px;
        margin-top: 60px
}
    }

.box-yellow .inner {
        background-color: #fff;
        padding: 20px 15px;
    }

@media(min-width:768px) {

.box-yellow .inner {
            padding: 40px
    }
        }

.box-yellow .box-beginning {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

@media(max-width:768px) {

.box-yellow .box-beginning {
            flex-direction: column;
            gap: 20px
    }

        }

.box-yellow .box-beginning .left {
            width: 30%;
            margin: 0;
        }

@media(max-width:768px) {

.box-yellow .box-beginning .left {
                width: 100%;
                text-align: center
        }
            }

.box-yellow .box-beginning .right {
            width: 68%;
            margin: 0;
        }

@media(max-width:768px) {

.box-yellow .box-beginning .right {
                width: 100%
        }
            }

.qa-box {
    line-height: 1.5;
    margin-bottom: 30px;

}

@media(min-width:768px) {

.qa-box {
        margin-bottom: 50px

}
    }

.qa-box:last-child {
        /* margin-bottom: 0; */
    }

.qa-box dt {
        font-size: 1.5rem;
        padding: 0;
        background-color: #F7F7F7;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
        padding: 15px 35px 15px 40px;
        font-weight: bold;
    }

@media(min-width:768px) {

.qa-box dt {
            font-size: 2rem;
            padding: 25px 80px 25px 90px
    }
        }

.qa-box dt .question {
            width: 19px;
            height: 19px;
            line-height: 19px;
            text-align: center;
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            color: #fff;
            font-size: 1rem;
            border-radius: 50%;
            background-color: #CA101A;
        }

@media(min-width:768px) {

.qa-box dt .question {
                font-size: 1.8rem;
                width: 40px;
                height: 40px;
                line-height: 40px
        }
            }

.qa-box dd {
        position: relative;
        font-size: 1.5rem;
        display: none;

    }

@media(min-width:768px) {

.qa-box dd {
            font-size: 1.8rem

    }
        }

.qa-box dd .inner {
            padding: 15px 10px 0 40px;
        }

@media(min-width:768px) {

.qa-box dd .inner {
                padding: 25px 10px 0 90px
        }
            }

.qa-box dd p {
            margin-bottom: 10px;
        }

@media(min-width:768px) {

.qa-box dd p {
                margin-bottom: 20px
        }
            }

.qa-box dd p:last-child {
                margin-bottom: 0;
            }

.qa-box dd b,.qa-box dd strong {
            font-weight: bold;
        }

.qa-box dd ul li, .qa-box dd ol li {
                padding-bottom: 5px;
                line-height: 1.5;
            }

@media(min-width:768px) {
                }

.qa-box dd ol {
            list-style-type: decimal
        }

.qa-box dd ul {
            list-style-type: disc;
        }

.qa-box dd a {
            color: #275EAC;
        }

@media(min-width:992px) {
                .qa-box dd a:hover {
                    text-decoration: underline;
                }
            }

.qa-box dd a[target="_blank"] {
            color: #275EAC;
            text-decoration: underline;
            position: relative;
            padding-right: 20px;
            background-image: url(../icon/icon_window-blue.svg);
            background-repeat: no-repeat;
            background-size: 14px;
            background-position: right center;
            margin-right: 5px;
        }

@media(min-width:768px) {

.qa-box dd a[target="_blank"] {
                padding-right: 28px;
                background-size: 18px
        }
            }

@media(min-width:992px) {
                .qa-box dd a[target="_blank"]:hover {
                    text-decoration: none;
                }
            }

.qa-box .question,
    .qa-box .anser {
        position: absolute;
        top: 15px;
        left: 15px;
    }

@media(min-width:768px) {

.qa-box .question,
    .qa-box .anser {
            top: 20px;
            left: 20px
    }
        }

.qa-box.open dt {
            cursor: default;

        }

.qa-box.open dd {
            display: block;
        }

.m-link-pdf {
    padding-right: 22px;
    color: #275EAC;
    text-decoration: underline;
    position: relative;
    background-image: url(../img/common/post-pdf.png);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: right center;
    margin-right: 5px;
}

@media(min-width:768px) {

.m-link-pdf {
        padding-right: 30px;
        background-size: 26px;
        padding-top: 10px
}
    }

@media(min-width:992px) {
        .m-link-pdf:hover {
            text-decoration: none;
        }
    }

.m-link-excel {
    padding-right: 25px;
    color: #275EAC;
    text-decoration: underline;
    position: relative;

}

@media(min-width:768px) {

.m-link-excel {
        padding-right: 40px

}
    }

@media(min-width:992px) {
        .m-link-excel:hover {
            text-decoration: none;
        }
    }

.m-link-excel:after {
        content: '';
        display: block;
        background-image: url(../icon/icon_excel.svg);
        background-repeat: no-repeat;
        position: absolute;
        top: -2px;
        right: 0;
        background-size: 18px;
        width: 20px;
        height: 20px;
    }

@media(min-width:768px) {

.m-link-excel:after {
            top: -6px;
            background-size: 29px;
            width: 34px;
            height: 34px
    }
        }

.m-link-blank {
    color: #275EAC;
    text-decoration: underline;
    position: relative;
    padding-right: 20px;
    background-image: url(../img/common/post-window-blue.png);
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: right center;
    margin-right: 5px;


}

@media(min-width:768px) {

.m-link-blank {
        padding-right: 28px;
        background-size: 18px


}
    }

@media(min-width:992px) {
        .m-link-blank:hover {
            text-decoration: none;
        }
    }


.link-arrow-right {
    color: #CA101A;
    display: inline-block;
    font-size: 1.2rem;
}


@media(min-width:992px) {
        .link-arrow-right:hover {
            text-decoration: underline;
        }
    }


.link-arrow-right .icon {
        width: 13px;
        height: 13px;
        vertical-align: middle;
        margin-left: 1px;
        position: relative;
        top: -2px;
    }


.link-blue {
    color: #275EAC;
    text-decoration: underline;
}


@media(min-width:992px) {
        .link-blue:hover {
            text-decoration: none;
        }
    }

.link-banner:hover {
    opacity: 0.5;
}

/* 背景色 */
body {
    background: #fff;
}
.bg-yellow {
    background-color: #FAF8EF;
}

/* アニメーション */
/*----------------------------------------------------------------------------*/

a,
input,
select,
textarea,
button,
.btn,
.btn * {
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s;
}

.scroll-effect,
.parent-scroll-effect.list > * {
    /* transition: all 0.35s ease-out 0.25s; */
    /* transition: all 0.5s cubic-bezier(.4,0,.4,1) 0.4s; */
    transition: opacity 0.5s cubic-bezier(.4,0,.4,1) 0.4s,
                -webkit-transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s,
                opacity 0.5s cubic-bezier(.4,0,.4,1) 0.4s;
    transition: transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s,
                opacity 0.5s cubic-bezier(.4,0,.4,1) 0.4s,
                -webkit-transform 0.75s cubic-bezier(.4,0,.4,1) 0.2s;


}
.scroll-effect,
.parent-scroll-effect.list > * {
    -webkit-transform: none;
            transform: none;
    opacity: 1.0;
}
.scroll-effect.inactive,
.parent-scroll-effect.list > *.inactive {
    transition: none;
    -webkit-transform: translate(0, 20px);
            transform: translate(0, 20px);
    opacity: 0.0;
}

.scroll-effect.customize-body {
    transition-delay: 0.5s;
}

a .thumbnail,
a.thumbnail,
.thumbnail a,

a .thumbnail img,
a.thumbnail img,
.thumbnail a img,

a .thumbnail .mask,
a.thumbnail .mask,
.thumbnail a .mask {
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                -webkit-transform 0.15s ease-in-out 0s;
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                transform 0.15s ease-in-out 0s;
    transition: opacity 0.15s ease-in-out 0s,
                background 0.15s ease-in-out 0s,
                background-color 0.15s ease-in-out 0s,
                border-radius 0.15s ease-in-out 0s,
                border-color 0.15s ease-in-out 0s,
                color 0.15s ease-in-out 0s,
                box-shadow 0.15s ease-in-out 0s,
                text-shadow 0.15s ease-in-out 0s,
                outline 0.15s ease-in-out 0s,
                transform 0.15s ease-in-out 0s,
                -webkit-transform 0.15s ease-in-out 0s;;
}

a:hover .thumbnail img,
a.thumbnail:hover img,
.thumbnail a:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 0.5;
}

a:hover .thumbnail .mask,
a.thumbnail:hover .mask,
.thumbnail a:hover .mask {

}
/*
.bx-wrapper .bx-viewport li {
    transition: all 0.1s ease 0s;
}
.bx-wrapper .bx-viewport li.active {
    transition: all 0.25s ease 0s;
}
*/
.bx-wrapper .bx-pager.bx-default-pager a {
    /* transition: all 0.15s ease 0.75s; */
}

/* 高さを画面幅に */
.scroll-effect.contain {
    box-sizing: border-box;
    position: relative;
}
body > .scroll-effect.contain:not(.parent-popup-content) {
    transition: all 0.75s ease-in-out;
}

.scroll-effect.contain .scroll-effect {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.parent-scroll-effect.parallax {
    position: relative;
}
.parent-scroll-effect.parallax > *:not(.not-move) {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}



/* カスタマイズ */
/*----------------------------------------------------------------------------*/
.mainvisual .slider li.active img {
    transition: -webkit-transform 12.0s ease-in 0s;
    transition: transform 12.0s ease-in 0s;
    transition: transform 12.0s ease-in 0s, -webkit-transform 12.0s ease-in 0s;
}
.mainvisual .slider li img {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
}
.mainvisual .slider li.active img {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
}

.slider li {
    transition: opacity 0.9s ease-in 0s;
}

.l-section {
    margin-bottom: 40px;
}

@media(min-width:768px) {

.l-section {
        margin-bottom: 80px
}
    }

.l-section:last-child {
        /* margin-bottom: 0; */
    }

.anchor {
    padding-top: 160px;
    margin-top: -160px;
}


.column-pc2-sp1 {
    width: 100%;
    max-width: 1100px;
    margin: auto;
}


@media(min-width:768px) {


.column-pc2-sp1 {
        display: flex;
        justify-content: space-between
}

    }


.column-pc2-sp1 .column {
        padding-bottom: 20px;
    }


@media(min-width:768px) {


.column-pc2-sp1 .column {
            width: 49%;
            padding-bottom: 0
    }
        }


@media(min-width:1200px) {


.column-pc2-sp1 .column {
            width: 530px
    }
        }


.column-pc2-sp1 .column .heading-text-small {
            margin-bottom: 0;
        }


.column-pc2-sp1 .column:last-child {
            padding-bottom: 0;
        }


.column-img-pc2-sp1 .img {
        margin-top: 20px;
        text-align: center;
    }


@media(min-width:992px) {


.column-img-pc2-sp1 {
        display: flex;
        justify-content: space-between
}

        .column-img-pc2-sp1 .img {
            margin-top: 0;
            text-align: left;
        }
    }

.spacer-large {
    padding-bottom: 40px;

}

@media(min-width:768px) {

.spacer-large {
        padding-bottom: 80px

}
    }
.spacer-medium {
    padding-bottom: 30px;
}
@media(min-width:768px) {
.spacer-medium {
        padding-bottom: 50px
}
    }
.spacer-small {
    padding-bottom: 20px;
}
@media(min-width:768px) {
.spacer-small {
        padding-bottom: 30px
}
    }

.spacer-xsmall {
    padding-bottom: 10px;
    margin-bottom: 0 !important;
}

@media(min-width:768px) {

.spacer-xsmall {
        padding-bottom: 20px
}
    }

.l-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    border-top: 4px solid #CA101A;
    border-bottom: 1px solid #D0D0D0;
    background-color: #fff;


}

@media(min-width:992px) {

.l-header {
        border-top: 8px solid #CA101A;
        transition: all .5s


}
    }

@media(min-width:992px) {

.l-header.sticky {
            border-top: 4px solid #CA101A
    }
            .l-header.sticky .header-inner {
                padding: 0 10px 0;
            }
                @media(min-width:1200px) {
            .l-header.sticky .header-inner {
                    padding: 0
            }
                }
            .l-header.sticky .l-logo-nav .logo {
                width: 130px;
                top: 10px;
            }
            .l-header.sticky .l-logo-nav {
                margin-bottom: 10px;
            }
            .l-header.sticky .l-global-nav .l-list .item {
                padding-bottom: 10px;
            }
            .l-header.sticky .l-global-nav .l-list .link {
                font-size: 18px;
            }
            .l-header.sticky .l-global-nav .l-list .link:after {
                bottom: -10px;
            }
            .l-header.sticky .l-global-nav .sub-menu {
                top: 109px;
            }
        }

.l-header .btn-sp-wrap {
        display: flex;
        align-items: center;
    }

.l-header .btn-sp-font-size {
        margin-right: 10px;
    }

@media(min-width:992px) {

.l-header .btn-sp-font-size {
            display: none
    }
        }

.l-header .btn-sp-menu {
        width: 60px;
        height: 56px;
        border-left: 1px solid #F0F0F0;
        position: relative;


    }

@media(min-width:992px) {

.l-header .btn-sp-menu {
            display: none


    }
        }

.l-header .btn-sp-menu.active .item.first {
                    -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                    top: 22px;
                }

.l-header .btn-sp-menu.active .item.second {
                    opacity: 0;
                }

.l-header .btn-sp-menu.active .item.thaad {
                    -webkit-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                    top: 22px;
                }

.l-header .btn-sp-menu.active .text.inactive {
                    display: none;
                }

.l-header .btn-sp-menu.active .text.active {
                    display: block;
                }

.l-header .btn-sp-menu .item {
            width: 22px;
            height: 2px;
            margin: auto;
            background-color: #CA101A;
            border-radius: 2px;
            position: absolute;
            left: 0;
            right: 0;
            transition: all .2s;
        }

.l-header .btn-sp-menu .item.first {
                top: 15px;
            }

.l-header .btn-sp-menu .item.second {
                top: 22px;
            }

.l-header .btn-sp-menu .item.thaad {
                top: 29px;
            }

.l-header .btn-sp-menu .text {
            text-align: center;
            font-size: 8px;
            color: #CA101A;
            position: absolute;
            left: 0;
            right: 0;
            top: 38px;
            margin: auto;
        }

.l-header .btn-sp-menu .text.active {
                display: none;
            }

.l-header .header-inner {
        max-width: 1100px;
        margin-right: auto;
        margin-left: auto;
        padding: 0 0 0 15px;
    }

@media(min-width:992px) {

.l-header .header-inner {
            padding: 33px 10px 0;
            transition: all .5s
    }
        }

@media(min-width:1200px) {

.l-header .header-inner {
            padding: 33px 0 0
    }
        }

.l-header .l-logo-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

@media(min-width:992px) {

.l-header .l-logo-nav {
            align-items: flex-start;
            margin-bottom: 30px;
            transition: all .5s

    }
        }

.l-header .l-logo-nav .logo {
            display: block;
            width: 114px;
            margin-bottom: 0;

        }

@media(min-width:992px) {

.l-header .l-logo-nav .logo {
                width: 190px;
                position: relative;
                top: 0;
                transition: all .2s

        }
            }

@media(min-width:1200px) {

.l-header .l-logo-nav .logo {
                width: 224px

        }
            }

.l-header .l-logo-nav .logo a {
                display: block;
            }

.l-header .l-logo-nav .l-list {
            display: none;


        }

@media(min-width:992px) {

.l-header .l-logo-nav .l-list {
                display: flex;
                align-items: center;
                margin-top: 10px


        }
            }

.l-header .l-logo-nav .l-list li {
                padding-left: 10px;
            }

@media(min-width:1200px) {

.l-header .l-logo-nav .l-list li {
                    padding-left: 10px
            }
                }

.l-header .l-logo-nav .l-list .link-text {
                font-size: 1.4rem;
                color: #454545;
            }

.l-header .l-logo-nav .l-list .link-btn {
                border: 1px solid #CA101A;
                background-color: #CA101A;
                border-radius: 4px;
                display: block;
                /* width: 183px; */
                color: #fff;
                font-size: 1.3rem;
                text-align: center;
                /* height: 36px; */
                /* line-height: 34px; */
                padding: 8px;



            }

@media(min-width:992px) {
                    .l-header .l-logo-nav .l-list .link-btn:hover {
                        background-color: #CA101A;
                    }
                }

.l-header .l-logo-nav .l-list .link-btn.green {
                    border: 1px solid #029162;
                    background-color: #029162;
                }

@media(min-width:992px) {
                        .l-header .l-logo-nav .l-list .link-btn.green:hover {
                            background-color: #017A52;
                        }
                    }

.l-header .l-logo-nav .l-list .link-btn .icon {
                    position: relative;
                    top: 1px;
                }

.l-header .l-font-size {
        margin-right: 5px;
    }

.l-header .l-font-size .font-size-btn {
            background-color: #D9D9D9;
            padding: 5px 8px;
            border-radius: 4px;
            font-size: 13px;
        }

.l-header .l-font-size .is-active {
            background-color: #454545;
            color: #f0f0f0;
        }

/* google カスタム検索 */

.l-header .l-search {
        border-top: 1px solid #DDDDDD;
        margin-top: 0;
        padding: 15px 15px 0;


    }

@media(min-width:992px) {

.l-header .l-search {
            border-top: none;
            padding: 0


    }
        }

.l-header .l-search .inner {
            display: flex;
            position: relative;
        }

.l-header .l-search .wrap {
            position: relative;
            width: 80%;

        }

@media(min-width:992px) {

.l-header .l-search .wrap {
                width: 140px

        }
            }

@media(min-width:1200px) {

.l-header .l-search .wrap {
                width: 200px

        }
            }

.l-header .l-search .wrap .icon {
                position: absolute;
                top: 0;
                left: 10px;
                bottom: 0;
                margin: auto;
                z-index: 1;
            }

.l-header .l-search .search-box{
            border: none;
            font-size: 1.6rem;
            background-color: #F7F7F7;
            width: 100%;
            padding: 8px 10px 8px 30px;
            position: relative;
            color: #222222;
        }

.l-header .l-search .search-box::-webkit-input-placeholder{
                color: #888888;
                font-size: 1.3rem;
            }

.l-header .l-search .search-box:-ms-input-placeholder{
                color: #888888;
                font-size: 1.3rem;
            }

.l-header .l-search .search-box::-ms-input-placeholder{
                color: #888888;
                font-size: 1.3rem;
            }

.l-header .l-search .search-box::placeholder{
                color: #888888;
                font-size: 1.3rem;
            }

.l-header .l-search .search-btn {
             display: block;
             background-color: #454545;
             color: #fff;
             text-align: center;
             font-size: 1.4rem;
             width: 20%;
            /*  height: 40px; */
            /*  line-height: 40px; */
             border-top-right-radius : 4px;
             border-bottom-right-radius : 4px;
             cursor: pointer;
        }

@media(min-width:992px) {

.l-header .l-search .search-btn {
                 width: 63px
        }
                 .l-header .l-search .search-btn:hover {
                     background-color: #2B2B2B;
                 }
             }

.l-global-nav {
    display: none;
    background-color: #F0F0F0;
    position: fixed;
    width: 100%;
    top: 61px;
    left: 0;
    padding: 15px 0 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

}

@media(min-width: 992px) {

.l-global-nav {
        overflow-y: visible;
        padding: 0;
        display: block !important;
        background-color: transparent;
        position: static;
        height: auto !important

}
    }

@media(max-width:992px) {

.l-global-nav.active {
            display: block
    }
        }

.l-global-nav .l-list {
        width: 100%;
        margin-top: -8px;
        border-top: 1px solid #DDDDDD;
        margin-top: 15px;
        padding: 15px 0 0;
    }

@media(min-width: 992px) {

.l-global-nav .l-list {
            border-top: none;
            margin-top: 0;
            padding: 0;
            display: table;
            table-layout: fixed
    }
        }

.l-global-nav .l-list.sp-list {
            margin-top: 0;
            border-top: 1px solid #BBBBBB;
            padding-top: 0;
            padding-bottom: 30%;
        }

.l-global-nav .l-list.sp-list .link {
                font-size: 1.4rem;
            }

.l-global-nav .l-list.sp-list li:last-child .link {
                        border-bottom-width: 1px;
                    }

@media(min-width: 992px) {

.l-global-nav .l-list .item {
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                padding-bottom: 20px;
                transition: all .5s;
                background-color: #fff

        }
            }

.l-global-nav .l-list .item:first-child .link {
                    border-top-width: 0;
                }

@media(min-width: 992px) {

.l-global-nav .l-list .item:first-child .link {
                        border-left-width: 1px
                }
                    }

@media(min-width: 992px) {

.l-global-nav .l-list .item {
        		transition: all .2s

        }
                .l-global-nav .l-list .item:hover {
                    color: #CA101A;
                }
                    .l-global-nav .l-list .item:hover .link {
                        color: #CA101A;
                    }
                        .l-global-nav .l-list .item:hover .link:after {
                            width: 100%;
                        }
                    .l-global-nav .l-list .item:hover .sub-menu {
                        max-height: 600px;
                        /* opacity: 1; */
                        transition: all 1s;
                        overflow: scroll;
                    }
        	}

@media(min-width: 992px) {

.l-global-nav .l-list .item.current {
                    transition: all 0s;
                    color: #CA101A
            }
                    .l-global-nav .l-list .item.current .link {
                        color: #CA101A;
                        transition: all 0s;
                    }
                        .l-global-nav .l-list .item.current .link:after {
                            width: 100%;
                            transition: all 0s;
                        }
                }

.l-global-nav .l-list .item .l-list-child:last-child {
                    padding-bottom: 0;
                }

.l-global-nav .l-list .link {
            font-size: 16px;
            color: #222222;
            display: block;
            position: relative;
            padding: 20px 15px 15px;
            border-style: solid;
            border-top-width: 1px;
            border-color: #DDDDDD;
            font-weight: bold;
        }

@media(min-width: 992px) {

.l-global-nav .l-list .link {
                font-size: 18px;
                padding: 10px;
                border-color: #BBBBBB;
                border-top-width: 0;
                border-right-width: 1px;
                transition: all .2s
        }
            }

@media(min-width:1200px) {

.l-global-nav .l-list .link {
                font-size: 20px
        }
            }

@media(min-width: 992px) {

.l-global-nav .l-list .link:after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 2px;
                    background-color: #CA101A;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    bottom: -21px;
                    transition: all .2s
            }
                }

/* サブメニュー */

.l-global-nav .sub-menu {
        display: none;


    }

@media(min-width: 992px) {

.l-global-nav .sub-menu {
            display: block;
            background-color: #FAF8EF;
            position: absolute;
            z-index: -1;
            top: 175px;
            left: 0;
            width: 100%;
            text-align: left;
            max-height: 0;
            height: auto;
            overflow: hidden;
            transition: all .2s
            /* opacity: 0; */


    }
        }

@media(min-width:1200px) {

.l-global-nav .sub-menu {
            top: 185px


    }
        }

.l-global-nav .sub-menu .inner {
            width: 100%;
            max-width: 1100px;
            margin-right: auto;
            margin-left: auto;
            padding-top: 50px;
            padding-bottom: 68px;
            padding-right: 10px;
            padding-left: 10px;

        }

@media(min-width:1200px) {

.l-global-nav .sub-menu .inner {
                padding-right: 0;
                padding-left: 0

        }
            }

.l-global-nav .sub-menu .heading-large {
            color: #CA101A;
            font-size: 2.4rem;
            font-weight: bold;
        }

.l-global-nav .sub-menu .child-box {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            -webkit-column-gap: 30px;
               -moz-column-gap: 30px;
                    column-gap: 30px;
        }

.l-global-nav .sub-menu .child-box .group {
                border-right: 1px solid #A8A8A8;
                width: 50%;
                padding-right: 50px;
            }

.l-global-nav .sub-menu .child-box .group.single {
                    width: 100%;
                }

.l-global-nav .sub-menu .child-box .group:last-child {
                    border-right: none;
                }

.l-global-nav .sub-menu .heading-medium {
            font-size: 2rem;
            color: #222222;
            padding-bottom: 25px;
            line-height: 1.5;
            font-weight: bold;
        }

.l-global-nav .sub-menu .heading-medium a {
                color: #222222;
            }

.l-global-nav .sub-menu .l-list-child {
            padding-bottom: 30px;


        }

.l-global-nav .sub-menu .l-list-child li {
                margin-bottom: 25px;
                position: relative;
                padding-left: 12px;
            }

.l-global-nav .sub-menu .l-list-child li:last-child {
                    margin-bottom: 0;
                }

.l-global-nav .sub-menu .l-list-child .link-child {
                color:#222222;
                font-size: 1.6rem;
                line-height: 1.25;
            }

.l-global-nav .sub-menu .l-list-child .icon {
                width: 7px;
                height: 7px;
                position: absolute;
                left: 0;
                top: 6px;
            }

.sp-navigation-open-bg {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F0F0F0;
    opacity: 0;
    transition: all .2s;

}

@media(min-width: 992px) {

.sp-navigation-open-bg {
        display: none

}
    }

.sp-navigation-open-bg.active {
        z-index: 99;
        opacity: 1;

    }

.l-footer .l-footer-qa {
        background-color: #F7F7F7;
        padding: 30px 0;
        border-top: 1px solid #D1D1D1;


    }

@media(min-width:768px) {

.l-footer .l-footer-qa {
            padding: 60px 0


    }
        }

.l-footer .l-footer-qa .upside{
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
        }

@media(min-width:768px) {

.l-footer .l-footer-qa .upside{
                margin-bottom: 80px
        }
            }

.l-footer .l-footer-qa .heading {
            font-size: 1.8rem;
            font-weight: bold;
            width: 60%;
        }

@media(min-width:480px) {

.l-footer .l-footer-qa .heading {
                font-size: 2.2rem
        }
            }

@media(min-width:992px) {

.l-footer .l-footer-qa .heading {
                font-size: 3.0rem
        }
            }

.l-footer .l-footer-qa .link-text {
            font-size: 1.2rem;
        }

@media(min-width:992px) {

.l-footer .l-footer-qa .link-text {
                font-size: 1.8rem
        }
            }

.l-footer .l-footer-qa .link-text .arrow-right {
                width: 13px;
                height: 13px;
            }

@media(min-width:992px) {

.l-footer .l-footer-qa .link-text .arrow-right {
                    width: 11px;
                    height: 11px
            }
                }

.l-footer .l-footer-qa .link-text a {
                color: #CA101A;
            }

.l-footer .l-footer-banner {
        padding: 30px 0;
        border-top: 1px solid #D1D1D1;
    }

@media(min-width:768px) {

.l-footer .l-footer-banner {
            padding: 40px 0
    }
        }

.l-footer .l-footer-banner .l-list-banner {
            padding-top: 20px;
            margin-top: 20px;
            border-top: 1px solid #DDDDDD;
            display: flex;
            justify-content:space-between;
            flex-wrap: wrap;
        }

@media(min-width:768px) {

.l-footer .l-footer-banner .l-list-banner {
                padding-top: 40px;
                margin-top: 40px;
                justify-content: flex-start
        }
            }

.l-footer .l-footer-banner .l-list-banner li {
                width: 48%;
                margin-bottom: 4%;
            }

@media(min-width:768px) {

.l-footer .l-footer-banner .l-list-banner li {
                    width: auto;
                    margin-right: 20px;
                    margin-bottom: 20px
            }
                }

@media(min-width:1200px) {

.l-footer .l-footer-banner .l-list-banner li {
                    margin-bottom: 0
            }
                }

.l-footer .l-footer-banner .l-list-banner li:last-child {
                    margin-right: 0;
                }

.l-footer .l-list-text {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;



    }

@media(min-width:768px) {

.l-footer .l-list-text {
            padding-top: 40px;
            justify-content: flex-start



    }
        }

.l-footer .l-list-text li {
            width: 47%;
            margin-bottom: 6%;
        }

@media(min-width:768px) {

.l-footer .l-list-text li {
                padding-left: 0.5em;
                width: auto;
                margin-right: 30px;
                margin-bottom: 3%
        }
            }

@media(min-width:1200px) {

.l-footer .l-list-text li {
                margin-bottom: 0
        }
            }

.l-footer .l-list-text li:last-child {
                margin-right: 0;
            }

.l-footer .l-list-text .link {
            color: #222222;
            display: flex;
            font-size: 1.2rem;
        }

@media(min-width:768px) {

.l-footer .l-list-text .link {
                font-size: 1.4rem
        }
            }

.l-footer .l-list-text .icon {
            margin-left: 5px;
            vertical-align: bottom;
        }

@media(min-width:768px) {

.l-footer .l-list-text .icon {
                margin-left: 10px
        }
            }

.l-footer .l-beneath {
        background-color: #F7F7F7;
        padding: 30px 0 25px;






    }

@media(min-width:768px) {

.l-footer .l-beneath {
            padding: 40px 0 25px






    }
        }

@media(min-width:768px) {
                }

.l-footer .l-beneath .l-left .l-list li {
                    border-right: 1px solid #222222;
                    display: inline-block;
                    vertical-align: middle;
                    padding-right: 10px;
                    margin-right: 10px;
                }

@media(min-width:768px) {

.l-footer .l-beneath .l-left .l-list li {
                        padding-right: 18px;
                        margin-right: 18px
                }
                    }

.l-footer .l-beneath .l-left .l-list li:last-child {
                        border-right: none;
                        padding-right: 0;
                        margin-right: 0;
                    }

@media(min-width:768px) {
                        }

.l-footer .l-beneath .l-left .l-list a {
                    font-size: 1.3rem;
                    color: #222222;

                }

@media(min-width:768px) {

.l-footer .l-beneath .l-left .l-list a {
                        font-size: 1.6rem

                }
                    }

.l-footer .l-beneath .l-right {
            text-align: left;
            padding-top: 25px;



        }

@media(min-width:768px) {

.l-footer .l-beneath .l-right {
                padding-right: 40px



        }
            }

.l-footer .l-beneath .l-right .img {
                width: 148px;
                margin: 0;
            }

@media(min-width:768px) {

.l-footer .l-beneath .l-right .img {
                    width: 205px
            }
                }

.l-footer .l-beneath .l-right .text {
                padding-top: 25px;
                font-size: 1.3rem;
                line-height: 1.5;
            }

@media(min-width:768px) {

.l-footer .l-beneath .l-right .text {
                    padding-top: 20px;
                    font-size: 1.6rem
            }
                }

.l-footer .l-copyright {
        display: block;
        background-color: #CA101A;
        color: #fff;
        font-size: 8px;
        font-family: 'Roboto', sans-serif;
        line-height: 1.5;
        padding: 10px 0;
        font-weight: normal;
    }

@media(min-width:768px) {

.l-footer .l-copyright {
            font-size: 1.4rem
    }
        }

@media(max-width:768px) {

.l-footer .l-copyright {
            font-size: 1.4rem;
            padding-bottom: 100px
    }
        }

.l-footer .back-top {
        display: block;
        opacity: 0;
        z-index: -1;
        width: 58px;
        height: 58px;
        right: 15px;
        background-color: #fff;
        border: 1px solid #D1D1D1;
        position: fixed;
        bottom: -5%;
        border-radius: 50%;
        cursor: pointer;
        transition: all .5s;
    }

@media(min-width:768px) {

.l-footer .back-top {
            right: 12px
    }
        }

@media(min-width:992px) {

.l-footer .back-top {
            transition: all .2s
    }
            .l-footer .back-top:hover {
                background-color: #fff;
                background-color: #CA101A;
                border: 1px solid #CA101A;
            }
                .l-footer .back-top:hover .icon {
                    fill:#fff;
                }
        }

.l-footer .back-top.is-display {
            bottom: 120px;
            opacity: 1;
            z-index: 10;
        }

.l-footer .back-top .icon {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            transition: all .2s;
        }

@media(min-width:768px) {

.l-footer .back-top .icon {
                width: 22px;
                height: 22px
        }
            }

.l-footer .sp-navigation {
        background-color: #F0F0F0;
    }

@media(min-width:992px) {

.l-footer .sp-navigation {
            display: none
    }
        }

.l-footer .sp-navigation .l-list {
            border-top: 1px solid #DDDDDD;
        }

.l-footer .sp-navigation .l-list li {
                border-bottom: 1px solid #DDDDDD;
            }

.l-footer .sp-navigation .l-list li:last-child {
                    border-bottom: none;
                }

.l-footer .sp-navigation .l-list .link {
                display: block;
                font-size: 1.4rem;
                color: #222222;
                padding: 19px 15px;

            }

@media(min-width:768px) {

.l-footer .sp-navigation .l-list .link {
                    font-size: 1.6rem

            }
                }

.l-footer .sp-navigation .l-list-sub {
            border-top: 1px solid #BBBBBB;
        }

.l-footer .sp-navigation .l-list-sub li {
                border-bottom: 1px solid #DDDDDD;
            }

.l-footer .sp-navigation .l-list-sub .link {
                display: block;
                font-size: 1.2rem;
                color: #222222;
                padding: 16px 15px;
            }

.l-footer .fixed-sub-menu {
        margin: 0;
        margin-bottom: 12px;
        display: flex;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 2;
    }

.l-footer .fixed-sub-menu li {
            background: #CA101A;
            text-align: center;
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: -12px;
            border-right: solid 1px #F0F0F0;
        }

.l-footer .fixed-sub-menu li:first-child {
            border-radius: 4px 0 0 0;
        }

.l-footer .fixed-sub-menu li:last-child {
            border-radius: 0 4px 0 0;
            border-right: none;
        }

.l-footer .fixed-sub-menu a {
            color: #F0F0F0;
        }

.l-footer .fixed-sub-menu a .group {
                padding: 12px;
            }

@media (hover: hover) {
            .l-footer .fixed-sub-menu li:hover {
                background: #AC0000;
            }
        }

@media(max-width:768px) {
        .l-footer .fixed-sub-menu {
            width: 100%;
        }
            .l-footer .fixed-sub-menu li {
                width: 100%;
            }
    }

.l-contact-center {
    border-style: solid;
    border-color: #DDDDDD;
    border-top-width: 1px;
    padding: 30px 0 30px;
    background-color: #FAF8EF;






}

@media(min-width:768px) {

.l-contact-center {
        text-align: center;
        padding: 50px 0






}
    }

.mainvisual {
    padding: 20px 0;
    min-height: 120px;

    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: #ffffff;
}

.mainvisual .title {
        margin: 0;
        font-size: 2.4rem;
        display: block;
    }

.mainvisual .subtitle {
        margin: 10px 0 0 0;
        display: block;
    }

@media only screen and (min-width: 480px) {

.mainvisual {
        min-height: 150px
}

        .mainvisual .title {
            font-size: 2.8rem;
        }
    }

@media only screen and (min-width: 660px) {
        .mainvisual .title {
            font-size: 3.2rem;
        }
    }

@media only screen and (min-width: 770px) {

.mainvisual {
        padding: 40px 0;
        min-height: 200px
}

        .mainvisual .title {
            font-size: 3.6rem;
        }
    }

@media only screen and (min-width: 1000px) {

.mainvisual {
        min-height: 250px
}

        .mainvisual .title {
            font-size: 4.0rem;
        }
    }

.mainvisual {
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.2);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow:hidden;
    position: relative;
}

body.module .mainvisual {
        background-image: url(../img/module/mainvisual.jpg);
    }

body.contact .mainvisual {
        background-image: url(../img/contact/mainvisual.jpg);
    }

body.error404 .mainvisual {
        background-image: url(../img/error404/mainvisual.jpg);
    }

body:not(.home) .mainvisual > * {
        position: relative;
        z-index: 1000;
    }

body:not(.home) .mainvisual::before {
        content: "";
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.5);
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
    }



body.service .main-header {

        background: #fff;
    }

body.case .main-header {
        background-image: url(../img/case/case-main-header-bg.jpg);
    }
body.company .main-header {
        background-image: url(../img/company/company-main-header-bg.jpg);
    }
body.contact .main-header, body.confirm .main-header, body.complete .main-header {
        background-image: url(../img/contact/contact-main-header-bg.jpg);
    }

body .main-header-child {
        background: ;
    }

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;

  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;

  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/common/lightbox/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../img/common/lightbox/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../img/common/lightbox/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 1.2rem;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../img/common/lightbox/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

@charset "utf-8";

.slick-dots {
  text-align: center;
}

.slick-dots li {
    display: inline-block;
    padding: 0 5px;
  }

.slick-dots li.slick-active button {
        background-color: #fff;
      }

.slick-dots li button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      -webkit-appearance: none;
              appearance: none;
      background-color: #C0C1C3;
      font-size: 0;
      width: 8px;
      height: 8px;
      @include border-radius(50%);
      @include transition(0.2s);
    }

@media(min-width:768px) {

.slick-dots li button {
        width: 18px;
        height: 18px
    }
      }

@media(min-width:992px) {
        .slick-dots li button:hover {
          background-color: #fff;
        }
    	}

.slick-slider {
  position: relative;

}

.slick-slider .slick-arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    -webkit-appearance: none;
            appearance: none;
    font-size: 0;

    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;


  }

.slick-slider .slick-arrow:before,.slick-slider .slick-arrow:after {
      font-size: 3.0rem;
      color: #fff;
    }

@media(min-width:768px) {

.slick-slider .slick-arrow:before,.slick-slider .slick-arrow:after {
        font-size: 6.0rem
    }
      }

.slick-slider .slick-arrow.slick-prev {
      left: -10%;
    }

@media(min-width:992px) {

.slick-slider .slick-arrow.slick-prev {
        left: 5%;
        /*サブナビゲーションのタブの上に出ないように調整 */
        z-index: 99
    }
      }

.slick-slider .slick-arrow.slick-next {
      right: -10%;
    }

@media(min-width:992px) {

.slick-slider .slick-arrow.slick-next {
        right: 5%;
        /*サブナビゲーションのタブの上に出ないように調整 */
        z-index: 99
    }
      }

@charset "utf-8";

.accident-contact .card-red-heading .heading {
            line-height: 1.2;
            padding: 15px 10px;
        }

.accident-contact .card-red-heading .text-sm {
            font-size: 1.2rem;
        }

@media(min-width:992px) {

.accident-contact .card-red-heading .text-sm {
                font-size: 1.6rem
        }
            }

.accident-contact .card-red-heading .padding-variable {
            padding: 24px 10px;
        }

@media(min-width:992px) {

.accident-contact .card-red-heading .padding-variable {
                padding: 30px 10px
        }
            }

.accident-contact .card-red-contact .text-left {
            text-align: left;
        }

.accident-contact .card-red-contact .list-dot {
            line-height: 1.5;
            text-align: left;
            font-size: 1.4rem;
        }

.accident-contact .card-red-contact .list-dot li+li {
                margin-top: 0px;
            }

.accident-contact .card-red-contact .service-link .text-parts-1 {
                text-align: center;
            }

.accident-contact .card-red-contact .l-caption {
            display: inline-block;
        }

.accident-contact .card-red-contact .caption-text {
            text-align: left;
            line-height: 1.5;
            margin-top: 20px;
            font-size: 1.4rem;
        }


.accident-contact-travel .card-red-heading .heading {
            line-height: 1.2;
            padding: 15px 10px;
        }


.accident-contact-travel .card-red-heading .text-sm {
            font-size: 1.2rem;
        }


@media(min-width:992px) {


.accident-contact-travel .card-red-heading .text-sm {
                font-size: 1.6rem
        }
            }


.accident-contact-travel .card-red-heading .padding-variable {
            padding: 24px 10px;
        }


@media(min-width:992px) {


.accident-contact-travel .card-red-heading .padding-variable {
                padding: 30px 10px
        }
            }


@media(min-width:768px) {


.accident-contact-travel .card-red-heading {
            width: 100%


    }
        }


@media(min-width:1200px) {


.accident-contact-travel .card-red-heading {
            width: 100%


    }
        }


.accident-contact-travel .card-red-contact .l-number .number {
                font-size: 24px;
            }


@media(min-width:768px) {


.accident-contact-travel .card-red-contact .l-number .number {
                    font-size: 28px
            }
                }


@media(min-width:992px) {


.accident-contact-travel .card-red-contact .l-number .number {
                    font-size: 36px
            }
                }


.accident-contact-travel .card-red-contact .list-dot {
            line-height: 1.5;
            text-align: left;
            font-size: 1.4rem;
        }


.accident-contact-travel .card-red-contact .list-dot li+li {
                margin-top: 0px;
            }


.accident-contact-travel .card-red-contact .l-caption {
            display: inline-block;
        }


.accident-contact-travel .card-red-contact .caption-text {
            text-align: left;
            line-height: 1.5;
            margin-top: 20px;
            font-size: 1.4rem;
        }


.accident-contact-travel .card-red-contact .contact-wrap {
            display: flex;
            flex-direction: column;
            gap: 20px;


        }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group {
                border: 1px solid #d1d1d1;
                flex: 1;
            }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .header {
                    background-color: #fdefef;
                    padding: 16px;
                }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .header .text {
                        font-size: 2rem;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .header .text-small {
                        font-size: 1.4rem;
                        font-weight: bold;
                        line-height: 1.4;
                    }


@media(max-width:768px) {

                        .accident-contact-travel .card-red-contact .contact-wrap .contact-group .header .text {
                            font-size: 1.5rem;
                            margin-bottom: 5px;
                        }
                        .accident-contact-travel .card-red-contact .contact-wrap .contact-group .header .text-small {
                            font-size: 1.2rem;
                            line-height: 1.2;
                        }
                    }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .group {
                    border: none;
                    padding-bottom: 20px;
                    padding-right: 20px;
                    padding-left: 20px;
                }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .group .item-wrap {
                        display: flex;
                        gap: 40px;
                        align-items: center;
                        justify-content: center;
                    }


@media(max-width:768px) {


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .group .item-wrap {
                            flex-direction: column
                    }
                        }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .group .l-text {
                        text-align: left;
                    }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .group .l-text .text {
                            font-size: 1.6rem;
                            font-weight: bold;
                            line-height: 1.4;
                        }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .group .l-text .text-small {
                            font-size: 1.4rem;
                            margin-top: 8px;
                            margin-bottom: 16px;
                            line-height: 1.4;
                        }


@media(max-width:768px) {

                            .accident-contact-travel .card-red-contact .contact-wrap .contact-group .group .l-text .text {
                                font-size: 1.4rem;
                            }
                            .accident-contact-travel .card-red-contact .contact-wrap .contact-group .group .l-text .text-small {
                                font-size: 1.2rem;
                            }
                        }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .group .number-wrap {
                        display: flex;
                        flex-direction: column;
                        gap: 12px 0;
                        text-align: left;
                    }


@media(min-width:768px) {


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .group .number-wrap .l-number .number {
                                    font-size: 26px
                            }
                                }


@media(min-width:992px) {


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .group .number-wrap .l-number .number {
                                    font-size: 28px
                            }
                                }


.accident-contact-travel .card-red-contact .contact-wrap .contact-group .group:not(:first-child){
                    border-top: 1px solid #d1d1d1;
                }


.accident-contact-travel .l-caption {
        border-top: 1px solid #d1d1d1;
    }

.l-post-nav {
    background-color: #F7F7F7;
    padding: 15px 20px;
    margin-bottom: 40px;
}

@media(min-width:768px) {

.l-post-nav {
        display: flex;
        align-items: center;
        padding: 20px 15px;
        margin-bottom: 80px
}
    }

@media(min-width:992px) {

.l-post-nav {
        padding: 20px 70px
}
    }

.l-post-nav .post-nav-group {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

@media(min-width:768px) {

.l-post-nav .post-nav-group {
            margin-right: 10px;
            margin-bottom: 0
    }
        }

@media(min-width:992px) {

.l-post-nav .post-nav-group {
            margin-right: 50px
    }
        }

.l-post-nav .post-nav-group:last-child {
            margin-bottom: 0;
        }

.l-post-nav .title {
        font-size: 1.6rem;
        padding-right: 10px;
        display: inline-block;
        width: 50px;
    }

@media(min-width:768px) {

.l-post-nav .title {
            font-size: 1.8rem;
            width: 80px
    }
        }


.post-area {
    padding-bottom: 50px;





}


@media(min-width:768px) {


.post-area {
        padding-bottom: 140px





}
    }


.post-area .post-group {
        border-style: dashed;
        border-color: #D1D1D1;
        border-bottom-width: 1px;
    }


.post-area .post-group li {
            border-style: dashed;
            border-color: #D1D1D1;
            border-top-width: 1px;
            padding: 15px 5px 10px;
        }


@media(min-width:768px) {


.post-area .post-group li {
                padding: 25px 10px
        }
            }


@media(min-width:992px) {}


.post-area .post-group a:hover .title {
                        color: #275EAC;
                    }


@media(min-width:768px) {


.post-area .content {
            display: flex;
            align-items: flex-start

    }
        }


.post-area .date-area {
        display: flex;
        align-items: center;
    }


@media(min-width:768px) {


.post-area .date-area {
            width: 40%
    }
        }


@media(min-width:992px) {


.post-area .date-area {
            width: 30%
    }
        }


@media(min-width:1200px) {


.post-area .date-area {
            width: 26.86%
    }
        }


.post-area .date-area .date {
            color: #454545;
            font-size: 1.6rem;
            font-family: 'Roboto', sans-serif;
        }


@media(min-width:768px) {


.post-area .date-area .date {
                font-size: 1.8rem
        }
            }


.post-area .date-area .badge-group {
            display: block;
            width: 90px;
            margin-left: 10px;
        }


@media(min-width:768px) {


.post-area .date-area .badge-group {
                width: 136px;
                margin-left: 30px
        }
            }


.post-area .title {
        display: inline-block;
        color: #222222;
        line-height:1.5;
        font-size: 1.6rem;
        margin-top: 5px;
    }


@media(min-width:768px) {


.post-area .title {
            margin-top: 0;
            font-size: 1.8rem;
            width: 60%
    }
        }


@media(min-width:992px) {


.post-area .title {
            width: 70%
    }
        }


@media(min-width:1200px) {


.post-area .title {
            width: 73.14%
    }
        }


.article-content {
    margin-bottom: 40px;







}


@media(min-width:768px) {


.article-content {
        margin-bottom: 80px







}
    }


.article-content .article-header {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
    }


@media(min-width:768px) {


.article-content .article-header {
            padding-bottom: 20px
    }
        }


.article-content .date {
        color: #454545;
        font-size: 1.6rem;
        font-family: 'Roboto', sans-serif;
    }


@media(min-width:768px) {


.article-content .date {
            font-size: 2rem
    }
        }


.article-content .badge {
        min-width: 90px;
        margin-left: 10px;
    }


@media(min-width:768px) {


.article-content .badge {
            min-width: 136px;
            margin-left: 20px
    }
        }


.article-content .title {
        font-size: 2.4rem;
        line-height: 1.25;
        border-style: solid;
        border-color: #D1D1D1;
        border-bottom-width: 1px;
        padding-bottom: 15px;
        font-weight: bold;
    }


@media(min-width:768px) {


.article-content .title {
            padding-bottom: 20px;
            font-size: 3.8rem
    }
        }


.article-content .tag-list {
        width: 100%;
        margin-top: 10px;
    }


@media(min-width:768px) {


.article-content .tag-list {
            margin-top: 15px;
            display: table
    }
        }


.article-content .tag-list dt {
            color: #5C5C5C;
            font-size: 1.6rem;
            display: table-cell;
            vertical-align: top;
            padding-bottom: 10px;
        }


@media(min-width:768px) {


.article-content .tag-list dt {
                width: 60px;
                padding-top: 7px;
                padding-bottom: 0
        }
            }


@media(min-width:768px) {


.article-content .tag-list dd {
                display: table-cell;
                vertical-align: top
        }
            }


.article-content .tag-list dd span {
                display: inline-block;
                background-color: #fff;
                border-radius: 2px;
                border: 1px solid #454545;
                text-align: center;
                color: #454545;
                padding: 6px 8px;
                font-size: 14px !important;
                margin-right: 5px;
                margin-bottom: 5px;
            }


@media(min-width:768px) {


.article-content .tag-list dd span {
                    padding: 7px 10px;
                    font-size: 15px !important;
                    margin-right: 10px;
                    margin-bottom: 10px
            }
                }


.article-content .container {

        padding-top: 25px;
        margin-bottom: 40px;





    }


@media(min-width:768px) {


.article-content .container {
            margin-bottom: 50px;
            padding-top: 45px





    }
        }


.article-content .container p {
            font-size: 1.6rem;
            margin-bottom: 30px;
            line-height: 1.75;
        }


@media(min-width:768px) {


.article-content .container p {
                font-size: 1.8rem;
                margin-bottom: 45px
        }
            }


.article-content .container .wp-caption-text {
            font-size: 1.4rem;
            padding-top: 5px;
            color: #5C5C5C;
        }


@media(min-width:768px) {


.article-content .container .wp-caption-text {
                font-size: 1.6rem;
                padding-top: 10px
        }
            }


.article-content .container .wp-caption {
            max-width: 100% !important;
        }


.article-content .container a {
            color: #275EAC;
            text-decoration: underline;
        }


@media(min-width:992px) {
                .article-content .container a:hover {
                    text-decoration: none;
                }
            }


.article-content .container h2 {
            font-size: 2.2rem;
            padding-left: 10px;
            margin-bottom: 25px;
            position: relative;
            font-weight: bold;
            line-height: 1.25;
            padding-top: 5px;
        }


@media(min-width:768px) {


.article-content .container h2 {
                font-size: 3.6rem;
                padding-left: 25px;
                margin-bottom: 45px
        }
            }


.article-content .container h2:before {
                content: '';
                display: block;
                width: 3px;
                height: 80%;
                background-color: #CA101A;
                border-radius: 4px;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;

            }


@media(min-width:768px) {


.article-content .container h2:before {
                    width: 6px
                    /* height: 36px; */

            }

                }


.article-content .container h3 {
            font-size: 2rem;
            margin-bottom: 10px;
            line-height: 1.5;
            font-weight: bold;
        }


@media(min-width:768px) {


.article-content .container h3 {
                font-size: 2.6rem;
                margin-bottom: 20px
        }
            }


.article-content .container img {
            max-width: 100%;
            height: auto;
        }


.article-content .container .iframe {
            width: 100%;
        }


.article-content .container b,.article-content .container strong {
            font-weight: bold;
        }


.article-content .container .mtli_pdf {
            background-size: 16px !important;
            background-image: url(../img/common/post-pdf.png);
            background-size: cover;
            background-position: right center;
            display: inline;
            padding-right: 20px !important;
        }


@media(min-width:768px) {


.article-content .container .mtli_pdf {
                background-size: 20px !important;
                padding-right: 25px !important;
                padding-top: 2px
        }
            }


.article-content .container ul li, .article-content .container ol li {
                padding-bottom: 5px;
                line-height: 1.5;
            }


@media(min-width:768px) {
                }


.article-content .container ol {
            list-style-type: decimal
        }


.article-content .container ul {
            list-style-type: disc;
        }

.article-nav-area {
    padding-top: 30px;
    padding-bottom: 60px;
    border-style: solid;
    border-color: #D1D1D1;
    border-top-width: 1px;
}

@media(min-width:768px) {

.article-nav-area {
        padding-top: 60px;
        padding-bottom: 120px
}
    }

.article-nav-area .btn-round {
        margin-left: auto;
        margin-right: auto;
    }

@media(min-width:768px) {
        }

.article-nav-area .article-nav {
        position: relative;
        padding-top: 50px;



    }

@media(min-width:768px) {

.article-nav-area .article-nav {
            padding-top: 0



    }
        }

.article-nav-area .article-nav .link {
            font-size: 1.3rem;
            color: #CA101A;
            position: absolute;
            top: 0;
        }

@media(min-width:768px) {

.article-nav-area .article-nav .link {
                top: 22px;
                font-size: 1.8rem
        }
            }

.article-nav-area .article-nav .link .icon {
                width: 11px;
                height: 11px;
            }

@media(min-width:768px) {

.article-nav-area .article-nav .link .icon {
                    width: 13px;
                    height: 13px
            }
                }

.article-nav-area .article-nav .link.link-prev {
                left: 0;
            }

.article-nav-area .article-nav .link.link-prev .icon {
                    margin-right: 3px;
                }

@media(min-width:768px) {

.article-nav-area .article-nav .link.link-prev .icon {
                        margin-right: 6px
                }
                    }

.article-nav-area .article-nav .link.link-next {
                right: 0;
            }

.article-nav-area .article-nav .link.link-next .icon {
                    margin-left: 3px;
                }

@media(min-width:768px) {

.article-nav-area .article-nav .link.link-next .icon {
                        margin-left: 6px
                }
                    }

.main {
    display: block;
    padding-top: 61px;
}

@media(min-width:992px) {

.main {
        padding-top: 193px
}
    }

/* --------------------------------------------------*/

/* KV
    /* --------------------------------------------------*/

.is-home .l-kv {
        background-color: #F7F7F7;
        padding: 0px 0 40px;
        position: relative;
        /* margin-bottom: 30px; */

        /* NOTE: 2021/03/19: ホームのスライドショー部分にて、スマホ時に横スクロールが生じるため追加 */
        overflow: hidden;
    }

@media(min-width:768px) {

.is-home .l-kv {
            padding: 0px 0 70px
            /* margin-bottom: 50px; */
    }
        }

.is-home .l-kv .img {
            /* height: 254px; */
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;

            /* padding-top: 67.5%; */
            /* NOTE: 画像の縦横比から高さを割り出す */
            padding-top: calc(100vw * 768 / 1080);

            position: relative;
        }

@media(min-width:768px) {

.is-home .l-kv .img {
                padding-top: 27.5%
                /* background-size: contain;
                padding-top: 0;
                height: 460px; */
        }
            }

.is-home .l-kv .img.sp {
                display: block;
            }

@media(min-width:768px) {

.is-home .l-kv .img.sp {
                    display: none
            }
                }

.is-home .l-kv .img.pc {
                display: none;
            }

@media(min-width:768px) {

.is-home .l-kv .img.pc {
                    display: block
            }
                }

/* --------------------------------------------------*/

/* 70周年記念CSS
                /* --------------------------------------------------*/

.is-home .l-kv .img.pc.aniv70-key-visual {
                    padding-top: 460px;
                }

@media(min-width: 1700px) {

.is-home .l-kv .img.pc.aniv70-key-visual {
                        padding-top: 30%
                }
                    }

.is-home .l-kv .img.pc.smartphonepay-key-visual {
                    padding-top: 460px;
                }

.is-home .l-kv .img.pc.smartphonepay-key-visual:hover {
                        opacity: 0.8;
                    }

@media(min-width: 1700px) {

.is-home .l-kv .img.pc.smartphonepay-key-visual {
                        padding-top: 30%
                }
                    }

.is-home .l-kv .img:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                z-index: 1;
                opacity: 0;
                transition: all .2s;
            }

.is-home .l-kv .variable-box {
            background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;

            /* padding-top: 67.5%; */
            /* NOTE: 画像の縦横比から高さを割り出す */
            padding-top: calc(100vw * 768 / 1080);

            position: relative;
            background-image: url(../img/top/kv-01.jpg);

        }

@media(min-width:768px) {

.is-home .l-kv .variable-box {
                padding-top: 0;
                height: 460px

        }
            }

@media(min-width: 1700px) {

.is-home .l-kv .variable-box {
                padding-top: 30%

        }
            }

.is-home .l-kv .variable-box .btn-link {
                display: block;
                position: absolute;
                top: 17%;
                left: 0;
                right: 0;
                margin-right: auto;
                margin-left: auto;
                width: 55%;
            }

@media(min-width:768px) {

.is-home .l-kv .variable-box .btn-link {
                    top: 20%;
                    max-width: 300px;
                    width: 100%
            }
                }

@media(min-width:992px) {

.is-home .l-kv .variable-box .btn-link {
                    transition: all .2s
            }
                    .is-home .l-kv .variable-box .btn-link:hover {
                        opacity: 0.8;
                    }
                }

.is-home .l-kv:not(.slick-initialized) > div:not(:first-child){
                display: none;
            }

.is-home .l-kv .slick-dots {
            position: absolute;
            bottom: 0;
            right: 0;
            bottom: 15px;
            left: 0;
            margin: auto;
        }

@media(min-width:768px) {

.is-home .l-kv .slick-dots {
                bottom: 30px
        }
            }

.is-home .l-kv .slick-dots li {
                padding: 0 4px;
            }

@media(min-width:768px) {

.is-home .l-kv .slick-dots li {
                    padding: 0 8px
            }
                }

.is-home .l-kv .slick-dots .slick-active button {
                    background-color: #CA101A;
                }

.is-home .l-kv .slick-dots button {
                display: block;
                border-radius: 50%;
                width: 7px;
                height: 7px;
                background-color: #BBBBBB;
            }

@media(min-width:768px) {

.is-home .l-kv .slick-dots button {
                    width: 13px;
                    height: 13px
            }
                }

@media(min-width:992px) {

.is-home .l-kv .slick-dots button {
                    transition: all .2s
            }
                    .is-home .l-kv .slick-dots button:hover {
                        background-color: #CA101A;
                    }
                }

@media(min-width:992px) {
                        .is-home .l-kv .link:hover .img:before {
                            opacity: 0.2;
                        }
            }

/* --------------------------------------------------*/

/* 重要なお知らせ
    /* --------------------------------------------------*/

.is-home .l-important-notices {
        border-style: solid;
        border-color: #ECADAF;
        border-width: 1px;
        border-radius: 4px;
        width: 100%;
        margin-bottom: 30px;
        margin-top: 30px;

    }

@media(min-width:768px) {

.is-home .l-important-notices {
            display: table;
            border-width: 3px;
            margin-top: 50px;
            margin-bottom: 50px

    }
        }

.is-home .l-important-notices .heading {
            background-color: #FDEFEF;
            text-align: center;
            color: #CA101A;
            font-size: 1.5rem;
            padding: 11px 0;
            font-weight: bold;
        }

@media(min-width:768px) {

.is-home .l-important-notices .heading {
                padding: 0;
                width: 240px;
                display: table-cell;
                vertical-align: middle;
                font-size: 1.8rem
        }
            }

.is-home .l-important-notices .l-list {
            padding: 15px 15px;
        }

@media(min-width:768px) {

.is-home .l-important-notices .l-list {
                padding: 28px 40px;
                display: table-cell;
                vertical-align: middle
        }
            }

.is-home .l-important-notices .l-list li {
                padding-bottom: 10px;

            }

@media(min-width:768px) {

.is-home .l-important-notices .l-list li {
                    padding-bottom: 26px

            }
                }

.is-home .l-important-notices .l-list li:last-child {
                    padding-bottom: 0;
                }

.is-home .l-important-notices .l-list li a {
                    line-height: 1.5;
                    color: #222222;
                    font-size: 1.4rem;
                }

@media(min-width:768px) {

.is-home .l-important-notices .l-list li a {
                        font-size: 1.8rem
                }
                    }

/* --------------------------------------------------*/

/* 交通事故にあわれたら
    /* --------------------------------------------------*/

.is-home .l-accident {
        padding-top: 40px;
        padding-bottom: 40px;
        text-align: center;




    }

@media(min-width:768px) {

.is-home .l-accident {
            padding-top: 80px;
            padding-bottom: 80px




    }
        }

.is-home .l-accident .text-parts-1 {
            text-align: left;
        }

@media(min-width:768px) {

.is-home .l-accident .text-parts-1 {
                text-align: center
        }

            }

.is-home .l-accident .box-announce {
            background-color: #fff;
            border: 1px solid #D1D1D1;
            margin-top: 20px;
            padding: 20px 15px;
            border-radius: 4px;


        }

@media(min-width:768px) {

.is-home .l-accident .box-announce {
                margin-top: 40px;
                padding: 30px 30px;
                align-items: center;
                justify-content: space-between


        }
            }

@media(min-width:992px) {

.is-home .l-accident .box-announce {
                display: flex


        }
            }

@media(min-width:1200px) {

.is-home .l-accident .box-announce {
                padding: 30px 50px


        }
            }

.is-home .l-accident .box-announce h4 {
                font-size: 1.6rem;
                font-weight: bold;
                line-height: 1.5;
            }

@media(min-width:768px) {

.is-home .l-accident .box-announce h4 {
                    font-size: 1.8rem
            }
                }

@media(min-width:1200px) {

.is-home .l-accident .box-announce h4 {
                    font-size: 2rem
            }
                }

.is-home .l-accident .box-announce .l-list {
                display: flex;
                justify-content: space-between;
                padding-top: 15px;
            }

@media(min-width:768px) {

.is-home .l-accident .box-announce .l-list {
                    justify-content: flex-start
            }
                }

@media(min-width:992px) {

.is-home .l-accident .box-announce .l-list {
                    padding-top: 0
            }
                }

.is-home .l-accident .box-announce .l-list li {
                    width: 48%;
                }

@media(min-width:768px) {

.is-home .l-accident .box-announce .l-list li {
                        width: 300px;
                        margin-left: 30px
                }
                    }

@media(min-width:1200px) {

.is-home .l-accident .box-announce .l-list li {
                        margin-left: 40px
                }
                    }

.is-home .l-accident .box-announce .l-list li:first-child {
                        margin-left: 0;
                    }

/* --------------------------------------------------*/

/* ご検討中のお客さま
    /* --------------------------------------------------*/

.is-home .l-consideration {
        text-align: center;
        padding-top: 40px;



    }

@media(min-width:768px) {

.is-home .l-consideration {
            padding-top: 80px



    }
        }

@media(min-width:768px) {

.is-home .l-consideration .heading-top {
                margin-bottom: 45px
        }
            }

.is-home .l-consideration .btn-square {
            margin-top: 10px;
        }

@media(min-width:768px) {

.is-home .l-consideration .btn-square {
                margin-top: 20px
        }
            }

/* --------------------------------------------------*/

/* ご契約者さま
    /* --------------------------------------------------*/

.is-home .l-contractor .text-parts-1 {
            text-align: center;
        }

.is-home .l-contractor .l-information-link {
            margin-top: 30px;


        }

@media(min-width:768px) {

.is-home .l-contractor .l-information-link {
                margin-top: 40px;
                display: flex;
                justify-content: space-between


        }

            }

@media(min-width:768px) {

.is-home .l-contractor .l-information-link .card-red-heading {
                    width: 55%
            }
                }

@media(min-width:1200px) {

.is-home .l-contractor .l-information-link .card-red-heading {
                    width: 625px
            }
                }

.is-home .l-contractor .l-information-link .card-red-heading .text-parts-1 {
                    text-align: left;
                }

.is-home .l-contractor .l-information-link .column {
                background-color: #F7F7F7;
                padding: 30px 15px;
                margin-top: 30px;



            }

@media(min-width:768px) {

.is-home .l-contractor .l-information-link .column {
                    margin-top: 0;
                    padding: 30px 25px;
                    width: 42%



            }
                }

@media(min-width:1200px) {

.is-home .l-contractor .l-information-link .column {
                    width: 435px



            }
                }

.is-home .l-contractor .l-information-link .column .inner {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                }

@media(min-width:768px) {

.is-home .l-contractor .l-information-link .column .inner {
                        margin-bottom: 33px
                }
                    }

@media(min-width:992px) {

.is-home .l-contractor .l-information-link .column .inner {
                        margin-bottom: 3px
                }
                    }

.is-home .l-contractor .l-information-link .column .inner .left {
                        width: 64%;
                    }

.is-home .l-contractor .l-information-link .column .inner .right {
                        width: 25.978%;
                    }

@media(min-width:768px) {

.is-home .l-contractor .l-information-link .column .inner .right {
                            position: relative;
                            top: -10px;
                            width: 30%
                    }
                        }

@media(min-width:992px) {

.is-home .l-contractor .l-information-link .column .inner .right {
                            width: 25.978%
                    }
                        }

.is-home .l-contractor .l-information-link .column h3 {
                    font-size: 2rem;
                    font-weight: bold;
                    padding-bottom: 25px;
                }

@media(min-width:768px) {

.is-home .l-contractor .l-information-link .column h3 {
                        padding-bottom: 35px;
                        font-size: 2.6rem
                }
                    }

.is-home .l-contractor .l-information-link .column .text {
                    line-height: 1.5;
                    font-size: 1.4rem;
                }

@media(min-width:768px) {

.is-home .l-contractor .l-information-link .column .text {
                        font-size: 1.8rem
                }
                    }

.is-home .l-contractor .l-information-link .column .btn-round {
            		margin: auto;
            		width: 90%;
            	}

@media(min-width:768px) {

.is-home .l-contractor .l-information-link .column .btn-round {
            			width: 100%
            	}
            		}

/* --------------------------------------------------*/

/* ご検討中のお客さま
    /* --------------------------------------------------*/

.is-home .l-effort {
        background-color: #F7F7F7;
        text-align: center;
        padding-top: 40px;
        padding-bottom: 30px;
    }

@media(min-width:768px) {

.is-home .l-effort {
            padding-top: 80px;
            padding-bottom: 60px
    }
        }

.is-home .l-effort .l-list {
            margin-top: 20px;
        }

/* 768px */

@media(min-width:768px) {

.is-home .l-effort .l-list {
                display: flex;
                justify-content: space-between;
                margin-top: 45px
        }
            }

.is-home .l-effort .l-list li {
                margin-bottom: 15px;
            }

.is-home .l-effort .l-list li:last-child {
                    margin-bottom: 0;
                }

/* 768px */

@media(min-width:768px) {

.is-home .l-effort .l-list li {
                    flex: 1 1 0%;
                    /* width: 48%; */
                    margin-bottom: 0;
                    margin-right: 40px
            }

                    .is-home .l-effort .l-list li:last-child {
                        margin-right: 0;
                    }
                }

/* 1200px */

@media(min-width:1200px) {

.is-home .l-effort .l-list li {
                    /* width: 530px; */
            }
                }

.is-home .l-effort .l-list .group {
                text-align: left;
                display: block;
                color: #222222;
                text-decoration: none;

            }

@media(min-width:992px) {
                            .is-home .l-effort .l-list .group:hover .img:before {
                                opacity:0.2;
                            }
                }

.is-home .l-effort .l-list .group .img {
                    position: relative;
                }

.is-home .l-effort .l-list .group .img:before {
                		content: '';
                		display: block;
                		width: 100%;
                		height: 100%;
                		background-color: #fff;
                		position: absolute;
                		top: 0;
                		left: 0;
                		z-index: 1;
                		opacity: 0;
                		transition: all .2s;
                	}

.is-home .l-effort .l-list .group .title {
                    font-size: 1.6rem;
                    padding-top: 15px;
                    font-weight: bold;
                }

@media(min-width:768px) {

.is-home .l-effort .l-list .group .title {
                        font-size: 2rem;
                        padding-top: 20px
                }
                    }

@media(min-width:992px) {

.is-home .l-effort .l-list .group .title {
                        font-size: 2.4rem;
                        padding-top: 35px
                }
                    }

.is-home .l-effort .l-list .group .text {
                    line-height: 1.5;
                    font-size: 1.4rem;
                    padding-top: 5px;
                }

@media(min-width:768px) {

.is-home .l-effort .l-list .group .text {
                        font-size: 1.6rem;
                        padding-top: 10px
                }
                    }

@media(min-width:992px) {

.is-home .l-effort .l-list .group .text {
                        font-size: 1.8rem
                }
                    }

/* --------------------------------------------------*/

/* ニュース
    /* --------------------------------------------------*/

.is-home .l-news {
        padding-top: 40px;

    }

@media(min-width:768px) {

.is-home .l-news {
            padding-top: 80px

    }

        }

.is-home .l-news .post-area {
            background-color: #fff;
            border: 1px solid #D1D1D1;
            border-radius: 4px;
            padding: 20px 15px;
        }

@media(min-width:992px) {

.is-home .l-news .post-area {
                padding: 40px
        }
            }

/* --------------------------------------------------*/

/* コラム
    /* --------------------------------------------------*/

.is-home .l-column {
        padding-bottom: 0;
    }

@media(min-width:768px) {
        }

.is-home .l-column .post-list {
            padding-bottom: 20px;
        }

@media(min-width:768px) {

.is-home .l-column .post-list {
                padding-bottom: 40px
        }
            }



/* 横並び3つ、4つ、5つとHTML側の修正だけで対応できないため書き直す (2020/01/15) */



/* .l-policy-nav { */



/*     display: flex; */



/*     justify-content: space-between; */



/*     flex-wrap: wrap; */



/*     li { */



/*         width: 48%; */



/*         margin-bottom: 4%; */



/*         @media(min-width:$screen-sm-min) { */



/*             width: 23%; */



/*             margin-bottom: 0; */



/*         } */



/*         @media(min-width:$screen-lg-min) { */



/*             width: 260px; */



/*         } */



/*     } */



/* } */



.is-contractor-policy .l-policy-nav {
        margin: -10px;
        display: flex;
        flex-wrap: wrap;
    }



.is-contractor-policy .l-policy-nav li {
            margin: 10px;
            flex-grow: 0;
            flex-shrink: 0;
            /* 20pxは余白 */
            flex-basis: calc(50% - 20px * 1);
        }



@media(min-width:992px) {



.is-contractor-policy .l-policy-nav li {
                flex: 1 0 0%
        }
            }



@media(min-width:1200px) {



.is-contractor-policy .l-policy-nav li {
                /* width: 260px; */
        }
            }



.is-contractor-policy .l-policy-pdf-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }



@media(min-width:768px) {



.is-contractor-policy .l-policy-pdf-title {
            margin-bottom: 50px
    }
        }



.is-contractor-policy .l-policy-pdf-title .thumbnail {
            width: 20.29%;
        }



@media(min-width:768px) {



.is-contractor-policy .l-policy-pdf-title .thumbnail {
                width: 120px
        }
            }



.is-contractor-policy .l-policy-pdf-title .thumbnail img {
                width: 100% !important;
                height: auto !important;
            }



.is-contractor-policy .l-policy-pdf-title .text {
            width: 74.71%;
            font-size: 1.6rem;
            line-height: 1.5;
            margin-left: 5%;
            font-weight: bold;
        }



@media(min-width:768px) {



.is-contractor-policy .l-policy-pdf-title .text {
                margin-left: 50px;
                font-size: 3.0rem
        }
            }



.is-contractor-policy .l-policy-pdf-list {
        padding-top: 5px;
    }



@media(min-width:768px) {



.is-contractor-policy .l-policy-pdf-list {
            padding-top: 15px
    }
        }



.is-contractor-policy .l-policy-pdf-list li {
            margin-bottom: 10px;
        }



.is-contractor-policy .l-policy-pdf-list li:last-child {
                margin-bottom: 0;
            }



.is-contractor-policy .l-policy-category-description {
        font-size: 1.2rem;
        line-height: 1.5;
        color: #5C5C5C;
    }



@media(min-width:768px) {



.is-contractor-policy .l-policy-category-description {
            font-size: 1.6rem
    }
        }



.is-contractor-policy .l-policy-category-description p {
            padding-top: 5px;
        }



@media(min-width:768px) {



.is-contractor-policy .l-policy-category-description p {
                padding-top: 15px
        }
            }



.is-contractor-policy .l-group .spacer-large:last-child {
                padding-bottom: 0;
            }



.is-contractor-policy #injury-1 h2, .is-contractor-policy #injury-2 h2, .is-contractor-policy #injury-3 h2, .is-contractor-policy #outdoor-1 h2, .is-contractor-policy #outdoor-2 h2, .is-contractor-policy #property-1 h2, .is-contractor-policy #liability-1 h2, .is-contractor-policy #work-2 h2 {
                display: none;
            }

.is-contractor .inner-column {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-top: 80px;
    }

.is-contractor .inner-column .img {
            text-align: center;
        }

.is-contractor .btn-wrap {
        text-align: center;
    }

.is-contractor .btn-wrap .btn-round {
            display: inline-block;
        }

.is-contractor .contact-document {
        border: 1px solid #D1D1D1;
        border-radius: 4px;
        padding: 20px 15px;
        margin-top: 20px;
    }

@media(min-width:768px) {

.is-contractor .contact-document {
            margin-top: 40px;
            padding: 30px
    }
        }

.is-contractor .contact-document h3 {
            font-size: 1.8rem;
            font-weight: bold;
            padding-bottom: 10px;
        }

@media(min-width:768px) {

.is-contractor .contact-document h3 {
                font-size: 2.6rem;
                padding-bottom: 15px
        }
            }

@media(min-width:992px) {

.is-contractor .contact-document .inner {
                display: flex;
                justify-content: space-between

        }
            }

@media(min-width:992px) {

.is-contractor .contact-document .inner .l-text {
                    width: 65.438%
            }
                }

@media(min-width:1200px) {

.is-contractor .contact-document .inner .l-text {
                    width: 67.438%
            }
                }

.is-contractor .contact-document .inner .text {
                font-size: 1.4rem;
                line-height: 1.5;
            }

@media(min-width:768px) {

.is-contractor .contact-document .inner .text {
                    font-size: 1.8rem
            }
                }

.is-contractor .contact-document .inner .l-list {
                margin-top: 10px;
            }

@media(min-width:768px) {

.is-contractor .contact-document .inner .l-list {
                    display: flex;
                    margin-top: 15px
            }
                }

.is-contractor .contact-document .inner .l-list li {
                    padding-bottom: 10px;
                }

@media(min-width:768px) {

.is-contractor .contact-document .inner .l-list li {
                        padding-bottom: 0;
                        margin-right: 15px
                }
                    }

@media(min-width:1200px) {

.is-contractor .contact-document .inner .l-list li {
                        margin-right: 40px
                }

                    }

.is-contractor .contact-document .inner .l-list li:last-child {
                        margin-right: 0;
                        padding-bottom: 0;
                    }

.is-contractor .contact-document .inner .l-list .link {
                    font-size: 14px;
                    text-decoration: underline;
                    color: #275EAC;
                }

@media(min-width:768px) {

.is-contractor .contact-document .inner .l-list .link {
                        font-size: 1.6rem
                }
                    }

@media(min-width:1200px) {

.is-contractor .contact-document .inner .l-list .link {
                        font-size: 1.8rem
                }
                    }

@media(min-width:992px) {
                        .is-contractor .contact-document .inner .l-list .link:hover {
                            text-decoration: none;
                        }
                    }

.is-contractor .contact-document .inner .l-list .icon {
                    width: 20px;
                    height: 20px;
                    vertical-align: bottom;
                    margin-left: 5px;
                }

@media(min-width:768px) {

.is-contractor .contact-document .inner .l-list .icon {
                        width: 22px;
                        height: 22px
                }
                    }

@media(min-width:1200px) {

.is-contractor .contact-document .inner .l-list .icon {
                        width: 29px;
                        height: 29px
                }
                    }

.is-contractor .contact-document .inner .l-btn {
                padding-top: 15px;
            }

@media(min-width:768px) {

                }

@media(min-width:992px) {

.is-contractor .contact-document .inner .l-btn {
                    padding-top: 0;
                    width: 30.903%
            }
                }

@media(min-width:1200px) {

.is-contractor .contact-document .inner .l-btn {
                    width: 28.903%
            }
                }

.is-contractor .l-coral {
        padding-top: 30px;


    }

@media(min-width:768px) {

.is-contractor .l-coral {
            padding-top: 50px


    }
        }

@media(min-width:992px) {

.is-contractor .l-coral {
            display: flex;
            justify-content: space-between


    }
        }

.is-contractor .l-coral .left {
            width: 80%;
            margin: auto;
        }

@media(min-width:768px) {

.is-contractor .l-coral .left {
                width: 380px
        }
            }

@media(min-width:992px) {

.is-contractor .l-coral .left {
                margin: 0;
                width: 34.546%
        }
            }

.is-contractor .l-coral .right {
            padding-top: 20px;

        }

@media(min-width:992px) {

.is-contractor .l-coral .right {
                padding-top: 0;
                width: 62.6%

        }
            }

.is-contractor .l-coral .text-link {
            padding-top: 15px;
        }

@media(min-width:768px) {
            }

.is-contractor .l-coral .text-link a {
                color: #222222;
                font-size: 1.6rem;
            }

@media(min-width:768px) {

.is-contractor .l-coral .text-link a {
                    font-size: 1.8rem
            }
                }

.is-contractor .l-coral .text-link a .icon {
                    width: 9px;
                    height: 9px;
                }

@media(min-width:768px) {

.is-contractor .l-coral .text-link a .icon {
                        width: 13px;
                        height: 13px
                }
                    }

.is-contractor .l-coral .text {
            font-size: 1.6rem;
            line-height: 1.75;
        }

@media(min-width:768px) {

.is-contractor .l-coral .text {
                font-size: 1.8rem
        }
            }

.is-contractor .l-coral .l-list {
            padding-top: 20px;
        }

@media(min-width:768px) {

.is-contractor .l-coral .l-list {
                padding-top: 55px;
                display: flex;
                justify-content: space-between;
                width: 90%;
                margin: auto
        }
            }

@media(min-width:992px) {

.is-contractor .l-coral .l-list {
                width: 100%
        }
            }

.is-contractor .l-coral .l-list li {
                position: relative;
                margin-bottom: 15px;
            }

@media(min-width:768px) {

.is-contractor .l-coral .l-list li {
                    width: 20%;
                    margin-bottom: 0
            }
                }

.is-contractor .l-coral .l-list li:after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 15px 11px 0 11px;
                    border-color: #e79499 transparent transparent transparent;
                    margin: 5px auto;
                }

@media(min-width:768px) {

.is-contractor .l-coral .l-list li:after {
                        margin: 0;
                        position: absolute;
                        top: 20%;
                        right: -10%;
                        border-width: 16px 0 16px 22px;
                        border-color: transparent transparent transparent #e79499
                }
                    }

.is-contractor .l-coral .l-list li:last-child {
                    margin-bottom: 0;
                }

.is-contractor .l-coral .l-list li:last-child:after {
                        display: none;
                    }

.is-contractor .l-coral .l-list .group {
                display: flex;
                align-items: center;
            }

@media(min-width:768px) {

.is-contractor .l-coral .l-list .group {
                    display: block
            }
                }

.is-contractor .l-coral .l-list .group .img {
                    display: block;
                    width: 60px;
                    margin-right: 15px;
                }

@media(min-width:768px) {

.is-contractor .l-coral .l-list .group .img {
                        width: auto;
                        margin-right: 0
                }
                    }

@media(min-width:768px) {

.is-contractor .l-coral .l-list .group .img img {
                            display: block;
                            margin: auto;
                            width: 91px
                    }
                        }

.is-contractor .l-coral .l-list .group .caption {
                    display: block;
                    font-size: 1.4rem;
                    line-height: 1.5;
                }

@media(min-width:768px) {

.is-contractor .l-coral .l-list .group .caption {
                        text-align: center;
                        font-size: 1.1rem;
                        padding-top: 10px
                }
                    }

.is-contractor .l-coral .l-list .group .caption br {
                        display: none;
                    }

@media(min-width:768px) {

.is-contractor .l-coral .l-list .group .caption br {
                            display: block
                    }
                        }

.is-contractor .heading-emergency {
        background-color: #FAF8EF;
        text-align: center;
        font-weight: bold;
        font-size: 2.2rem;
        height: 70px;
        line-height: 70px;
        margin-bottom: 30px;
        position: relative;
    }

@media(min-width:768px) {

.is-contractor .heading-emergency {
            font-size: 4.0rem;
            height: 130px;
            line-height: 130px;
            margin-bottom: 55px
    }
        }

.is-contractor .heading-emergency:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 14px 13px 0 13px;
            bottom: -12px;
            border-color: #FAF8EF transparent transparent transparent;
            position: absolute;
            right: 0;
            left: 0;
            margin: auto;
        }

@media(min-width:768px) {

.is-contractor .heading-emergency:after {
                border-width: 24px 23px 0 23px;
                bottom: -22px
        }
            }

.is-contractor .content-nav {
        display: flex;
        justify-content: space-between;

    }

.is-contractor .content-nav li {
            width: 48%;
        }

@media(min-width:768px) {
            }

@media(min-width:1200px) {

.is-contractor .content-nav li {
                width: 540px
        }
            }

.is-contractor .content-nav .link {
            display: block;
            background-color: #fff;
            color: #222222;
            text-align: center;
            border: 1px solid #D1D1D1;
            border-radius: 4px;
            font-size: 1.6rem;
            padding: 14px 5px;
            font-weight: bold;
            height: 100%;
        }

@media(min-width:768px) {

.is-contractor .content-nav .link {
                font-size: 1.8rem;
                padding: 40px 10px 0;
                height: 110px
        }
            }

@media(min-width:992px) {

.is-contractor .content-nav .link {
                transition: all .2s
        }
                .is-contractor .content-nav .link:hover {
                    color: #CA101A;
                }
            }

.is-contractor .content-nav .link .icon {
                display: block;
                width: 14px;
                height: 14px;
                margin-top: 10px;
                margin-left: auto;
                margin-right: auto;
                -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
            }

@media(min-width:768px) {

.is-contractor .content-nav .link .icon {
                    width: 18px;
                    height: 18px;
                    margin-top: 20px
            }
                }

.is-contractor .resolution-box01 {
        background-color: #FAF8EF;
        border-radius: 4px;
        padding: 30px 15px;
        margin-top: 30px;
    }

@media(min-width:768px) {

.is-contractor .resolution-box01 {
            text-align: center;
            padding: 50px 40px;
            margin-top: 50px
    }
        }

.is-contractor .resolution-box01 .img {
            text-align: center;
        }

.is-contractor .resolution-box01 .inner-white {
            background-color: #fff;
            margin-top: 20px;
            padding: 20px 15px;
        }

@media(min-width:768px) {

.is-contractor .resolution-box01 .inner-white {
                margin-top: 40px;
                padding: 40px 60px
        }
            }

.is-contractor .automobile_liability_insurance-box {
        background-color: #F7F7F7;
        padding: 20px 15px;
        margin-top: 20px;

    }

@media(min-width:768px) {

.is-contractor .automobile_liability_insurance-box {
            padding: 40px;
            margin-top: 40px

    }
        }

.is-contractor .automobile_liability_insurance-box .inner {
            padding: 20px 15px;
            background-color: #fff;
        }

@media(min-width:768px) {

.is-contractor .automobile_liability_insurance-box .inner {
                padding: 40px
        }
            }

.is-contractor .automobile_liability_insurance-box .text-parts-2 {
            margin-bottom: 10px;
            font-weight: bold;
        }

.is-contractor .resolution-box02 {
        margin-top:20px;
        padding: 30px 20px;
        text-align: center;
        background-color: #fff;
    }

@media(min-width:768px) {

.is-contractor .resolution-box02 {
            margin-top: 60px;
            padding: 80px 30px
    }
        }

@media(min-width:1200px) {

.is-contractor .resolution-box02 {
            padding: 80px 175px
    }
        }

.is-contractor .resolution-box02 .wrap-btn {
            padding-bottom: 0;
        }

.is-contractor .resolution-box02 .list {
            margin-top: 20px;
        }

@media(min-width:768px) {

.is-contractor .resolution-box02 .list {
                margin-top: 35px;
                display: flex;
                justify-content: space-between
        }

            }

.is-contractor .resolution-box02 .list li {
                margin-bottom: 20px;
            }

@media(min-width:768px) {

.is-contractor .resolution-box02 .list li {
                    margin-bottom: 0;
                    width: 48%
            }
                }

@media(min-width:1200px) {

.is-contractor .resolution-box02 .list li {
                    width: 360px
            }
                }

.is-contractor .resolution-box02 .list li:last-child {
                    margin-bottom: 0;
                }

.is-contractor .resolution-box02 .list .link {
                color: #222222;
                border: 1px solid #D1D1D1;
                display: block;
                padding: 20px 15px;
                background-color: #fff;
            }

@media(min-width:768px) {

.is-contractor .resolution-box02 .list .link {
                    padding: 35px
            }
                }

@media(min-width:992px) {

.is-contractor .resolution-box02 .list .link {
                    transition: all .2s
            }
                        .is-contractor .resolution-box02 .list .link:hover .img {
                            opacity:0.8;
                        }
                        .is-contractor .resolution-box02 .list .link:hover .text-parts-1 {
                            color: #CA101A;
                        }
                }

.is-contractor .resolution-box02 .list .link .heading-text-small {
                    color: #CA101A;
                    padding-top: 15px;
                }

@media(min-width:768px) {

.is-contractor .resolution-box02 .list .link .heading-text-small {
                        padding-top: 35px
                }
                    }

.is-contractor .resolution-box02 .list .link .img {
                    transition: all .2s;
                }

.is-contractor .resolution-box02 .list .link .text-parts-1 {
                    transition: all .2s;
                    font-weight: bold;
                }

.is-personal .wrap-quote {
        text-align: center;

    }

.is-personal .wrap-quote .quote-btn {
            padding: 25px 0;
    }

.is-personal .wrap-quote .quote-btn a {
                margin: 0 auto;
            }

@media(max-width:480px) {

.is-personal .wrap-quote .quote-btn a {
                    margin: auto
            }
                }

.is-personal .charm-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

@media(min-width:768px) {

.is-personal .charm-list {
            margin-bottom: -40px
    }
        }

.is-personal .charm-list li {
            margin-bottom: 15px;
        }

@media(min-width:768px) {

.is-personal .charm-list li {
                width: 49%;
                margin-bottom: 2%
        }
            }

@media(min-width:1200px) {

.is-personal .charm-list li {
                width: 530px;
                margin-bottom: 40px
        }
            }

@media(max-width:768px) {

.is-personal .charm-list li:last-child {
                    margin-bottom: 0
            }
                }

.is-personal .tab-3-group .heading-text-large {
            text-align: center;
        }

.is-personal .tab-3-group .img {
            text-align: center;
            margin-bottom: 20px;
            width: 70%;
            margin-right: auto;
            margin-left: auto;
        }

@media(min-width:768px) {

.is-personal .tab-3-group .img {
                width: 380px;
                margin-bottom: 60px
        }
            }

.is-personal .supplement-text {
        font-size: 1.6rem;
        color: #5C5C5C;
        line-height: 1.5;
    }

.is-personal .car-box {
        margin-top:20px;
        padding: 30px 20px;
        text-align: center;
        background-color: #fff;

    }

@media(min-width:768px) {

.is-personal .car-box {
            margin-top: 60px;
            padding: 80px 20px

    }
        }

@media(min-width:1200px) {

.is-personal .car-box {
            padding: 80px 40px

    }
        }

.is-personal .car-box .wrap-btn {
            padding-bottom: 0;
            max-width: 740px;
            margin-right: auto;
            margin-left: auto;
        }

.is-personal .car-box .car-list {
            margin-top: 20px;




        }

@media(min-width:768px) {

.is-personal .car-box .car-list {
                margin-top: 35px;
                display: flex;
                justify-content: space-between




        }

            }

.is-personal .car-box .car-list li {
                margin-bottom: 20px;
            }

@media(min-width:768px) {

.is-personal .car-box .car-list li {
                    margin-bottom: 0;
                    width: 32%
            }
                }

@media(min-width:1200px) {

.is-personal .car-box .car-list li {
                    width: 320px
            }
                }

.is-personal .car-box .car-list li:last-child {
                    margin-bottom: 0;
                }

.is-personal .car-box .car-list .link {
                color: #222222;
                border: 1px solid #D1D1D1;
                display: block;
                padding: 20px 15px;
                background-color: #fff;
                position: relative;



            }

@media(min-width:768px) {

.is-personal .car-box .car-list .link {
                    padding: 35px 10px



            }
                }

@media(min-width:1200px) {

.is-personal .car-box .car-list .link {
                    padding: 35px;
                    height: auto !important



            }
                }

@media(min-width:992px) {

.is-personal .car-box .car-list .link {
                    transition: all .2s



            }
                        .is-personal .car-box .car-list .link:hover:after {
                            opacity: 0.4;
                        }
                        .is-personal .car-box .car-list .link:hover .img {
                            opacity:0.8;
                        }
                        .is-personal .car-box .car-list .link:hover .text-parts-1 {
                            /* color: $color-red; */
                        }
                }

.is-personal .car-box .car-list .link .heading-text-small {
                    color: #CA101A;
                    padding-top: 15px;
                }

@media(min-width:768px) {

.is-personal .car-box .car-list .link .heading-text-small {
                        padding-top: 35px
                }
                    }

.is-personal .car-box .car-list .link .img {
                    transition: all .2s;
                }

.is-personal .car-box .car-list .link .text-parts-1 {
                    transition: all .2s;
                    font-weight: bold;
                }

.is-personal .car-box .car-list .link:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    opacity: 0;
                    transition: all .2s;
                    background-color: #eee;
                }

.is-personal .l-point .table-02 {
            margin: 10px 0;
        }

@media(min-width:768px) {

.is-personal .l-point .table-02 {
                margin: 15px 0
        }
            }

.is-personal .l-point .text-green {
            color: #029162;
        }

.is-personal .l-point .point-box {
            background-color: #FAF8EF;
            text-align: center;
            padding: 20px;
            margin-top: 20px;
        }

@media(min-width:768px) {

.is-personal .l-point .point-box {
                padding: 40px;
                margin-top: 40px
        }
            }

.is-personal .l-point .point-box .heading-text-medium {
                text-align: center;
                color: #CA101A;
            }

.is-personal .l-point .point-box .inner {
                margin-top: 20px;
                padding: 20px;
                background-color: #fff;

            }

@media(min-width:768px) {

.is-personal .l-point .point-box .inner {
                    margin-top: 30px;
                    padding: 40px

            }
                }

.is-personal .l-point .point-box .inner .text-parts-1,
                .is-personal .l-point .point-box .inner .list-dot {
                    text-align: left;
                }

@media(min-width:768px) {

.is-personal .l-point .point-list {
                display: flex;
                justify-content: space-between


        }
            }

.is-personal .l-point .point-list li {
                margin-bottom: 15px;
            }

@media(min-width:768px) {

.is-personal .l-point .point-list li {
                    margin-bottom: 0;
                    width: 32%
            }
                }

@media(min-width:1200px) {

.is-personal .l-point .point-list li {
                    width: 346px
            }
                }

.is-personal .l-point .point-list .group {
                border: 1px solid #D1D1D1;
                background-color: #fff;
                text-align: center;
                padding: 30px 10px 10px;
            }

@media(min-width:768px) {

.is-personal .l-point .point-list .group {
                    padding: 30px 10px
            }
                }

.is-personal .l-point .point-list .group .heading {
                    font-weight: bold;
                    font-size: 1.7rem;
                    margin-bottom: 15px;
                }

@media(min-width:768px) {

.is-personal .l-point .point-list .group .heading {
                        font-size: 2.4rem;
                        margin-bottom: 25px
                }
                    }

.is-personal .l-point .point-list .group .img {
                    text-align: center;
                    width: 80%;
                    margin: auto;
                }

@media(min-width:768px) {

.is-personal .l-point .point-list .group .img {
                        width: 260px
                }
                    }

.is-personal .l-point .point-group {
            padding-bottom: 30px;

        }

@media(min-width:768px) {

.is-personal .l-point .point-group {
                padding-bottom: 50px

        }
            }

.is-personal .l-point .point-group:last-child {
                margin-bottom: 0;
            }

.is-personal .l-point .point-group .heading {
                font-size: 1.7rem;
                margin-bottom: 5px;
                font-weight: bold;
                line-height: 1.5;
            }

@media(min-width:768px) {

.is-personal .l-point .point-group .heading {
                    font-size: 2.4rem;
                    margin-bottom: 10px
            }
                }

.is-personal .l-point .point-group .text {
                line-height: 1.5;
                font-size: 1.6rem;
                margin-bottom: 10px;
            }

@media(min-width:768px) {

.is-personal .l-point .point-group .text {
                    font-size: 1.8rem;
                    margin-bottom: 15px
            }
                }

.is-personal .l-point .point-group .text a {
                    color: #275EAC;
                    text-decoration: underline !important;


                }

@media(min-width:992px) {
                        .is-personal .l-point .point-group .text a:hover {
                            text-decoration: none !important;
                        }
                    }

.is-personal .l-point .point-group .text a .window {
                        fill: #275EAC;
                    }

.is-personal .l-point .point-group .text2 {
                font-size: 1.2rem;
                margin-bottom: 10px;
                line-height: 1.5;
                color: #5C5C5C;
            }

@media(min-width:768px) {

.is-personal .l-point .point-group .text2 {
                    font-size: 1.6rem;
                    margin-bottom: 15px
            }
                }

.is-personal .l-point .point-group .group-list {
                line-height: 1.5;
                font-size: 1.4rem;
                margin-bottom: 10px;
            }

@media(min-width:768px) {

.is-personal .l-point .point-group .group-list {
                    font-size: 1.8rem;
                    margin-bottom: 15px
            }
                }

.is-personal .l-point .point-group .group-list li {
                    margin-bottom: 3px;
                    padding-left: 10px;
                    position: relative;
                }

@media(min-width:768px) {

.is-personal .l-point .point-group .group-list li {
                        padding-left: 15px
                }
                    }

.is-personal .l-point .point-group .group-list li:last-child {
                        margin-bottom: 0;
                    }

.is-personal .l-point .point-group .group-list li:before {
                        content: '';
                        display: block;
                        background-color: #222222;
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        position: absolute;
                        top: 10px;
                        left: 0;
                    }

@media(min-width:768px) {

.is-personal .l-point .point-group .group-list li:before {
                            width: 6px;
                            height: 6px;
                            top: 10px
                    }
                        }

.is-personal .l-point .point-group .text:last-child, .is-personal .l-point .point-group .text2:last-child, .is-personal .l-point .point-group .group-list:last-child {
                    margin-bottom: 0;
                }

.is-personal .l-point .border-box {
            border-style: solid;
            border-color: #F0F0F0;
            border-top-width: 3px;
            border-bottom-width: 3px;
            padding: 30px 0 40px;
        }

@media(min-width:768px) {

.is-personal .l-point .border-box {
                padding: 45px 0 80px
        }
            }

.is-personal .l-point .style-box {
            background-color: #FAF8EF;
            padding: 20px;
            margin-bottom: 20px;
        }

@media(min-width:768px) {

.is-personal .l-point .style-box {
                padding: 40px;
                margin-bottom: 40px
        }
            }

@media(min-width:768px) {

.is-personal .l-point .style-box .box-list {
                    display: flex;
                    justify-content: space-between
            }
                }

.is-personal .l-point .style-box .box-list li {
                    margin-bottom: 10px;
                }

@media(min-width:768px) {

.is-personal .l-point .style-box .box-list li {
                        width: 30%;
                        margin-bottom: 0
                }
                    }

.is-personal .l-point .style-box .box-list .group {
                    display: flex;
                    justify-content: space-between;
                }

.is-personal .l-point .style-box .box-list .group .left {
                        width: 60%;
                        padding-top: 10px;
                    }

@media(min-width:768px) {

.is-personal .l-point .style-box .box-list .group .left {
                            width: 170px;
                            padding-top: 10px
                    }
                        }

.is-personal .l-point .style-box .box-list .group .right {
                        width: 35%;
                    }

@media(min-width:768px) {

.is-personal .l-point .style-box .box-list .group .right {
                            width: 120px
                    }
                        }

.is-personal .l-point .style-box .box-list .group .heading {
                        font-size: 1.6rem;
                        margin-bottom: 10px;
                        font-weight: bold;
                        color: #CA101A;
                    }

@media(min-width:768px) {

.is-personal .l-point .style-box .box-list .group .heading {
                            font-size: 2rem;
                            margin-bottom: 15px
                    }
                        }

.is-personal .l-point .style-box .box-list .group .text {
                        font-size: 1.4rem;
                        line-height: 1.5;
                    }

@media(min-width:768px) {

.is-personal .l-point .style-box .box-list .group .text {
                            font-size: 1.6rem;
                            line-height: 1.5
                    }
                        }

@media(min-width:768px) {

.is-personal .l-point .style-box .box-list li.harf-01 {
                        width: 47%;
                        margin-bottom: 0
                }

                        .is-personal .l-point .style-box .box-list li.harf-01 .left {
                            width: 280px;
                            padding-top: 10px;
                        }
                        .is-personal .l-point .style-box .box-list li.harf-01 .right {
                            width: 160px;
                        }
                    }

@media(min-width:768px) {

.is-personal .l-point .style-box .box-list li.harf-02 {
                        width: 47%;
                        margin-bottom: 0
                }

                        .is-personal .l-point .style-box .box-list li.harf-02 .left {
                            width: 320px;
                            padding-top: 10px;
                        }
                        .is-personal .l-point .style-box .box-list li.harf-02 .right {
                            width: 120px;
                        }
                    }

.is-personal .personal-card-layout {
        margin-bottom: 20px;
    }

@media(min-width:768px) {

.is-personal .personal-card-layout {
            margin-bottom: 4%;
            display: flex;
            justify-content: space-between
    }
        }

@media(min-width:1200px) {

.is-personal .personal-card-layout {
            margin-bottom: 40px
    }
        }

.is-personal .personal-card-layout:last-child {
            margin-bottom: 0;
        }

.is-personal.is-special.special .heading-text-large {
                margin-bottom: 5px;
            }

@media(min-width:768px) {

.is-personal.is-special.special .heading-text-large {
                    margin-bottom: 15px
            }
                }

.is-personal.is-special .wrap-btn {
            padding: 20px 0 0;
        }

@media(min-width:768px) {

.is-personal.is-special .wrap-btn {
                padding: 40px 0 0
        }
            }

.is-business .automobile_liability_insurance-box {
        background-color: #FAF8EF;
        padding: 20px 15px;
        margin-top: 30px;
    }

@media(min-width:768px) {

.is-business .automobile_liability_insurance-box {
            padding: 40px;
            margin-top: 60px
    }
        }

.is-business .automobile_liability_insurance-box .inner {
            background-color: #fff;
            padding: 20px 15px;
        }

@media(min-width:768px) {

.is-business .automobile_liability_insurance-box .inner {
                padding: 40px
        }
            }

.is-business .automobile-list li {
            font-size: 1.4rem;
            padding-left: 10px;
            line-height: 1.5;
            margin-bottom: 5px;
            position: relative;
        }

@media(min-width:768px) {

.is-business .automobile-list li {
                font-size: 1.8rem;
                padding-left: 10px
        }
            }

.is-business .automobile-list li .indent {
                display: block;
                padding-left: 1em;
                text-indent: -1em;
            }

.is-business .automobile-list li:before {
                content: '';
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #222222;
                position: absolute;
                left: 0;
                top: 7px;
            }

@media(min-width:768px) {

.is-business .automobile-list li:before {
                    top: 10px
            }
                }

.is-business .automobile-list li:last-child {
                margin-bottom: 0;
            }

.is-business .special_terms-box {
        text-align: center;
        background-color: #FAF8EF;
        text-align: center;
        padding: 20px 15px;


    }

@media(min-width:768px) {

.is-business .special_terms-box {
            padding: 40px


    }
        }

.is-business .special_terms-box .heading {
            font-size: 2rem;
            margin-bottom: 20px;
            font-weight: bold;
            color: #CA101A;
            line-height: 1.5;
        }

@media(min-width:768px) {

.is-business .special_terms-box .heading {
                font-size: 2.6rem;
                margin-bottom: 25px
        }
            }

.is-business .special_terms-box .heading2 {
            font-weight: bold;
            margin-bottom: 10px;
            font-size: 1.7rem;
            line-height: 1.5;
        }

@media(min-width:768px) {

.is-business .special_terms-box .heading2 {
                margin-bottom: 15px;
                font-size: 2.4rem
        }
            }

.is-business .special_terms-box .text-box {
            background-color: #fff;
            padding: 10px;
        }

.is-corporate-cm-gallery .wrap-content .middle {
        max-width: 840px;
    }

.is-corporate-cm-gallery .btn-wrap {
        text-align: center;
        margin: 120px 0;

    }

.is-corporate-cm-gallery .btn-wrap .btn {
            font-size: 2.6rem;
            background-color: #CA101A;
            color: #fff;
            padding: 20px;
            border-radius: 10px;
            text-align: center;
        }

.is-corporate-cm-gallery .btn-wrap .btn:hover {
                background-color: #ac0000;
            }

@media(max-width: 768px) {
            .is-corporate-cm-gallery .btn-wrap .btn {
                font-size: 1.8rem;
            }
        }

@media(max-width: 480px) {

.is-corporate-cm-gallery .btn-wrap {
            margin: 80px 0

    }

            .is-corporate-cm-gallery .btn-wrap .btn {
                font-size: 1.4rem;
            }
        }

.is-corporate .l-intro-box {
        background-color: #FAF8EF;
        padding: 45px 15px 50px;
        text-align: center;
    }

@media(min-width:768px) {

.is-corporate .l-intro-box {
            padding: 90px 20px 100px
    }
        }

.is-corporate .l-intro-box .heading-text-largest {
            margin-bottom: 35px;
        }

@media(min-width:768px) {

.is-corporate .l-intro-box .heading-text-largest {
                margin-bottom: 70px
        }
            }

.is-corporate .l-intro-box .img {
            width: 180px;
            margin-top: 30px;
            margin-right: auto;
            margin-left: auto;
        }

@media(min-width:768px) {

.is-corporate .l-intro-box .img {
                width: 240px;
                margin-top: 70px
        }
            }

.is-corporate .corporate-card-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;




    }

@media(min-width:768px) {

.is-corporate .corporate-card-list {
            margin-bottom: -40px




    }
        }

.is-corporate .corporate-card-list li {
            width: 48%;
            margin-bottom: 4%;
        }

@media(min-width:768px) {

.is-corporate .corporate-card-list li {
                width: 32%
        }
            }

@media(min-width:1200px) {

.is-corporate .corporate-card-list li {
                width: 340px;
                margin-bottom: 40px
        }
            }

.is-corporate .corporate-card-list .corporate-card {
            display: block;
            color: #222222;

        }

@media(min-width:992px) {
                        .is-corporate .corporate-card-list .corporate-card:hover .img:before {
            				opacity:0.2;
            			}
                    .is-corporate .corporate-card-list .corporate-card:hover .text {
                        color: #CA101A;
                    }
            }

.is-corporate .corporate-card-list .corporate-card .img {
                position: relative;
            }

.is-corporate .corporate-card-list .corporate-card .img:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    opacity: 0;
                    transition: all .2s;
                }

.is-corporate .corporate-card-list .corporate-card .text {
                font-size: 1.4rem;
                margin-top: 10px;
                display: block;
                line-height: 1.5;
                font-weight: bold;

            }

@media(min-width:768px) {

.is-corporate .corporate-card-list .corporate-card .text {
                    font-size: 2rem;
                    margin-top: 15px

            }
                }

@media(min-width:992px) {

.is-corporate .corporate-card-list .corporate-card .text {
                    font-size: 2.2rem

            }
                }

@media(min-width:992px) {

.is-corporate .corporate-card-list .corporate-card .text {
                    transition: all .2s

            }
                }

.is-corporate .corporate-card-list .corporate-card .icon {
                width: 10px;
                height: 10px;
                vertical-align: middle;
                position: relative;
                top: -2px;
            }

@media(min-width:768px) {

.is-corporate .corporate-card-list .corporate-card .icon {
                    width: 16px;
                    height: 16px;
                    top: -1px
            }
                }

.is-corporate .corporate-recruit-column {
        margin-bottom: 40px;


    }

@media(min-width:768px) {

.is-corporate .corporate-recruit-column {
            margin-bottom: 50px


    }
        }

.is-corporate .corporate-recruit-column:last-child {
            margin-bottom: 0;
        }

@media(min-width:768px) {

.is-corporate .corporate-recruit-column .inner {
                display: flex;
                justify-content: space-between
        }
            }

@media(min-width:768px) {

.is-corporate .corporate-recruit-column .left {
                width: 48%
        }
            }

@media(min-width:1200px) {

.is-corporate .corporate-recruit-column .left {
                width: 530px
        }
            }

.is-corporate .corporate-recruit-column .right {
            position: relative;
            margin-top: 10px;
        }

@media(min-width:768px) {

.is-corporate .corporate-recruit-column .right {
                margin-top: 0;
                width: 48%
        }
            }

@media(min-width:1200px) {

.is-corporate .corporate-recruit-column .right {
                width: 530px
        }
            }

.is-corporate .corporate-recruit-column .heading-text-large {
            margin-bottom: 10px !important;
        }

@media(min-width:768px) {

.is-corporate .corporate-recruit-column .heading-text-large {
                margin-bottom: 20px !important
        }
            }

.is-corporate .corporate-recruit-column .m-btn {
            padding-top: 15px;
        }

@media(min-width:1200px) {

.is-corporate .corporate-recruit-column .m-btn {
                padding-top: 0;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto
        }
            }

@media(min-width:768px) {

.is-corporate .about-box01 {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between
    }
        }

.is-corporate .about-box01 .l-img {
            width: 50%;
            margin: auto;

        }

@media(min-width:768px) {

.is-corporate .about-box01 .l-img {
                width: 30%;
                margin: 0

        }
            }

@media(min-width:1200px) {

.is-corporate .about-box01 .l-img {
                width: 245px

        }
            }

.is-corporate .about-box01 .l-text {
            margin-top: 15px;
        }

@media(min-width:768px) {

.is-corporate .about-box01 .l-text {
                margin-top: 0;
                width: 64%
        }
            }

@media(min-width:1200px) {

.is-corporate .about-box01 .l-text {
                width: 760px
        }
            }

.is-corporate .about-box02 {
        margin: 20px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
    }

@media(min-width:768px) {

.is-corporate .about-box02 {
            width: auto;
            margin: 30px 0 0
    }
        }

.is-corporate .about-box02 .left {
            margin-right: 20px;
        }

@media(min-width:768px) {

.is-corporate .about-box02 .left {
                width: 181px;
                margin-right: 30px
        }
            }

@media(min-width:992px) {

.is-corporate .about-box02 .left {
                margin-right: 70px
        }
            }

@media(min-width:768px) {

.is-corporate .about-box02 .right {
                width: 340px
        }
            }

@media(min-width:768px) {

.is-corporate .about-box03 {
            display: flex;
            justify-content: space-between
    }
        }

@media(min-width:768px) {

.is-corporate .about-box03 .left {
                width: 60%
        }
            }

@media(min-width:1200px) {

.is-corporate .about-box03 .left {
                width: 665px
        }
            }

.is-corporate .about-box03 .right {
            margin-top: 15px;
            text-align: center;
        }

@media(min-width:768px) {

.is-corporate .about-box03 .right {
                width: 34.5%;
                margin-top: 0
        }
            }

@media(min-width:1200px) {

.is-corporate .about-box03 .right {
                width: 380px
        }
            }

.is-corporate .recruit-site-list {
        display: flex;
        flex-wrap: wrap;

    }

@media(min-width:768px) {
        }

.is-corporate .recruit-site-list li {
            padding: 10px;
            background-color: #F7F7F7;
            margin-bottom: 4%;
            margin-right: 4%;
            width: 48%;
        }

@media(min-width:768px) {

.is-corporate .recruit-site-list li {
                padding: 20px;
                width: 32%;
                margin-bottom: 0;
                margin-right: 2%;
                margin-bottom: 2%
        }
            }

@media(min-width:1200px) {

.is-corporate .recruit-site-list li {
                width: 346px;
                margin-right: 20px;
                margin-bottom: 20px
        }
            }

@media(max-width:480px) {

.is-corporate .recruit-site-list li:nth-child(2n) {
                    margin-right: 0
            }
                }

@media(min-width:768px) {

.is-corporate .recruit-site-list li:last-child {
                    margin-right: 0
            }
                }

.is-corporate .recruit-site-list .link {
            display: block;
            position: relative;

        }

@media(min-width:992px) {
        			.is-corporate .recruit-site-list .link:hover:before {
        				opacity:0.2;
        			}
        	}

.is-corporate .recruit-site-list .link:before {
        		content: '';
        		display: block;
        		width: 100%;
        		height: 100%;
        		background-color: #fff;
        		position: absolute;
        		top: 0;
        		left: 0;
        		z-index: 1;
        		opacity: 0;
        		transition: all .2s;
        	}

.is-corporate .knowledge-flow {
        line-height: 1.5;
        margin-top: 20px;
    }

@media(min-width:992px) {

.is-corporate .knowledge-flow {
            display: flex;
            justify-content: space-between;
            margin-top: 40px
    }
        }

.is-corporate .knowledge-flow .box-start {
            color: #CA101A;
            text-align: center;
            background-color: #FDEFEF;
            border-radius: 2px;
            font-size: 1.6rem;
            width: 100%;
            display: table;
            font-weight: bold;
        }

@media(min-width:992px) {

.is-corporate .knowledge-flow .box-start {
                font-size: 1.8rem;
                width: 140px;
                height: 100px
        }
            }

.is-corporate .knowledge-flow .box-start .inner {
                display: table-cell;
                vertical-align: middle;
                padding: 10px;
            }

.is-corporate .knowledge-flow .box-flow {
            position: relative;
            width: 100%;
            background-color: #FAF8EF;
            border-radius: 2px;
            font-size: 1.4rem;
            position: relative;
            display: table;
            text-align: center;
            margin-top: 40px;
            font-weight: bold;
        }

@media(min-width:992px) {

.is-corporate .knowledge-flow .box-flow {
                margin-top: 0;
                width: 180px;
                font-size: 1.6rem;
                height: 100px
        }

            }

.is-corporate .knowledge-flow .box-flow:before {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 0 7px 10px;
                border-color: transparent transparent transparent #E69497;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: -30px;
                -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
            }

@media(min-width:992px) {

.is-corporate .knowledge-flow .box-flow:before {
                    top: 0;
                    bottom: 0;
                    left: -12%;
                    right: auto;
                    border-width: 10px 0 10px 14px;
                    -webkit-transform: rotate(0deg);
                            transform: rotate(0deg)
            }
                }

.is-corporate .knowledge-flow .box-flow .number {
                background-color: #E69497;
                color: #fff;
                border-radius: 50%;
                text-align: center;
                font-size: 14px;
                width: 28px;
                height: 28px;
                line-height: 28px;
                font-family: 'Roboto', sans-serif;
                display: block;
                position: absolute;
                top: -12px;
                left: -12px;
            }

@media(min-width:992px) {

.is-corporate .knowledge-flow .box-flow .number {
                    font-size: 1.6rem;
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    top: -16px;
                    left: -16px
            }
                }

.is-corporate .knowledge-flow .box-flow .content {
                display: table-cell;
                vertical-align: middle;
                padding: 10px;
            }

.is-corporate .knowledge-equation .equation-inner {
            background-color: #FAF8EF;
            align-items: center;
            padding: 20px 10px;
            margin: 15px 0;

        }

@media(min-width:768px) {

.is-corporate .knowledge-equation .equation-inner {
                display: flex;
                justify-content: center;
                padding: 30px 10px;
                margin: 30px 0

        }
            }

.is-corporate .knowledge-equation .text {
            font-size: 1.6rem;
            font-weight: bold;
            margin-bottom: 10px;
        }

@media(min-width:768px) {

.is-corporate .knowledge-equation .text {
                margin-bottom: 0;
                font-size: 2rem;
                margin-right: 20px
        }
            }

@media(min-width:1200px) {

.is-corporate .knowledge-equation .text {
                margin-right: 70px
        }
            }

.is-corporate .knowledge-equation .img {
            width: 100%;
        }

@media(min-width:768px) {

.is-corporate .knowledge-equation .img {
                width: 430px
        }

            }

@media(min-width:768px) {

.is-corporate .sa-img-box {
            display: flex;
            justify-content: space-between
    }
        }

.is-corporate .sa-img-box .column {
            margin-bottom: 15px;
        }

@media(min-width:768px) {

.is-corporate .sa-img-box .column {
                width: 48%;
                margin-bottom: 0
        }
            }

@media(min-width:1200px) {

.is-corporate .sa-img-box .column {
                width: 530px
        }
            }

.is-corporate .sa-img-box .column:last-child {
                margin-bottom: 0;
            }

@media(min-width:768px) {

.is-corporate .sa-interview {
            display: flex;
            align-items: flex-end





    }
        }

.is-corporate .sa-interview .img {
            width: 70%;
            margin: 0 auto 15px;
        }

@media(min-width:768px) {

.is-corporate .sa-interview .img {
                margin: 0;
                margin-right: 30px;
                width: 360px
        }
            }

.is-corporate .sa-interview .box-gray {
            margin-bottom: 0;
        }

@media(min-width:768px) {

.is-corporate .sa-interview .box-gray {
                width: 324px
        }
            }

.is-corporate .recovery-list {
        border-style: dashed;
        border-color: #D1D1D1;
        border-bottom-width: 1px;

    }

.is-corporate .recovery-list li {
            border-style: dashed;
            border-color: #D1D1D1;
            border-top-width: 1px;
            padding: 10px 5px;
        }

@media(min-width:768px) {

.is-corporate .recovery-list li {
                padding: 30px 10px
        }
            }

.is-corporate .recovery-list li dl {
                display: flex;
                align-items: center;
            }

.is-corporate .recovery-list li dl dt {
                    font-size: 1.8rem;
                    font-family: 'Roboto', sans-serif;
                    color: #454545;
                    line-height: 1.75;
                    width: 34%;
                }

@media(min-width:768px) {

.is-corporate .recovery-list li dl dt {
                        width: 18%
                }
                    }

@media(min-width:992px) {

.is-corporate .recovery-list li dl dt {
                        width: 11%
                }
                    }

.is-corporate .recovery-list li dl dd {
                    width: 66%;
                }

@media(min-width:768px) {

.is-corporate .recovery-list li dl dd {
                        width: 82%
                }
                    }

@media(min-width:992px) {

.is-corporate .recovery-list li dl dd {
                        width: 89%
                }
                    }

.is-corporate .recovery-list li dl dd .link {
                        color: #275EAC;
                    }

.is-corporate .brand-logo-box .img {
            text-align: center;
        }

.is-corporate .brand-color .img {
            width: 50%;
        }

@media(max-width: 767px) {

.is-corporate .brand-color .img {
                width: 100%
        }
            }

@media(min-width:768px) {

.is-corporate .monument-box01 {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between
    }
        }

.is-corporate .monument-box01 .l-img {
            width: 50%;
            margin: auto;

        }

@media(min-width:768px) {

.is-corporate .monument-box01 .l-img {
                width: 30%;
                margin: 0

        }
            }

@media(min-width:1200px) {

.is-corporate .monument-box01 .l-img {
                width: 245px

        }
            }

.is-corporate .monument-box01 .l-text {
            margin-top: 15px;
        }

@media(min-width:768px) {

.is-corporate .monument-box01 .l-text {
                margin-top: 0;
                width: 64%
        }
            }

@media(min-width:1200px) {

.is-corporate .monument-box01 .l-text {
                width: 760px
        }
            }

.is-corporate-privacy a {
        color: #275EAC;
    }

@media(min-width:992px) {
            .is-corporate-privacy a:hover {
                text-decoration: underline;
            }
        }

.about-character {
    border: 2px solid #EA5505;
    border-radius: 40px;
    border-style: dashed;
    padding: 40px;
    display: inline-block;
    text-align: center;
}

.about-character .heading-text-medium {
        border-bottom: 2px solid #EA5505;
        border-style: dashed;
        display: inline-block;
        padding: 0 40px;
    }

.line-account .column-pc2-sp1 {
        text-align: center;
    }

.line-account .column-pc2-sp1 .column .img img {
                    width: 100%;
                }

@media(max-width: 992px) {

.line-account .column-pc2-sp1 .column .img {
                    width: 50%;
                    display: inline-block
            }
                }

.day-go-apps .wrap-content .img a img {
                    width: 100%;
                }


.is-corporate-disclosure .year-list {
        background-color: #F7F7F7;
        border-radius: 4px;
        display: flex;
        width: 100%;
        padding: 20px 0;
        margin-top: 25px;


    }


@media(min-width:768px) {


.is-corporate-disclosure .year-list {
            padding: 25px 0;
            margin-top: 40px


    }
        }


.is-corporate-disclosure .year-list li {
            border-right: 2px solid #D1D1D1;
            border-radius: 2px;
            text-align: center;
            width: 20%;
            padding: 3px 0;
        }


@media(min-width:768px) {


.is-corporate-disclosure .year-list li {
                width: 150px;
                padding: 6px 0
        }
            }


.is-corporate-disclosure .year-list li:last-child {
                border-right: none;
            }


.is-corporate-disclosure .year-list a {
            color: #222222;
            font-size: 1.6rem;
        }


@media(min-width:768px) {


.is-corporate-disclosure .year-list a {
                font-size: 1.8rem
        }
            }


.is-corporate-disclosure .list-pdf li {
            display: flex;
            gap: 20px;
            flex-direction: column;
            justify-content: space-between;
        }


.is-corporate-disclosure .list-pdf li .add {
                display: flex;
                flex-direction: column;
                gap: 20px;
                text-align: right;
            }


.is-corporate-disclosure .list-pdf li .add a {
                    margin-left: auto;
                }


@media(min-width:768px) {


.is-corporate-disclosure .list-pdf li {
                flex-direction: row
        }
            }


@media(min-width:768px) {


.is-corporate-disclosure .column-layout {
            display: flex;
            justify-content: space-between
    }
        }


@media(min-width:768px) {


.is-corporate-disclosure .column-layout .cell {
                width: 48.183%
        }
            }


.is-corporate-disclosure .column-layout .group {
            padding-top: 30px;
        }


@media(min-width:768px) {


.is-corporate-disclosure .column-layout .group {
                padding-top: 50px
        }
            }

.is-corporate-sustainability .sdgs-declaration .card-yellow-light-heading .heading.heading-color-01 {
                    background-color: #fd9d241c;
                }

.is-corporate-sustainability .sdgs-declaration .card-yellow-light-heading .heading.heading-color-02 {
                    background-color: #e1f5e3;
                }

.is-corporate-sustainability .sdgs-declaration .card-yellow-light-heading .heading.heading-color-03 {
                    background-color: #dd136717;
                }

.is-corporate-sustainability .sustainability-box {
        display: flex;
        gap: 40px;
        padding: 40px;
        border-radius: 4px;
    }

@media(max-width:768px) {

.is-corporate-sustainability .sustainability-box {
            flex-direction: column;
            align-items: center;
            padding: 20px
    }
        }

.is-corporate-sustainability .sustainability-box.box-color-01 {
            background-color: #fd9d241c;
        }

.is-corporate-sustainability .sustainability-box.box-color-02 {
            background-color: #e1f5e3;
        }

.is-corporate-sustainability .sustainability-box.box-color-03 {
            background-color: #dd136717;
        }

.is-corporate-sustainability .sustainability-box .box-img-wrap {
            width: 80px;
            height: 80px;
        }

.is-corporate-sustainability .sustainability-box .box-img-wrap .e-img {
                border-radius: 1000px;
                border: 5px solid #fff;
            }

.is-corporate-sustainability .sustainability-box .box-contents {
            width: 100%;
        }

.is-corporate-sustainability .sustainability-box .box-contents .heading-wrap {
                display: flex;
                justify-content: space-between;
                gap: 8px 20px;
            }

@media(max-width:768px) {

.is-corporate-sustainability .sustainability-box .box-contents .heading-wrap {
                    flex-direction: column;
                    justify-content: left;
                    margin-bottom: 20px
            }
                }

@media(max-width:768px) {

.is-corporate-sustainability .sustainability-box .box-contents .heading-wrap .heading-text-small {
                        margin-bottom: 5px;
                        text-align: center;
                        font-size: 2.0rem
                }
                    }

.is-corporate-sustainability .sustainability-box .box-contents .heading-wrap .icon-wrap {
                    display: flex;
                    gap: 5px;
                }

@media(max-width:768px) {

.is-corporate-sustainability .sustainability-box .box-contents .heading-wrap .icon-wrap {
                        justify-content: center
                }
                    }

.is-corporate-sustainability .sustainability-box .box-contents .heading-wrap .icon-wrap .e-img {
                        width: 80px;
                    }

.is-corporate-sustainability .sustainability-box .box-contents .list-dot {
                font-size: 1.6rem;
                margin-top: 10px;
            }

.is-corporate-sustainability .text-box {
        display: flex;
        align-items: center;
        gap: 20px;
    }

@media(max-width:480px) {

.is-corporate-sustainability .text-box {
            flex-direction: column
    }
        }

.is-corporate-sustainability .text-box .e-img {
            max-width: 192px;
            margin-right: 20px;
        }

@media(max-width:480px) {

.is-corporate-sustainability .text-box .e-img {
                margin-right: 0
        }
            }

.is-corporate-sustainability .table-01 {
        font-size: 1.3rem;
    }

@media(max-width:480px) {

.is-corporate-sustainability .table-01 {
            font-size: 1.2rem
    }
        }

.is-corporate-sustainability .table-01 th {
            padding: 10px;
            text-align: center;
            width: 25%;
        }

@media(max-width:480px) {

.is-corporate-sustainability .table-01 th {
                padding: 6px
        }
            }

.is-corporate-sustainability .table-01 td {
            padding: 10px;
        }

@media(max-width:480px) {

.is-corporate-sustainability .table-01 td {
                padding: 6px
        }
            }

.is-corporate-sustainability .table-description {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 1.4rem;
        text-indent: initial;
    }

.is-corporate-sustainability .list-dot li {
            line-height: 1.5;
        }

.is-corporate-sustainability .list-dot li .img-wrap {
                display: inline-flex;
                margin-top: 20px;
            }

.is-support-store .area-group {
        margin-bottom: 30px;
    }

@media(min-width:768px) {

.is-support-store .area-group {
            margin-bottom: 50px
    }
        }

.is-support-store .area-group .l-list {
            border-top: 1px solid #D1D1D1;
        }

.is-support-store .area-group .l-list li {
                line-height: 1.5;
                border-bottom: 1px solid #D1D1D1;
                padding: 15px 0;
            }

@media(min-width:768px) {

.is-support-store .area-group .l-list li {
                    display: flex;
                    padding: 20px 0
            }
                }

.is-support-store .area-group .l-list li .l-name {
                    position: relative;


                }

@media(min-width:768px) {

.is-support-store .area-group .l-list li .l-name {
                        width: 60%;
                        padding-left: 158px;
                        padding-right: 20px;
                        padding-top: 10px


                }
                    }

.is-support-store .area-group .l-list li .l-name a {
                        font-size: 1.4rem;
                        color: #275EAC;
                    }

@media(min-width:768px) {

.is-support-store .area-group .l-list li .l-name a {
                            font-size: 1.8rem
                    }
                        }

@media(min-width:992px) {

.is-support-store .area-group .l-list li .l-name .premium-link {
                            transition: all .2s
                    }
                            .is-support-store .area-group .l-list li .l-name .premium-link:hover{
                                opacity:0.8;
                            }
                        }

.is-support-store .area-group .l-list li .address,
                .is-support-store .area-group .l-list li .department {
                    font-size: 1.4rem;
                    color: #5C5C5C;
                    padding-top: 5px;
                }

@media(min-width:768px) {

.is-support-store .area-group .l-list li .address,
                .is-support-store .area-group .l-list li .department {
                        padding-top: 0;
                        width: 40%;
                        padding-right: 20px;
                        font-size: 1.6rem
                }
                    }

@media(min-width:768px) {

.is-support-store .area-group .l-list.store li {
                        justify-content: space-between



                }
                    }

@media(min-width:768px) {

.is-support-store .area-group .l-list.store li .l-name {
                            width: 30%;
                            padding-left: 0
                    }
                        }

@media(min-width:768px) {

.is-support-store .area-group .l-list.store li .department {
                            padding-top: 10px;
                            width: 20%;
                            padding-right: 0
                    }
                        }

@media(min-width:768px) {

.is-support-store .area-group .l-list.store li .address {
                            width: 50%;
                            padding-right: 0
                    }
                        }

@media(min-width:768px) {

.is-support-store .l-agent-greeting {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between
    }
        }

.is-support-store .l-agent-greeting .content {
            margin-bottom: 20px;
        }

@media(min-width:768px) {

.is-support-store .l-agent-greeting .content {
                width: 30%
        }
            }

.is-support-store .l-agent-greeting .content:last-child {
                margin-bottom: 0;
            }

@media(min-width:768px) {

.is-support-store .l-agent-greeting .content:last-child {
                    width: 68%
            }
                }

.is-support-store .l-agent-greeting .content:only-child {
                width: 100%;
            }

.is-support-store .l-agent-greeting .content .img {
                margin-left: auto;
                margin-right: auto;
            }

@media(min-width:768px) {

.is-support-store .l-agent-greeting .content .img {
                    margin-right: 0;
                    max-width: 380px
            }
                }

.is-support-store .l-agent-greeting .content .img img {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    height: auto;
                    width: 100%;
                    max-width: none;
                }

@media(min-width:768px) {

.is-support-store .l-agent-greeting .content .img img {
                        margin-right: 0
                }
                    }

.is-support-store .l-agent-data-list {
        border-style: solid;
        border-color: #D1D1D1;
        border-top-width: 1px;
    }

.is-support-store .l-agent-data-list .l-block {
            display: flex;
            font-size: 1.4rem;
            color: #222222;
            line-height: 1.5;
            border-style: solid;
            border-color: #D1D1D1;
            border-bottom-width: 1px;
            padding: 15px 2px;
        }

@media(min-width:768px) {

.is-support-store .l-agent-data-list .l-block {
                font-size: 1.8rem;
                padding: 25px 2px
        }
            }

.is-support-store .l-agent-data-list .l-block dt {
                width: 32%;
                font-weight: bold;
            }

@media(min-width:768px) {

.is-support-store .l-agent-data-list .l-block dt {
                    width: 15.455%
            }
                }

.is-support-store .l-agent-data-list .l-block dd {
                width: 68%;
            }

@media(min-width:768px) {

.is-support-store .l-agent-data-list .l-block dd {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 84.545%
            }


                }

.is-support-store .l-agent-data-list .l-block dd .icon.premium {
                    position: static;
                    margin-top: 5px;
                }

@media(min-width:768px) {

.is-support-store .l-agent-data-list .l-block dd .icon.premium {
                        margin-top: 0;
                        margin-left: 20px
                }
                    }

.is-support-store .l-agent-data-list .l-block dd a {
                    color: #077DB0;
                }

.is-support-store .l-agent-data-list .l-block dd .hover-img {
                    display: block;
                }

@media(min-width:768px) {

.is-support-store .l-agent-data-list .l-block dd .hover-img {
                        display: inline-block
                }
                    }

@media(min-width:768px) {

.is-support-store .l-agent-gallery {
            display: flex;
            flex-wrap: wrap
    }
        }

.is-support-store .l-agent-gallery li {
            margin-bottom: 15px;

        }

@media(min-width:768px) {

.is-support-store .l-agent-gallery li {
                margin-bottom: 0;
                width: 32%;
                margin-right: 2%;
                margin-bottom: 2%

        }
            }

@media(min-width:1200px) {

.is-support-store .l-agent-gallery li {
                width: 350px;
                /* height: 248px; */
                margin-right: 25px;
                margin-bottom: 25px

        }

            }

.is-support-store .l-agent-gallery li .img {
                overflow: hidden;
                position: relative;
                width: 100%;
                padding-top: 70.86%;
                display: block;

            }

.is-support-store .l-agent-gallery li .img img {
                    width: auto;
                    height: auto;
                    min-width: 100%;
                    min-height: 100%;
                    max-width: inherit;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                }

.is-support-store .l-agent-gallery li:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }

@media(min-width:768px) {

.is-support-store .l-agent-gallery li:nth-child(3n) {
                    margin-right: 0
            }
                }

.is-support-store .l-agent-gallery li img {
                max-width: none;
                width: 100%;
            }

.is-support-store .l-agent-gallery img {
            height: auto;
        }

.is-support-store .store-img {
        max-width: 600px;
    }

.l-information-desk {
    border-style: solid;
    border-color: #DDDDDD;
    border-top-width: 1px;
    /* border-bottom-width: 1px; */
    padding: 30px 0 30px;
}

@media(min-width:768px) {

.l-information-desk {
        padding: 50px 0 30px
}
    }

.l-information-desk .list-column-3  {
        justify-content: flex-start;
    }

@media(min-width:768px) {

.l-information-desk .list-column-3 li {
                margin-right: 2%
        }
            }

@media(min-width:1200px) {

.l-information-desk .list-column-3 li {
                margin-right: 22px
        }
            }

.l-information-desk .list-column-3 li:nth-child(3n) {
                margin-right: 0;
            }

@media(min-width:768px) {

.l-information-desk .btn-list {
    		height: 100px
    }
    	}





.adr-flow-wrap {
    display: flex;
    justify-content: space-between;
}
.adr-flow-left {
    width: 8%;
}
@media(min-width:768px) {
.adr-flow-left {
        margin-right: 8px;
        width: auto
}
    }
.adr-flow-left > div {
        background-color: #FAF8EF;
        border-radius: 4px;
        text-align: center;
        font-size: 1.3rem;
    }
@media(min-width:768px) {
.adr-flow-left > div {
            width: 20px
    }
        }
.adr-flow-left > div div {
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            display: inline-block;
            text-align: left;
            padding: 50px 0;
            font-weight: bold;
        }
.adr-flow-left > div#text01 {
            height: 573px;
        }
.adr-flow-left > div#text02 {
            height: 1196px;
            position: relative;
            top: 403px;
        }
@media(min-width:480px) {
.adr-flow-left {
        margin-right: 25px
}

        .adr-flow-left > div {
            width: 60px;
            font-size: 1.6rem;
        }
    }
.adr-flow {
    width: 73%;
}
@media(min-width:768px) {
.adr-flow {
        margin-right: 8px;
        width: auto
}
    }
.adr-flow .step {
        padding: 15px;
    }
.adr-flow .step dl dt span {
                    display: inline-block;
                }
.adr-flow .step dl dt .number {
                    font-family: 'Roboto', sans-serif;
                    font-size: 1.8rem;
                    vertical-align: middle;
                    margin-right: 10px;
                }
.adr-flow .step dl dt .number span {
                        font-size: 3.4rem;
                        margin-left: 5px;
                        vertical-align: middle;
                    }
.adr-flow .step dl dt .text {
                    vertical-align: bottom;
                    font-weight: bold;
                }
.adr-flow .step dl dd {
                margin-top: 20px;
            }
.adr-flow .step01, .adr-flow .step03, .adr-flow .step05, .adr-flow .step08 {
        background-color: #F1F7EE;
    }
.adr-flow .step01 dl dt, .adr-flow .step03 dl dt, .adr-flow .step05 dl dt, .adr-flow .step08 dl dt {
            color: #80BA5F;
        }
.adr-flow .step02, .adr-flow .step04, .adr-flow .step06, .adr-flow .step07, .adr-flow .step09 {
        background-color: #E4F4F5;
    }
.adr-flow .step02 dl dt, .adr-flow .step04 dl dt, .adr-flow .step06 dl dt, .adr-flow .step07 dl dt, .adr-flow .step09 dl dt {
            color: #00AAB0;
        }
.adr-flow .arrow-block {
        background-image: url(../img/support/adr/flow/flow-bg01.png);
        background-repeat: repeat-y;
        background-position: 37px;
        min-height: 20px;
        line-height: 1.5;
    }
.adr-flow .arrow-block .arrow-block-inner-01 {
            padding: 20px 0 20px 70px;
        }
.adr-flow .arrow-block.last {

            width: 290px;
        }
.adr-flow .arrow-block .arrow-block-inner-02 {
            padding: 20px 0;
        }
.adr-flow .arrow-block .arrow-block-inner-02 .text {
                padding-left: 70px;
                margin-bottom: 30px;
            }
.adr-flow .arrow-block .arrow-block-inner-03 {
            padding: 20px 0 0 0;
        }
@media(min-width:480px) {
.adr-flow {
        margin-right: 25px
}

        .adr-flow .step {
            padding: 30px;
        }
    }
.adr-flow-right {
    width: 14%;
}
@media(min-width:768px) {
.adr-flow-right {
        width: auto
}
    }
.adr-flow-right dl {
        background-color: #E4F4F5;
        border-radius: 4px;
    }
@media(min-width:768px) {
.adr-flow-right dl {
            width: 48px
    }
        }
.adr-flow-right dl dt {
            background-color: #00AAB0;
            color: #ffffff;
            text-align: center;
            border-radius: 4px 4px 0 0;
            padding: 10px 0;
            width: 100%;
            line-height: 1.3;
            font-size: 1.2rem;
        }
.adr-flow-right dl dt div {
                -webkit-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                display: inline-block;
                text-align: left;
                font-weight: bold;
            }
.adr-flow-right dl dd {
            color: #00AAB0;
            width: 100%;
            padding: 15px 0 0 0;
            line-height: 1.5;
            text-align: center;
            font-size: 1.4rem;
        }
.adr-flow-right dl dd div {
                -webkit-writing-mode: vertical-rl;
                -ms-writing-mode: tb-rl;
                writing-mode: vertical-rl;
                display: inline-block;
                text-align: left;
                letter-spacing: 0.25em;
                font-weight: bold;
            }
.adr-flow-right dl#text01 {
            height: 861px;
        }
.adr-flow-right dl#text02 {
            height: 1028px;
        }
@media(min-width:480px) {

        .adr-flow-right dl {
            width: 130px;
        }

            .adr-flow-right dl dt {
                font-size: 1.6rem;
            }

                .adr-flow-right dl dt div {
                    -webkit-writing-mode: inherit;
                    -ms-writing-mode: inherit;
                    writing-mode: inherit;
                    text-align: center;
                }

            .adr-flow-right dl dd {
                font-size: 2rem;
            }
    }





.is-support .premium-box {
        background-color: #FAF8EF;
        padding: 15px;
    }





@media(min-width:768px) {





.is-support .premium-box {
            padding: 40px
    }
        }





.is-support .premium-box .heading-text-small {
            color: #CA101A;
        }





.is-support .premium-box .inner {
            background-color: #fff;
            padding: 15px;
        }





@media(min-width:768px) {





.is-support .premium-box .inner {
                padding: 40px
        }
            }





.is-support .premium-box .top-part {
            display: flex;
            align-items: center;
        }





.is-support .premium-box .top-part .img {
                margin-left: 10px;
                margin-bottom: 20px;
            }





@media(min-width:768px) {





.is-support .premium-box .top-part .img {
                    margin-left: 20px
            }
                }





.is-support .premium-box .list-dot {
            margin-top: 10px;
        }





@media(min-width:768px) {





.is-support .premium-box .list-dot {
                margin-top: 30px
        }
            }





.is-support .premium-box .list-dot li {
                font-size: 1.4rem;
                margin-top: 0;
                line-height: 1.5;
                padding-bottom: 5px;
            }





@media(min-width:768px) {





.is-support .premium-box .list-dot li {
                    font-size: 1.8rem
            }
                }





.is-support .premium-box .list-dot li:last-child {
                    padding-bottom: 0;
                }





.is-support .accident-contact .service-link .arrow-right {
                -webkit-transform: rotate(90deg);
                        transform: rotate(90deg);
            }





.is-support .service-box {
        background-color: #FAF8EF;
        padding: 20px;
        margin-top: 30px;

    }





@media(min-width:768px) {





.is-support .service-box {
            padding: 40px;
            margin-top: 50px

    }
        }





.is-support .service-box .heading-text-medium {
            text-align: center;
        }





.is-support .service-box .inner {
            background-color: #fff;
            margin-top: 20px;
            padding: 20px;
        }





@media(min-width:768px) {





.is-support .service-box .inner {
                margin-top: 40px;
                padding: 40px
        }
            }





.is-support .service-box .inner .text-parts-small {
                color: #222222;
            }





.is-support .service-box .inner .l-list {
                margin-top: 5px;
            }





@media(min-width:992px) {





.is-support .service-box .inner .l-list {
                    display: flex
            }

                }





.is-support .service-box .inner .l-list li {
                    margin-bottom: 10px;
                    font-size: 1.4rem;
                }





@media(min-width:768px) {





.is-support .service-box .inner .l-list li {
                        font-size: 1.8rem
                }
                    }





@media(min-width:992px) {





.is-support .service-box .inner .l-list li {
                        margin-bottom: 0;
                        margin-right: 15px
                }
                    }





@media(min-width:1200px) {





.is-support .service-box .inner .l-list li {
                        margin-right: 40px
                }
                    }





.is-support .service-box .inner .l-list li.last-child {
                        margin-right: 0;
                    }





.is-support .service-tel-contact {
        border-top: 1px solid #D1D1D1;
        margin-top: 30px;

    }





@media(min-width:768px) {





.is-support .service-tel-contact {
            margin-top: 50px

    }
        }





.is-support .service-tel-contact .group {
            border-bottom: 1px solid #D1D1D1;
            padding: 20px 0;
        }





@media(min-width:768px) {





.is-support .service-tel-contact .group {
                padding: 30px 0
        }
            }





@media(min-width:992px) {





.is-support .service-tel-contact .group {
                display: flex
        }
            }





.is-support .service-tel-contact .group .left {
                padding-bottom: 10px;
            }





@media(min-width:992px) {





.is-support .service-tel-contact .group .left {
                    padding-bottom: 0;
                    padding-top: 10px;
                    width: 40%
            }
                }





@media(min-width:1200px) {





.is-support .service-tel-contact .group .left {
                    width: 520px
            }
                }





.is-support .service-tel-contact .group .heading {
                font-size: 1.7rem;
                font-weight: bold;
            }





@media(min-width:768px) {





.is-support .service-tel-contact .group .heading {
                    font-size: 2.4rem
            }
                }





.is-support .service-tel-contact .group .heading span {
                    margin-top: 5px;
                    display: block;
                    font-weight: normal;
                }





.is-support .service-tel-contact .group .telephone {
                font-size: 14px;
                margin-top: 5px;

            }





@media(min-width:768px) {





.is-support .service-tel-contact .group .telephone {
                    font-size: 1.8rem;
                    margin-top: 10px

            }
                }





.is-support .service-tel-contact .group .telephone .icon {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                }





@media(min-width:768px) {





.is-support .service-tel-contact .group .telephone .icon {
                        width: 26px;
                        height: 26px
                }
                    }





.is-support .service-tel-contact .group .telephone .number {
                    font-family: 'Roboto', sans-serif;
                    font-weight: bold;
                    font-size: 26px;
                }





@media(min-width:768px) {





.is-support .service-tel-contact .group .telephone .number {
                        font-size: 32px
                }
                    }





@media(min-width:768px) {





.is-support .adr-column {
            display: flex;
            justify-content: space-between


    }
        }





@media(min-width:768px) {





.is-support .adr-column .left {
                width: 56%
        }
            }





@media(min-width:1200px) {





.is-support .adr-column .left {
                width: 625px
        }
            }





.is-support .adr-column .right {
            width: 100%;
            text-align: center;
            margin: 20px auto 0;
        }





@media(min-width:768px) {





.is-support .adr-column .right {
                margin: 0;
                width: 39%
        }
            }





@media(min-width:1200px) {





.is-support .adr-column .right {
                width: 435px
        }
            }





.is-support .adr-column a {
            text-decoration: underline;
            color: #275EAC;

        }





@media(min-width:992px) {
                .is-support .adr-column a:hover {
                    text-decoration: none;
                }
            }





.is-support .adr-column2 {
        margin-top: 30px;


    }





@media(min-width:768px) {





.is-support .adr-column2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 60px


    }
        }





@media(min-width:768px) {





.is-support .adr-column2 .left {
                width: 856px
        }
            }





.is-support .adr-column2 .right {
            margin: 20px auto 0;
            width: 70%;
        }





@media(min-width:768px) {





.is-support .adr-column2 .right {
                margin: 0;
                width: 200px
        }
            }





.is-support .adr-column2 .heading-text-medium {
            margin-bottom: 5px;
        }





@media(min-width:768px) {





.is-support .adr-column2 .heading-text-medium {
                margin-bottom: 10px
        }
            }





.is-support .adr-column2 a {
            text-decoration: underline;
            color: #275EAC;

        }





@media(min-width:992px) {
                .is-support .adr-column2 a:hover {
                    text-decoration: none;
                }
            }





.is-support .adr-cole-box {
        text-align: center;
        margin-top: 20px;
        position: relative;
    }





@media(min-width:768px) {





.is-support .adr-cole-box {
            margin-top: 40px
    }
        }





.is-support .adr-cole-box:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 17px 11px 0 11px;
            border-color: #E69497 transparent transparent transparent;
            margin-left: auto;
            margin-right: auto;

        }





@media(min-width:768px) {





.is-support .adr-cole-box:after {
                border-width: 34px 23px 0 23px

        }
            }





.is-support .adr-box {
        background-color: #FAF8EF;
        margin-top: 15px;
        padding: 15px;
    }





@media(min-width:768px) {





.is-support .adr-box {
            margin-top: 25px;
            padding: 40px
    }
        }





.is-support .adr-box .inner {
            background-color: #fff;
            padding: 15px;
        }





@media(min-width:768px) {





.is-support .adr-box .inner {
                padding: 30px
        }
            }





.is-support .adr-box .m-name {
            border-bottom: 1px solid #BBBBBB;
            padding-bottom: 5px;
            margin-bottom: 15px;

        }





@media(min-width:768px) {





.is-support .adr-box .m-name {
                margin-bottom: 20px

        }
            }





@media(min-width:992px) {





.is-support .adr-box .m-name {
                display: flex;
                align-items: flex-end

        }
            }





.is-support .adr-box .m-name span {
                display: block;
            }





.is-support .adr-box .m-name .heading-top-medium {
                margin-bottom: 0;
            }





@media(min-width:992px) {





.is-support .adr-box .m-name .heading-top-medium {
                    margin-right: 20px
            }
                }





@media(min-width:1200px) {





.is-support .adr-box .m-name .heading-top-medium {
                    margin-right: 40px
            }
                }





.is-support .adr-box .l-dial {
            margin-bottom: 10px;
        }





@media(min-width:768px) {





.is-support .adr-box .l-dial {
                display: flex;
                margin-bottom: 10px
        }
            }





.is-support .adr-box .l-dial dt {
                font-size: 1.4rem;
                font-weight: bold;
                margin-bottom: 10px;
            }





@media(min-width:768px) {





.is-support .adr-box .l-dial dt {
                    margin-bottom: 0;
                    padding-top: 10px;
                    width: 210px;
                    font-size: 1.8rem
            }
                }





.is-support .adr-box .l-dial dd {
                font-family: 'Roboto', sans-serif;
                font-weight: bold;
                font-size: 2.4rem;
            }





@media(min-width:768px) {





.is-support .adr-box .l-dial dd {
                    font-size: 3.2rem
            }
                }





.is-support .adr-box .l-dial dd .icon {
                    vertical-align: middle;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: -2px;
                }





@media(min-width:768px) {





.is-support .adr-box .l-dial dd .icon {
                        width: 26px;
                        height: 26px
                }
                    }





.is-support .adr-box .text {
            margin-top: 10px;
            font-size: 1.2rem;
            line-height: 1.5;
        }





@media(min-width:768px) {





.is-support .adr-box .text {
                font-size: 1.6rem
        }
            }





.is-support .adr-box2 {
        background-color: #FAF8EF;
        padding: 15px;
        margin-top: 15px;

    }





@media(min-width:768px) {





.is-support .adr-box2 {
            padding: 40px;
            margin-top: 50px

    }
        }





.is-support .adr-box2 .heading-text-medium {
            text-align: center;
        }





@media(min-width:768px) {





.is-support .adr-box2 .inner {
                display: flex;
                justify-content: space-between
        }
            }





.is-support .adr-box2 .column {
            background-color: #fff;
            padding: 15px;
            margin-bottom: 15px;

        }





@media(min-width:768px) {





.is-support .adr-box2 .column {
                margin-bottom: 0;
                width: 495px;
                padding: 30px

        }
            }





.is-support .adr-box2 .column:last-child {
                margin-bottom: 0;
            }





.is-support .adr-box2 .column .text-parts-1 {
                margin-bottom: 10px;
            }





@media(min-width:768px) {





.is-support .adr-box2 .column .text-parts-1 {
                    margin-bottom: 20px
            }
                }





.is-support .adr-box2 .column .text-parts-1.last-child {
                    margin-bottom: 0;
                }

.spacer-medium .list-dot:not(:first-child) {
        margin-top: 20px;
    }

.card-red-heading .text-sm {
        font-size: 12px;
    }

@media(min-width:992px) {

.card-red-heading .text-sm {
            font-size: 16px
    }
        }

.card-red-contact .inner {
        display: flex;
        flex-direction: column-reverse;
        gap: 0px;
        align-items: center;
    }

@media(min-width:1200px) {

.card-red-contact .inner {
            gap: 40px;
            flex-direction: row
    }
        }

.l-tag-list {
    background-color: #F7F7F7;
    padding: 15px 15px 5px;



}

@media(min-width:768px) {

.l-tag-list {
        display: flex;
        padding: 30px 30px 20px



}
    }

.l-tag-list .l-left {
        padding-bottom: 5px;
    }

@media(min-width:768px) {

.l-tag-list .l-left {
            padding-bottom: 0;
            width: 7%;
            padding-top: 8px
    }
        }

.l-tag-list .l-left p {
            font-size: 1.6rem;
        }

@media(min-width:768px) {

.l-tag-list .l-left p {
                font-size: 1.8rem
        }
            }

.l-tag-list .l-right {
        font-size: 0;
    }

@media(min-width:768px) {

.l-tag-list .l-right {
            width: 93%
    }
        }

.l-tag-list .l-right a {
            display: inline-block;
            background-color: #fff;
            border-radius: 2px;
            border: 1px solid #454545;
            text-align: center;
            color: #454545;
            padding: 6px 8px;
            font-size: 14px !important;
            margin-right: 5px;
            margin-bottom: 5px;
        }

@media(min-width:768px) {

.l-tag-list .l-right a {
                padding: 7px 10px;
                font-size: 15px !important;
                margin-right: 10px;
                margin-bottom: 10px
        }
            }

@media(min-width:992px) {

.l-tag-list .l-right a {
                transition: all .2s
        }
                .l-tag-list .l-right a:hover {
                    background-color: #454545;
                    color: #fff;
                }

            }


.post-list {
    padding-bottom: 40px;

}


@media(min-width:768px) {


.post-list {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 80px

}
    }


.post-list .card-post-thumbnail {
        width: 100%;
        margin-bottom: 30px;
    }


@media(min-width:768px) {


.post-list .card-post-thumbnail {
            width: 32%;
            margin-right: 1.5%;
            margin-bottom: 1.5%
    }
        }


@media(min-width:1200px) {


.post-list .card-post-thumbnail {
            width: 338px;
            margin-right: 43px;
            margin-bottom: 40px
    }
        }


.post-list .card-post-thumbnail:nth-child(3n) {
            margin-right: 0;
        }

@media(min-width:768px) {

        }

.is-policy .policy-box .column {
            margin-bottom: 15px;
        }

.is-policy .policy-box .heading-text-medium {
            margin-bottom: 10px;
        }

@media(min-width:768px) {

.is-policy .policy-box .heading-text-medium {
                margin-bottom: 20px
        }
            }

.l-sitemap a:hover {
        text-decoration: underline;
    }

.l-sitemap h2 a {
            color: #222222;
        }

.l-sitemap h2 a .arrow-right {
                width: 20px;
                height: 20px;
            }

.l-sitemap div.group {
        margin-bottom: 50px;
    }

.l-sitemap div.group ul li {

                font-size: 1.4rem;
                margin-bottom: 20px;
                line-height: 1.6;
            }

.l-sitemap div.group ul li a {
                    color: #222222;
                }

.l-sitemap div.group ul li a .arrow-right {
                        width: 11px;
                        height: 11px;
                    }

.l-404 div.box-beginning {
        margin-bottom: 90px;
    }

.l-404 div.box-beginning p.text-box {
            margin-top: 0;
        }

@media(min-width:768px) {
                .l-sitemap div.group ul li {
                    font-size: 1.8rem;
                }
}

@media(min-width:992px) {
            .l-sitemap div.group ul {
                display: flex;
                flex-wrap: wrap;
            }

                .l-sitemap div.group ul li {
                    width: 33.33333%;
                    font-size: 1.8rem;
                }
}

.is-disaster .disaster-box {
        margin-bottom: 20px;
    }

@media(min-width:768px) {

.is-disaster .disaster-box {
            margin-bottom: 0;
            display: flex;
            justify-content: space-between
    }
        }

@media(min-width:768px) {

.is-disaster .disaster-box .left {
                width: 700px
        }
            }

.is-disaster .disaster-box .right {
            width: 70%;
            margin: 20px auto 0;
        }

@media(min-width:768px) {

.is-disaster .disaster-box .right {
                margin: 0;
                width: 340px
        }
            }

.is-disaster .disaster-title {
        font-size: 2.2rem;
        line-height: 1.25;
        font-weight: bold;
        margin-bottom: 20px;
        background-color: #029162;
        color: #fff;
        padding: 5px 10px;
    }

@media(min-width:768px) {

.is-disaster .disaster-title {
            padding: 10px 20px;
            font-size: 3.0rem;
            margin-bottom: 30px
    }
        }

.is-disaster .disaster-question {
        margin-bottom: 25px;
        display: flex;
    }

@media(min-width:768px) {

.is-disaster .disaster-question {
            margin-bottom: 30px
    }
        }

.is-disaster .disaster-question:last-child {
            margin-bottom: 0;
        }

.is-disaster .disaster-question label {
            border: 1px solid #029162;
            width: 70px;
            height: 110px;
            padding: 0 0 20px;
            cursor:pointer;
            margin-right: 10px;
            background-color: #fff;

        }

@media(min-width:768px) {

.is-disaster .disaster-question label {
                margin-right: 20px;
                height: auto;
                width: 90px;
                padding: 0 0 20px

        }
            }

@media(min-width:992px) {

.is-disaster .disaster-question label {
                width: 70px;
                margin-right: 40px

        }

            }

.is-disaster .disaster-question label span {
                background-color: #029162;
                display: inline-block;
                text-align: center;
                padding: 20px 10px;
                color: #fff;
                font-weight: bold;
                display: block;
                font-family: 'Roboto', sans-serif;
                font-size: 1.8rem;
            }

.is-disaster .disaster-question label i.icon {
                margin: 15px auto 0;
                display: block;
            }

.is-disaster .disaster-question .text {
            width: 100%;
            padding-top: 10px;
            line-height: 1.5;
        }

@media(min-width:768px) {

.is-disaster .disaster-question .text {
                width: 980px
        }
            }

@media(min-width:992px) {

.is-disaster .disaster-question .text {
                padding-top: 20px
        }
            }

.is-disaster .disaster-question .text h4 {
                font-size: 1.6rem;
                margin-bottom: 5px;
                font-weight: bold;
            }

@media(min-width:768px) {

.is-disaster .disaster-question .text h4 {
                    font-size: 2rem;
                    margin-bottom: 10px
            }
                }

.is-disaster .disaster-question .text p {
                font-size: 1.4rem;
            }

@media(min-width:768px) {

.is-disaster .disaster-question .text p {
                    font-size: 1.8rem
            }
                }

.is-disaster .disaster-btn-list {
        margin-top: 10px;
    }

@media(min-width:768px) {

.is-disaster .disaster-btn-list {
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center
    }

        }

.is-disaster .disaster-btn-list button {
            margin: 0 auto 20px;
        }

@media(min-width:768px) {

.is-disaster .disaster-btn-list button {
                margin: 0 10px
        }
            }

.is-disaster .disaster-result {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        background-color: #98cb00;
        max-width: 900px;
        padding: 15px;
        border-radius: 6px;



    }

@media(min-width:768px) {

.is-disaster .disaster-result {
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
            padding: 20px



    }
        }

.is-disaster .disaster-result .left {
            width: 20%;
            margin: auto;
        }

@media(min-width:768px) {

.is-disaster .disaster-result .left {
                width: 20%;
                margin: 0
        }
            }

.is-disaster .disaster-result .right {
            background-color: #fff;
            padding: 20px 15px;
            font-size: 1.6rem;
            line-height: 1.75;
            margin-top: 10px;
        }

@media(min-width:768px) {

.is-disaster .disaster-result .right {
                margin-top: 0;
                padding: 20px;
                width: 80%;
                font-size: 1.8rem
        }
            }

@media(min-width:768px) {

.is-disaster .disaster-maemii {
            display: flex;
            justify-content: space-between
    }
        }

.is-disaster .disaster-maemii .left {
            width: 300px;
            margin: auto;
        }

@media(min-width:768px) {

.is-disaster .disaster-maemii .left {
                width: 27%;
                margin: 0
        }
            }

@media(min-width:1200px) {

.is-disaster .disaster-maemii .left {
                width: 300px
        }
            }

.is-disaster .disaster-maemii .right {
            padding-top: 10px;
        }

@media(min-width:768px) {

.is-disaster .disaster-maemii .right {
                width: 68%;
                padding-top: 0
        }
            }

@media(min-width:1200px) {

.is-disaster .disaster-maemii .right {
                width: 750px
        }
            }

.is-disaster .disaster-img-box-list {
        display: flex;
        flex-wrap:wrap;
        justify-content: space-between;
    }

@media(min-width:768px) {

.is-disaster .disaster-img-box-list {
            justify-content: flex-start
    }
        }

.is-disaster .disaster-img-box {
        padding:20px 15px;
        background-color: #fff;
        width: 48%;
        margin-bottom: 4%;

    }

@media(min-width:768px) {

.is-disaster .disaster-img-box {
            padding: 30px 20px;
            margin-right: 2%;
            margin-bottom: 2%;
            width: 32%

    }

        }

.is-disaster .disaster-img-box:nth-child(3) {
            margin-right: 0;
        }

.is-disaster .disaster-img-box img {
            display: block;
            margin: auto;
        }

.is-disaster .disaster-img-box .text-parts-1 {
            margin-top: 5px;
        }

@media(min-width:768px) {

.is-disaster .disaster-img-box .text-parts-1 {
                margin-top: 10px
        }
            }

.is-disaster .disaster-img {
        margin-top: 10px;
        margin-bottom: 10px;
    }

@media(min-width:768px) {

.is-disaster .disaster-img {
            margin-top: 20px;
            margin-bottom: 20px
    }
        }

.is-disaster .box-table {
        background-color: #fff;
        padding: 10px;
    }

@media(min-width:768px) {

.is-disaster .box-table {
            padding: 20px
    }
        }

.is-disaster .li-large {
        font-size: 1.8rem;
        font-weight: bold;
    }

.is-disaster .tab-2 .list-menu .inline-flex {
                display: inline-flex;
            }

.is-shimaeco .recycling-img {
        width: 60px;
        margin-right: 0;
        margin-left: auto;
    }

@media(min-width:768px) {

.is-shimaeco .recycling-img {
            width: 120px
    }
        }

.is-shimaeco .activity-box {
        background-color: #FAF8EF;
        padding: 20px 15px;
    }

@media(min-width:768px) {

.is-shimaeco .activity-box {
            padding: 40px
    }
        }

.is-shimaeco .activity-box .inner div:first-child {
            margin-bottom: 25px;
        }

@media(min-width:768px) {

.is-shimaeco .activity-box .inner div:first-child {
                margin-bottom: 50px
        }
            }

.is-shimaeco .activity-box p.text-parts-1 {
            margin-bottom: 15px;
        }

@media(min-width:768px) {

.is-shimaeco .activity-box p.text-parts-1 {
                margin-bottom: 30px
        }
            }

.is-shimaeco .activity-box p.text-parts-1 a {
                color: #275EAC;
                text-decoration: underline;
            }

@media(min-width:992px) {
                    .is-shimaeco .activity-box p.text-parts-1 a:hover {
                        text-decoration: none;
                    }
                }

.is-shimaeco .activity-box p.text-parts-1 a icon.window {
                    fill: #275EAC;
                }

.is-shimaeco .activity-box p.text-parts-1.mb-30 {
                margin-bottom: 30px;
            }

.is-shimaeco .activity-box p.text-parts-small {
            margin-bottom: 30px;
        }

.is-shimaeco .corporate-card-list {
        margin-bottom: 50px;
        margin-top: 40px;
    }

@media(min-width:768px) {

.is-shimaeco .corporate-card-list {
            margin-top: 80px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 110px
    }
        }

.is-shimaeco .corporate-card-list li {
            margin-bottom: 4%;
        }

@media(min-width:768px) {

.is-shimaeco .corporate-card-list li {
                width: 32%
        }
            }

@media(min-width:1200px) {

.is-shimaeco .corporate-card-list li {
                width: 530px;
                margin-bottom: 40px
        }
            }

.is-shimaeco .corporate-card-list .corporate-card {
            display: block;
            color: #222222;

        }

@media(min-width:992px) {
                        .is-shimaeco .corporate-card-list .corporate-card:hover .img:before {
            				opacity:0.2;
            			}
                    .is-shimaeco .corporate-card-list .corporate-card:hover .text {
                        color: #CA101A;
                    }
            }

.is-shimaeco .corporate-card-list .corporate-card .img {
                position: relative;
            }

.is-shimaeco .corporate-card-list .corporate-card .img:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    opacity: 0;
                    transition: all .2s;
                }

.is-shimaeco .corporate-card-list .corporate-card .text {
                font-size: 1.4rem;
                margin-top: 10px;
                display: block;
                line-height: 1.5;

            }

@media(min-width:768px) {

.is-shimaeco .corporate-card-list .corporate-card .text {
                    font-size: 2rem;
                    margin-top: 15px

            }
                }

@media(min-width:992px) {

.is-shimaeco .corporate-card-list .corporate-card .text {
                    font-size: 2.2rem

            }
                }

@media(min-width:992px) {

.is-shimaeco .corporate-card-list .corporate-card .text {
                    transition: all .2s

            }
                }

.is-shimaeco .corporate-card-list .corporate-card .icon {
                width: 10px;
                height: 10px;
                vertical-align: middle;
                position: relative;
                top: -2px;
            }

@media(min-width:768px) {

.is-shimaeco .corporate-card-list .corporate-card .icon {
                    width: 16px;
                    height: 16px;
                    top: -1px
            }
                }

.is-shanaiho .year-list {
        background-color: #F7F7F7;
        border-radius: 4px;
        display: flex;
        width: 100%;
        padding: 20px 0;
        margin-top: 25px;


    }

@media(min-width:768px) {

.is-shanaiho .year-list {
            padding: 25px 0;
            margin-top: 40px


    }
        }

.is-shanaiho .year-list li {
            border-right: 2px solid #D1D1D1;
            border-radius: 2px;
            text-align: center;
            width: 20%;
            padding: 3px 0;
        }

@media(min-width:768px) {

.is-shanaiho .year-list li {
                width: 150px;
                padding: 6px 0
        }
            }

.is-shanaiho .year-list li:last-child {
                border-right: none;
            }

.is-shanaiho .year-list a {
            color: #222222;
            font-size: 1.6rem;
        }

@media(min-width:768px) {

.is-shanaiho .year-list a {
                font-size: 18px
        }
            }

.is-shanaiho .shnaiho-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

.is-shanaiho .shnaiho-list .shanaiho-content {
            margin-bottom: 40px;
        }

@media(min-width:768px) {

.is-shanaiho .shnaiho-list .shanaiho-content {
                width: 49%;
                margin-bottom: 50px
        }
            }

@media(min-width:1200px) {

.is-shanaiho .shnaiho-list .shanaiho-content {
                width: 530px
        }
            }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-title {
                font-size: 18px;
                margin-bottom: 15px;
            }

@media(min-width:768px) {

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-title {
                    margin-bottom: 20px;
                    font-size: 2.4rem
            }
                }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
            }

@media(min-width:768px) {

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info {
                    margin-bottom: -40px
            }
                }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-shanaiho-link-area {
                    width: 25%;
                    margin: 0 auto;

                }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-shanaiho-link-area a {
                        display: block;
                        font-size: 1.6rem;
                        color: #275eac;
                        line-height: 1.25;
                    }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-shanaiho-link-area a img {
                            background-color: #f7f7f7;
                        }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-shanaiho-link-area a figcaption {
                            margin-top: 5px;
                        }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-shanaiho-link-area a .icon {
                            width: 16px;
                            height: 16px;
                            vertical-align: middle;
                        }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-info {
                    padding-left: 20px;
                    width: 75%;
                    box-sizing: border-box;
                }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-info .shanaiho-topics-title {
                        font-size: 18px;
                        margin-bottom: 15px;
                    }

@media(min-width:768px) {

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-info .shanaiho-topics-title {
                            font-size: 2.4rem
                    }
                        }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-info hr {
                        border: 0;
                        border-top: 1px dashed #D1D1D1;
                    }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-info .topic-text {
                        word-break: break-all;
                    }

@media(min-width:768px) {

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-info .topic-text {
                            font-size: 1.6rem
                    }
                        }

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-info .topic-other-text {
                        word-break: break-all;
                    }

@media(min-width:768px) {

.is-shanaiho .shnaiho-list .shanaiho-content .shanaiho-info .pdf-info .topic-other-text {
                            font-size: 1.6rem
                    }
                        }


.is-rode-service .area-group .l-list {
            border-top: 1px solid #D1D1D1;
        }


.is-rode-service .area-group .l-list li {
                line-height: 1.5;
                border-bottom: 1px solid #D1D1D1;
                padding: 15px 0;
            }


@media(min-width:768px) {


.is-rode-service .area-group .l-list li {
                    display: flex;
                    padding: 20px 0
            }
                }


.is-rode-service .area-group .l-list li .l-name {
                    position: relative;


                }


@media(min-width:768px) {


.is-rode-service .area-group .l-list li .l-name {
                        width: 40%;
                        padding-left: 158px;
                        padding-right: 20px;
                        padding-top: 10px


                }
                    }


.is-rode-service .area-group .l-list li .l-name .name {
                        font-size: 1.4rem;
                    }


@media(min-width:768px) {


.is-rode-service .area-group .l-list li .l-name .name {
                            font-size: 1.8rem
                    }
                        }


.is-rode-service .area-group .l-list li .l-name a {
                        color: #275EAC;
                    }


@media(min-width:992px) {


.is-rode-service .area-group .l-list li .l-name .premium-link {
                            transition: all .2s
                    }
                            .is-rode-service .area-group .l-list li .l-name .premium-link:hover{
                                opacity:0.8;
                            }
                        }


.is-rode-service .area-group .l-list li .address,
                .is-rode-service .area-group .l-list li .department {
                    font-size: 1.4rem;
                    color: #5C5C5C;
                    padding-top: 5px;
                }


@media(min-width:768px) {


.is-rode-service .area-group .l-list li .address,
                .is-rode-service .area-group .l-list li .department {
                        padding-top: 0;
                        width: 60%;
                        padding-right: 20px;
                        font-size: 1.6rem
                }
                    }


@media(min-width:768px) {


.is-rode-service .area-group .l-list.store li {
                        justify-content: space-between



                }
                    }


@media(min-width:768px) {


.is-rode-service .area-group .l-list.store li .l-name {
                            width: 30%;
                            padding-left: 0
                    }
                        }


@media(min-width:768px) {


.is-rode-service .area-group .l-list.store li .department {
                            padding-top: 10px;
                            width: 20%;
                            padding-right: 0
                    }
                        }


@media(min-width:768px) {


.is-rode-service .area-group .l-list.store li .address {
                            width: 50%;
                            padding-right: 0
                    }
                        }

.is-seiho .automobile_liability_insurance-box {
        background-color: #FAF8EF;
        padding: 20px 15px;
        margin-top: 30px;
    }

@media(min-width:768px) {

.is-seiho .automobile_liability_insurance-box {
            padding: 40px;
            margin-top: 60px
    }
        }

.is-seiho .automobile_liability_insurance-box .inner {
            background-color: #fff;
            padding: 20px 15px;
        }

@media(min-width:768px) {

.is-seiho .automobile_liability_insurance-box .inner {
                padding: 40px
        }
            }

.is-seiho .box-beginning {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

@media(max-width:768px) {

.is-seiho .box-beginning {
            flex-direction: column;
            gap: 20px
    }

        }

.is-seiho .box-beginning .left {
            width: 30%;
            margin: 0;
        }

@media(max-width:768px) {

.is-seiho .box-beginning .left {
                width: 100%;
                text-align: center
        }
            }

.is-seiho .box-beginning .right {
            width: 68%;
            margin: 0;
        }

@media(max-width:768px) {

.is-seiho .box-beginning .right {
                width: 100%
        }
            }

@media(max-width:768px) {

.is-seiho .list-column-3 .img {
                width: 60%
        }
            }

.is-one_jibai .heading-huge .content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
    }

.is-one_jibai .banner-one_jibai {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }

.center {
    text-align: center;
}

.text-box {
    width: 100%;
    font-size: 1.4rem;
    padding: 20px 15px;
    line-height: 1.75;
    border: 1px solid #d1d1d1;
}

.text-box {
    width: 100%;
    font-size: 1.4rem;
    padding: 20px 15px;
    line-height: 1.75;
    border: 1px solid #D1D1D1;
    margin: 20px auto ;
}

@media(min-width:768px) {

.text-box {
        font-size: 1.8rem;
        margin: 25px auto;
        padding: 25px 30px
}
    }

.text-box .insurance-name {
        margin-right:100px;
    }

.text-box .insurance-start-date {
        display: inline-block;
    }

.smartphonestep {
    width: 80%;
}

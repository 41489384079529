.l-contact-center {
    border-style: solid;
    border-color: $color-border;
    border-top-width: 1px;
    padding: 30px 0 30px;
    background-color: $color-bg-yellow;
    @media(min-width:$screen-sm-min) {
        text-align: center;
        padding: 50px 0;
    }






}

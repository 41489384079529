.l-post-nav {
    background-color: $color-bg;
    padding: 15px 20px;
    margin-bottom: 40px;
    @media(min-width:$screen-sm-min) {
        display: flex;
        align-items: center;
        padding: 20px 15px;
        margin-bottom: 80px;
    }
    @media(min-width:$screen-md-min) {
        padding: 20px 70px;
    }
    .post-nav-group {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        @media(min-width:$screen-sm-min) {
            margin-right: 10px;
            margin-bottom: 0;
        }
        @media(min-width:$screen-md-min) {
            margin-right: 50px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    .title {
        font-size: 1.6rem;
        padding-right: 10px;
        display: inline-block;
        width: 50px;
        @media(min-width:$screen-sm-min) {
            font-size: 1.8rem;
            width: 80px;
        }
    }
}


.post-area {
    padding-bottom: 50px;
    @media(min-width:$screen-sm-min) {
        padding-bottom: 140px;
    }
    .post-group {
        border-style: dashed;
        border-color: $color-border-frame;
        border-bottom-width: 1px;
        li {
            border-style: dashed;
            border-color: $color-border-frame;
            border-top-width: 1px;
            padding: 15px 5px 10px;
            @media(min-width:$screen-sm-min) {
                padding: 25px 10px;
            }
        }
        a {
            @media(min-width:$screen-md-min) {}
                &:hover {
                    .title {
                        color: $color-text-hover;
                    }
                }
        }
    }

    .content {
        @media(min-width:$screen-sm-min) {
            display: flex;
            align-items: flex-start;
        }

    }

    .date-area {
        display: flex;
        align-items: center;
        @media(min-width:$screen-sm-min) {
            width: 40%;
        }
        @media(min-width:$screen-md-min) {
            width: 30%;
        }
        @media(min-width:$screen-lg-min) {
            width: 26.86%;
        }
        .date {
            color: $color-text-sub1;
            font-size: 1.6rem;
            font-family: $font-number;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
            }
        }
        .badge-group {
            display: block;
            width: 90px;
            margin-left: 10px;
            @media(min-width:$screen-sm-min) {
                width: 136px;
                margin-left: 30px;
            }
        }
    }

    .title {
        display: inline-block;
        color: $color-base;
        line-height:1.5;
        font-size: 1.6rem;
        margin-top: 5px;
        @media(min-width:$screen-sm-min) {
            margin-top: 0;
            font-size: 1.8rem;
            width: 60%;
        }
        @media(min-width:$screen-md-min) {
            width: 70%;
        }
        @media(min-width:$screen-lg-min) {
            width: 73.14%;
        }
    }





}


.article-content {
    margin-bottom: 40px;
    @media(min-width:$screen-sm-min) {
        margin-bottom: 80px;
    }
    .article-header {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        @media(min-width:$screen-sm-min) {
            padding-bottom: 20px;
        }
    }
    .date {
        color: $color-text-sub1;
        font-size: 1.6rem;
        font-family: $font-number;
        @media(min-width:$screen-sm-min) {
            font-size: 2rem;
        }
    }

    .badge {
        min-width: 90px;
        margin-left: 10px;
        @media(min-width:$screen-sm-min) {
            min-width: 136px;
            margin-left: 20px;
        }
    }

    .title {
        font-size: 2.4rem;
        line-height: 1.25;
        border-style: solid;
        border-color: $color-border-frame;
        border-bottom-width: 1px;
        padding-bottom: 15px;
        font-weight: bold;
        @media(min-width:$screen-sm-min) {
            padding-bottom: 20px;
            font-size: 3.8rem;
        }
    }

    .tag-list {
        width: 100%;
        margin-top: 10px;
        @media(min-width:$screen-sm-min) {
            margin-top: 15px;
            display: table;
        }
        dt {
            color: #5C5C5C;
            font-size: 1.6rem;
            display: table-cell;
            vertical-align: top;
            padding-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                width: 60px;
                padding-top: 7px;
                padding-bottom: 0;
            }
        }
        dd {
            @media(min-width:$screen-sm-min) {
                display: table-cell;
                vertical-align: top;
            }
            span {
                display: inline-block;
                background-color: #fff;
                border-radius: 2px;
                border: 1px solid $color-text-sub1;
                text-align: center;
                color: $color-text-sub1;
                padding: 6px 8px;
                font-size: 14px !important;
                margin-right: 5px;
                margin-bottom: 5px;
                @media(min-width:$screen-sm-min) {
                    padding: 7px 10px;
                    font-size: 15px !important;
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }


    .container {

        padding-top: 25px;
        margin-bottom: 40px;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 50px;
            padding-top: 45px;
        }
        p {
            font-size: 1.6rem;
            margin-bottom: 30px;
            line-height: 1.75;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
                margin-bottom: 45px;
            }
        }
        .wp-caption-text {
            font-size: 1.4rem;
            padding-top: 5px;
            color: #5C5C5C;
            @media(min-width:$screen-sm-min) {
                font-size: 1.6rem;
                padding-top: 10px;
            }
        }
        .wp-caption {
            max-width: 100% !important;
        }

        a {
            color: $color-text-hover;
            text-decoration: underline;
            @media(min-width:$screen-md-min) {
                &:hover {
                    text-decoration: none;
                }
            }
        }

        h2 {
            font-size: 2.2rem;
            padding-left: 10px;
            margin-bottom: 25px;
            position: relative;
            font-weight: bold;
            line-height: 1.25;
            padding-top: 5px;
            @media(min-width:$screen-sm-min) {
                font-size: 3.6rem;
                padding-left: 25px;
                margin-bottom: 45px;
            }

            &:before {
                content: '';
                display: block;
                width: 3px;
                height: 80%;
                background-color: $color-red;
                border-radius: 4px;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                @media(min-width:$screen-sm-min) {
                    width: 6px;
                    /* height: 36px; */

                }

            }
        }

        h3 {
            font-size: 2rem;
            margin-bottom: 10px;
            line-height: 1.5;
            font-weight: bold;
            @media(min-width:$screen-sm-min) {
                font-size: 2.6rem;
                margin-bottom: 20px;
            }
        }


        img {
            max-width: 100%;
            height: auto;
        }

        .iframe {
            width: 100%;
        }
        b,strong {
            font-weight: bold;
        }



        .mtli_pdf {
            background-size: 16px !important;
            background-image: url(../img/common/post-pdf.png);
            background-size: cover;
            background-position: right center;
            display: inline;
            padding-right: 20px !important;
            @media(min-width:$screen-sm-min) {
                background-size: 20px !important;
                padding-right: 25px !important;
                padding-top: 2px;
            }
        }


        ul,ol {
            li {
                padding-bottom: 5px;
                line-height: 1.5;
                @media(min-width:$screen-sm-min) {
                }
            }
        }

        ol {
            list-style-type: decimal
        }

        ul {
            list-style-type: disc;
        }





    }







}

.article-nav-area {
    padding-top: 30px;
    padding-bottom: 60px;
    border-style: solid;
    border-color: $color-border-frame;
    border-top-width: 1px;
    @media(min-width:$screen-sm-min) {
        padding-top: 60px;
        padding-bottom: 120px;
    }


    .btn-round {
        margin-left: auto;
        margin-right: auto;
        @media(min-width:$screen-sm-min) {
        }
    }
    .article-nav {
        position: relative;
        padding-top: 50px;
        @media(min-width:$screen-sm-min) {
            padding-top: 0;
        }
        .link {
            font-size: 1.3rem;
            color: $color-red;
            position: absolute;
            top: 0;
            @media(min-width:$screen-sm-min) {
                top: 22px;
                font-size: 1.8rem;
            }
            .icon {
                width: 11px;
                height: 11px;
                @media(min-width:$screen-sm-min) {
                    width: 13px;
                    height: 13px;
                }
            }
            &.link-prev {
                left: 0;
                .icon {
                    margin-right: 3px;
                    @media(min-width:$screen-sm-min) {
                        margin-right: 6px;
                    }
                }
            }
            &.link-next {
                right: 0;
                .icon {
                    margin-left: 3px;
                    @media(min-width:$screen-sm-min) {
                        margin-left: 6px;
                    }
                }
            }
        }



    }
}

.qa-box {
    line-height: 1.5;
    margin-bottom: 30px;
    @media(min-width:$screen-sm-min) {
        margin-bottom: 50px;
    }
    &:last-child {
        /* margin-bottom: 0; */
    }
    dt {
        font-size: 1.5rem;
        padding: 0;
        background-color: $color-bg;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
        padding: 15px 35px 15px 40px;
        font-weight: bold;
        @media(min-width:$screen-sm-min) {
            font-size: 2rem;
            padding: 25px 80px 25px 90px;
        }
        .question {
            width: 19px;
            height: 19px;
            line-height: 19px;
            text-align: center;
            font-family: $font-number;
            font-weight: bold;
            color: #fff;
            font-size: 1rem;
            border-radius: 50%;
            background-color: $color-red;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
                width: 40px;
                height: 40px;
                line-height: 40px;
            }
        }
    }
    dd {
        position: relative;
        font-size: 1.5rem;
        display: none;
        @media(min-width:$screen-sm-min) {
            font-size: 1.8rem;
        }
        .inner {
            padding: 15px 10px 0 40px;
            @media(min-width:$screen-sm-min) {
                padding: 25px 10px 0 90px;
            }
        }
        p {
            margin-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 20px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        b,strong {
            font-weight: bold;
        }

        ul,ol {
            li {
                padding-bottom: 5px;
                line-height: 1.5;
                @media(min-width:$screen-sm-min) {
                }
            }
        }

        ol {
            list-style-type: decimal
        }

        ul {
            list-style-type: disc;
        }


        a {
            color: $color-text-hover;
            @media(min-width:$screen-md-min) {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        a[target="_blank"] {
            color: $color-text-hover;
            text-decoration: underline;
            position: relative;
            padding-right: 20px;
            background-image: url(../icon/icon_window-blue.svg);
            background-repeat: no-repeat;
            background-size: 14px;
            background-position: right center;
            margin-right: 5px;
            @media(min-width:$screen-sm-min) {
                padding-right: 28px;
                background-size: 18px;
            }
            @media(min-width:$screen-md-min) {
                &:hover {
                    text-decoration: none;
                }
            }
        }

    }
    .question,
    .anser {
        position: absolute;
        top: 15px;
        left: 15px;
        @media(min-width:$screen-sm-min) {
            top: 20px;
            left: 20px;
        }
    }



    &.open {
        dt {
            cursor: default;

        }
        dd {
            display: block;
        }
    }

}

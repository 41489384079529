.is-seiho {
    .automobile_liability_insurance-box {
        background-color: $color-bg-yellow;
        padding: 20px 15px;
        margin-top: 30px;
        @media(min-width:$screen-sm-min) {
            padding: 40px;
            margin-top: 60px;
        }
        .inner {
            background-color: #fff;
            padding: 20px 15px;
            @media(min-width:$screen-sm-min) {
                padding: 40px;
            }
        }
    }
    .box-beginning {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media(max-width:$screen-sm-min) {
            flex-direction: column;
            gap: 20px;

        }

        .left {
            width: 30%;
            margin: 0;
            @media(max-width:$screen-sm-min) {
                width: 100%;
                text-align: center;
            }
        }
        .right {
            width: 68%;
            margin: 0;
            @media(max-width:$screen-sm-min) {
                width: 100%;
            }
        }
    }
    .list-column-3 {
        .img {
            @media(max-width:$screen-sm-min) {
                width: 60%;
            }
        }
    }
}

.badge {
    border-style: solid;
    border-color: #919BA8;
    color: #919BA8;
    border-width: 1px;
    font-size: 1.2rem;
    display: block;
    text-align: center;
    padding: 6px 5px;
    background-color: #fff;
    @media(min-width:$screen-sm-min) {
        font-size: 1.4rem;
    }
    &.recruit {
        border-color: #DB9356;
        color: #DB9356;
    }
    &.csr {
        border-color: #439D69;
        color: #439D69;
    }
    &.organization {
        border-color: #43779D;
        color: #43779D;
    }
    &.service {
        border-color: #39A0C9;
        color: #39A0C9;
    }
    &.disaster {
        border-color: #D87376;
        color: #D87376;
    }
}

.mainvisual {
    padding: 20px 0;
    min-height: 120px;

    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: #ffffff;

    .title {
        margin: 0;
        font-size: 2.4rem;
        display: block;
    }
    .subtitle {
        margin: 10px 0 0 0;
        display: block;
    }

    @media only screen and (min-width: 480px) {
        min-height: 150px;

        .title {
            font-size: 2.8rem;
        }
    }
    @media only screen and (min-width: 660px) {
        .title {
            font-size: 3.2rem;
        }
    }
    @media only screen and (min-width: 770px) {
        padding: 40px 0;
        min-height: 200px;

        .title {
            font-size: 3.6rem;
        }
    }
    @media only screen and (min-width: 1000px) {
        min-height: 250px;

        .title {
            font-size: 4.0rem;
        }
    }
}

.mainvisual {
    background-color: rgba(0, 0, 0, 0.2);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow:hidden;
    position: relative;

    body.module & {
        background-image: url(../img/module/mainvisual.jpg);
    }
    body.contact & {
        background-image: url(../img/contact/mainvisual.jpg);
    }
    body.error404 & {
        background-image: url(../img/error404/mainvisual.jpg);
    }

    body:not(.home) & > * {
        position: relative;
        z-index: 1000;
    }

    body:not(.home) &::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
    }
}



body.service {
    .main-header {

        background: #fff;
    }
}

body.case {
    .main-header {
        background-image: url(../img/case/case-main-header-bg.jpg);
    }
}
body.company {
    .main-header {
        background-image: url(../img/company/company-main-header-bg.jpg);
    }
}
body.contact,
body.confirm,
body.complete {
    .main-header {
        background-image: url(../img/contact/contact-main-header-bg.jpg);
    }
}

body {
    .main-header-child {
        background: ;
    }
}

/* WordPressの管理者用ヘッダー */
body.logged-in {
    #wpadminbar {
        position: fixed;
        top: 0;
    }
    header {
        top: 46px;

        .global-nav nav {
            top: calc(46px + $header-height-sp);
        }
    }
    .alert-group {
        top: calc(46px + $header-height-sp + 5px);
    }
}

@media only screen and (min-width: 660px) {

}
@media only screen and (min-width: 783px) {
    body.logged-in {
        header {
            top: 32px;

            .global-nav nav {
                top: calc(32px + $header-height-sp);
            }
        }
        .alert-group {
            top: calc(32px + $header-height-sp + 5px);
        }
    }

}
@media only screen and (min-width: 1000px) {
    /* body.customize-support header, */
    body.logged-in {
        header .global-nav nav {
            top: auto;
        }
        .alert-group {
            top: calc(32px + $header-height-pc + 5px);
        }
    }
}

/* WordPress管理画面対応 */
/*
html.global-nav-active {
    body.customize-support .toggle-content {
        max-height: calc(100vh - 46px - 70px);

        @media only screen and (min-width: 783px) {
            max-height: calc(100vh - 32px - 70px);
        }
    }
}
*/

// WordPress
.mw_wp_form {
    form .error {
        padding: 5px 10px;
        background-color: #ee9999;
        color: #ffffff;
    }
}

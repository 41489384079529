.l-tag-list {
    background-color: $color-bg;
    padding: 15px 15px 5px;
    @media(min-width:$screen-sm-min) {
        display: flex;
        padding: 30px 30px 20px;
    }
    .l-left {
        padding-bottom: 5px;
        @media(min-width:$screen-sm-min) {
            padding-bottom: 0;
            width: 7%;
            padding-top: 8px;
        }
        p {
            font-size: 1.6rem;
            @media(min-width:$screen-sm-min) {
                font-size: 1.8rem;
            }
        }
    }
    .l-right {
        font-size: 0;
        @media(min-width:$screen-sm-min) {
            width: 93%;
        }
        a {
            display: inline-block;
            background-color: #fff;
            border-radius: 2px;
            border: 1px solid $color-text-sub1;
            text-align: center;
            color: $color-text-sub1;
            padding: 6px 8px;
            font-size: 14px !important;
            margin-right: 5px;
            margin-bottom: 5px;
            @media(min-width:$screen-sm-min) {
                padding: 7px 10px;
                font-size: 15px !important;
                margin-right: 10px;
                margin-bottom: 10px;
            }
            @media(min-width:$screen-md-min) {
                transition: all .2s;
                &:hover {
                    background-color: $color-text-sub1;
                    color: #fff;
                }

            }
        }
    }



}


.post-list {
    padding-bottom: 40px;
    @media(min-width:$screen-sm-min) {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 80px;
    }
    .card-post-thumbnail {
        width: 100%;
        margin-bottom: 30px;
        @media(min-width:$screen-sm-min) {
            width: 32%;
            margin-right: 1.5%;
            margin-bottom: 1.5%;
        }
        @media(min-width:$screen-lg-min) {
            width: 338px;
            margin-right: 43px;
            margin-bottom: 40px;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
    }

}

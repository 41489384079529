.list-column-3 {
    justify-content: space-between;
    @media(min-width:$screen-sm-min) {
        display: flex;
    }
    li {
        padding-bottom: 15px;
        @media(min-width:$screen-sm-min) {
            padding-bottom: 0;
            width: 32%;
        }
        @media(min-width:$screen-lg-min) {
            width: 340px;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }

    &.flex-wra{
        flex-wrap: wrap;

        li {
            @media(min-width:$screen-sm-min) {
                margin-bottom: 2%;
            }

            @media(min-width:$screen-lg-min) {
                width: 352px;
                margin-bottom: 20px;
            }
        }
    }

    &.qa {
        display: flex;
        @media(min-width:$screen-sm-min) {
            justify-content: flex-start;
        }
        li {
            padding-bottom: 0;
            width: 48%;
            @media(min-width:$screen-sm-min) {
                width: 32%;
                margin-right: 4%;
            }
            &:last-child {
                @media(min-width:$screen-sm-min) {
                    margin-right: 0;
                }
            }
            &:nth-child(3) {
                display: none;
                @media(min-width:$screen-sm-min) {
                    display: block;
                }
            }




        }
    }
}


.list-column-4 {
    justify-content: space-between;
    @media(min-width:$screen-sm-min) {
        display: flex;
    }
    li {
        padding-bottom: 15px;
        @media(min-width:$screen-sm-min) {
            padding-bottom: 0;
            width: 23%;
        }
        @media(min-width:$screen-lg-min) {
            width: 260px;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }



}

.list-pdf {
    margin-top: 15px;
    border-style: dashed;
    border-color: $color-border-frame;
    border-bottom-width: 1px;
    @media(min-width:$screen-sm-min) {
        margin-top: 20px;
    }
    li {
        border-style: dashed;
        border-color: $color-border-frame;
        border-top-width: 1px;
        padding: 10px 5px;
        @media(min-width:$screen-sm-min) {
            padding: 30px 10px;
        }
        &.indent-1 {
            padding-left: 1em;
            @media(min-width:$screen-sm-min) {
                padding-left: 2em;
            }
        }
        &.indent-2 {
            padding-left: 2em;
            @media(min-width:$screen-sm-min) {
                padding-left: 4em;
            }
        }
    }
    a {
        font-size: 1.6rem;
        color: $color-text-hover;
        line-height: 1.25;
        @media(min-width:$screen-sm-min) {
            font-size: 1.8rem;
        }
    }
    .icon.pdf {
        width: 16px;
        height: 16px;
        @media(min-width:$screen-sm-min) {
            width: 18px;
            height: 18px;
        }
    }
}

.list-btn {
    @media(min-width:$screen-sm-min) {
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        margin-bottom: -20px;
    }
    li {
        margin-bottom: 20px;
        @media(min-width:$screen-sm-min) {
            width: 49%;
        }
        @media(min-width:$screen-lg-min) {
            width: 540px;
        }

    }
}


.list-flow {
    border-top: 3px solid #F0F0F0;
    .item {
        border-bottom: 3px solid #F0F0F0;
        padding: 20px 0;
        position: relative;
        @media(min-width:$screen-sm-min) {
            padding: 50px 0;
        }
        &:last-child {
            &:before {
                display: none;
            }
        }
        &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 14px 0 14px;
            bottom: -14px;
            border-color: #E69497 transparent transparent transparent;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            @media(min-width:$screen-sm-min) {
                border-width: 34px 23px 0 23px;
                bottom: -22px;
            }
        }
    }
    .group {
        @media(min-width:$screen-sm-min) {
            display: flex;
            justify-content: space-between;
        }



        &.no-img {
            display: block;
        }




        .left {
            padding-bottom: 15px;
            @media(min-width:$screen-sm-min) {
                padding-bottom: 0;
                width: 65%;
            }
            @media(min-width:$screen-lg-min) {
                width: 720px;
            }
        }
        .right {
            margin: auto;
            width: 60%;
            text-align: center;
            @media(min-width:$screen-sm-min) {
                width: 26%;
                margin: 0;
            }
            @media(min-width:$screen-lg-min) {
                width: 270px;

            }
        }
        .title {
            font-size: 1.8rem;
            margin-bottom: 10px;
            line-height: 1.5;
            font-weight: bold;
            position: relative;
            padding-left: 38px;
            @media(min-width:$screen-sm-min) {
                font-size: 3.0rem;
                margin-bottom: 25px;
                padding-left: 60px;
            }
            .number {
                width: 28px;
                height: 28px;
                line-height: 28px;
                font-size: 14px;
                display: inline-block;
                text-align: center;
                color: #fff;
                background-color: $color-red;
                font-weight: bold;
                font-family: $font-number;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: -8px;
                bottom: 0;
                margin: auto;
                @media(min-width:$screen-sm-min) {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 2rem;
                }
            }
        }
        .step-title {
            font-size: 1.8rem;
            margin-bottom: 10px;
            line-height: 1.5;
            font-weight: bold;
            position: relative;
            @media(min-width:$screen-sm-min) {
                font-size: 3.0rem;
                margin-bottom: 25px;
                padding-left: 100px;
            }
            .number {
                font-family: $font-number;
                color: $color-red;
                font-weight: bold;
                vertical-align: middle;
                font-size: 14px;
                display: block;
                @media(min-width:$screen-sm-min) {
                    position: absolute;
                    top: -5px;
                    left: 0;
                    display: inline-block;
                    font-size: 1.8rem;
                }
                span {
                    font-size: 2.2rem;
                    vertical-align: middle;
                    display: inline-block;
                    padding-left: 2px;
                    @media(min-width:$screen-sm-min) {
                        padding-left: 5px;
                        font-size: 3.4rem;
                    }
                }
            }


        }
        .l-contact {
            background-color: $color-bg;
            padding: 20px;
            margin-top: 20px;
            text-align: center;
            @media(min-width:$screen-sm-min) {
                text-align: left;
                padding: 30px;
                margin-top: 25px;
            }
            .heading {
                font-size: 1.8rem;
                margin-bottom: 10px;
                font-weight: bold;
                @media(min-width:$screen-sm-min) {
                    font-size: 2.6rem;
                    margin-bottom: 15px;
                }
            }
            .l-tel {
                @media(min-width:$screen-sm-min) {
                    display: flex;
                    align-items: flex-end;
                }
                .number {
                    font-size: 2.4rem;
                    margin-right: 5px;
                    font-weight: bold;
                    font-family: $font-number;
                    @media(min-width:$screen-sm-min) {
                        font-size: 3.8rem;
                        margin-right: 10px;
                    }
                }
                .supplement {
                    font-size: 1.4rem;
                    display: inline-block;
                    padding-top: 10px;
                    @media(min-width:$screen-sm-min) {
                        padding-top: 0;
                        font-size: 1.6rem;
                    }
                }
                .free-dial {
                    width: 25px;
                    height: auto;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 5px;
                    @media(min-width:$screen-sm-min) {
                        width: 35px;
                    }
                }
            }
        }

        .text-parts-1 {
            margin-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 20px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $color-text-hover;
                text-decoration: underline;
                @media(min-width:$screen-md-min) {
                    &:hover {
                        text-decoration: none;
                    }
                }


            }
        }



    }

    .group-box {
        background-color: $color-bg-yellow;
        text-align: center;
        padding: 30px 15px;
        @media(min-width:$screen-sm-min) {
            padding: 40px;
        }
        .l-report {
            display: flex;
            justify-content: center;
            padding-bottom: 20px;
            @media(min-width:$screen-sm-min) {
                padding-bottom: 25px;
            }
            .number {
                padding: 0 10px;
                font-size: 1.8rem;
                font-weight: bold;
                @media(min-width:$screen-sm-min) {
                    padding: 0 20px;
                    font-size: 3.0rem;
                }
                span {
                    font-size: 2rem;
                    color: $color-red;
                    @media(min-width:$screen-sm-min) {
                        font-size: 3.4rem;
                    }
                }
            }
        }
        .l-step {
            background-color: $color-bg-red;
            margin-top: 10px;
            padding: 20px;
            text-align: left;
            @media(min-width:$screen-sm-min) {
                display: flex;
                justify-content: center;
                margin-top: 15px;
                padding: 20px 0;
            }
            li {
                font-size: 1.4rem;
                font-weight: bold;
                padding-bottom: 10px;
                color: $color-red;
                @media(min-width:$screen-sm-min) {
                    padding: 0 17px;
                    font-size: 2rem;
                }

                &:last-child {
                    padding-bottom: 0;

                }
                span {
                    width: 19px;
                    height: 19px;
                    line-height: 19px;
                    font-size: 1rem;
                    margin-right: 5px;
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    color: #fff;
                    background-color: $color-red;
                    font-weight: bold;
                    font-family: $font-number;
                    border-radius: 50%;
                    @media(min-width:$screen-sm-min) {
                        width: 26px;
                        height: 26px;
                        line-height: 26px;
                        font-size: 1.4rem;
                        margin-right: 10px;
                    }
                }
            }
        }
        .inner {
            background-color: #fff;
            margin-top: 20px;
            padding: 20px 15px;
            text-align: left;
            @media(min-width:$screen-sm-min) {
                text-align: center;
                margin-top: 40px;
                padding: 40px 60px;
            }
            .text-parts-1 {
                text-align: left;
                padding: 15px 0 10px;
                @media(min-width:$screen-sm-min) {
                    padding: 20px 0 25px;
                }
            }


            .supplement {
                text-align: left;
                font-size: 1.3rem;
                color: #5C5C5C;
                line-height: 1.5;
                @media(min-width:$screen-sm-min) {
                    font-size: 1.6rem;
                }
                li {
                    padding-bottom: 5px;
                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    .wrap-btn {
        padding-bottom: 0;
    }

}


.list-dot {

    li {
        padding-left: 1em;
        text-indent: -1em;

        &::before {
            content: '・';
        }

        .wrap-btn {
            padding-bottom: 0;
        }
        .btn-round {
            max-width: 740px;
            margin-left: auto;
            margin-right: auto;
        }


        + li {
            margin-top: 10px;
        }

        @media(min-width:$screen-sm-min) {

            + li {
                margin-top: 20px;
            }
        }
    }

    &.small {
        li {
            margin-top: 0;
            font-size: 1.6rem;
            line-height: 1.5;
            padding-bottom: 5px;
            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &.mt-none {
        li + li {
            margin-top: 0;
        }
    }

    // pdfアイコンの表示レイアウトが崩れてしまうため、崩れないように設定を上書き
    .mtli_attachment {
        display: inline;
    }
}


.list-anker-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media(min-width:$screen-sm-min) {
        display: table;
        table-layout:fixed;
    }


    li {
        width: 49%;
        margin-bottom: 2%;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 0;
            width: auto;
            padding-right: 5px;
            display: table-cell;
            vertical-align: top;
        }
        @media(min-width:$screen-md-min) {
            padding-right: 20px;
        }
        &:last-child {
            padding-right: 0;
        }
    }

    .link {
        display: block;
        color: $color-text;
        text-align: center;
        font-size: 13px;
        height: 65px;
        padding: 0 5px 0;
        background-color: $color-bg-yellow;
        border: 1px solid $color-border-frame;
        border-radius: 4px;
        position: relative;
        line-height: 1.25;
        font-weight: bold;
        display: table;
        width: 100%;
        @media(min-width:$screen-sm-min) {
            font-size: 18px;
            height: 110px;
            padding: 0 10px 0;
        }
        @media(min-width:$screen-md-min) {
            transition: all .2s;
            &:hover {
                color: $color-red;
            }
        }
        .inner {
            display: table-cell;
            vertical-align: middle;
            padding-bottom: 5px;
            @media(min-width:$screen-sm-min) {
                padding-bottom: 10px;
            }
        }


        .icon {
            width: 11px;
            height: 11px;
            margin-right: auto;
            margin-left: auto;
            transform: rotate(180deg);
            position: absolute;
            bottom: 5px;
            left: 0;
            right: 0;
            margin-right: auto;
            margin-left: auto;
            @media(min-width:$screen-sm-min) {
                width: 18px;
                height: 18px;
                bottom: 10px;
            }
        }
    }

}


/* .column-pc2-sp1 { */
.list-card-red-heading {
    width: 100%;
    max-width: 1100px;
    margin: auto;
    @media(min-width:$screen-sm-min) {
        display: flex;
        justify-content: space-between;
        align-items:stretch;

    }
}



/*
(1)
用
*/
.list-ol-01 {
    > li {
        padding-left: 34px;
        text-indent: -34px;

        .indent-none {
            padding-left: 0;
            text-indent: 0;
            margin-left: -34px;
        }
    }
}
/*
1.
用
*/
.list-ol-02 {
    > li {
        padding-left: 1.6em;
        text-indent: -1.6em;

        .indent-none {
            padding-left: 0;
            text-indent: 0;
            margin-left: -1.6em;
        }
    }
}

.list-ol-03 {
    > li {
        padding-left: 1em;
        text-indent: -1em;

        .indent-none {
            padding-left: 0;
            text-indent: 0;
            margin-left: -1em;
        }
    }
}

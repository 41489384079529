.l-global-nav {
    display: none;
    background-color: #F0F0F0;
    position: fixed;
    width: 100%;
    top: 61px;
    left: 0;
    padding: 15px 0 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    @media(min-width: $screen-md-min) {
        overflow-y: visible;
        padding: 0;
        display: block !important;
        background-color: transparent;
        position: static;
        height: auto !important;
    }

    &.active {
        @media(max-width:$screen-md-min) {
            display: block;
        }
    }




    .l-list {
        width: 100%;
        margin-top: -8px;
        border-top: 1px solid $color-border;
        margin-top: 15px;
        padding: 15px 0 0;
        @media(min-width: $screen-md-min) {
            border-top: none;
            margin-top: 0;
            padding: 0;
            display: table;
            table-layout: fixed;
        }
        &.sp-list {
            margin-top: 0;
            border-top: 1px solid $color-border-navi;
            padding-top: 0;
            padding-bottom: 30%;
            .link {
                font-size: 1.4rem;
            }
            li {
                &:last-child {
                    .link {
                        border-bottom-width: 1px;
                    }
                }
            }
        }

        .item {
            @media(min-width: $screen-md-min) {
                text-align: center;
                display: table-cell;
                vertical-align: middle;
                padding-bottom: 20px;
                transition: all .5s;
                background-color: #fff;
            }
            &:first-child {
                .link {
                    border-top-width: 0;
                    @media(min-width: $screen-md-min) {
                        border-left-width: 1px;
                    }
                }
            }
            @media(min-width: $screen-md-min) {
        		transition: all .2s;
                &:hover {
                    color: $color-red;
                    .link {
                        color: $color-red;
                        &:after {
                            width: 100%;
                        }
                    }
                    .sub-menu {
                        max-height: 600px;
                        /* opacity: 1; */
                        transition: all 1s;
                        overflow: scroll;
                    }
                }
        	}

            &.current {
                @media(min-width: $screen-md-min) {
                    transition: all 0s;
                    color: $color-red;
                    .link {
                        color: $color-red;
                        transition: all 0s;
                        &:after {
                            width: 100%;
                            transition: all 0s;
                        }
                    }
                }
            }




            .l-list-child {
                &:last-child {
                    padding-bottom: 0;
                }
            }

        }
        .link {
            font-size: 16px;
            color: $color-base;
            display: block;
            position: relative;
            padding: 20px 15px 15px;
            border-style: solid;
            border-top-width: 1px;
            border-color: $color-border;
            font-weight: bold;
            @media(min-width: $screen-md-min) {
                font-size: 18px;
                padding: 10px;
                border-color: $color-border-navi;
                border-top-width: 0;
                border-right-width: 1px;
                transition: all .2s;
            }
            @media(min-width:$screen-lg-min) {
                font-size: 20px;
            }


            &:after {
                @media(min-width: $screen-md-min) {
                    content: '';
                    display: block;
                    width: 0;
                    height: 2px;
                    background-color: $color-red;
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: auto;
                    bottom: -21px;
                    transition: all .2s;
                }
            }
        }
    }

    /* サブメニュー */
    .sub-menu {
        display: none;
        @media(min-width: $screen-md-min) {
            display: block;
            background-color: $color-bg-yellow;
            position: absolute;
            z-index: -1;
            top: 175px;
            left: 0;
            width: 100%;
            text-align: left;
            max-height: 0;
            height: auto;
            overflow: hidden;
            transition: all .2s;
            /* opacity: 0; */
        }
        @media(min-width:$screen-lg-min) {
            top: 185px;
        }


        .inner {
            width: 100%;
            max-width: 1100px;
            margin-right: auto;
            margin-left: auto;
            padding-top: 50px;
            padding-bottom: 68px;
            padding-right: 10px;
            padding-left: 10px;
            @media(min-width:$screen-lg-min) {
                padding-right: 0;
                padding-left: 0;
            }

        }

        .heading-large {
            color: $color-red;
            font-size: 2.4rem;
            font-weight: bold;
        }

        .child-box {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            column-gap: 30px;
            .group {
                border-right: 1px solid #A8A8A8;
                width: 50%;
                padding-right: 50px;
                &.single {
                    width: 100%;
                }
                &:last-child {
                    border-right: none;
                }
            }
        }

        .heading-medium {
            font-size: 2rem;
            color: $color-text;
            padding-bottom: 25px;
            line-height: 1.5;
            font-weight: bold;
            a {
                color: $color-text;
            }
        }

        .l-list-child {
            padding-bottom: 30px;
            li {
                margin-bottom: 25px;
                position: relative;
                padding-left: 12px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .link-child {
                color:$color-text;
                font-size: 1.6rem;
                line-height: 1.25;
            }

            .icon {
                width: 7px;
                height: 7px;
                position: absolute;
                left: 0;
                top: 6px;
            }


        }


    }

}

.sp-navigation-open-bg {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F0F0F0;
    opacity: 0;
    transition: all .2s;
    @media(min-width: $screen-md-min) {
        display: none;
    }


    &.active {
        z-index: 99;
        opacity: 1;

    }

}

.is-corporate-sustainability {

    .sdgs-declaration {
        .card-yellow-light-heading {
            .heading {
                &.heading-color-01 {
                    background-color: #fd9d241c;
                }

                &.heading-color-02 {
                    background-color: #e1f5e3;
                }

                &.heading-color-03 {
                    background-color: #dd136717;
                }
            }
        }
    }

    .sustainability-box {
        display: flex;
        gap: 40px;
        padding: 40px;
        border-radius: 4px;

        @media(max-width:$screen-sm-min) {
            flex-direction: column;
            align-items: center;
            padding: 20px;
        }

        &.box-color-01 {
            background-color: #fd9d241c;
        }

        &.box-color-02 {
            background-color: #e1f5e3;
        }

        &.box-color-03 {
            background-color: #dd136717;
        }

        .box-img-wrap {
            width: 80px;
            height: 80px;

            .e-img {
                border-radius: 1000px;
                border: 5px solid #fff;
            }
        }

        .box-contents {
            width: 100%;

            .heading-wrap {
                display: flex;
                justify-content: space-between;
                gap: 8px 20px;

                @media(max-width:$screen-sm-min) {
                    flex-direction: column;
                    justify-content: left;
                    margin-bottom: 20px;
                }

                .heading-text-small {
                    @media(max-width:$screen-sm-min) {
                        margin-bottom: 5px;
                        text-align: center;
                        font-size: 2.0rem;
                    }
                }

                .icon-wrap {
                    display: flex;
                    gap: 5px;

                    @media(max-width:$screen-sm-min) {
                        justify-content: center;
                    }

                    .e-img {
                        width: 80px;
                    }
                }
            }

            .list-dot {
                font-size: 1.6rem;
                margin-top: 10px;
            }
        }
    }


    .text-box {
        display: flex;
        align-items: center;
        gap: 20px;

        @media(max-width:$screen-xs-min) {
            flex-direction: column;
        }

        .e-img {
            max-width: 192px;
            margin-right: 20px;

            @media(max-width:$screen-xs-min) {
                margin-right: 0;
            }
        }
    }

    .table-01 {
        font-size: 1.3rem;

        @media(max-width:$screen-xs-min) {
            font-size: 1.2rem;
        }

        th {
            padding: 10px;
            text-align: center;
            width: 25%;
            @media(max-width:$screen-xs-min) {
                padding: 6px;
            }
        }
        td {
            padding: 10px;
            @media(max-width:$screen-xs-min) {
                padding: 6px;
            }
        }
    }
    .table-description {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 1.4rem;
        text-indent: initial;
    }

    .list-dot {
        li {
            line-height: 1.5;
            .img-wrap {
                display: inline-flex;
                margin-top: 20px;
            }
        }
    }
}

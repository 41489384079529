.is-corporate-cm-gallery {
    .wrap-content .middle {
        max-width: 840px;
    }
    .btn-wrap {
        text-align: center;
        margin: 120px 0;
        .btn {
            font-size: 2.6rem;
            background-color: $color-red;
            color: #fff;
            padding: 20px;
            border-radius: 10px;
            text-align: center;

            &:hover {
                background-color: #ac0000;
            }
        }

        @media(max-width: $screen-sm-min) {
            .btn {
                font-size: 1.8rem;
            }
        }

        @media(max-width: $screen-xs-min) {
            margin: 80px 0;

            .btn {
                font-size: 1.4rem;
            }
        }

    }
}

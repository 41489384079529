img {
    max-width: 100%;
}

// https://www.daidokasai.co.jp/personal/injury/ で使用
.img-group {
    margin: -15px;
    display: flex;
    // flex-wrap: wrap;

    & > * {
        margin: 15px;
    }
}

.icon {
    display: inline-block;
    vertical-align: initial;
    &.tryangle-right {
        width: 9px;
        height: 9px;
        fill:$color-text-sub1;
    }
    &.home,
    &.key {
        width: 12px;
        height: 12px;
        fill:#fff;
    }

    &.search {
        width: 14px;
        height: 14px;
        fill:$color-text-sub2;
    }

    &.arrow-right,
    &.arrow-left,
    &.arrow-top {
        fill:$color-red;
    }

    &.question {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-family: $font-number;
        font-weight: bold;
        color: #fff;
        font-size: 1.8rem;
        border-radius: 50%;
        background-color: $color-red;
        @media(min-width:$screen-sm-min) {
            font-size: 2.4rem;
            width: 52px;
            height: 52px;
            line-height: 52px;
        }
    }
    &.anser {
        width: 19px;
        height: 19px;
        line-height: 19px;
        text-align: center;
        font-family: $font-number;
        font-weight: bold;
        color: #fff;
        font-size: 1rem;
        border-radius: 50%;
        background-color: $color-text-sub2;
        @media(min-width:$screen-sm-min) {
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 1.8rem;
        }
    }

    &.open-close {
        width: 20px;
        height: 20px;
        top: 15px;
        right: 10px;
        position: absolute;
        border-radius: 4px;
        background-color: $color-border;
        @media(min-width:$screen-sm-min) {
            width: 30px;
            height: 30px;
            top: 25px;
            right: 25px;
        }
        &:before,
        &:after {
            content: '';
            display: block;
            border-radius: 2px;
            background-color: $color-text-sub2;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
        &:before {
            width: 12px;
            height: 2px;
            @media(min-width:$screen-sm-min) {
                width: 18px;
            }
        }
        &:after {
            width: 2px;
            height: 12px;
            transition: all .2s;
            @media(min-width:$screen-sm-min) {
                height: 18px;
            }
        }

        &.active {
            &:after {
                opacity:0;
                transform: rotate(90deg);
            }
        }


    }




    &.window {
        width: 10px;
        height: 10px;
        fill:$color-text-sub1;
        @media(min-width:$screen-sm-min) {
            width: 15px;
            height: 15px;
        }
    }

    &.tel {
        width: 14px;
        height: 14px;
        .object {
            fill:$color-red;
        }
        .bgc {
            fill:#fff;
        }
    }

    &.freee-dial {
        display: inline-block;
        width: 36px;
        height: 27px;
        background-image: url(../img/common/freee-dial.png);
        background-size: cover;
        margin-right: 5px;
        vertical-align: bottom;
        @media(min-width:$screen-sm-min) {
            margin-right: 10px;
            vertical-align: initial;
        }
    }
    &.hidden {
        visibility: hidden;
    }


    &.tryangle-right {
        width: 6px;
        height: 6px;
        fill: #CA101A;
        vertical-align: middle;
        position: relative;
        top: -2px;
    }

    &.premium {
        display: block;
        width: 98px;
        height: 40px;
        background-image: url(../img/support/agency/premium.png);
        background-size: cover;
        margin: 0 0 5px;
        @media(min-width:$screen-sm-min) {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }

    &.pdf {
        width: 18px;
        height: 18px;
        @media(min-width:$screen-sm-min) {
            width: 29px;
            height: 29px;
        }
        .red {
            fill:$color-red;
        }
        .white {
            fill:#fff;
        }
    }

    &.excel {
        fill:#00672E;
    }


    &.exclamation {
        width: 14px;
        height: 14px;
        margin-right: 3px;
        fill:$color-red;

        @media(min-width:$screen-sm-min) {
            vertical-align: sub;
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }

    &.tel {
        width: 18px;
        height: 18px;
        @media(min-width:$screen-sm-min) {
            width: 34px;
            height: 34px;
        }
        .white{
            fill:none;
        }
        .red{
            fill:$color-red;
        }
    }

    &.document,
    &.mail {
        width: 16px;
        height: 16px;
        fill:#fff;
        vertical-align: middle;
        @media(min-width:$screen-sm-min) {
            position: relative;
            top: -2px;
            width: 20px;
            height: 20px;
        }
    }

    &.people {
        fill:$color-green;
    }
    &.building {
        fill:$color-blue;
    }










}

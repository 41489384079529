.is-policy {

    .policy-box {
        @media(min-width:$screen-sm-min) {

        }
        .column {
            margin-bottom: 15px;
        }

        .heading-text-medium {
            margin-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 20px;
            }
        }

    }



}

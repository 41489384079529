.l-sitemap {

    a:hover {
        text-decoration: underline;
    }

    h2 {
        a {
            color: $color-text;

            .arrow-right {
                width: 20px;
                height: 20px;
            }
        }
    }

    div.group {
        margin-bottom: 50px;

        ul {
            li {

                font-size: 1.4rem;
                margin-bottom: 20px;
                line-height: 1.6;

                a {
                    color: $color-text;

                    .arrow-right {
                        width: 11px;
                        height: 11px;
                    }
                }
            }
        }
    }
}

.l-404 {
    div.box-beginning {
        margin-bottom: 90px;

        p.text-box {
            margin-top: 0;
        }
    }
}

@media(min-width:$screen-sm-min) {
    .l-sitemap {
        div.group {
            ul {
                li {
                    font-size: 1.8rem;
                }
            }
        }
    }
}

@media(min-width:$screen-md-min) {
    .l-sitemap {
        div.group {
            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 33.33333%;
                    font-size: 1.8rem;
                }
            }
        }
    }
}

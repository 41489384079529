.card-qa {
	display: block;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid $color-border-frame;
	position: relative;
	padding: 30px 10px 10px;
	height: 100%;
	@media(min-width:$screen-sm-min) {
		padding: 45px 25px 10px;
	}
	@media(min-width:$screen-md-min) {
		&:hover {
			.text {
				color: $color-red;
			}
		}
	}


	.icon {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		top: -18px;
		@media(min-width:$screen-sm-min) {
			top: -24px;
		}
	}
	.text {
		color: $color-text;
		line-height: 1.75;
		font-size: 1.3rem;
		font-weight: bold;
		@media(min-width:$screen-sm-min) {
			font-size: 1.6rem;
		}
		@media(min-width:$screen-md-min) {
			transition: all .2s;

		}
	}
}

.card-pattern1 {
	display: block;
	background-color: $color-bg-yellow;
	border: 1px solid $color-border-frame;
	border-radius: 4px;
	padding: 15px 10px;
	@media(min-width:$screen-sm-min) {
		padding: 25px;
		height: 100%;
	}
	@media(min-width:$screen-md-min) {
		&:hover {
			.text {
				color: $color-red;
			}
		}
	}
	.heading {
		color: $color-red;
		font-size: 1.4rem;
		font-weight: bold;
		@media(min-width:$screen-sm-min) {
			font-size: 1.8rem;
		}
	}
	.icon {
		width: 11px;
		height: 11px;
		margin-left: 10px;
		vertical-align: middle;
		@media(min-width:$screen-sm-min) {
			width: 13px;
			height: 13px;
			margin-left: 25px;
		}
	}
	.text {
		color: $color-text;
		font-size: 1.3rem;
		line-height: 1.75;
		padding-top: 10px;
		font-weight: bold;
		@media(min-width:$screen-sm-min) {
			font-size: 1.4rem;
			padding-top: 15px;
		}
		@media(min-width:$screen-md-min) {
			transition: all .2s;
		}
	}
}

.card-arrow-bottom {
	border: 1px solid $color-border-frame;
	border-radius: 4px;
	background-color: $color-bg-yellow;
	display: block;
	text-align: center;
	color: $color-text;
	font-size: 1.3rem;
	height: 60px;
	padding-top: 16px;
	line-height: 1.5;
	position: relative;
	font-weight: bold;
	@media(min-width:$screen-sm-min) {
		font-size: 1.6rem;
		height: 120px;
		padding-top: 30px;
	}
	@media(min-width:$screen-md-min) {
		// font-size: 1.8rem;
		transition: all .2s;
		&:hover {
			color: $color-red;
		}
	}
	@media(min-width:$screen-lg-min) {
		font-size: 1.8rem;
	}
	.icon {
		width: 11px;
		height: 11px;
		margin-top: 8px;
		transform: rotate(180deg);
		display: block;
		margin-right: auto;
		margin-left: auto;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 10px;
		@media(min-width:$screen-sm-min) {
			width: 18px;
			height: 18px;
			bottom: 25px;

		}
	}
}

.card-pdf {
	display: block;
	border: 1px solid $color-border-frame;
	border-radius: 4px;
	background-color: $color-bg-yellow;
	padding: 0 15px;
	font-weight: bold;
	@media(min-width:$screen-sm-min) {
		padding: 0 20px;
	}
	a {
		position: relative;
		display: block;
		padding: 17px 30px 17px 0;
		font-size: 1.3rem;
		color: $color-text;
		line-height: 1.5;
		@media(min-width:$screen-sm-min) {
			padding: 22px 40px 22px 0;
			font-size: 1.6rem;
		}
		@media(min-width:$screen-md-min) {
			transition: all .2s;
			&:hover {
				color: $color-red;
			}
		}
	}

	.icon {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
	}

	.l-supplement {
		border-top: 1px dashed $color-border-frame;
	}
}

.card-red-heading {
	text-align: center;
	margin-bottom: 20px;
	border-radius: 4px;
	border-style: solid;
	border-color: $color-border-frame;
	border-bottom-width: 1px;
	border-top-width: 1px;
	border-left-width: 1px;
	border-right-width: 1px;
	background-color: #fff;
	@media(min-width:$screen-sm-min) {
		width: 49%;
		margin-bottom: 0;
	}
	@media(min-width:$screen-lg-min) {
		width: 530px;
	}
	&:last-child {
		padding-bottom: 0;
	}
	&.w-100 {
		width: 100%;
		text-align: left;
	}

	.title {
		border: solid 1px;
		border-radius: 4px;
		margin-bottom: 20px;
		padding: 10px;
		font-weight: 700;

		@media(min-width:$screen-sm-min) {
			margin-bottom: 20px;
			font-size: 1.4rem;
		}
	}
	.sub-title {
		font-size: 2.4rem;
		font-weight: 700;
		margin-top: 20px;
	}

	.heading {
		text-align: center;
		background-color: $color-red;
		color: #fff;
		font-size: 1.5rem;
		padding: 13px 10px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		font-weight: bold;
		@media(min-width:$screen-md-min) {
			font-size: 2.4rem;
			padding: 17px 10px;
		}

		.text-parts-small {
			margin-top: 10px;
			color: #fff;
			display: block;
		}
	}
	.time {
		padding-top: 10px;
		font-size: 1.2rem;
	    line-height: 1.5;
	    text-align: center;
	    @media(min-width:$screen-sm-min) {
	        font-size: 1.6rem;
	    }
	}

	.card-red-contact {
		background-color: #fff;
		padding-top: 25px;
		padding-bottom: 25px;
		padding-right: 10px;
		padding-left: 10px;
		@media(min-width:$screen-sm-min) {
			padding-top: 30px;
			padding-bottom: 30px;
			padding-right: 20px;
			padding-left: 20px;
		}
	}

	.text-parts-1,
	.text-parts-2 {
		margin-bottom: 10px;
		@media(min-width:$screen-sm-min) {
			margin-bottom: 15px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}

	.btn-round {
		width: 90%;
		margin-right: auto;
		margin-left: auto;
		margin-top: 10px;
		@media(min-width:$screen-sm-min) {
			width: 100%;
			margin-top: 20px;
		}
	}


	.l-number {
		.number {
			font-family: $font-number;
			font-weight: 900;
			font-size: 30px;
			@media(min-width:$screen-sm-min) {
				font-size: 38px;
			}
			@media(min-width:$screen-md-min) {
				font-size: 50px;
			}
		}
		.number-en {
			font-family: $font-number;
			font-weight: 900;
			font-size: 20px;
			@media(min-width:$screen-sm-min) {
				font-size: 28px;
			}
			@media(min-width:$screen-md-min) {
				font-size: 36px;
			}
		}
	}

	.group {
		border-bottom: 1px solid #d1d1d1;
		padding-bottom: 20px;
	}

	.number-en-group {
		display: inline-block;
		text-align: left;
	}
	.caption-text-2 {
		text-align: center;
		line-height: 1.5;
		font-size: 14px;
		font-weight: bold;
	}




}

.card-post-thumbnail {
	display: block;
	border: 1px solid $color-border-frame;
	border-radius: 4px;
	background-color: #fff;
	@media(min-width:$screen-md-min) {
		&:hover {
			.img {
				&:before {
					opacity:0.3;
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
					overflow: hidden;
				}
			}
			.title {
				color: $color-red-light;
			}
			.text {
				text-decoration: underline;
			}
		}
	}




	.img {
		width: 100%;
		padding-top: 58.04%;
		position: relative;
		background-image: url(../img/column/noimage.png);
		background-size: cover;
		background-position: center;
		background-color: #fff;
		position: relative;
		&:before {
			content: '';
			display: block;
			background-color: #fff;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			transition: all .2s;
			opacity: 0;

		}
	}
	.l-text {
		padding: 15px 15px 8px;
		background-color: #fff;
		@media(min-width:$screen-sm-min) {
			padding: 25px 20px 8px;
		}
	}


	.title {
		font-size: 1.8rem;
		padding-bottom: 5px;
		line-height: 1.5;
		color: $color-text;
		transition: all .2s;
		font-weight: bold;
		@media(min-width:$screen-sm-min) {
			font-size: 2rem;
			padding-bottom: 10px;
		}
	}
	.text {
		font-size: 1.4rem;
		padding-bottom: 5px;
		line-height: 1.5;
		color: $color-text-sub1;
		@media(min-width:$screen-sm-min) {
			font-size: 1.6rem;
			padding-bottom: 10px;
		}
	}
	.date {
		font-family: $font-number;
		color: $color-text-sub1;
		font-size: 1.3rem;
		padding-bottom: 5px;
		@media(min-width:$screen-sm-min) {
			font-size: 1.5rem;
			padding-bottom: 10px;
		}
	}

	.tags-list {
		font-size: 0;

		li {
			display: inline-block;
			border: 1px solid $color-text-sub1;
			color: $color-text-sub1;
			text-align: center;
			border-radius: 2px;
			margin-right: 4px;
			margin-bottom: 4px;
			padding: 5px 5px;
			font-size: 1rem;
			@media(min-width:$screen-sm-min) {
				font-size: 1.2rem;
				margin-right: 6px;
				margin-bottom: 6px;
			}
		}
	}





}


.card-support {
	height: 100%;
	text-align: center;
	background-color: $color-bg-yellow;
	border: 1px solid $color-border-frame;
	padding: 20px 15px;
	@media(min-width:$screen-sm-min) {
		padding: 30px 25px;
	}
	.heading {
		background-color: $color-red;
		color: #fff;
		font-size: 1.4rem;
		height: 28px;
		line-height: 28px;
		border-radius: 4px;
		display: inline-block;
		width: 100px;
		font-weight: bold;
		@media(min-width:$screen-sm-min) {
			width: 120px;
			font-size: 1.8rem;
			height: 36px;
			line-height: 36px;
		}
	}
	.text {
		font-size: 1.8rem;
		padding: 20px 0 15px;
		line-height: 1.5;
		font-weight: bold;
		@media(min-width:$screen-sm-min) {
			font-size: 2.2rem;
			padding: 25px 0 20px;
		}
		@media(min-width:$screen-md-min) {
			font-size: 2.4rem;
		}
	}
	.small-text {
		font-size: 1.2rem;
	    line-height: 1.5;
	    color: #5C5C5C;
		display: block;
		text-align: center;
		margin-top: 5px;
		font-weight: normal;
	    @media(min-width:$screen-sm-min) {
			margin-top: 10px;
	        font-size: 1.6rem;
	    }
	}

	.wrap {
		&.double {
			@media(min-width:$screen-sm-min) {
				display: flex;
				justify-content: space-between;
			}
			a {
				margin-bottom: 15px;
				@media(min-width:$screen-sm-min) {
					margin-bottom: 0;
					width: 48%;
				}
				@media(min-width:$screen-lg-min) {
					width: 220px;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.btn-round {
		font-size: 1.3rem;
		@media(min-width:$screen-sm-min) {
			font-size: 1.8rem;
		}

		&:last-child {
			margin-top: 10px;
			@media(min-width:$screen-sm-min) {
				margin-top: 15px;
			}
		}
	}
}


.card-red-light-heading {
	border: 1px solid $color-border-frame;
	border-radius: 4px;
	margin-bottom: 20px;
	@media(min-width:$screen-sm-min) {
		width: 48%;
		margin-bottom: 0;
	}
	@media(min-width:$screen-lg-min) {
		width: 530px;
	}
	&:last-child {
		margin-bottom: 0;
	}
	&.w-100 {
		width: 100%;
	}
	.m-link-pdf {
		font-weight: normal;
		@media(max-width:$screen-lg-min) {
			font-size: 1.6rem;
		}
	}

	.heading {
		font-size: 1.8rem;
		font-weight: bold;
		line-height: 1.5;
		padding: 15px;
		border-radius: 4px;
		background-color: $color-bg-red;
		@media(min-width:$screen-sm-min) {
			font-size: 2.2rem;
			padding: 18px 25px;
		}
	}
	.l-text {
		padding: 15px;
		@media(min-width:$screen-sm-min) {
			padding: 25px;
		}

		.layout {
			@media(min-width:$screen-sm-min) {
				display: flex;
				justify-content: space-between;
			}
			.left {
				@media(min-width:$screen-sm-min) {
					width: 60%;
				}
				@media(min-width:$screen-lg-min) {
					width: 660px;
				}
			}
			.right {
				width: 70%;
				margin: 15px auto 0;
				@media(min-width:$screen-sm-min) {
					margin: 0;
					width: 35%;
				}
				@media(min-width:$screen-lg-min) {
					width: 340px;
				}

			}
		}


	}

	.card-pdf {
		max-width: 540px;
		margin-bottom: 20px;
		@media(min-width:$screen-sm-min) {
			margin-bottom: 30px;
		}
	}

	.text-parts-1,
	.text-parts-small {
		padding-bottom: 10px;
		@media(min-width:$screen-sm-min) {
			padding-bottom: 20px;
		}
	}
	.text-parts-1 {
		font-weight: bold;
	}
	ul {
		padding-bottom: 10px;
		font-weight: normal;
		font-weight: bold;
		@media(min-width:$screen-sm-min) {
			padding-bottom: 20px;
		}
		li {
			position: relative;
			font-size: 1.2rem;
			padding-left: 10px;
			line-height: 1.5;
			margin-bottom: 3px;
			@media(min-width:$screen-sm-min) {
				font-size: 1.6rem;
				padding-left: 10px;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&:before {
				content: '';
				display: block;
				width: 4px;
				height: 4px;
				background-color: $color-text;
				border-radius: 50%;
				position: absolute;
				top: 7px;
				left: 0;
				@media(min-width:$screen-sm-min) {
					top: 10px;
				}
			}
		}
	}

	.text-parts-1,
	.text-parts-small,
	ul {
		&:last-child {
			padding-bottom: 0;
		}
	}

}

.card-yellow-light-heading {
	border: 1px solid $color-border-frame;
	margin-bottom: 20px;
	@media(min-width:$screen-sm-min) {
		width: 48%;
		margin-bottom: 0;
	}
	@media(min-width:$screen-lg-min) {
		width: 530px;
	}
	&:last-child {
		margin-bottom: 0;
	}
	&.w-100 {
		width: 100%;

	}

	.heading {
		font-size: 1.8rem;
		font-weight: bold;
		line-height: 1.5;
		padding: 15px;
		background-color: $color-bg-yellow;
		@media(min-width:$screen-sm-min) {
			font-size: 2.2rem;
			padding: 18px 25px;
		}
	}
	.l-text {
		padding: 15px;
		@media(min-width:$screen-sm-min) {
			padding: 25px;
		}

		.layout {
			@media(min-width:$screen-sm-min) {
				display: flex;
				justify-content: space-between;
			}
			.left {
				@media(min-width:$screen-sm-min) {
					width: 60%;
				}
				@media(min-width:$screen-lg-min) {
					width: 660px;
				}
			}
			.right {
				width: 70%;
				margin: 15px auto 0;
				@media(min-width:$screen-sm-min) {
					margin: 0;
					width: 35%;
				}
				@media(min-width:$screen-lg-min) {
					width: 340px;
				}

			}
		}


	}

	.card-pdf {
		max-width: 540px;
		margin-bottom: 20px;
		@media(min-width:$screen-sm-min) {
			margin-bottom: 30px;
		}
	}

	.text-parts-1,
	.text-parts-small {
		padding-bottom: 10px;
		@media(min-width:$screen-sm-min) {
			padding-bottom: 20px;
		}
	}
	ul {
		padding-bottom: 10px;
		@media(min-width:$screen-sm-min) {
			padding-bottom: 20px;
		}
		li {
			position: relative;
			font-size: 1.2rem;
			padding-left: 10px;
			line-height: 1.5;
			margin-bottom: 3px;
			@media(min-width:$screen-sm-min) {
				font-size: 1.6rem;
				padding-left: 10px;
			}
			&:last-child {
				margin-bottom: 0;
			}
			&:before {
				content: '';
				display: block;
				width: 4px;
				height: 4px;
				background-color: $color-text;
				border-radius: 50%;
				position: absolute;
				top: 7px;
				left: 0;
				@media(min-width:$screen-sm-min) {
					top: 10px;
				}
			}
		}
	}

	.text-parts-1,
	.text-parts-small,
	ul {
		&:last-child {
			padding-bottom: 0;
		}
	}

}

.breadcrumb {
	.l-list {
		font-size: 0;
		padding-top: 15px;
		li {
			font-size: 1.3rem;
			color: $color-text;
			position: relative;
			padding-bottom: 15px;
			padding-right: 20px;
			margin-right: 20px;
			display: inline-block;
			&:after {
				content: '';
				display: block;
				width: 6px;
			    height: 6px;
			    border: 1px solid;
			    border-color: $color-border-navi $color-border-navi transparent transparent;
			    transform: rotate(45deg);
				position: absolute;
				top: 0;
				right: 0;
			}
			&:last-child {
				padding-right: 0;
				margin-right: 0;
				&:after {
					display: none;
				}
			}
			a {
				color: $color-text;
			}
		}
	}
}

.is-corporate {

    .l-intro-box {
        background-color: $color-bg-yellow;
        padding: 45px 15px 50px;
        text-align: center;
        @media(min-width:$screen-sm-min) {
            padding: 90px 20px 100px;
        }
        .heading-text-largest {
            margin-bottom: 35px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 70px;
            }
        }
        .img {
            width: 180px;
            margin-top: 30px;
            margin-right: auto;
            margin-left: auto;
            @media(min-width:$screen-sm-min) {
                width: 240px;
                margin-top: 70px;
            }
        }
    }

    .corporate-card-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media(min-width:$screen-sm-min) {
            margin-bottom: -40px;
        }
        li {
            width: 48%;
            margin-bottom: 4%;
            @media(min-width:$screen-sm-min) {
                width: 32%;
            }
            @media(min-width:$screen-lg-min) {
                width: 340px;
                margin-bottom: 40px;
            }
        }

        .corporate-card {
            display: block;
            color: $color-text;
            @media(min-width:$screen-md-min) {
                &:hover {
                    .img {
                        &:before {
            				opacity:0.2;
            			}
                    }
                    .text {
                        color: $color-red;
                    }

                }
            }

            .img {
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    opacity: 0;
                    transition: all .2s;
                }
            }

            .text {
                font-size: 1.4rem;
                margin-top: 10px;
                display: block;
                line-height: 1.5;
                font-weight: bold;
                @media(min-width:$screen-sm-min) {
                    font-size: 2rem;
                    margin-top: 15px;
                }
                @media(min-width:$screen-md-min) {
                    font-size: 2.2rem;
                }
                @media(min-width:$screen-md-min) {
                    transition: all .2s;
                }

            }
            .icon {
                width: 10px;
                height: 10px;
                vertical-align: middle;
                position: relative;
                top: -2px;
                @media(min-width:$screen-sm-min) {
                    width: 16px;
                    height: 16px;
                    top: -1px;
                }
            }

        }




    }

    .corporate-recruit-column {
        margin-bottom: 40px;
        @media(min-width:$screen-sm-min) {
            margin-bottom: 50px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        .inner {
            @media(min-width:$screen-sm-min) {
                display: flex;
                justify-content: space-between;
            }
        }
        .left {
            @media(min-width:$screen-sm-min) {
                width: 48%;
            }
            @media(min-width:$screen-lg-min) {
                width: 530px;
            }
        }
        .right {
            position: relative;
            margin-top: 10px;
            @media(min-width:$screen-sm-min) {
                margin-top: 0;
                width: 48%;
            }
            @media(min-width:$screen-lg-min) {
                width: 530px;
            }
        }
        .heading-text-large {
            margin-bottom: 10px !important;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 20px !important;
            }
        }
        .m-btn {
            padding-top: 15px;
            @media(min-width:$screen-lg-min) {
                padding-top: 0;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }


    }

    .about-box01 {
        @media(min-width:$screen-sm-min) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
        }
        .l-img {
            width: 50%;
            margin: auto;
            @media(min-width:$screen-sm-min) {
                width: 30%;
                margin: 0;
            }

            @media(min-width:$screen-lg-min) {
                width: 245px;
            }

        }
        .l-text {
            margin-top: 15px;
            @media(min-width:$screen-sm-min) {
                margin-top: 0;
                width: 64%;
            }
            @media(min-width:$screen-lg-min) {
                width: 760px;
            }
        }
    }

    .about-box02 {
        margin: 20px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        @media(min-width:$screen-sm-min) {
            width: auto;
            margin: 30px 0 0;
        }
        .left {
            margin-right: 20px;
            @media(min-width:$screen-sm-min) {
                width: 181px;
                margin-right: 30px;
            }
            @media(min-width:$screen-md-min) {
                margin-right: 70px;
            }
        }
        .right {
            @media(min-width:$screen-sm-min) {
                width: 340px;
            }
        }
    }

    .about-box03 {
        @media(min-width:$screen-sm-min) {
            display: flex;
            justify-content: space-between;
        }
        .left {
            @media(min-width:$screen-sm-min) {
                width: 60%;
            }
            @media(min-width:$screen-lg-min) {
                width: 665px;
            }
        }
        .right {
            margin-top: 15px;
            text-align: center;
            @media(min-width:$screen-sm-min) {
                width: 34.5%;
                margin-top: 0;
            }
            @media(min-width:$screen-lg-min) {
                width: 380px;
            }
        }
    }

    .recruit-site-list {
        display: flex;
        flex-wrap: wrap;
        @media(min-width:$screen-sm-min) {
        }
        li {
            padding: 10px;
            background-color: $color-bg;
            margin-bottom: 4%;
            margin-right: 4%;
            width: 48%;
            @media(min-width:$screen-sm-min) {
                padding: 20px;
                width: 32%;
                margin-bottom: 0;
                margin-right: 2%;
                margin-bottom: 2%;
            }
            @media(min-width:$screen-lg-min) {
                width: 346px;
                margin-right: 20px;
                margin-bottom: 20px;
            }
            &:nth-child(2n) {
                @media(max-width:$screen-xs-min) {
                    margin-right: 0;
                }
            }



            &:last-child {
                @media(min-width:$screen-sm-min) {
                    margin-right: 0;
                }
            }
        }
        .link {
            display: block;
            position: relative;
            @media(min-width:$screen-md-min) {
        		&:hover{
        			&:before {
        				opacity:0.2;
        			}
        		}
        	}

            &:before {
        		content: '';
        		display: block;
        		width: 100%;
        		height: 100%;
        		background-color: #fff;
        		position: absolute;
        		top: 0;
        		left: 0;
        		z-index: 1;
        		opacity: 0;
        		transition: all .2s;
        	}

        }

    }

    .knowledge-flow {
        line-height: 1.5;
        margin-top: 20px;
        @media(min-width:$screen-md-min) {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
        }
        .box-start {
            color: $color-red;
            text-align: center;
            background-color: $color-bg-red;
            border-radius: 2px;
            font-size: 1.6rem;
            width: 100%;
            display: table;
            font-weight: bold;
            @media(min-width:$screen-md-min) {
                font-size: 1.8rem;
                width: 140px;
                height: 100px;
            }
            .inner {
                display: table-cell;
                vertical-align: middle;
                padding: 10px;
            }
        }
        .box-flow {
            position: relative;
            width: 100%;
            background-color: $color-bg-yellow;
            border-radius: 2px;
            font-size: 1.4rem;
            position: relative;
            display: table;
            text-align: center;
            margin-top: 40px;
            font-weight: bold;
            @media(min-width:$screen-md-min) {
                margin-top: 0;
                width: 180px;
                font-size: 1.6rem;
                height: 100px;

            }
            &:before {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 0 7px 10px;
                border-color: transparent transparent transparent #E69497;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: -30px;
                transform: rotate(90deg);
                @media(min-width:$screen-md-min) {
                    top: 0;
                    bottom: 0;
                    left: -12%;
                    right: auto;
                    border-width: 10px 0 10px 14px;
                    transform: rotate(0deg);
                }
            }
            .number {
                background-color: #E69497;
                color: #fff;
                border-radius: 50%;
                text-align: center;
                font-size: 14px;
                width: 28px;
                height: 28px;
                line-height: 28px;
                font-family: $font-number;
                display: block;
                position: absolute;
                top: -12px;
                left: -12px;
                @media(min-width:$screen-md-min) {
                    font-size: 1.6rem;
                    width: 34px;
                    height: 34px;
                    line-height: 34px;
                    top: -16px;
                    left: -16px;
                }
            }
            .content {
                display: table-cell;
                vertical-align: middle;
                padding: 10px;
            }
        }
    }

    .knowledge-equation {
        .equation-inner {
            background-color: $color-bg-yellow;
            align-items: center;
            padding: 20px 10px;
            margin: 15px 0;
            @media(min-width:$screen-sm-min) {
                display: flex;
                justify-content: center;
                padding: 30px 10px;
                margin: 30px 0;
            }

        }
        .text {
            font-size: 1.6rem;
            font-weight: bold;
            margin-bottom: 10px;
            @media(min-width:$screen-sm-min) {
                margin-bottom: 0;
                font-size: 2rem;
                margin-right: 20px;
            }
            @media(min-width:$screen-lg-min) {
                margin-right: 70px;
            }
        }
        .img {
            width: 100%;
            @media(min-width:$screen-sm-min) {
                width: 430px;

            }
        }





    }

    .sa-img-box {
        @media(min-width:$screen-sm-min) {
            display: flex;
            justify-content: space-between;
        }
        .column {
            margin-bottom: 15px;
            @media(min-width:$screen-sm-min) {
                width: 48%;
                margin-bottom: 0;
            }
            @media(min-width:$screen-lg-min) {
                width: 530px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .sa-interview {
        @media(min-width:$screen-sm-min) {
            display: flex;
            align-items: flex-end;
        }
        .img {
            width: 70%;
            margin: 0 auto 15px;
            @media(min-width:$screen-sm-min) {
                margin: 0;
                margin-right: 30px;
                width: 360px;
            }
        }
        .box-gray {
            margin-bottom: 0;
            @media(min-width:$screen-sm-min) {
                width: 324px;
            }
        }





    }

    .recovery-list {
        border-style: dashed;
        border-color: $color-border-frame;
        border-bottom-width: 1px;
        li {
            border-style: dashed;
            border-color: $color-border-frame;
            border-top-width: 1px;
            padding: 10px 5px;
            @media(min-width:$screen-sm-min) {
                padding: 30px 10px;
            }
            dl {
                display: flex;
                align-items: center;
                dt {
                    font-size: 1.8rem;
                    font-family: $font-number;
                    color: $color-text-sub1;
                    line-height: 1.75;
                    width: 34%;
                    @media(min-width:$screen-sm-min) {
                        width: 18%;
                    }
                    @media(min-width:$screen-md-min) {
                        width: 11%;
                    }
                }
                dd {
                    width: 66%;
                    @media(min-width:$screen-sm-min) {
                        width: 82%;
                    }
                    @media(min-width:$screen-md-min) {
                        width: 89%;
                    }
                    .link {
                        color: $color-text-hover;
                    }
                }
            }
        }

    }

    .brand-logo-box {
        .img {
            text-align: center;
        }

    }

    .brand-color {
        .img {
            width: 50%;
            @media(max-width: 767px) {
                width: 100%;
            }
        }
    }

    .monument-box01 {
        @media(min-width:$screen-sm-min) {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
        }
        .l-img {
            width: 50%;
            margin: auto;
            @media(min-width:$screen-sm-min) {
                width: 30%;
                margin: 0;
            }

            @media(min-width:$screen-lg-min) {
                width: 245px;
            }

        }
        .l-text {
            margin-top: 15px;
            @media(min-width:$screen-sm-min) {
                margin-top: 0;
                width: 64%;
            }
            @media(min-width:$screen-lg-min) {
                width: 760px;
            }
        }
    }

}

.is-corporate-privacy {
    a {
        color: $color-text-hover;
        @media(min-width:$screen-md-min) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

}

.about-character {
    border: 2px solid #EA5505;
    border-radius: 40px;
    border-style: dashed;
    padding: 40px;
    display: inline-block;
    text-align: center;

    .heading-text-medium {
        border-bottom: 2px solid #EA5505;
        border-style: dashed;
        display: inline-block;
        padding: 0 40px;
    }
}

.line-account {
    .column-pc2-sp1 {
        text-align: center;

        .column {
            .img {
                img {
                    width: 100%;
                }
                @media(max-width: $screen-md-min) {
                    width: 50%;
                    display: inline-block;
                }
            }
        }
    }
}

.day-go-apps {
    .wrap-content{
        .img {
            a {
                img {
                    width: 100%;
                }
            }
        }
    }
}

